import gql from 'graphql-tag';

export const GET_ARTICLE = gql`
  query Article($id: MongoID!) {
    article(_id: $id) {
      _id
      headline
      description
      body
      author
      imageUrl
      publishedAt
      published
      updatedAt
    }
  }
`;
