import React from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './NotificationMessage.module.scss';
import { UserNotification, useUpdateUserNotificationByIdMutation } from 'generated/graphql';
import { GET_USERNOTIFICATIONS } from 'graphql/queries';
import moment from 'moment';

interface Props {
  userNotification: UserNotification | undefined | null;
  iconClass?: string;
  isNew?: Boolean;
}

const NotificationMessage: React.FC<Props> = ({ userNotification, iconClass, isNew = false }) => {
  const [userNotificationUpdateById] = useUpdateUserNotificationByIdMutation();

  const toggleIsReadStatus = () => {
    userNotificationUpdateById({
      variables: {
        id: userNotification && userNotification._id,
        isRead: !(userNotification && userNotification.isRead),
      },
      refetchQueries: [{ query: GET_USERNOTIFICATIONS }],
    });
  };

  return userNotification ? (
    <div styleName={`notification${userNotification.isRead ? '' : '-unread'}`} className="animated fadeIn">
      <div styleName="notification-message">
        {/* {!!iconClass && <i className={iconClass} />} */}
        <div styleName="heading">{userNotification.notification && userNotification.notification.headline}:</div>
        <div className="content">
          {userNotification.notification && userNotification.notification.description}
          <div className="mt-2">
            {userNotification.notification?.link && <Link to={userNotification.notification.link}>Read more</Link>}
          </div>
          <div styleName="date" className="mt-2">
            {userNotification.receivedAt && `${moment(userNotification.receivedAt).fromNow()}`}
          </div>
        </div>
      </div>
      <div styleName="notification-action" className="text-blue" onClick={() => toggleIsReadStatus()}>
        {userNotification.isRead ? (
          <>
            <i className="far fa-circle"></i>
            <div styleName="tooltip">Mark as Unread</div>
          </>
        ) : (
          <>
            <i className="fas fa-circle"></i>
            <div styleName="tooltip">Mark as Read</div>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default CSSModules(NotificationMessage, styles);
