import gql from 'graphql-tag';

export const GET_COMMERCE_APP_USERS_BY_CONTEXT = gql`
  query CommerceAppUsersByContext(
    $contextId: String!
    $userIds: [String]
    $search: String
    $page: Int!
    $pageSize: PageSize!
  ) {
    commerceAppUsersByContext(
      contextId: $contextId
      userIds: $userIds
      search: $search
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        page
        pageSize
        pageCount
        hasNextPage
        hasPreviousPage
      }
      count
      result {
        id
        contextId
        email
        customerCode
      }
    }
  }
`;
