import React from 'react';
import Iframe from 'react-iframe';
import { View } from 'ui';
import styles from './Playground.module.scss';

const Playground = () => {
  return (
    <View sleek={true} iconClass="fas fa-toolbox">
      <Iframe
        url={process.env.REACT_APP_NWG_API_PLAYGROUND_URL!}
        id="myId"
        className={styles.playground}
        position="relative"
        scrolling="no"
        frameBorder={0}
      />
    </View>
  );
};

export default Playground;
