import gql from 'graphql-tag';

export const GET_APPLICATIONS_BY_USER_ID = gql`
  query ApplicationsByUserId($userId: String) {
    applicationsByUserId(userId: $userId) {
      _id
      name
      userId
      companyCode
      commerceApp {
        name
      }
      commerceAppUser {
        email
      }
      active
      token
      tokenExp
      tokenExpiration
      deletedAt
      user {
        _id
        username
        pricelists {
          pricelist
          companyCode
          currency
        }
      }
      complexityLimit
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
  }
`;
