import React, { useEffect, useState } from 'react';

import { ListType, Pagination, ListModeSelector } from 'ui';
import { ProductBox } from 'app/components';
import ReactTable from 'react-table';
import Skeleton from 'react-loading-skeleton';
import { SearchProductsQueryVariables, useSearchProductsQuery } from 'generated/graphql';
import { toast } from 'react-toastify';

const columns = [
  {
    Header: 'Article',
    accessor: 'productNumber',
  },
  {
    Header: 'Name',
    accessor: 'productName',
  },
  {
    Header: 'Brand',
    accessor: 'productBrand',
  },
];

const listDefaultSizes = {
  [ListType.CardLarge]: 8,
  [ListType.CardSmall]: 18,
  [ListType.List]: 20,
};

type ProductListProps = {
  queryVariables?: SearchProductsQueryVariables | null;
};

const ProductList: React.FC<ProductListProps> = ({ queryVariables }) => {
  const [page, setPage] = useState(1);
  const [listType, setListType] = useState(ListType.CardLarge);
  const [pageSize, setPageSize] = useState(listDefaultSizes[listType]);
  const [pageCount, setPageCount] = useState(1);

  const { data, loading, refetch } = useSearchProductsQuery({
    variables: {
      ...queryVariables!,
      page: page,
      pageSize: pageSize,
    },
    skip: !queryVariables || !queryVariables.assortmentId,
  });

  useEffect(() => {
    if (queryVariables) refetch(queryVariables);
  }, [queryVariables, refetch]);

  useEffect(() => {
    if (data && data.productSearch) {
      if (data.productSearch.error) {
        toast.error(data.productSearch.error.message);
      } else {
        setPageCount(data.productSearch.pageInfo!.pageCount || 1);
        if (page > pageCount) setPage(data.productSearch.pageInfo!.pageCount);
      }
    }
  }, [data, setPageCount, page, pageCount, setPage]);

  useEffect(() => {
    setPageSize(listDefaultSizes[listType]); // TODO
  }, [listType, setPageSize]);

  return (
    <>
      <div className="row mt-2 pr-2">
        <div className="col-12 d-flex justify-content-between align-items-end">
          {data?.productSearch?.count && <label>{data.productSearch.count} products</label>}
          <div>
            <ListModeSelector current={listType} onSelect={setListType}></ListModeSelector>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-12">
          {listType === ListType.List ? (
            <ReactTable
              loading={loading}
              className="w-100"
              data={data && data.productSearch!.result!}
              columns={columns}
              defaultPageSize={pageSize}
              manual
              sortable={false}
              page={page - 1}
              pageSize={pageSize}
              pages={pageCount}
              onPageChange={(newIndex) => {
                setPage(newIndex + 1);
              }}
              onPageSizeChange={(newSize, newIndex) => {
                setPage(newIndex + 1);
                setPageSize(newSize);
              }}
              getTheadProps={() => ({
                style: {
                  boxShadow: 'none',
                  borderBottom: '1px solid rgba(0,0,0,0.02)',
                  background: 'rgba(0,0,0,0.05)',
                },
              })}
              getPaginationProps={() => ({
                style: {
                  boxShadow: 'none',
                },
              })}
            />
          ) : loading && !data ? (
            <Skeleton height={350} count={2} />
          ) : (
            <div className="d-flex flex-column contain-height justify-content-between">
              <div className="row px-2">
                {data?.productSearch?.result &&
                  data.productSearch.result.map((product) => {
                    return (
                      product && (
                        <ProductBox
                          key={product.productNumber || ''}
                          product={product}
                          colWidth={listType === ListType.CardLarge ? 3 : 2}
                        ></ProductBox>
                      )
                    );
                  })}
              </div>
              {pageCount > 1 && (
                <div className="row mt-4">
                  <div className="col-12">
                    <Pagination
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      forcePage={page - 1}
                      onPageChange={({ selected }) => {
                        setPage(selected + 1);
                      }}
                    ></Pagination>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductList;
