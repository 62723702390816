import React from 'react';
import { Select } from 'ui';
import Skeleton from 'react-loading-skeleton';
import { UpdateUserByIdMutationVariables, useServiceContainersQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';

type Props = {
  defaultValues: Partial<UpdateUserByIdMutationVariables>;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionServiceAccess: React.FC<Props> = ({ defaultValues, setValue, register }) => {
  const { data, loading, error } = useServiceContainersQuery();

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading service containers: <br />
        {error.message}
      </div>
    );
  }

  const options = data?.serviceContainers?.map((service) => ({
    value: service?._id!,
    label: service?.name!,
  }));

  const handleChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setValue('serviceContainerIds', [], false);
    } else {
      const ids = values.map((val: OptionTypeBase) => val.value);
      setValue('serviceContainerIds', ids, false);
    }
  };

  register({ name: 'serviceContainerIds' });

  return (
    <>
      <Select
        isMulti
        label="Service containers"
        name="serviceContainerIds"
        defaultValue={options?.filter((option) => defaultValues?.serviceContainerIds?.includes(option.value))}
        onChange={(value) => handleChange(value)}
        options={options}
      />
    </>
  );
};

export default FormSectionServiceAccess;
