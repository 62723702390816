import React, { useState, useEffect } from 'react';
import { useConfirmUserMutation } from 'generated/graphql';
import { Alert, StateButton } from 'ui';
import styles from './UserConfirm.module.scss';
import logo from 'media/logo.svg';
import { useUserDataQuery } from 'generated/graphql';

interface Inputs {
  password: string;
  passwordRepeat: string;
}

interface InputError {
  error: boolean;
  message: string;
}

const UserConfirm: React.FC<HistoryProps> = (props) => {
  const [inputs, setInputs] = useState<Inputs>({
    password: '',
    passwordRepeat: '',
  });
  const [inputError, setInputError] = useState<InputError>({
    error: false,
    message: '',
  });
  const [success, setSuccess] = useState(false);
  const [userConfirm, { data, loading, error }] = useConfirmUserMutation();
  const { data: userData, loading: loadingUserData, error: errorUserData } = useUserDataQuery();

  useEffect(() => {
    localStorage.setItem('token', props.match.params.token!);
  });

  const handleInputChange = (event: any) => {
    event.persist();

    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  if (loadingUserData) {
    return <div>Loading...</div>;
  }

  if (errorUserData) {
    return <div>ERROR</div>;
  }

  if (userData && userData.isLoggedIn) {
    props.history.push('/my-applications');
  }

  return (
    <div className={`bg-gray ${styles.wrapper}`}>
      <div className={`${styles.logowrapper} d-none d-lg-flex`}>
        <div className="animated">
          <div className={`${styles.logo} ${styles.shadowDropCenter} d-none d-lg-flex`}>
            <img src={logo} alt="NWG Gateway" className="w-100" />
          </div>
        </div>
      </div>
      <div className="row h-100">
        <div className="col col-lg-6 bg-white">
          <div className={`${styles.login} bg-white animated fadeIn`}>
            <div className="text-center mb-2 d-block d-lg-none">
              <img src={logo} alt="NWG Gateway" className="mx-auto mb-3" />
            </div>
            <h4 className="mb-2 text-center">Choose password</h4>
            {inputError.error ? (
              <Alert message={inputError.message} messageType="error" classNames="text-center" />
            ) : null}
            {error && !inputError.error && (
              <Alert
                message={'Something went wrong. Please contact an administrator.'}
                messageType="error"
                classNames="text-center"
              />
            )}
            {success && (
              <Alert message={'Success! Redirecting to login view...'} messageType="success" classNames="text-center" />
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (inputs.password !== inputs.passwordRepeat) {
                  setInputError({
                    error: true,
                    message: 'Passwords do not match.',
                  });
                  return;
                } else {
                  setInputError({ error: false, message: '' });
                }

                userConfirm({
                  variables: {
                    id: props.match.params.id,
                    password: inputs.password,
                  },
                })
                  .then((res) => {
                    if (res.data?.userConfirm?.confirmed) {
                      setSuccess(true);

                      setTimeout(() => {
                        localStorage.clear();
                        props.history.push('/login');
                      }, 5000);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control mb-2"
                  type="password"
                  name="password"
                  required
                  value={inputs.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Repeat password</label>
                <input
                  className="form-control mb-2"
                  type="password"
                  name="passwordRepeat"
                  required
                  value={inputs.passwordRepeat}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex mt-3">
                <StateButton
                  type="submit"
                  size={35}
                  color="primary"
                  className="w-100"
                  isLoading={loading}
                  isSuccess={!!data}
                  isError={!!error && !inputError.error}
                >
                  Confirm
                </StateButton>
              </div>
            </form>
          </div>
        </div>
        <div className={`col col-lg-6 d-none d-lg-flex animated fadeIn p-0`}>
          <div className={`${styles.bgPan} w-100 h-100`}></div>
        </div>
      </div>
    </div>
  );
};

export default UserConfirm;
