import gql from 'graphql-tag';

export const FRAGMENT_ALL_LANGUAGES = gql`
  fragment allLanguages on Translation {
    cs
    da
    de
    deCH
    en
    es
    fi
    fr
    frCH
    is
    it
    nl
    no
    pl
    sv
  }
`;
