import React, { FormEvent } from 'react';
import { Alert } from 'ui';
import { ApolloError } from 'apollo-boost';

interface Props {
  className?: string;
  loading?: boolean;
  error?: ApolloError;
  onSubmit(event: React.FormEvent<HTMLFormElement>): void;
}

const InputForm: React.FC<Props> = ({ children, className, onSubmit, loading, error }) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {error ? <Alert message={error.graphQLErrors[0].message} messageType='error' classNames='text-center' /> : null}
      {children}
    </form>
  );
};

export default InputForm;
