import React from 'react';
import {
  useCreateApplicationMutation,
  MutationApplicationCreateArgs,
  ApplicationsByUserIdQuery,
  ApplicationsQuery,
} from 'generated/graphql';
import { GET_APPLICATIONS_BY_USER_ID, GET_APPLICATIONS } from 'graphql/queries';
import { UserApplicationForm } from 'app/components';

const CreateApplication = ({ callback }: { callback: () => void }) => {
  const [applicationCreate, { data, loading, error }] = useCreateApplicationMutation();

  const createApp = (variables: MutationApplicationCreateArgs) => {
    applicationCreate({
      variables: variables,
      update(cache, { data }) {
        if (!data) return null;

        const getExistingApplications = cache.readQuery<ApplicationsByUserIdQuery>({
          query: GET_APPLICATIONS_BY_USER_ID,
        });

        const existingApplications = getExistingApplications ? getExistingApplications.applicationsByUserId : [];
        const newApplication = data.applicationCreate;

        if (newApplication) {
          cache.writeQuery<ApplicationsQuery>({
            query: GET_APPLICATIONS,
            data: {
              applications: [newApplication, ...(existingApplications || [])],
            },
          });

          cache.writeQuery<ApplicationsByUserIdQuery>({
            query: GET_APPLICATIONS_BY_USER_ID,
            data: {
              applicationsByUserId: [newApplication, ...(existingApplications || [])],
            },
          });

          cache.writeQuery<ApplicationsByUserIdQuery>({
            query: GET_APPLICATIONS_BY_USER_ID,
            variables: { userId: newApplication?.userId },
            data: {
              applicationsByUserId: [newApplication, ...(existingApplications || [])],
            },
          });
        }
      },
    }).then(() => {
      callback();
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <UserApplicationForm submit={createApp} error={error!} isSuccess={!!data} isLoading={!!loading} />;
};

export default CreateApplication;
