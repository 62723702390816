import gql from 'graphql-tag';

export const FRAGMENT_FULL_PRODUCT_TRANSLATED = gql`
  fragment fullProductTranslated on ProductTranslated {
    productNumber
    productName {
      ...allLanguages
    }
    productGender {
      key
      translation {
        ...allLanguages
      }
    }
    productBrand
    productRange
    productPrintCode
    productCapacityStandard
    productDesignerStandard
    productDiameterStandard
    productPackaging {
      ...allLanguages
    }
    productPackagingStandard {
      key
      translation {
        ...allLanguages
      }
    }
    productColorComment {
      ...allLanguages
    }
    productColorName {
      ...allLanguages
    }
    productCareInstructions {
      key
      translation {
        ...allLanguages
      }
    }
    productCertifications {
      key
      translation {
        ...allLanguages
      }
    }
    productApplicationAreas {
      key
      translation {
        ...allLanguages
      }
    }
    productApplicationTypes {
      key
      translation {
        ...allLanguages
      }
    }
    productActivityTypes {
      key
      translation {
        ...allLanguages
      }
    }
    productCatalogSize
    productCategory {
      key
      translation {
        ...allLanguages
      }
    }
    productClosure {
      key
      translation {
        ...allLanguages
      }
    }
    productText {
      ...allLanguages
    }
    productCommerceText {
      ...allLanguages
    }
    productCatalogText {
      ...allLanguages
    }
    productCatalogPrice {
      ...allLanguages
    }
    productFabrics {
      ...allLanguages
    }
    productFeature {
      key
      translation {
        ...allLanguages
      }
    }
    productFit {
      key
      translation {
        ...allLanguages
      }
    }
    productHeightStandard
    productHoodDetails {
      key
      translation {
        ...allLanguages
      }
    }
    productLengthStandard
    productMaterialTechnique {
      key
      translation {
        ...allLanguages
      }
    }
    productMeasure
    productNeckline {
      key
      translation {
        ...allLanguages
      }
    }
    productPockets {
      key
      translation {
        ...allLanguages
      }
    }
    productSleeve {
      key
      translation {
        ...allLanguages
      }
    }
    productTheme {
      key
      translation {
        ...allLanguages
      }
    }
    productUsp {
      ...allLanguages
    }
    productVolumeStandard
    productWidthStandard
    productWeight
    productWeightStandard
    filterColor {
      key
      translation {
        ...allLanguages
      }
    }
    sizes
    variations {
      itemNumber
      itemColorCode
      itemColorName {
        ...allLanguages
      }
      filterColor {
        key
        translation {
          ...allLanguages
        }
      }
      pictures {
        imageUrl
        resourcePictureType
        resourceFileId
      }
      sizes
    }
    pictures {
      resourcePictureType
      imageUrl
      resourceFileId
    }
    skus {
      sku
      company
      eanCode
      defaultSalesUnitGrossVolume
      description
      enDescription
      stockUnitCode
      commodityCode
      countryOfOrigin
      defaultSalesUnitCode
      availability
      nextStockUpdate
      changed
      skucolor
      skuSize {
        size
        webtext
      }
      defaultSalesUnitGrossWeight
      defaultSalesUnitNetWeight
      defaultSalesUnitGrossVolume
      defaultSalesUnitNetVolume
      defaultSalesUnitLength
      defaultSalesUnitWidth
      defaultSalesUnitHeight
      defaultSalesUnitItems
      retailPrice {
        price
        currency
      }
      prices {
        salesPrice
        currency
        priceList
      }
      master {
        ...skuUnit
      }
      pall {
        ...skuUnit
      }
      box {
        ...skuUnit
      }
      categorization {
        group1
        group2
        group3
        group4
        group5
        group6
      }
    }
  }
`;
