import React from 'react';
import {
  useCreateCommerceUserLinkMutation,
  CreateOnecommerceUserLinkInput,
  CommerceUserLinksQuery,
} from 'generated/graphql';
import { GET_COMMERCE_USER_LINKS } from 'graphql/queries';
import { CommerceUserLinkForm } from 'app/components';

const CreateCommerceUserLink = ({ callback }: { callback: () => void }) => {
  const [commerceUserLinkCreate, { data, loading, error }] = useCreateCommerceUserLinkMutation();

  const submitData = (variables: CreateOnecommerceUserLinkInput) => {
    commerceUserLinkCreate({
      variables: variables,
      update(cache, { data }) {
        if (!data) return null;

        const getExistingItems = cache.readQuery<CommerceUserLinksQuery>({
          query: GET_COMMERCE_USER_LINKS,
        });

        const existingItems = getExistingItems ? getExistingItems.commerceUserLinks : [];
        const newItem = data.commerceUserLinkCreate?.record!;

        cache.writeQuery<CommerceUserLinksQuery>({
          query: GET_COMMERCE_USER_LINKS,
          data: {
            commerceUserLinks: [newItem, ...(existingItems || [])],
          },
        });
      },
    }).then(() => {
      callback();
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <CommerceUserLinkForm
      defaultInputValues={{
        id: null,
        name: '',
        contexts: [],
        users: [],
      }}
      submit={submitData}
      error={error!}
      isSuccess={!!data}
      isLoading={!!loading}
    />
  );
};

export default CreateCommerceUserLink;
