import React from 'react';
import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './View.module.scss';

interface Props {
  iconClass?: string;
  title?: string;
  subTitle?: string;
  sleek?: boolean;
}

const View: React.FC<Props> = ({ children, title, subTitle, iconClass, sleek }) => {
  return (
    <div styleName="view" className="animated fadeIn">
      {title && (
        <div styleName="view-header">
          <div styleName="view-title-icon">
            <i className={iconClass && iconClass}></i>
          </div>
          <div>
            <div styleName={`${subTitle ? 'view-title' : 'view-title--only'}`}>{title}</div>
            <div styleName="view-subtitle">{subTitle}</div>
          </div>
        </div>
      )}
      <div styleName={classNames({ 'view-body': !sleek })}>{children}</div>
    </div>
  );
};

export default CSSModules(View, styles);
