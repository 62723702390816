import React from 'react';
import { View } from 'ui';
import { CreateArticle, EditArticle } from 'app/components';

const ArticleEditor = ({ articleId }: { articleId?: string }) => {
  return (
    <View sleek>
      <div className="p-5">
        <div className="col-12 col-md-10 mx-auto">
          <div className="p-4">{articleId ? <EditArticle articleId={articleId} /> : <CreateArticle />}</div>
        </div>
      </div>
    </View>
  );
};

export default ArticleEditor;
