//**
// * Returns up to two significant decimal points as a string
// *
// * 123456.00 -> '123456'
// * 1234.5678 -> '1234.57'
// * 0.5678 -> '0.57'
// * 0.000789 -> '0.00079'
//**
export default function formatFloat(float: number) {
  if (float < 0.01) {
    return float.toPrecision(2).toString();
  } else {
    return Number(float.toFixed(2)).toString();
  }
}
