import React from 'react';
import {
  useUpdateCommerceUserLinkMutation,
  UpdateCommerceUserLinkMutationVariables,
  CommerceUserLink,
} from 'generated/graphql';
import { CommerceUserLinkForm } from 'app/components';

const EditCommerceUserLink = ({
  callback,
  commerceUserLink,
}: {
  callback: () => void;
  commerceUserLink: CommerceUserLink | undefined;
}) => {
  const [commerceUserLinkUpdateById, { data, loading, error }] = useUpdateCommerceUserLinkMutation();

  const submitData = (variables: UpdateCommerceUserLinkMutationVariables) => {
    commerceUserLinkUpdateById({
      variables: {
        id: variables.id,
        name: variables.name,
        contexts: variables.contexts,
        users: variables.users,
      },
    }).then(() => {
      callback();
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <>
        <div>Error: {error && error.message}</div>
      </>
    );
  }

  return (
    <CommerceUserLinkForm
      defaultInputValues={{
        id: commerceUserLink?._id,
        name: commerceUserLink?.name || '',
        contexts: commerceUserLink?.contexts || [],
        users: commerceUserLink?.users || [],
      }}
      submit={submitData}
      error={error!}
      isSuccess={!!data}
      isLoading={!!loading}
    />
  );
};

export default EditCommerceUserLink;
