import gql from 'graphql-tag';

export const GET_USERS = gql`
  query Users {
    users {
      _id
      username
      email
      companyIds
      serviceContainerIds
      commerceUserLinkId
      serviceContainers {
        _id
        name
      }
      companies {
        _id
        name
        companyCode
      }
      confirmed
      active
      registeredAt
      lastLogin
      updatedAt
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
  }
`;
