import {
  ProductResource,
  ProductTranslated,
  ProductVariationTranslated,
  Sku,
  SkuPrice,
  SkuRetailPrice,
  Translation,
  TranslationKvp,
  UserPricelists,
} from 'generated/graphql';
import Maybe from 'graphql/tsutils/Maybe';
import { Column } from 'react-table';
import { isDimensions, isTranslation, isTranslationKvpArray } from 'typings/typeguards';
import {
  dimensionsUnitFields,
  lengthUnitFields,
  volumeUnitFields,
  volumeUnitFieldsBaseCl,
  volumeUnitFieldsBaseL,
  weightUnitFields,
} from './exportConstants';
import formatFloat from './floatUtil';

export type Dimensions = {
  h?: Maybe<number>;
  w?: Maybe<number>;
  l?: Maybe<number>;
};

// ### Rows - format data based on settings
export type SheetRow = {
  // Model level
  articleNumber: string;
  name?: Maybe<Translation>;
  text?: Maybe<Translation>;
  brand?: Maybe<string>;
  gender?: Maybe<TranslationKvp>[];
  images?: Maybe<string>;

  activityTypes?: Maybe<TranslationKvp>[];
  applicationAreas?: Maybe<TranslationKvp>[];
  applicationTypes?: Maybe<TranslationKvp>[];
  capacity?: Maybe<number>;
  careInstructions?: Maybe<TranslationKvp>[];
  catalogText?: Maybe<Translation>;
  catalogPrice?: Maybe<Translation>;
  catalogSizes?: Maybe<string>;
  category?: Maybe<TranslationKvp>[];
  certifications?: Maybe<TranslationKvp>[];
  closure?: Maybe<TranslationKvp>[];
  colorComment?: Maybe<Translation>;
  commerceText?: Maybe<Translation>;
  currency?: Maybe<string>;
  designer?: Maybe<Maybe<string>[]>;
  diameter?: Maybe<number>;
  dimensions?: Dimensions;
  fabrics?: Maybe<Translation>;
  feature?: Maybe<TranslationKvp>[];
  filterColor?: Maybe<TranslationKvp>[];
  fit?: Maybe<TranslationKvp>[];
  height?: Maybe<number>;
  hoodDetails?: Maybe<TranslationKvp>[];
  length?: Maybe<number>;
  materialTechnique?: Maybe<TranslationKvp>[];
  measure?: Maybe<string>;
  modelSizes?: Maybe<string>;
  neckline?: Maybe<TranslationKvp>[];
  packaging?: Maybe<Translation>;
  packagingStandard?: Maybe<TranslationKvp>[];
  pockets?: Maybe<TranslationKvp>[];
  prices?: Maybe<PriceByPricelist>;
  printCode?: Maybe<Maybe<string>[]>;
  range?: Maybe<string>;
  retailPrice?: Maybe<number>;
  sleeve?: Maybe<TranslationKvp>[];
  theme?: Maybe<TranslationKvp>[];
  usp?: Maybe<Translation>;
  volume?: Maybe<number>;
  weight?: Maybe<number>;
  weightText?: Maybe<string>;
  width?: Maybe<number>;

  // Color variation level
  model: string;
  colorCode?: Maybe<string>;
  colorName?: Maybe<Translation>;
  colorSizes?: Maybe<string>;

  // Sku skize level
  availability?: Maybe<number>;
  changed?: Maybe<string>;
  commodityCode?: Maybe<string>;
  countryOfOrigin?: Maybe<string>;
  description?: Maybe<string>;
  enDescription?: Maybe<string>;
  isAvailable?: Maybe<string>;
  nextStockUpdate?: Maybe<string>;
  salesUnit?: Maybe<string>;
  size?: Maybe<string>;
  sizeDescription?: Maybe<string>;

  defaultSalesUnitHeight?: Maybe<number>;
  defaultSalesUnitWidth?: Maybe<number>;
  defaultSalesUnitLength?: Maybe<number>;
  defaultSalesUnitVolume?: Maybe<number>;
  defaultSalesUnitDimensions?: Dimensions;
  grossVolume?: Maybe<number>;
  netVolume?: Maybe<number>;
  grossWeight?: Maybe<number>;
  netWeight?: Maybe<number>;
  items?: Maybe<number>;

  masterLength?: Maybe<number>;
  masterWidth?: Maybe<number>;
  masterHeight?: Maybe<number>;
  masterDimensions?: Dimensions;
  masterGrossVolume?: Maybe<number>;
  masterNetVolume?: Maybe<number>;
  masterGrossWeight?: Maybe<number>;
  masterNetWeight?: Maybe<number>;
  masterItems?: Maybe<number>;

  pallLength?: Maybe<number>;
  pallWidth?: Maybe<number>;
  pallHeight?: Maybe<number>;
  pallDimensions?: Dimensions;
  pallGrossVolume?: Maybe<number>;
  pallNetVolume?: Maybe<number>;
  pallGrossWeight?: Maybe<number>;
  pallNetWeight?: Maybe<number>;
  pallItems?: Maybe<number>;

  boxLength?: Maybe<number>;
  boxWidth?: Maybe<number>;
  boxHeight?: Maybe<number>;
  boxDimensions?: Dimensions;
  boxGrossVolume?: Maybe<number>;
  boxNetVolume?: Maybe<number>;
  boxGrossWeight?: Maybe<number>;
  boxNetWeight?: Maybe<number>;
  boxItems?: Maybe<number>;

  skuCategorizationGroup1?: Maybe<string>;
  skuCategorizationGroup2?: Maybe<string>;
  skuCategorizationGroup3?: Maybe<string>;
  skuCategorizationGroup4?: Maybe<string>;
  skuCategorizationGroup5?: Maybe<string>;
  skuCategorizationGroup6?: Maybe<string>;
};

export function getRows(data: Maybe<ProductTranslated>[], rowLevel: string, imageLevel: string, maxRows: number = 0) {
  if (!data) return [];

  switch (rowLevel) {
    case 'variation':
      return getVariationRows(data as ProductTranslated[], imageLevel);
    case 'size':
      return getSizeRows(data as ProductTranslated[], imageLevel, maxRows);
    case 'model':
    default:
      return getProductRows(data as ProductTranslated[], imageLevel);
  }
}

function getProductRows(data: ProductTranslated[], imageLevel: string): SheetRow[] {
  const articles = data.map((prod: ProductTranslated) => {
    return getProductRow(prod, imageLevel);
  });

  return articles;
}

const EMPTY_TRANSLATION = { en: null };
const EMPTY_TRANSLATION_KVP_ARRAY = [{ key: null, translation: EMPTY_TRANSLATION }];

function getProductRow(prod: ProductTranslated, imageLevel: string): SheetRow {
  const retailPrice = findModeRetailFromSkus(prod?.skus);
  const salesPrices = findModePricesFromSkus(prod?.skus);

  return {
    articleNumber: prod.productNumber!,
    model: prod.productNumber!,
    name: prod.productName || EMPTY_TRANSLATION,
    text: prod.productText || EMPTY_TRANSLATION,
    brand: prod.productBrand,
    gender: getTranslationKvpDataOrEmpty(prod.productGender),
    images: getImageLinks(prepImages(prod.pictures, imageLevel, prod.variations)),

    activityTypes: getTranslationKvpDataOrEmpty(prod.productActivityTypes),
    applicationAreas: getTranslationKvpDataOrEmpty(prod.productApplicationAreas),
    applicationTypes: getTranslationKvpDataOrEmpty(prod.productApplicationTypes),
    capacity: prod.productCapacityStandard,
    careInstructions: getTranslationKvpDataOrEmpty(prod.productCareInstructions),
    catalogText: prod.productCatalogText || EMPTY_TRANSLATION,
    catalogPrice: prod.productCatalogPrice || EMPTY_TRANSLATION,
    catalogSizes: prod.productCatalogSize,
    category: getTranslationKvpDataOrEmpty(prod.productCategory),
    certifications: getTranslationKvpDataOrEmpty(prod.productCertifications),
    closure: getTranslationKvpDataOrEmpty(prod.productClosure),
    colorComment: prod.productColorComment || EMPTY_TRANSLATION,
    colorName: prod.productColorName || EMPTY_TRANSLATION,
    commerceText: prod.productCommerceText || EMPTY_TRANSLATION,
    currency: retailPrice?.currency,
    designer: prod.productDesignerStandard || [],
    diameter: prod.productDiameterStandard,
    fabrics: prod.productFabrics || EMPTY_TRANSLATION,
    feature: getTranslationKvpDataOrEmpty(prod.productFeature),
    filterColor: getTranslationKvpDataOrEmpty(prod.filterColor),
    fit: getTranslationKvpDataOrEmpty(prod.productFit),
    height: prod.productHeightStandard,
    hoodDetails: getTranslationKvpDataOrEmpty(prod.productHoodDetails),
    length: prod.productLengthStandard,
    materialTechnique: getTranslationKvpDataOrEmpty(prod.productMaterialTechnique),
    measure: prod.productMeasure,
    modelSizes: prod.sizes,
    neckline: getTranslationKvpDataOrEmpty(prod.productNeckline),
    packaging: prod.productPackaging || EMPTY_TRANSLATION,
    packagingStandard: getTranslationKvpDataOrEmpty(prod.productPackagingStandard),
    pockets: getTranslationKvpDataOrEmpty(prod.productPockets),
    printCode: prod.productPrintCode || [],
    range: prod.productRange,
    retailPrice: retailPrice?.price,
    sleeve: getTranslationKvpDataOrEmpty(prod.productSleeve),
    theme: getTranslationKvpDataOrEmpty(prod.productTheme),
    usp: prod.productUsp || EMPTY_TRANSLATION,
    volume: prod.productVolumeStandard,
    weight: prod.productWeightStandard,
    weightText: prod.productWeight,
    width: prod.productWidthStandard,
    dimensions: {
      h: prod.productHeightStandard,
      w: prod.productWidthStandard,
      l: prod.productLengthStandard,
    },
    prices: salesPrices,
  };
}

function getTranslationKvpDataOrEmpty(kvpField: Maybe<Maybe<TranslationKvp>[]> | undefined) {
  return kvpField?.length ? kvpField : EMPTY_TRANSLATION_KVP_ARRAY;
}

function getVariationRows(data: ProductTranslated[], imageLevel: string, maxRows: number = 0) {
  const rows = data.reduce((acc, prod) => {
    if (maxRows >= 1 && acc.length >= maxRows) return acc;
    const variations = maxRows >= 1 ? prod.variations?.slice(0, maxRows) : prod.variations;

    const articles = variations?.length
      ? variations.map((v) => {
          return getVariationRow(prod, v!, imageLevel);
        }, new Array<SheetRow>())
      : [getProductRow(prod, imageLevel)];

    return [...acc, ...articles];
  }, new Array<SheetRow>());

  return rows;
}

type Distribution = {
  [key: number]: number;
};
function findModeRetailFromSkus(skus: Maybe<Sku>[] | null | undefined): SkuRetailPrice {
  if (!skus) return {};

  const prices = skus.map((sku) => sku?.retailPrice);
  const distribution: Distribution = {};
  let max = 0;
  let result: SkuRetailPrice = {};

  if (prices.length) {
    prices.forEach((p) => {
      if (p && p.price && p.currency) {
        distribution[p.price] = (distribution[p.price] || 0) + 1;
        if (distribution[p.price] > max) {
          max = distribution[p.price];
          result = p;
        }
      }
    });
  }

  return result;
}

type PricesByPriceList = {
  [key: string]: SkuPrice[];
};
export type PriceByPricelist = {
  [key: string]: SkuPrice;
};
function findModePricesFromSkus(skus: Maybe<Sku>[] | null | undefined): PriceByPricelist | null {
  if (!skus) return null;

  const pricesByPriceList = skus.reduce((acc: PricesByPriceList, sku: Maybe<Sku>) => {
    sku?.prices?.forEach((p) => {
      if (p?.priceList && p.currency) {
        acc = {
          ...acc,
          [`${p.priceList}_${p.currency}`]: [...(acc[`${p.priceList}_${p.currency}`] || []), p],
        };
      }
    });

    return acc;
  }, {});

  const modePrices = Object.keys(pricesByPriceList).reduce((acc, k) => {
    const prices = pricesByPriceList[k];

    const distribution: Distribution = {};
    let max = 0;
    let result: SkuPrice | null = null;

    if (prices.length) {
      prices.forEach((p) => {
        if (p?.salesPrice && p.currency) {
          distribution[p.salesPrice] = (distribution[p.salesPrice] || 0) + 1;
          if (distribution[p.salesPrice] > max) {
            max = distribution[p.salesPrice];
            result = p;
          }
        }
      });
    }

    return result ? { ...acc, [k]: result } : acc;
  }, {});

  return modePrices;
}

function getVariationRow(product: ProductTranslated, variation: ProductVariationTranslated, imageLevel: string) {
  const varSkus = product?.skus?.filter((sku) => sku?.skucolor === variation.itemColorCode);
  const modePrice = findModeRetailFromSkus(varSkus);
  const salesPrices = findModePricesFromSkus(varSkus);

  return {
    ...getProductRow(product, imageLevel),
    articleNumber: variation.itemNumber!,
    images: getImageLinks(
      prepImages(
        variation.pictures,
        imageLevel,
        null,
        product.pictures?.filter((p) => p?.resourcePictureType === 'Imagepicture'),
      ),
    ),
    colorCode: variation.itemColorCode,
    colorName: variation.itemColorName || EMPTY_TRANSLATION,
    colorSizes: variation.sizes,
    filterColor: getTranslationKvpDataOrEmpty(variation.filterColor),
    retailPrice: modePrice?.price,
    prices: salesPrices,
  };
}

function getSizeRows(data: ProductTranslated[], imageLevel: string, maxRows: number = 0) {
  const rows = data.reduce((acc, product) => {
    if (product && product.skus) {
      if (maxRows >= 1 && acc.length >= maxRows) return acc;
      const skus = maxRows >= 1 ? product.skus.slice(0, maxRows - 1) : product.skus;

      const articles = skus.reduce((acc, sku) => {
        if (sku) {
          const sizeProperties = {
            articleNumber: sku.sku!,
            availability: typeof sku.availability === 'number' ? sku.availability : null,
            changed: sku.changed,
            commodityCode: sku.commodityCode,
            countryOfOrigin: sku.countryOfOrigin,
            description: sku.description,
            enDescription: sku.enDescription,
            isAvailable: sku.availability && sku.availability > 0 ? 'Yes' : 'No',
            nextStockUpdate: sku.nextStockUpdate,
            salesUnit: sku.defaultSalesUnitCode,
            size: (sku.skuSize && sku.skuSize.size) || '',
            sizeDescription: (sku.skuSize && sku.skuSize.webtext) || 'One size',
            stockUnitCode: sku.stockUnitCode,

            ean: sku.eanCode,
            eanMaster: sku.master?.eanCode,
            eanPall: sku.pall?.eanCode,

            retailPrice: sku.retailPrice?.price || null,
            prices:
              sku.prices?.reduce((acc: Maybe<PriceByPricelist>, curr) => {
                if (curr?.currency && curr.priceList) return { ...acc, [`${curr.priceList}_${curr.currency}`]: curr };
                return acc;
              }, {}) || null,

            defaultSalesUnitHeight: sku.defaultSalesUnitHeight,
            defaultSalesUnitWidth: sku.defaultSalesUnitWidth,
            defaultSalesUnitLength: sku.defaultSalesUnitLength,
            defaultSalesUnitDimensions: {
              h: sku.defaultSalesUnitHeight,
              w: sku.defaultSalesUnitWidth,
              l: sku.defaultSalesUnitLength,
            },
            grossWeight: sku.defaultSalesUnitGrossWeight,
            netWeight: sku.defaultSalesUnitNetWeight,
            grossVolume: sku.defaultSalesUnitGrossVolume,
            netVolume: sku.defaultSalesUnitNetVolume,
            items: sku.defaultSalesUnitItems,

            masterHeight: sku.master?.height,
            masterWidth: sku.master?.width,
            masterLength: sku.master?.length,
            masterDimensions: {
              h: sku.master?.height,
              w: sku.master?.width,
              l: sku.master?.length,
            },
            masterGrossWeight: sku.master?.grossWeight,
            masterNetWeight: sku.master?.netWeight,
            masterGrossVolume: sku.master?.grossVolume,
            masterNetVolume: sku.master?.netVolume,
            masterItems: sku.master?.items,

            pallHeight: sku.pall?.height,
            pallWidth: sku.pall?.width,
            pallLength: sku.pall?.length,
            pallDimensions: {
              h: sku.pall?.height,
              w: sku.pall?.width,
              l: sku.pall?.length,
            },
            pallGrossWeight: sku.pall?.grossWeight,
            pallNetWeight: sku.pall?.netWeight,
            pallGrossVolume: sku.pall?.grossVolume,
            pallNetVolume: sku.pall?.netVolume,
            pallItems: sku.pall?.items,

            boxHeight: sku.box?.height,
            boxWidth: sku.box?.width,
            boxLength: sku.box?.length,
            boxDimensions: {
              h: sku.box?.height,
              w: sku.box?.width,
              l: sku.box?.length,
            },
            boxGrossWeight: sku.box?.grossWeight,
            boxNetWeight: sku.box?.netWeight,
            boxGrossVolume: sku.box?.grossVolume,
            boxNetVolume: sku.box?.netVolume,
            boxItems: sku.box?.items,

            skuCategorizationGroup1: sku.categorization?.group1,
            skuCategorizationGroup2: sku.categorization?.group2,
            skuCategorizationGroup3: sku.categorization?.group3,
            skuCategorizationGroup4: sku.categorization?.group4,
            skuCategorizationGroup5: sku.categorization?.group5,
            skuCategorizationGroup6: sku.categorization?.group6,
          };

          if (!product.variations?.length) {
            return [
              ...acc,
              {
                ...getProductRow(product, imageLevel),
                ...sizeProperties,
              },
            ];
          }

          const variation = product.variations.find((v) => v!.itemColorCode === sku.skucolor);
          if (variation) {
            return [
              ...acc,
              {
                ...getVariationRow(product, variation, imageLevel),
                ...sizeProperties,
              },
            ];
          }
        }

        return acc;
      }, new Array<SheetRow>());

      return [...acc, ...articles];
    }

    return acc;
  }, new Array<SheetRow>());

  return rows;
}

function unwrapMaybeImages(images: Maybe<ProductResource>[] | undefined | null) {
  return [
    ...(images || [])?.reduce((acc, curr) => {
      return curr ? [...acc, curr] : acc;
    }, new Array<ProductResource>()),
  ];
}

function prepImages(
  images: Maybe<ProductResource>[] | undefined | null,
  imageLevel: string,
  variations?: Maybe<Array<Maybe<ProductVariationTranslated>>>,
  imagePictures?: Maybe<ProductResource>[] | undefined | null,
) {
  let newImages = unwrapMaybeImages(images);

  switch (imageLevel) {
    case 'all':
      if (variations) {
        newImages = [...newImages, ...variations.flatMap((v) => unwrapMaybeImages(v?.pictures))];
      }

      if (imagePictures) {
        newImages = [...newImages, ...unwrapMaybeImages(imagePictures)];
      }
      break;
    case 'productOnly':
      newImages = newImages.filter((i) => i.resourcePictureType && i.resourcePictureType === 'Productpicture');
      break;
    case 'mainOnly':
    default:
      return newImages.length ? [newImages[0]] : [];
  }

  return newImages.filter((img, i, self) => self.findIndex((img2) => img2.resourceFileId === img.resourceFileId) === i);
}

function getImageLinks(images: ProductResource[]) {
  if (!images.length) return '';

  return images.reduce((acc: string, curr: ProductResource) => {
    if (curr?.imageUrl) {
      const link = curr.imageUrl;
      return acc ? `${acc},${link}` : link;
    } else return acc;
  }, '');
}

function createColumn(name: string) {
  return {
    Header: name,
    accessor: name,
  };
}

interface ITranslation {
  [key: string]: string;
}

export function getSelectedColumns(
  data: SheetRow[],
  languages: Maybe<string>[],
  columns: Maybe<string>[],
  pricelists: Maybe<Maybe<UserPricelists>[]>,
  units: Maybe<string>[],
) {
  const selectedColumns = data
    ? columns.reduce((acc, colKey, index) => {
        if (!colKey) return [...acc, createColumn('Unknown key ' + index)];

        const rowWithColData = data.find((row) =>
          row[colKey as keyof SheetRow] && Array.isArray(row[colKey as keyof SheetRow])
            ? (row[colKey as keyof SheetRow] as Array<any>).length // If array, make sure the array isn't empty.
            : true,
        );
        const colData = rowWithColData ? rowWithColData[colKey as keyof SheetRow] : null;

        if (colKey === 'prices') {
          return [...acc, ...getPricesColumns(pricelists)];
        }

        const unitsToConvert = shouldConvertUnits(colKey, units);

        if (dimensionsUnitFields.includes(colKey)) {
          if (unitsToConvert.length) return [...acc, ...getDimensionsColumns(colKey, unitsToConvert, false)];
          return [...acc, ...getDimensionsColumns(colKey, ['mm'])];
        }

        if (unitsToConvert.length) {
          return [...acc, ...getUnitColumns(colKey, unitsToConvert)];
        }

        if (isTranslation(colData)) {
          return [...acc, ...getTranslationColumns(colKey, languages)];
        }

        if (isTranslationKvpArray(colData)) {
          return [...acc, getKvpKeyColumn(colKey), ...getKvpTranslationColumns(colKey, languages)];
        }
        if (Array.isArray(colData)) {
          return [...acc, getArrayColumn(colKey)];
        }

        return [...acc, createColumn(colKey)];
      }, new Array<Column>())
    : [];

  return [createColumn('articleNumber'), ...(selectedColumns || [])];
}

function getUnitColumns(name: string, units: string[]) {
  return units.map((unit) => {
    return {
      id: name + '_' + unit,
      Header: name + '_' + unit,
      accessor: (data: SheetRow | null) => {
        const d = data && data[name as keyof SheetRow];
        if (typeof d === 'number') {
          const converted = formatFloat(convertUnit(unit, d, name));
          return converted;
        }
      },
    };
  });
}

function getDimensionsColumns(name: string, units: string[], isDefault: boolean = true) {
  return units.map((unit) => {
    return {
      id: `${name} ${isDefault ? '' : '_' + unit}`,
      Header: `${name} ${isDefault ? '' : '_' + unit}`,
      accessor: (data: SheetRow | null) => {
        const d = data && data[name as keyof SheetRow];

        if (isDimensions(d)) {
          const h = typeof d.h === 'number' && formatFloat(convertUnit(unit, d.h, 'height'));
          const w = typeof d.w === 'number' && formatFloat(convertUnit(unit, d.w, 'width'));
          const l = typeof d.l === 'number' && formatFloat(convertUnit(unit, d.l, 'length'));

          return `${h ? h : '-'}x${w ? w : '-'}x${l ? l : '-'}`;
        }
      },
    };
  });
}

function getPricesColumns(pricelists: Maybe<Maybe<UserPricelists>[]>) {
  const workingPricelists: UserPricelists[] =
    (pricelists?.filter((p) => p && p.pricelist && p.currency && p.companyCode) as UserPricelists[]) || [];
  const addSuffix = true; // workingPricelists.length > 1;

  return workingPricelists.map((pl) => {
    return {
      id: `salesPrice${addSuffix ? `_${pl.pricelist}_${pl.currency}` : ''}`,
      Header: `salesPrice${addSuffix ? `_${pl.pricelist}_${pl.currency}` : ''}`,
      accessor: (data: SheetRow | null) => {
        return data && data['prices'] && data.prices[`${pl.pricelist}_${pl.currency}`]?.salesPrice;
      },
    };
  });
}

function getTranslationColumns(name: string, languages: Maybe<string>[]) {
  return languages.map((lang) => {
    return {
      id: name + '_' + lang,
      Header: name + '_' + lang,
      accessor: (data: SheetRow | null) => {
        return data && data[name as keyof SheetRow] && isTranslation(data[name as keyof SheetRow])
          ? (data[name as keyof SheetRow] as ITranslation)[lang!]
          : null;
      },
    };
  });
}

function getKvpTranslationColumns(name: string, languages: Maybe<string>[]) {
  return languages.map((lang) => {
    return {
      id: name + '_' + lang,
      Header: name + '_' + lang,
      accessor: (data: SheetRow | null) => {
        return (
          data &&
          data[name as keyof SheetRow] &&
          isTranslationKvpArray(data[name as keyof SheetRow]) &&
          (data[name as keyof SheetRow] as Array<TranslationKvp>)
            .map((f: TranslationKvp) => f.translation && f.translation[lang as keyof Translation])
            .join(',')
        );
      },
    };
  });
}

function getKvpKeyColumn(name: string) {
  return {
    id: name + '_key',
    Header: name + '_key',
    accessor: (data: SheetRow | null) => {
      return (
        data &&
        data[name as keyof SheetRow] &&
        isTranslationKvpArray(data[name as keyof SheetRow]) &&
        (data[name as keyof SheetRow] as Array<TranslationKvp>).map((f: TranslationKvp) => f.key).join(',')
      );
    },
  };
}
function getArrayColumn(name: string) {
  return {
    id: name,
    Header: name,
    accessor: (data: SheetRow | null) => {
      return (
        data &&
        data[name as keyof SheetRow] &&
        Array.isArray(data[name as keyof SheetRow]) &&
        (data[name as keyof SheetRow] as Array<string>).join(',')
      );
    },
  };
}
function isStandardUnitFieldByType(key: string, unit: string): boolean {
  switch (unit) {
    case 'm3':
    case 'cl':
    case 'l':
    case 'oz':
      return volumeUnitFields.includes(key);
    case 'mm':
    case 'cm':
    case 'in':
    case 'ft':
      return lengthUnitFields.includes(key) || dimensionsUnitFields.includes(key);
    case 'g':
    case 'kg':
    case 'lbs':
      return weightUnitFields.includes(key);
    default:
      return false;
  }
}
export function shouldConvertUnits(key: string, units: Maybe<string>[]): string[] {
  if (!units || !units.length) return [];

  return units.reduce((acc, unit) => {
    if (unit && isStandardUnitFieldByType(key, unit)) {
      return [...acc, unit];
    }
    return acc;
  }, new Array<string>());
}

export function convertUnit(unit: string, data: number, field: string) {
  data = normalizeVolumeBase(field, data);

  switch (unit) {
    // Length, default mm
    case 'cm':
      return data * 0.1;
    case 'in':
      return data * 0.0393700787;
    case 'ft':
      return data * 0.0032808;
    // Weight, default kg
    case 'lbs':
      return data * 2.20462262185;
    case 'g':
      return data * 1000;
    // Volume, default m3
    case 'oz':
      return data * 33814.0227;
    case 'cl':
      return data * 100000;
    case 'l':
      return data * 1000;
    // Defaults
    case 'mm':
    case 'kg':
    case 'm3':
    default:
      return data;
  }
}

function normalizeVolumeBase(field: string, data: number) {
  if (volumeUnitFieldsBaseCl.includes(field)) return data * 0.00001;
  if (volumeUnitFieldsBaseL.includes(field)) return data * 0.001;

  return data;
}
