import React, { useState } from 'react';
import styles from './Applications.module.scss';
import { IconWrapper } from 'ui';
import { useApplicationsQuery, Application } from 'generated/graphql';
import CSSModules from 'react-css-modules';
import { OptionValue } from 'ui/Select/Select';
import { AppFilterOpts } from './Applications';

function isVisible(app: Partial<Application>, filterOptions: OptionValue[]) {
  if (app.deletedAt) return filterOptions.find((o) => o.value === AppFilterOpts.Removed);
  if (app.active && filterOptions.find((o) => o.value === AppFilterOpts.Active)) return true;
  if (!app.active && filterOptions.find((o) => o.value === AppFilterOpts.Inactive)) return true;
  return false;
}

type ApplicationListProps = {
  filter: string | undefined;
  onSelect: (app: Partial<Application>) => void;
  selectedApplication: Partial<Application> | undefined;
  isAsc: boolean;
  filterOptions: OptionValue[];
};
const ApplicationList: React.FC<ApplicationListProps> = ({
  filter,
  onSelect,
  selectedApplication,
  isAsc,
  filterOptions,
}) => {
  const { data, loading, error } = useApplicationsQuery();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const apps = data?.applications || [];

  let list = apps
    .filter((a) => {
      if (!a) return false;

      const show = filterOptions && filterOptions.length ? isVisible(a, filterOptions) : true;
      if (!show) return false;

      return (
        !filter ||
        (a.name && a.name.toLowerCase().indexOf(filter) >= 0) ||
        (a.user?.username && a.user.username.toLowerCase().indexOf(filter) >= 0)
      );
    })
    .sort((a, b) => ((a?.name?.toLowerCase() || '') < (b?.name?.toLowerCase() || '') ? -1 : 1));

  if (isAsc) list = list.reverse();

  return (
    <>
      {!loading &&
        list?.length &&
        list.map(
          (app) => app && <Item key={app._id} app={app} onSelect={onSelect} isSelected={selectedApplication === app} />,
        )}
      {loading && (
        <div className="animated fadeIn py-2">
          <IconWrapper iconClass="far fa-user" className="mr-1" />
          Loading...
        </div>
      )}
    </>
  );
};

function getIconColor(active: boolean, deleted: boolean, selected: boolean) {
  if (selected) return 'blue';

  if (deleted) return 'default';

  return active ? 'green' : 'red';
}

type ListItemProps = {
  app: Partial<Application>;
  onSelect: (app: Partial<Application>) => void;
  isSelected: boolean;
};
const ListItem: React.FC<ListItemProps> = ({ app, onSelect, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      key={app._id}
      styleName={`list-item ${isSelected ? 'active' : ''}`}
      className="animated fadeIn py-2"
      onClick={() => onSelect(app)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconWrapper
        color={getIconColor(app.active || false, !!app.deletedAt, isSelected)}
        iconClass={isSelected || isHovered ? 'fas fa-pencil-alt' : 'fas fa-key'}
        className="mr-1"
      />
      {app.name}
    </div>
  );
};

const Item = CSSModules(ListItem, styles, { allowMultiple: true });

export default ApplicationList;
