import gql from 'graphql-tag';

export const GET_ASSORTMENT_NODE = gql`
  query AssortmentNode($id: String!, $language: String!, $productPage: Int!, $productPageSize: PageSize!) {
    assortmentNodeById(id: $id, language: $language) {
      id
      name
      childProducts(page: $productPage, pageSize: $productPageSize) {
        result {
          productName
          productNumber
          pictures {
            imageUrl
            resourceFileId
          }
        }
        pageInfo {
          page
          hasNextPage
        }
        count
      }
    }
  }
`;
