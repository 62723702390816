import gql from 'graphql-tag';

export const GET_EXPORT_CONFIGURATION = gql`
  query GetExportConfiguration($id: MongoID!) {
    exportConfigurationById(_id: $id) {
      queryVariables {
        assortmentId
        companyCode
        language
        filters {
          filter
          values
        }
        sheetSettings {
          rowLevel
          imageLevel
          languages
          selectedColumns
          pricelists {
            pricelist
            currency
            companyCode
          }
          units
        }
      }
    }
  }
`;
