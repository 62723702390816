import React, { useState } from 'react';
import { GET_SERVICECONTAINERS } from 'graphql/queries';
import { useCreateServiceContainerMutation, ServiceContainersQuery } from 'generated/graphql';
import { Card, InputForm, StateButton } from 'ui';

interface IServiceUser {
  name: string;
  description?: string;
}

const CreateServiceContainer = () => {
  const [serviceContainerCreate, { data, loading, error }] = useCreateServiceContainerMutation();
  const [inputs, setInputs] = useState<IServiceUser>({ name: '' });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Card title="Add new service">
      <InputForm
        className="my-2"
        onSubmit={(e) => {
          e.preventDefault();
          serviceContainerCreate({
            variables: {
              name: inputs!.name,
              description: inputs!.description,
            },
            update(cache, { data }) {
              if (!data) return null;

              const getExistingServiceContainers = cache.readQuery<ServiceContainersQuery>({
                query: GET_SERVICECONTAINERS,
              });

              const existingServiceContainers = getExistingServiceContainers
                ? getExistingServiceContainers.serviceContainers
                : [];
              const newServiceContainer = data.serviceContainerCreate && data.serviceContainerCreate.record;

              if (newServiceContainer) {
                cache.writeQuery<ServiceContainersQuery>({
                  query: GET_SERVICECONTAINERS,
                  data: {
                    serviceContainers: [newServiceContainer, ...(existingServiceContainers || [])],
                  },
                });
              }
            },
          });
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                required
                value={(inputs && inputs.name) || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control"
              rows={7}
              name="description"
              placeholder="Description"
              value={(inputs && inputs.description) || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <StateButton
          type="submit"
          size={35}
          color="green"
          className="float-right my-2"
          isLoading={loading}
          isError={!!error}
          isSuccess={!!data}
        >
          Add Service
        </StateButton>
      </InputForm>
    </Card>
  );
};

export default CreateServiceContainer;
