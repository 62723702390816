import React, { useEffect, useState } from 'react';
import { InputField, Select, StateButton } from 'ui';
import Skeleton from 'react-loading-skeleton';
import { Maybe, PricelistInput, useCompaniesQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { ValueBox } from 'ui';
import { ArrayFixUpdateUserByIdMutationVariables } from '../EditUser/EditUser';

type Props = {
  defaultValues: Partial<ArrayFixUpdateUserByIdMutationVariables>;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};
const FormSectionCompanyAccess: React.FC<Props> = ({ defaultValues, setValue, register }) => {
  const { data, loading, error } = useCompaniesQuery();
  const [pricelists, setPricelists] = useState(defaultValues.pricelists);
  const [pricelistInput, setPricelistInput] = useState('');
  const [companyInput, setCompanyInput] = useState('');
  const [currencyInput, setCurrencyInput] = useState('');

  useEffect(() => {
    setValue('pricelists', pricelists, false);
  }, [pricelists, setValue]);

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading companies: <br />
        {error.message}
      </div>
    );
  }

  if (!data?.companies?.length) {
    return <div className="mb-1 p-2 bg-gray text-error">Error loading companies: No companies returned.</div>;
  }

  const options = data?.companies?.map((company) => ({
    value: company?.companyCode!,
    label: company?.name!,
  }));

  const handleCompanyChange = (val: ValueType<OptionTypeBase>) => {
    if (!val) {
      setCompanyInput('');
    } else {
      setCompanyInput((val as OptionTypeBase).value);
    }
  };

  const handlePricelistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setPricelistInput('');
    } else {
      setPricelistInput(e.target.value);
    }
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setCurrencyInput('');
    } else {
      setCurrencyInput(e.target.value.toUpperCase());
    }
  };

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (
      pricelists?.find(
        (pl) => pl?.companyCode === companyInput && pl.pricelist === pricelistInput && pl.currency === currencyInput,
      )
    )
      return;

    setPricelists([
      ...(pricelists || []),
      { pricelist: pricelistInput, companyCode: companyInput, currency: currencyInput },
    ]);
  };

  const handleRemove = (p: Maybe<PricelistInput>) => {
    if (!p || !p.companyCode || !p.pricelist) return;

    setPricelists(pricelists?.filter((pl) => pl?.companyCode !== p.companyCode || pl?.pricelist !== p.pricelist));
  };

  register({ name: 'pricelists' });

  return (
    <>
      <label>Pricelists</label>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-wrap mb-2">
            {pricelists != null &&
              pricelists.map((p) => (
                <ValueBox
                  key={`${p?.companyCode}_${p?.pricelist}_${p?.currency}`}
                  text={`${p?.pricelist}, ${
                    data.companies!.find((c) => c?.companyCode === p?.companyCode)?.name || p?.companyCode
                  }, ${p?.currency}`}
                  onRemove={() => handleRemove(p)}
                ></ValueBox>
              ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            name="pricelists.companyCode"
            onChange={(value) => handleCompanyChange(value)}
            options={options}
            placeholder="Company"
          />
        </div>
        <div className="col-auto">
          <InputField
            onChange={handlePricelistChange}
            name="pricelists.pricelist"
            placeholder="Pricelist id"
          ></InputField>
        </div>
        <div className="col-auto">
          <InputField onChange={handleCurrencyChange} name="pricelists.currency" placeholder="Currency"></InputField>
        </div>
        <div className="ml-auto col-auto">
          <StateButton
            size={35}
            color="primary"
            onClick={handleAdd}
            disabled={!pricelistInput || !companyInput || !currencyInput}
          >
            <i className="fas fa-plus mr-1" />
          </StateButton>
        </div>
      </div>
    </>
  );
};

export default FormSectionCompanyAccess;
