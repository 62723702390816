import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
  query Companies {
    companies {
      _id
      name
      companyCode
    }
  }
`;
