import React from 'react';
import { ApolloError } from 'apollo-boost';
import { InputForm, StateButton } from 'ui';
import { useForm } from 'react-hook-form';
import {
  CommerceUserLinkContextsInput,
  CommerceUserLinkUsersInput,
  CreateOnecommerceUserLinkInput,
  Maybe,
  UpdateCommerceUserLinkMutationVariables,
} from 'generated/graphql';

import FormSectionDescription from './FormSectionDescription';
import FormSectionCommerceApps from './FormSectionCommerceApps';
import FormSectionCommerceUsers from './FormSectionCommerceUsers';

export type ArrayFixUpdateCommerceUserLinkMutationVariables = Omit<
  UpdateCommerceUserLinkMutationVariables,
  'contexts' | 'users'
> & {
  contexts: Array<Maybe<CommerceUserLinkContextsInput>>;
  users: Array<Maybe<CommerceUserLinkUsersInput>>;
};

type CommerceUserLinkFormProps = {
  defaultInputValues?: ArrayFixUpdateCommerceUserLinkMutationVariables;
  submit: any;
  isLoading?: boolean;
  isSuccess?: boolean;
  error: ApolloError;
};

const CommerceUserLinkForm: React.FC<CommerceUserLinkFormProps> = ({
  defaultInputValues,
  submit,
  isLoading,
  isSuccess,
  error,
}) => {
  const { register, setValue, handleSubmit, errors, formState } = useForm<CreateOnecommerceUserLinkInput>({
    mode: 'onChange',
  });

  // Submit field data to mutation component
  const onSubmit = handleSubmit((data: CreateOnecommerceUserLinkInput) => {
    if (defaultInputValues) {
      submit({ ...data, id: defaultInputValues.id });
    } else {
      submit(data);
    }
  });

  return (
    <InputForm className="my-2" onSubmit={onSubmit}>
      <FormSectionDescription defaultValues={defaultInputValues} register={register} errors={errors} />
      <FormSectionCommerceApps defaultValues={defaultInputValues} register={register} setValue={setValue} />
      <FormSectionCommerceUsers defaultValues={defaultInputValues} register={register} setValue={setValue} />

      <StateButton
        type="submit"
        size={35}
        color="green"
        disabled={!formState.isValid}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={!!error}
      >
        Save
      </StateButton>
    </InputForm>
  );
};

export default CommerceUserLinkForm;
