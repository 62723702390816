import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateArticleMutation, Article, useCreateNotificationMutation, useUsersQuery } from 'generated/graphql';
import { ArticleForm } from 'app/components';
import { Card, Checkbox } from 'ui';

const CreateArticle = (refetch: any) => {
  const { push } = useHistory();
  const [sendNotification, setSendNotification] = useState(false);

  const { data, loading, error } = useUsersQuery({ skip: !sendNotification });

  const [
    articleCreate,
    { data: dataArticleCreate, error: errorArticleCreate, loading: loadingArticleCreate },
  ] = useCreateArticleMutation({
    onCompleted: () => refetch,
  });

  const [notificationCreate] = useCreateNotificationMutation();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const submitData = (inputs: Article) => {
    articleCreate({
      variables: {
        headline: inputs.headline!,
        description: inputs.description!,
        body: inputs.body!,
        author: inputs.author,
        imageUrl: inputs.imageUrl,
        published: inputs.published!,
      },
    }).then((res) => {
      if (sendNotification && inputs.published!) {
        notificationCreate({
          variables: {
            headline: 'A new article has been posted',
            description: inputs.headline!,
            body: inputs.description!,
            link: `article/${res.data?.articleCreate?.record?._id}`,
            userIds: data?.users?.map((u) => u?._id),
          },
        });
      }

      push('/docs');
    });
  };

  return (
    <Card title="Edit Article" className="mb-3 animated fadeIn">
      <Checkbox
        name="notify"
        label="Notify users"
        defaultChecked={sendNotification}
        onChange={() => setSendNotification(!sendNotification)}
      ></Checkbox>
      <ArticleForm
        defaultInputValues={{
          _id: null,
          headline: '',
          description: '',
          body: '',
          author: '',
          imageUrl: '',
          published: false,
        }}
        submitData={submitData}
        isSuccess={!!dataArticleCreate}
        error={errorArticleCreate!}
        isLoading={!!loadingArticleCreate}
      />
    </Card>
  );
};

export default CreateArticle;
