import React from 'react';
import styles from './AssortmentNode.module.scss';
import CSSModule from 'react-css-modules';
import { Product } from 'generated/graphql';
import { ProductListItem } from 'app/components';
import { ItemId } from '@atlaskit/tree';
import { DropdownMenu } from 'ui';

type AssortmentProductListProps = {
  product: Product;
  selected?: boolean;
  multiDragging?: boolean;
  count?: number | null;
  details?: boolean;
  onProductDeleted?: (itemId: ItemId) => void;
  itemId?: ItemId;
};
const AssortmentProductListNode: React.FC<AssortmentProductListProps> = ({
  product,
  selected,
  multiDragging,
  count,
  details,
  onProductDeleted,
  itemId,
}) => {
  const productMenuItems = [
    {
      title: 'Remove product(s)',
      action: () => onProductDeleted && onProductDeleted(itemId!),
    },
  ];

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-between`}
        styleName={`node-product${selected ? ' selected' : ''}${multiDragging ? ' multiselected' : ''}`}
      >
        <ProductListItem key={product.productNumber || ''} product={product} rightSpace={41}></ProductListItem>
        {details && (
          <div className="ml-2 pl-2 divider-vertical-before position-relative h-100 d-flex align-items-center">
            <div className="mr-1">
              <DropdownMenu closeOnSelect>
                {{
                  trigger: <i className="fas fa-ellipsis-v ml-1"></i>,
                  items: productMenuItems,
                }}
              </DropdownMenu>
            </div>
          </div>
        )}
      </div>
      {count && count > 1 && <div styleName="badge">{count}</div>}
    </>
  );
};

export default CSSModule(AssortmentProductListNode, styles, { allowMultiple: true });
