import React from 'react';
import { InputField } from 'ui';
import { NestDataObject } from 'react-hook-form';
import { MutationApplicationCreateArgs } from 'generated/graphql';

type Props = {
  register: any;
  errors: NestDataObject<MutationApplicationCreateArgs>;
};

const FormSectionApplicationDetails: React.FC<Props> = ({ register, errors }) => {
  return (
    <>
      <InputField
        name="name"
        placeholder="Name"
        showLabel
        ref={register({ required: true })}
        error={errors.name && 'Name required'}
      />
    </>
  );
};

export default FormSectionApplicationDetails;
