import React from 'react';
import { Article, useArticleQuery, useUpdateArticleMutation } from 'generated/graphql';
import { ArticleForm } from 'app/components';
import { Card } from 'ui';

const EditArticle = ({ articleId }: { articleId: string }) => {
  const { data, loading, error } = useArticleQuery({
    variables: {
      id: articleId,
    },
  });

  const [
    articleUpdate,
    { data: dataArticleUpdate, error: errorArticleUpdate, loading: loadingArticleUpdate },
  ] = useUpdateArticleMutation();

  if (loading) return <div>Loading article...</div>;

  if (error) return <div>Error: {error.message}</div>;

  const submitData = (inputs: Article) => {
    articleUpdate({
      variables: {
        id: articleId,
        headline: inputs.headline!,
        description: inputs.description!,
        body: inputs.body!,
        author: inputs.author,
        imageUrl: inputs.imageUrl,
        published: inputs.published!,
      },
    });
  };

  return (
    <Card title="Edit Article" className="mb-3 animated fadeIn">
      <ArticleForm
        defaultInputValues={{
          _id: articleId,
          headline: data?.article?.headline,
          description: data?.article?.description,
          body: data?.article?.body,
          author: data?.article?.author,
          imageUrl: data?.article?.imageUrl,
          published: data?.article?.published,
        }}
        submitData={submitData}
        isSuccess={!!dataArticleUpdate}
        error={errorArticleUpdate!}
        isLoading={!!loadingArticleUpdate}
      />
    </Card>
  );
};

export default EditArticle;
