import React from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './AppNavigation.module.scss';
import logo from 'media/logo.svg';

interface Props {
  isAdmin: boolean;
}

const AppNavigation: React.FC<Props> = ({ isAdmin }) => {
  return (
    <nav styleName="navbar">
      <div styleName="navbar-container">
        <div className="py-4 d-block text-center">
          <img src={logo} styleName="navbar-brand-img" alt="..." />
        </div>
        <ul styleName="navbar-menu-container">
          <div styleName="navbar-menu-title">
            <i className={`${styles['navbar-menu-icon']} ${styles['rocket']} fas fa-rocket`}></i>
            Gateway
          </div>
          <li>
            <ul styleName="navbar-menu-sub-container">
              <li>
                <Link to="/my-applications" className={styles['navbar-menu-link']}>
                  My applications
                </Link>
              </li>
              <li>
                <Link to="/playground" className={styles['navbar-menu-link']}>
                  Playground
                </Link>
              </li>
              <li className={styles['navbar-menu-item']}>
                <Link to="/products" className={styles['navbar-menu-link']}>
                  Products
                </Link>
              </li>
              <li className={styles['navbar-menu-item']}>
                <Link to="/docs" className={styles['navbar-menu-link']}>
                  Docs
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        {isAdmin && (
          <ul styleName="navbar-menu-container">
            <div styleName="navbar-menu-title">
              <i className={`${styles['navbar-menu-icon']} fas fa-cog`}></i>
              Settings
            </div>
            <li>
              <ul styleName="navbar-menu-sub-container">
                <li>
                  <Link to="/users" className={styles['navbar-menu-link']}>
                    Users
                  </Link>
                </li>
                <li>
                  <Link to="/applications" className={styles['navbar-menu-link']}>
                    Applications
                  </Link>
                </li>
                <li>
                  <Link to="/servicecontainers" className={styles['navbar-menu-link']}>
                    Service containers
                  </Link>
                </li>
                <li>
                  <Link to="/companies" className={styles['navbar-menu-link']}>
                    Companies
                  </Link>
                </li>
                <li className={styles['navbar-menu-item']}>
                  <Link to="/assortments" className={styles['navbar-menu-link']}>
                    Assortments
                  </Link>
                </li>
                <li>
                  <Link to="/commerce" className={styles['navbar-menu-link']}>
                    Commerce
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default CSSModules(AppNavigation, styles);
