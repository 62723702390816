import gql from 'graphql-tag';

export const CREATED_USERNOTIFICATIONS = gql`
  subscription CreatedUserNotifications {
    userNotificationCreated {
      _id
      userId
      notificationId
      isRead
      receivedAt
      notification {
        _id
        headline
        description
        body
        link
        createdAt
        author {
          name
        }
      }
    }
  }
`;
