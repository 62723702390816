import gql from 'graphql-tag';

export const GET_PRODUCTS_BY_ASSORTMENT_ID = gql`
  query Products($language: String!, $assortmentId: String!, $page: Int!, $pageSize: PageSize!) {
    productsByAssortmentId(language: $language, assortmentId: $assortmentId, page: $page, pageSize: $pageSize) {
      pageInfo {
        hasNextPage
      }
      count
      result {
        productNumber
        productName
        productGender {
          value
        }
        productBrand
        productCareInstructions {
          key
          value
        }
        variations {
          itemNumber
          itemColorCode
          itemColorName
          pictures {
            imageUrl
            resourceFileId
            resourcePictureType
          }
        }
        pictures {
          imageUrl
          resourceFileId
          resourcePictureType
        }
        retailPrice {
          price
          currency
        }
      }
    }
  }
`;

export const GET_TRANSLATED_PRODUCTS_BY_ASSORTMENT_ID = gql`
  query TranslatedProducts($assortmentId: String!, $page: Int!, $pageSize: PageSize!) {
    productsTranslatedByAssortmentId(assortmentId: $assortmentId, page: $page, pageSize: $pageSize) {
      result {
        ...fullProductTranslated
      }
      pageInfo {
        hasNextPage
      }
      count
    }
  }
`;
