import React, { useState } from 'react';
import { SEARCH_USERS } from 'graphql/queries';
import { Card, InputForm, InputField, StateButton, Select } from 'ui';
import { toast } from 'react-toastify';
import { OptionTypeBase, ValueType } from 'react-select/src/types';
import { useCompaniesQuery, useServiceContainersQuery, useInviteUserMutation } from 'generated/graphql';

interface Inputs {
  email: string;
  companyIds?: string[];
  serviceContainerIds?: string[];
}

const InviteUser = () => {
  const [inputs, setInputs] = useState<Inputs>({ email: '' });
  const [valid, setValid] = useState(true);

  const { data: companyData } = useCompaniesQuery();
  const { data: serviceContainerData } = useServiceContainersQuery();
  const [userInvite, { data, error, loading }] = useInviteUserMutation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCompanyChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setInputs((inputs) => ({ ...inputs, companyIds: [''] }));
      setValid(false);
    } else {
      const ids = values.map((val: OptionTypeBase) => val.value);
      setInputs((inputs) => ({ ...inputs, companyIds: ids }));
      setValid(true);
    }
  };

  const handleServiceContainerChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setInputs((inputs) => ({ ...inputs, serviceContainerIds: [''] }));
      setValid(false);
    } else {
      const ids = values.map((val: OptionTypeBase) => val.value);
      setInputs((inputs) => ({ ...inputs, serviceContainerIds: ids }));
      setValid(true);
    }
  };

  const notify = (userEmail: string) => toast.success(`Invite sent to ${userEmail}.`);

  const companyOptions =
    companyData &&
    companyData.companies!.map((company) => ({
      value: company!._id!,
      label: company!.name!,
    }));

  const serviceContainerOptions =
    serviceContainerData &&
    serviceContainerData.serviceContainers!.map((service) => ({
      value: service!._id!,
      label: service!.name!,
    }));

  return (
    <Card title="Invite user" className="mb-3">
      <InputForm
        onSubmit={(e) => {
          e.preventDefault();

          if (!inputs!.serviceContainerIds) {
            setValid(false);
            return;
          }

          userInvite({
            variables: {
              email: inputs!.email,
              companyIds: inputs!.companyIds,
              serviceContainerIds: inputs!.serviceContainerIds,
            },
            refetchQueries: [{ query: SEARCH_USERS, variables: { page: 1, perPage: 15 } }],
          });

          notify(inputs!.email);
        }}
        loading={loading}
        error={error}
      >
        <InputField
          name="email"
          placeholder="E-mail address"
          value={(inputs && inputs.email) || ''}
          onChange={handleInputChange}
          className="form-control"
          required
        ></InputField>
        {companyOptions && (
          <Select
            label="Company(s)"
            isMulti
            name="companies"
            defaultValue={[]}
            onChange={(value: ValueType<OptionTypeBase>) => handleCompanyChange(value)}
            options={companyOptions}
          />
        )}
        {serviceContainerOptions && (
          <Select
            label="Service container(s)"
            isMulti
            name="serviceContainers"
            defaultValue={[]}
            onChange={(value: ValueType<OptionTypeBase>) => handleServiceContainerChange(value)}
            options={serviceContainerOptions}
          />
        )}
        {!valid && <div className="text-red">Please choose services.</div>}
        <StateButton
          type="submit"
          size={35}
          color="green"
          className="float-right my-2"
          isLoading={loading}
          isError={!!error}
          isSuccess={!!data}
        >
          Send invite
        </StateButton>
      </InputForm>
    </Card>
  );
};

export default InviteUser;
