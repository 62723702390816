import gql from 'graphql-tag';

export const GET_USERNOTIFICATIONS = gql`
  query UserNotifications($limit: Int) {
    userNotifications(limit: $limit, sort: "desc") {
      _id
      userId
      notificationId
      isRead
      receivedAt
      notification {
        _id
        headline
        description
        body
        link
        createdAt
        author {
          name
        }
      }
    }
  }
`;
