import gql from 'graphql-tag';

export const GET_COMMERCE_APPS = gql`
  query CommerceApps {
    commerceApps {
      id
      name
      settings {
        erpCompanyCode
      }
    }
  }
`;
