import gql from 'graphql-tag';

export const GET_ARTICLES = gql`
  query Articles($page: Int!, $perPage: Int!, $published: Boolean) {
    articlesPagination(page: $page, perPage: $perPage, filter: { published: $published }, sort: _ID_DESC) {
      items {
        _id
        headline
        description
        body
        author
        imageUrl
        publishedAt
        published
        updatedAt
      }
      pageInfo {
        currentPage
        pageCount
        perPage
        hasNextPage
        hasPreviousPage
      }
      count
    }
  }
`;
