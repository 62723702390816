import gql from 'graphql-tag';

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productNumber: String!, $language: String!) {
    productById(productNumber: $productNumber, language: $language) {
      productNumber
      productName
      productText
      pictures {
        resourceFileId
      }
      retailPrice {
        price
        currency
      }
      variations {
        itemNumber
        itemColorName
        pictures {
          resourceFileId
        }
      }
    }
  }
`;
