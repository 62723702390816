import gql from 'graphql-tag';

export const GET_PRODUCT_FILTERS = gql`
  query GetProductFilters($assortmentId: String!, $language: String!, $companyCode: String) {
    productFilters(assortmentId: $assortmentId, language: $language, companyCode: $companyCode) {
      filter
      values {
        key
        value
      }
    }
  }
`;
