import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  /** Valid pagesize value, between 1 and 100. */
  PageSize: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};


export type _IdOperatorsFilterFindManyapplicationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyarticleInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManycommerceUserLinkInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyemailInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManynotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyserviceContainerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyuserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type Application = {
  __typename?: 'application';
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenExpiration?: Maybe<Scalars['String']>;
  tokenExp?: Maybe<Scalars['Date']>;
  tokenIat?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  companyCode?: Maybe<Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  commerceContextId?: Maybe<Scalars['String']>;
  commerceContextName?: Maybe<Scalars['String']>;
  commerceUserId?: Maybe<Scalars['String']>;
  commerceUserName?: Maybe<Scalars['String']>;
  complexityLimit?: Maybe<Scalars['Float']>;
  pricelists?: Maybe<Array<Maybe<UserPricelists>>>;
  _id: Scalars['MongoID'];
  user?: Maybe<User>;
  commerceApp?: Maybe<CommerceApp>;
  commerceAppUser?: Maybe<CommerceAppUser>;
};

export type Article = {
  __typename?: 'article';
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
};

/** List of items with pagination. */
export type ArticlePagination = {
  __typename?: 'articlePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Article>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AsideMenu = {
  __typename?: 'AsideMenu';
  active?: Maybe<Scalars['Boolean']>;
};

export type Assortment = {
  __typename?: 'Assortment';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assortmentId?: Maybe<Scalars['String']>;
  parent?: Maybe<Array<Maybe<Assortment>>>;
  parentId?: Maybe<Scalars['String']>;
  descendantNodes?: Maybe<Array<Maybe<Assortment>>>;
  childNodeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ancestorNodes?: Maybe<Array<Maybe<Assortment>>>;
  childProducts?: Maybe<ProductResult>;
  childProductIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  language?: Maybe<Scalars['String']>;
  childNodes?: Maybe<Array<Maybe<Assortment>>>;
};


export type AssortmentChildProductsArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
};

export type AssortmentResult = {
  __typename?: 'AssortmentResult';
  result?: Maybe<Array<Maybe<Assortment>>>;
  count?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfo>;
  took?: Maybe<Scalars['Float']>;
  error?: Maybe<ElasticError>;
};

export type CommerceApp = {
  __typename?: 'CommerceApp';
  settings?: Maybe<CommerceAppSettings>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<CommerceAppUser>>>;
};

export type CommerceAppBase = {
  __typename?: 'CommerceAppBase';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<CommerceAppSettings>;
};

export type CommerceAppSettings = {
  __typename?: 'CommerceAppSettings';
  erpCompanyCode?: Maybe<Scalars['String']>;
  erpCompanyUser?: Maybe<Scalars['String']>;
  erpCompanyUserKey?: Maybe<Scalars['String']>;
  assortmentId?: Maybe<Scalars['String']>;
  defaultTransportType?: Maybe<Scalars['String']>;
  defaultOrderType?: Maybe<Scalars['String']>;
  defaultSplitDelivery?: Maybe<Scalars['Boolean']>;
  stockExcludeCompaniesFromGlobal?: Maybe<Scalars['Boolean']>;
  stockSetting?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Scalars['String']>;
  blockInvalidCreditCustomers?: Maybe<Scalars['Boolean']>;
  hideRetailPrices?: Maybe<Scalars['Boolean']>;
  orderTypes?: Maybe<Array<Maybe<CommerceAppTypeSetting>>>;
  transportTypes?: Maybe<Array<Maybe<CommerceAppTypeSetting>>>;
  sellerOrderTypes?: Maybe<Array<Maybe<CommerceAppTypeSetting>>>;
  noLocalStock?: Maybe<Scalars['Boolean']>;
};

export type CommerceAppTypeSetting = {
  __typename?: 'CommerceAppTypeSetting';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CommerceAppUser = {
  __typename?: 'CommerceAppUser';
  contextId?: Maybe<Scalars['String']>;
  priceViewSetting?: Maybe<Scalars['Int']>;
  preferredColor?: Maybe<Scalars['String']>;
  preferredOrderType?: Maybe<Scalars['String']>;
  useCompanyEmailForOrderConfirmation?: Maybe<Scalars['Boolean']>;
  customerCode?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  orderTypes?: Maybe<Scalars['String']>;
  transportTypes?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  logoRef?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  appInfo?: Maybe<CommerceApp>;
};

export type CommerceAppUserResult = {
  __typename?: 'CommerceAppUserResult';
  result?: Maybe<Array<Maybe<CommerceAppUser>>>;
  count?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfo>;
  took?: Maybe<Scalars['Float']>;
  error?: Maybe<ElasticError>;
};

export type CommerceUser = {
  __typename?: 'CommerceUser';
  id?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  logoRef?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  contexts?: Maybe<Array<Maybe<CommerceAppUser>>>;
};

export type CommerceUserLink = {
  __typename?: 'commerceUserLink';
  name?: Maybe<Scalars['String']>;
  contexts?: Maybe<Array<Maybe<CommerceUserLinkContexts>>>;
  users?: Maybe<Array<Maybe<CommerceUserLinkUsers>>>;
  _id: Scalars['MongoID'];
};

export type CommerceUserLinkContexts = {
  __typename?: 'commerceUserLinkContexts';
  companyCode?: Maybe<Scalars['String']>;
  contextId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CommerceUserLinkContextsInput = {
  companyCode?: Maybe<Scalars['String']>;
  contextId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CommerceUserLinkUsers = {
  __typename?: 'commerceUserLinkUsers';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contextIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommerceUserLinkUsersInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contextIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommerceUserResult = {
  __typename?: 'CommerceUserResult';
  result?: Maybe<Array<Maybe<CommerceUser>>>;
  count?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfo>;
  took?: Maybe<Scalars['Float']>;
  error?: Maybe<ElasticError>;
};

export type Company = {
  __typename?: 'company';
  name?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  users?: Maybe<Array<Maybe<User>>>;
};


export type CompanyUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserInput>;
};

export type CreateOnearticleInput = {
  headline: Scalars['String'];
  description: Scalars['String'];
  body: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<Scalars['Date']>;
};

export type CreateOnearticlePayload = {
  __typename?: 'CreateOnearticlePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Article>;
};

export type CreateOnecommerceUserLinkInput = {
  name: Scalars['String'];
  contexts: Array<Maybe<CommerceUserLinkContextsInput>>;
  users: Array<Maybe<CommerceUserLinkUsersInput>>;
};

export type CreateOnecommerceUserLinkPayload = {
  __typename?: 'CreateOnecommerceUserLinkPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<CommerceUserLink>;
};

export type CreateOnecompanyInput = {
  name: Scalars['String'];
  companyCode: Scalars['String'];
};

export type CreateOnecompanyPayload = {
  __typename?: 'CreateOnecompanyPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Company>;
};

export type CreateOneemailInput = {
  from: Scalars['String'];
  to: Array<Maybe<Scalars['String']>>;
  subject: Scalars['String'];
  preheader: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
  c2a_text?: Maybe<Scalars['String']>;
  c2a_url?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  error?: Maybe<Scalars['String']>;
};

export type CreateOneemailPayload = {
  __typename?: 'CreateOneemailPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Email>;
};

export type CreateOnenotificationInput = {
  author?: Maybe<NotificationAuthorInput>;
  headline: Scalars['String'];
  description: Scalars['String'];
  body: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type CreateOnenotificationPayload = {
  __typename?: 'CreateOnenotificationPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Notification>;
};

export type CreateOneserviceContainerInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateOneserviceContainerPayload = {
  __typename?: 'CreateOneserviceContainerPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<ServiceContainer>;
};

export type CreateOneuserAssortmentInput = {
  name: Scalars['String'];
  assortmentId: Scalars['String'];
  users?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type CreateOneuserAssortmentPayload = {
  __typename?: 'CreateOneuserAssortmentPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserAssortment>;
};

export type CreateOneuserRoleInput = {
  name: Scalars['String'];
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type CreateOneuserRolePayload = {
  __typename?: 'CreateOneuserRolePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserRole>;
};


export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type DeliveryAddressInput = {
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ElasticError = {
  __typename?: 'ElasticError';
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'email';
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  preheader?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  c2a_text?: Maybe<Scalars['String']>;
  c2a_url?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  error?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  recipients?: Maybe<Array<Maybe<User>>>;
};


export type EmailRecipientsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserInput>;
};

export type ErpOrderLineItem = {
  __typename?: 'ERPOrderLineItem';
  sku?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  lineNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
  totalAmountExcludingVAT?: Maybe<Scalars['Float']>;
  totalAmountIncludingVAT?: Maybe<Scalars['Float']>;
  vatAmount?: Maybe<Scalars['Float']>;
  deliveryDate?: Maybe<Scalars['String']>;
  dispatchDate?: Maybe<Scalars['String']>;
  confirmedDispatchDate?: Maybe<Scalars['String']>;
  netVolume?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  grossVolume?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type ExportConfiguration = {
  __typename?: 'exportConfiguration';
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['MongoID']>;
  queryVariables?: Maybe<ExportConfigurationQueryVariables>;
  _id: Scalars['MongoID'];
  user?: Maybe<User>;
};

export type ExportConfigurationQueryVariables = {
  __typename?: 'exportConfigurationQueryVariables';
  assortmentId?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<ExportConfigurationQueryVariablesFilters>>>;
  sheetSettings?: Maybe<ExportConfigurationQueryVariablesSheetSettings>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ExportConfigurationQueryVariablesFilters = {
  __typename?: 'exportConfigurationQueryVariablesFilters';
  filter?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ExportConfigurationQueryVariablesFiltersInput = {
  filter?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ExportConfigurationQueryVariablesInput = {
  assortmentId?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<ExportConfigurationQueryVariablesFiltersInput>>>;
  sheetSettings?: Maybe<ExportConfigurationQueryVariablesSheetSettingsInput>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ExportConfigurationQueryVariablesSheetSettings = {
  __typename?: 'exportConfigurationQueryVariablesSheetSettings';
  imageLevel?: Maybe<Scalars['String']>;
  rowLevel?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricelists?: Maybe<Array<Maybe<UserPricelists>>>;
  units?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ExportConfigurationQueryVariablesSheetSettingsInput = {
  imageLevel?: Maybe<Scalars['String']>;
  rowLevel?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricelists?: Maybe<Array<Maybe<UserPricelistsInput>>>;
  units?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type FilterFindManyapplicationInput = {
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenExpiration?: Maybe<Scalars['String']>;
  tokenExp?: Maybe<Scalars['Date']>;
  tokenIat?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['MongoID']>;
  companyCode?: Maybe<Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  commerceContextId?: Maybe<Scalars['String']>;
  commerceContextName?: Maybe<Scalars['String']>;
  commerceUserId?: Maybe<Scalars['String']>;
  commerceUserName?: Maybe<Scalars['String']>;
  complexityLimit?: Maybe<Scalars['Float']>;
  pricelists?: Maybe<Array<Maybe<UserPricelistsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyapplicationInput>;
  OR?: Maybe<Array<FilterFindManyapplicationInput>>;
  AND?: Maybe<Array<FilterFindManyapplicationInput>>;
};

export type FilterFindManyarticleInput = {
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyarticleInput>;
  OR?: Maybe<Array<FilterFindManyarticleInput>>;
  AND?: Maybe<Array<FilterFindManyarticleInput>>;
};

export type FilterFindManycommerceUserLinkInput = {
  name?: Maybe<Scalars['String']>;
  contexts?: Maybe<Array<Maybe<CommerceUserLinkContextsInput>>>;
  users?: Maybe<Array<Maybe<CommerceUserLinkUsersInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManycommerceUserLinkInput>;
  OR?: Maybe<Array<FilterFindManycommerceUserLinkInput>>;
  AND?: Maybe<Array<FilterFindManycommerceUserLinkInput>>;
};

export type FilterFindManyemailInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject?: Maybe<Scalars['String']>;
  preheader?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  c2a_text?: Maybe<Scalars['String']>;
  c2a_url?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  error?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyemailInput>;
  OR?: Maybe<Array<FilterFindManyemailInput>>;
  AND?: Maybe<Array<FilterFindManyemailInput>>;
};

export type FilterFindManynotificationInput = {
  author?: Maybe<NotificationAuthorInput>;
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManynotificationInput>;
  OR?: Maybe<Array<FilterFindManynotificationInput>>;
  AND?: Maybe<Array<FilterFindManynotificationInput>>;
};

export type FilterFindManyserviceContainerInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyserviceContainerInput>;
  OR?: Maybe<Array<FilterFindManyserviceContainerInput>>;
  AND?: Maybe<Array<FilterFindManyserviceContainerInput>>;
};

export type FilterFindManyuserInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  companyIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  pricelists?: Maybe<Array<Maybe<UserPricelistsInput>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  registeredAt?: Maybe<Scalars['Date']>;
  lastLogin?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyuserInput>;
  OR?: Maybe<Array<FilterFindManyuserInput>>;
  AND?: Maybe<Array<FilterFindManyuserInput>>;
  /** Search by regExp */
  usernameRegexp?: Maybe<Scalars['String']>;
};


export type LineItemInput = {
  sku: Scalars['String'];
  quantity: Scalars['Int'];
  salesPrice?: Maybe<Scalars['Float']>;
  requestedDispatchDate?: Maybe<Scalars['Date']>;
  comment?: Maybe<Scalars['String']>;
  addOnArticle?: Maybe<Scalars['Boolean']>;
};


export type Mutation = {
  __typename?: 'Mutation';
  applicationCreate?: Maybe<Application>;
  applicationRemoveById?: Maybe<Application>;
  applicationUpdateStatus?: Maybe<Application>;
  applicationUpdateStatusByUserId?: Maybe<Array<Maybe<Application>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  articleCreate?: Maybe<CreateOnearticlePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  articleRemoveById?: Maybe<RemoveByIdarticlePayload>;
  /**
   * Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  articleUpdate?: Maybe<UpdateByIdarticlePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  commerceUserLinkCreate?: Maybe<CreateOnecommerceUserLinkPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  commerceUserLinkRemoveById?: Maybe<RemoveByIdcommerceUserLinkPayload>;
  /**
   * Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  commerceUserLinkUpdateById?: Maybe<UpdateByIdcommerceUserLinkPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  companyCreate?: Maybe<CreateOnecompanyPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  companyRemoveById?: Maybe<RemoveByIdcompanyPayload>;
  emailCreateAndSend?: Maybe<CreateOneemailPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  exportConfigurationRemove?: Maybe<RemoveByIdexportConfigurationPayload>;
  exportConfigurationSave?: Maybe<ExportConfiguration>;
  exportConfigurationUpdateCurrent?: Maybe<ExportConfiguration>;
  login?: Maybe<UpdateByIduserPayload>;
  meUpdate?: Maybe<User>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  notificationCreate?: Maybe<CreateOnenotificationPayload>;
  notificationCreateAndSend?: Maybe<CreateOnenotificationPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  serviceContainerCreate?: Maybe<CreateOneserviceContainerPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  serviceContainerRemoveById?: Maybe<RemoveByIdserviceContainerPayload>;
  toggleAsideMenu?: Maybe<AsideMenu>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  userAssortmentCreate?: Maybe<CreateOneuserAssortmentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  userAssortmentDelete?: Maybe<RemoveByIduserAssortmentPayload>;
  /**
   * Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  userAssortmentUpdate?: Maybe<UpdateByIduserAssortmentPayload>;
  userConfirm?: Maybe<User>;
  userInvite?: Maybe<User>;
  /**
   * Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  userNotificationUpdateById?: Maybe<UpdateByIduserNotificationPayload>;
  userRegister?: Maybe<User>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  userRemoveById?: Maybe<RemoveByIduserPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  userRoleCreate?: Maybe<CreateOneuserRolePayload>;
  userUpdateById?: Maybe<User>;
};


export type MutationApplicationCreateArgs = {
  name: Scalars['String'];
  companyCode: Scalars['String'];
  commerceContextId?: Maybe<Scalars['String']>;
  commerceUserId?: Maybe<Scalars['String']>;
  commerceContextName?: Maybe<Scalars['String']>;
  commerceUserName?: Maybe<Scalars['String']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>>>;
};


export type MutationApplicationRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationApplicationUpdateStatusArgs = {
  id: Scalars['MongoID'];
  active?: Maybe<Scalars['Boolean']>;
  complexityLimit?: Maybe<Scalars['Int']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>>>;
};


export type MutationApplicationUpdateStatusByUserIdArgs = {
  userId: Scalars['MongoID'];
  active: Scalars['Boolean'];
};


export type MutationArticleCreateArgs = {
  record: CreateOnearticleInput;
};


export type MutationArticleRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationArticleUpdateArgs = {
  record: UpdateByIdarticleInput;
};


export type MutationCommerceUserLinkCreateArgs = {
  record: CreateOnecommerceUserLinkInput;
};


export type MutationCommerceUserLinkRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationCommerceUserLinkUpdateByIdArgs = {
  record: UpdateByIdcommerceUserLinkInput;
};


export type MutationCompanyCreateArgs = {
  record: CreateOnecompanyInput;
};


export type MutationCompanyRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationEmailCreateAndSendArgs = {
  record: CreateOneemailInput;
};


export type MutationExportConfigurationRemoveArgs = {
  _id: Scalars['MongoID'];
};


export type MutationExportConfigurationSaveArgs = {
  name: Scalars['String'];
};


export type MutationExportConfigurationUpdateCurrentArgs = {
  queryVariables?: Maybe<ExportConfigurationQueryVariablesInput>;
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMeUpdateArgs = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationNotificationCreateArgs = {
  record: CreateOnenotificationInput;
};


export type MutationNotificationCreateAndSendArgs = {
  record: CreateOnenotificationInput;
  userIds?: Maybe<Array<Scalars['MongoID']>>;
};


export type MutationServiceContainerCreateArgs = {
  record: CreateOneserviceContainerInput;
};


export type MutationServiceContainerRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationToggleAsideMenuArgs = {
  active: Scalars['Boolean'];
};


export type MutationUserAssortmentCreateArgs = {
  record: CreateOneuserAssortmentInput;
};


export type MutationUserAssortmentDeleteArgs = {
  _id: Scalars['MongoID'];
};


export type MutationUserAssortmentUpdateArgs = {
  record: UpdateByIduserAssortmentInput;
};


export type MutationUserConfirmArgs = {
  id: Scalars['MongoID'];
  password: Scalars['String'];
};


export type MutationUserInviteArgs = {
  email: Scalars['String'];
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationUserNotificationUpdateByIdArgs = {
  record: UpdateByIduserNotificationInput;
};


export type MutationUserRegisterArgs = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>>>;
};


export type MutationUserRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type MutationUserRoleCreateArgs = {
  record: CreateOneuserRoleInput;
};


export type MutationUserUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  companyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>>>;
};

export type Notification = {
  __typename?: 'notification';
  author?: Maybe<NotificationAuthor>;
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
};

export type NotificationAuthor = {
  __typename?: 'notificationAuthor';
  userId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type NotificationAuthorInput = {
  userId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type NotificationPagination = {
  __typename?: 'notificationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyapplicationInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyapplicationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyarticleInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyarticleInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManycommerceUserLinkInput = {
  _id?: Maybe<_IdOperatorsFilterFindManycommerceUserLinkInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyemailInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyemailInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManynotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindManynotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyserviceContainerInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyserviceContainerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyuserInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyuserInput>;
};

export type Order = {
  __typename?: 'Order';
  deliveryAddress?: Maybe<DeliveryAddress>;
  invoices?: Maybe<Array<Maybe<OrderInvoice>>>;
  lines?: Maybe<Array<Maybe<ErpOrderLineItem>>>;
  tracking?: Maybe<Array<Maybe<OrderTracking>>>;
  orderNumber?: Maybe<Scalars['Int']>;
  orderDate?: Maybe<Scalars['String']>;
  packageLabel?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  customerOrderReference?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['Boolean']>;
  invoiced?: Maybe<Scalars['Boolean']>;
  clientOrderId?: Maybe<Scalars['String']>;
  total?: Maybe<OrderTotalValue>;
  orderType?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  completeDelivery?: Maybe<Scalars['Boolean']>;
};

export type OrderFees = {
  __typename?: 'OrderFees';
  total?: Maybe<Scalars['Float']>;
  freight?: Maybe<Scalars['Float']>;
  postage?: Maybe<Scalars['Float']>;
  insurance?: Maybe<Scalars['Float']>;
  administration?: Maybe<Scalars['Float']>;
  invoice?: Maybe<Scalars['Float']>;
};

export type OrderHeader = {
  __typename?: 'OrderHeader';
  orderNumber?: Maybe<Scalars['Int']>;
  orderDate?: Maybe<Scalars['String']>;
  packageLabel?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  customerOrderReference?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['Boolean']>;
  invoiced?: Maybe<Scalars['Boolean']>;
  clientOrderId?: Maybe<Scalars['String']>;
  total?: Maybe<OrderTotalValue>;
  orderType?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  completeDelivery?: Maybe<Scalars['Boolean']>;
};

export type OrderHeaderInput = {
  orderType?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  completeDelivery?: Maybe<Scalars['Boolean']>;
  packageLabel?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  customerOrderReference?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type OrderInvoice = {
  __typename?: 'OrderInvoice';
  customerNumber?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  deliveryDescription?: Maybe<Scalars['String']>;
  paymentDescription?: Maybe<Scalars['String']>;
  totalExVat?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  created?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientOrderId?: Maybe<Scalars['String']>;
};

export type OrderTotalValue = {
  __typename?: 'OrderTotalValue';
  total?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  gross?: Maybe<Scalars['Float']>;
  net?: Maybe<Scalars['Float']>;
  fees?: Maybe<OrderFees>;
  currency?: Maybe<Scalars['String']>;
  totalDiscount?: Maybe<Scalars['String']>;
};

export type OrderTracking = {
  __typename?: 'OrderTracking';
  /** @deprecated This field will be replaced by trackingUrl and might be removed in the future. */
  url?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  pageCount: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};


export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount?: Maybe<Scalars['Int']>;
  itemCount?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type PricelistInput = {
  pricelist: Scalars['String'];
  companyCode: Scalars['String'];
  currency: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  productActivityType?: Maybe<Array<Maybe<StringKvp>>>;
  productApplicationAreas?: Maybe<Array<Maybe<StringKvp>>>;
  productApplicationTypes?: Maybe<Array<Maybe<StringKvp>>>;
  productCareInstructions?: Maybe<Array<Maybe<StringKvp>>>;
  productCatalogText?: Maybe<Scalars['String']>;
  productCatalogPrice?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Array<Maybe<StringKvp>>>;
  productCertifications?: Maybe<Array<Maybe<StringKvp>>>;
  productClosure?: Maybe<Array<Maybe<StringKvp>>>;
  productColorComment?: Maybe<Scalars['String']>;
  productColorName?: Maybe<Scalars['String']>;
  productCommerceText?: Maybe<Scalars['String']>;
  productFabrics?: Maybe<Scalars['String']>;
  productFeature?: Maybe<Array<Maybe<StringKvp>>>;
  productFit?: Maybe<Array<Maybe<StringKvp>>>;
  productGender?: Maybe<Array<Maybe<StringKvp>>>;
  productHoodDetails?: Maybe<Array<Maybe<StringKvp>>>;
  productMaterialTechnique?: Maybe<Array<Maybe<StringKvp>>>;
  productName?: Maybe<Scalars['String']>;
  productNeckline?: Maybe<Array<Maybe<StringKvp>>>;
  productPackaging?: Maybe<Scalars['String']>;
  productPackagingStandard?: Maybe<Array<Maybe<StringKvp>>>;
  productPockets?: Maybe<Array<Maybe<StringKvp>>>;
  productSleeve?: Maybe<Array<Maybe<StringKvp>>>;
  productText?: Maybe<Scalars['String']>;
  productTheme?: Maybe<Array<Maybe<StringKvp>>>;
  productUsp?: Maybe<Scalars['String']>;
  filterColor?: Maybe<Array<Maybe<StringKvp>>>;
  variations?: Maybe<Array<Maybe<ProductVariation>>>;
  relatedProducts?: Maybe<Array<Maybe<Product>>>;
  replacementProducts?: Maybe<Array<Maybe<Product>>>;
  genderRelatedProducts?: Maybe<Array<Maybe<Product>>>;
  productBrand?: Maybe<Scalars['String']>;
  productCapacityStandard?: Maybe<Scalars['Float']>;
  productCatalogSize?: Maybe<Scalars['String']>;
  productDesignerStandard?: Maybe<Array<Maybe<Scalars['String']>>>;
  productDiameterStandard?: Maybe<Scalars['Float']>;
  productHeightStandard?: Maybe<Scalars['Float']>;
  productLengthStandard?: Maybe<Scalars['Float']>;
  productMeasure?: Maybe<Scalars['String']>;
  productNewUntil?: Maybe<Scalars['String']>;
  productAvailableFrom?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  productPrintCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  productPromoSeason?: Maybe<Array<Maybe<Scalars['String']>>>;
  productRange?: Maybe<Scalars['String']>;
  productRetailSeason?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVolumeStandard?: Maybe<Scalars['Float']>;
  productWebColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  productWeight?: Maybe<Scalars['String']>;
  productWeightStandard?: Maybe<Scalars['Float']>;
  productWidthStandard?: Maybe<Scalars['Float']>;
  pictures?: Maybe<Array<Maybe<ProductResource>>>;
  documents?: Maybe<Array<Maybe<ProductResource>>>;
  language?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  /** Is marked as on sale for token company */
  outlet?: Maybe<Scalars['Boolean']>;
  retailPrice?: Maybe<SkuRetailPrice>;
  prices?: Maybe<Array<Maybe<SkuPrice>>>;
  assortmentNodes?: Maybe<Array<Maybe<Assortment>>>;
  sizes?: Maybe<Scalars['String']>;
};


export type ProductAssortmentNodesArgs = {
  filterByAssortment?: Maybe<Scalars['String']>;
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  filter: Scalars['String'];
  values?: Maybe<Array<Maybe<StringKvp>>>;
};

export type ProductFilterInput = {
  filter?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductResource = {
  __typename?: 'ProductResource';
  resourceFileId?: Maybe<Scalars['String']>;
  resourceFileName?: Maybe<Scalars['String']>;
  resourcePictureType?: Maybe<Scalars['String']>;
  resourcePictureAngle?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type ProductResult = {
  __typename?: 'ProductResult';
  result?: Maybe<Array<Maybe<Product>>>;
  count?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfo>;
  took?: Maybe<Scalars['Float']>;
  error?: Maybe<ElasticError>;
};

export type ProductTranslated = {
  __typename?: 'ProductTranslated';
  productActivityTypes?: Maybe<Array<Maybe<TranslationKvp>>>;
  productApplicationAreas?: Maybe<Array<Maybe<TranslationKvp>>>;
  productApplicationTypes?: Maybe<Array<Maybe<TranslationKvp>>>;
  productCareInstructions?: Maybe<Array<Maybe<TranslationKvp>>>;
  productCatalogText?: Maybe<Translation>;
  productCatalogPrice?: Maybe<Translation>;
  productCategory?: Maybe<Array<Maybe<TranslationKvp>>>;
  productCertifications?: Maybe<Array<Maybe<TranslationKvp>>>;
  productClosure?: Maybe<Array<Maybe<TranslationKvp>>>;
  productColorComment?: Maybe<Translation>;
  productColorName?: Maybe<Translation>;
  productCommerceText?: Maybe<Translation>;
  productFabrics?: Maybe<Translation>;
  productFeature?: Maybe<Array<Maybe<TranslationKvp>>>;
  productFit?: Maybe<Array<Maybe<TranslationKvp>>>;
  productGender?: Maybe<Array<Maybe<TranslationKvp>>>;
  productHoodDetails?: Maybe<Array<Maybe<TranslationKvp>>>;
  productMaterialTechnique?: Maybe<Array<Maybe<TranslationKvp>>>;
  productName?: Maybe<Translation>;
  productNeckline?: Maybe<Array<Maybe<TranslationKvp>>>;
  productPackaging?: Maybe<Translation>;
  productPackagingStandard?: Maybe<Array<Maybe<TranslationKvp>>>;
  productPockets?: Maybe<Array<Maybe<TranslationKvp>>>;
  productSleeve?: Maybe<Array<Maybe<TranslationKvp>>>;
  productText?: Maybe<Translation>;
  productTheme?: Maybe<Array<Maybe<TranslationKvp>>>;
  productUsp?: Maybe<Translation>;
  filterColor?: Maybe<Array<Maybe<TranslationKvp>>>;
  variations?: Maybe<Array<Maybe<ProductVariationTranslated>>>;
  relatedProducts?: Maybe<Array<Maybe<ProductTranslated>>>;
  replacementProducts?: Maybe<Array<Maybe<ProductTranslated>>>;
  genderRelatedProducts?: Maybe<Array<Maybe<ProductTranslated>>>;
  productBrand?: Maybe<Scalars['String']>;
  productCapacityStandard?: Maybe<Scalars['Float']>;
  productCatalogSize?: Maybe<Scalars['String']>;
  productDesignerStandard?: Maybe<Array<Maybe<Scalars['String']>>>;
  productDiameterStandard?: Maybe<Scalars['Float']>;
  productHeightStandard?: Maybe<Scalars['Float']>;
  productLengthStandard?: Maybe<Scalars['Float']>;
  productMeasure?: Maybe<Scalars['String']>;
  productNewUntil?: Maybe<Scalars['String']>;
  productAvailableFrom?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  productPrintCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  productPromoSeason?: Maybe<Array<Maybe<Scalars['String']>>>;
  productRange?: Maybe<Scalars['String']>;
  productRetailSeason?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVolumeStandard?: Maybe<Scalars['Float']>;
  productWebColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  productWeight?: Maybe<Scalars['String']>;
  productWeightStandard?: Maybe<Scalars['Float']>;
  productWidthStandard?: Maybe<Scalars['Float']>;
  pictures?: Maybe<Array<Maybe<ProductResource>>>;
  documents?: Maybe<Array<Maybe<ProductResource>>>;
  language?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  /** Is marked as on sale for token company */
  outlet?: Maybe<Scalars['Boolean']>;
  retailPrice?: Maybe<SkuRetailPrice>;
  prices?: Maybe<Array<Maybe<SkuPrice>>>;
  assortmentNodes?: Maybe<Array<Maybe<Assortment>>>;
  sizes?: Maybe<Scalars['String']>;
};


export type ProductTranslatedAssortmentNodesArgs = {
  filterByAssortment?: Maybe<Scalars['String']>;
};

export type ProductTranslatedResult = {
  __typename?: 'ProductTranslatedResult';
  result?: Maybe<Array<Maybe<ProductTranslated>>>;
  count?: Maybe<Scalars['Int']>;
  pageInfo?: Maybe<PageInfo>;
  took?: Maybe<Scalars['Float']>;
  error?: Maybe<ElasticError>;
};

export type ProductVariation = {
  __typename?: 'ProductVariation';
  itemColorName?: Maybe<Scalars['String']>;
  filterColor?: Maybe<Array<Maybe<StringKvp>>>;
  productNumber?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  itemColorCode?: Maybe<Scalars['String']>;
  itemWebColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  pictures?: Maybe<Array<Maybe<ProductResource>>>;
  /** You need to be an administrator to query this field. */
  itemActiveForCompany?: Maybe<Array<Maybe<StringKvp>>>;
  itemNewUntil?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  /** Is marked as on sale for token company */
  outlet?: Maybe<Scalars['Boolean']>;
  sizes?: Maybe<Scalars['String']>;
};

export type ProductVariationTranslated = {
  __typename?: 'ProductVariationTranslated';
  itemColorName?: Maybe<Translation>;
  filterColor?: Maybe<Array<Maybe<TranslationKvp>>>;
  productNumber?: Maybe<Scalars['String']>;
  itemNumber?: Maybe<Scalars['String']>;
  itemColorCode?: Maybe<Scalars['String']>;
  itemWebColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  pictures?: Maybe<Array<Maybe<ProductResource>>>;
  /** You need to be an administrator to query this field. */
  itemActiveForCompany?: Maybe<Array<Maybe<StringKvp>>>;
  itemNewUntil?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Sku>>>;
  /** Is marked as on sale for token company */
  outlet?: Maybe<Scalars['Boolean']>;
  sizes?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allSkusByProductNumber?: Maybe<Array<Maybe<Sku>>>;
  applications?: Maybe<Array<Maybe<Application>>>;
  applicationsByUserId?: Maybe<Array<Maybe<Application>>>;
  article?: Maybe<Article>;
  articlesPagination?: Maybe<ArticlePagination>;
  asideMenu?: Maybe<AsideMenu>;
  assortment?: Maybe<AssortmentResult>;
  assortmentNodeById?: Maybe<Assortment>;
  commerceApp?: Maybe<CommerceApp>;
  commerceAppUsersByContext?: Maybe<CommerceAppUserResult>;
  commerceApps?: Maybe<Array<Maybe<CommerceAppBase>>>;
  commerceAppsByCompanyCode?: Maybe<Array<Maybe<CommerceAppBase>>>;
  commerceUser?: Maybe<CommerceUser>;
  commerceUserLinks?: Maybe<Array<Maybe<CommerceUserLink>>>;
  commerceUsers?: Maybe<CommerceUserResult>;
  commerceUsersByAppId?: Maybe<CommerceUserResult>;
  companies?: Maybe<Array<Maybe<Company>>>;
  dataLanguage?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  exportConfigurationById?: Maybe<ExportConfiguration>;
  exportConfigurationCurrent?: Maybe<ExportConfiguration>;
  exportConfigurationsByUserId?: Maybe<Array<Maybe<ExportConfiguration>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isLoggedIn: Scalars['Boolean'];
  me?: Maybe<User>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  notificationsPagination?: Maybe<NotificationPagination>;
  order?: Maybe<Order>;
  orderDelivery?: Maybe<DeliveryAddress>;
  orderHeader?: Maybe<OrderHeader>;
  orderInvoices?: Maybe<Array<Maybe<OrderInvoice>>>;
  orderLines?: Maybe<Array<Maybe<ErpOrderLineItem>>>;
  orderPlace?: Maybe<OrderResponse>;
  orderTracking?: Maybe<Array<Maybe<OrderTracking>>>;
  orders?: Maybe<Array<Maybe<OrderHeader>>>;
  productById?: Maybe<Product>;
  productFilters?: Maybe<Array<Maybe<ProductFilter>>>;
  productSearch?: Maybe<ProductResult>;
  productSearchAndSave?: Maybe<ProductResult>;
  productTranslatedById?: Maybe<ProductTranslated>;
  productTranslatedSearch?: Maybe<ProductTranslatedResult>;
  productTranslatedSearchAndSaveConfig?: Maybe<ProductTranslatedResult>;
  productsByAssortmentId?: Maybe<ProductResult>;
  productsTranslatedByAssortmentId?: Maybe<ProductTranslatedResult>;
  serviceContainers?: Maybe<Array<Maybe<ServiceContainer>>>;
  skuById?: Maybe<Sku>;
  skusByProductNumber?: Maybe<Array<Maybe<Sku>>>;
  userAssortments?: Maybe<Array<Maybe<UserAssortment>>>;
  userById?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  userInviteLink?: Maybe<Scalars['String']>;
  userNotifications?: Maybe<Array<Maybe<UserNotification>>>;
  userRoleById?: Maybe<UserRole>;
  userSearch?: Maybe<UserPagination>;
  username?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAllSkusByProductNumberArgs = {
  productNumber?: Maybe<Scalars['String']>;
};


export type QueryApplicationsArgs = {
  filter?: Maybe<FilterFindManyapplicationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyapplicationInput>;
};


export type QueryApplicationsByUserIdArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryArticleArgs = {
  _id: Scalars['MongoID'];
};


export type QueryArticlesPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManyarticleInput>;
  sort?: Maybe<SortFindManyarticleInput>;
};


export type QueryAssortmentArgs = {
  assortmentId: Scalars['String'];
  language: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
};


export type QueryAssortmentNodeByIdArgs = {
  id: Scalars['String'];
  language: Scalars['String'];
};


export type QueryCommerceAppArgs = {
  id: Scalars['String'];
};


export type QueryCommerceAppUsersByContextArgs = {
  contextId: Scalars['String'];
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
};


export type QueryCommerceAppsByCompanyCodeArgs = {
  companyCode: Scalars['String'];
};


export type QueryCommerceUserArgs = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryCommerceUserLinksArgs = {
  filter?: Maybe<FilterFindManycommerceUserLinkInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManycommerceUserLinkInput>;
};


export type QueryCommerceUsersArgs = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
};


export type QueryCommerceUsersByAppIdArgs = {
  contextId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
};


export type QueryEmailsArgs = {
  filter?: Maybe<FilterFindManyemailInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyemailInput>;
};


export type QueryExportConfigurationByIdArgs = {
  _id: Scalars['MongoID'];
};


export type QueryNotificationsArgs = {
  filter?: Maybe<FilterFindManynotificationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManynotificationInput>;
};


export type QueryNotificationsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManynotificationInput>;
  sort?: Maybe<SortFindManynotificationInput>;
};


export type QueryOrderArgs = {
  orderNumber: Scalars['String'];
  customerNumber?: Maybe<Scalars['String']>;
};


export type QueryOrderDeliveryArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrderHeaderArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrderInvoicesArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrderLinesArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrderPlaceArgs = {
  header?: Maybe<OrderHeaderInput>;
  deliveryAddress?: Maybe<DeliveryAddressInput>;
  skus?: Maybe<Array<Maybe<LineItemInput>>>;
  customerNumber?: Maybe<Scalars['String']>;
  shippingFee?: Maybe<Scalars['Float']>;
};


export type QueryOrderTrackingArgs = {
  orderNumber: Scalars['String'];
};


export type QueryOrdersArgs = {
  afterOrderNumber?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  customerNumber?: Maybe<Scalars['String']>;
};


export type QueryProductByIdArgs = {
  productNumber: Scalars['String'];
  language: Scalars['String'];
  disableColorFilter?: Maybe<Scalars['Boolean']>;
  disableCache?: Maybe<Scalars['Boolean']>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductFiltersArgs = {
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductSearchArgs = {
  q: Scalars['String'];
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>>>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductSearchAndSaveArgs = {
  q: Scalars['String'];
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>>>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductTranslatedByIdArgs = {
  productNumber: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  disableColorFilter?: Maybe<Scalars['Boolean']>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductTranslatedSearchArgs = {
  q: Scalars['String'];
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>>>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductTranslatedSearchAndSaveConfigArgs = {
  q: Scalars['String'];
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>>>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductsByAssortmentIdArgs = {
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  sort?: Maybe<SortingInput>;
  page?: Maybe<Scalars['Int']>;
  pageSize: Scalars['PageSize'];
  disableColorFilter?: Maybe<Scalars['Boolean']>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryProductsTranslatedByAssortmentIdArgs = {
  assortmentId: Scalars['String'];
  sort?: Maybe<SortingInput>;
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  disableColorFilter?: Maybe<Scalars['Boolean']>;
  companyCode?: Maybe<Scalars['String']>;
};


export type QueryServiceContainersArgs = {
  filter?: Maybe<FilterFindManyserviceContainerInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyserviceContainerInput>;
};


export type QuerySkuByIdArgs = {
  sku: Scalars['String'];
};


export type QuerySkusByProductNumberArgs = {
  productNumber: Scalars['String'];
};


export type QueryUserByIdArgs = {
  _id: Scalars['MongoID'];
};


export type QueryUserInviteLinkArgs = {
  userId: Scalars['MongoID'];
};


export type QueryUserNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryUserRoleByIdArgs = {
  _id: Scalars['MongoID'];
};


export type QueryUserSearchArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManyuserInput>;
  sort?: Maybe<SortFindManyuserInput>;
};


export type QueryUsersArgs = {
  filter?: Maybe<FilterFindManyuserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserInput>;
};

export type RemoveByIdarticlePayload = {
  __typename?: 'RemoveByIdarticlePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Article>;
};

export type RemoveByIdcommerceUserLinkPayload = {
  __typename?: 'RemoveByIdcommerceUserLinkPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<CommerceUserLink>;
};

export type RemoveByIdcompanyPayload = {
  __typename?: 'RemoveByIdcompanyPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Company>;
};

export type RemoveByIdexportConfigurationPayload = {
  __typename?: 'RemoveByIdexportConfigurationPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<ExportConfiguration>;
};

export type RemoveByIdserviceContainerPayload = {
  __typename?: 'RemoveByIdserviceContainerPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<ServiceContainer>;
};

export type RemoveByIduserAssortmentPayload = {
  __typename?: 'RemoveByIduserAssortmentPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<UserAssortment>;
};

export type RemoveByIduserPayload = {
  __typename?: 'RemoveByIduserPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<User>;
};

export type ServiceContainer = {
  __typename?: 'serviceContainer';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  users?: Maybe<Array<Maybe<User>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
};


export type ServiceContainerUsersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserInput>;
};


export type ServiceContainerRolesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserRoleInput>;
};

export type Sku = {
  __typename?: 'Sku';
  active?: Maybe<Scalars['Boolean']>;
  availability?: Maybe<Scalars['Int']>;
  /** Stock from regionally connected warehouse. */
  availabilityRegional?: Maybe<Scalars['Int']>;
  /** Aggregation of stock from all NWG european warehouses except from companies provided in excluded argument. */
  availabilityGlobal?: Maybe<Scalars['Int']>;
  boxItems?: Maybe<Scalars['Int']>;
  categorization?: Maybe<SkuCategorization>;
  changed?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discontinued?: Maybe<Scalars['Boolean']>;
  eanCode?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  nextStockUpdate?: Maybe<Scalars['String']>;
  /** Retailprice from default pricelist. */
  prices?: Maybe<Array<Maybe<SkuPrice>>>;
  productNumber?: Maybe<Scalars['String']>;
  /** Retailprice from default pricelist. */
  retailPrice?: Maybe<SkuRetailPrice>;
  sku?: Maybe<Scalars['String']>;
  skucolor?: Maybe<Scalars['String']>;
  stockUnitCode?: Maybe<Scalars['String']>;
  sizeSequence?: Maybe<Scalars['String']>;
  skuSize?: Maybe<SkuSize>;
  defaultSalesUnitCode?: Maybe<Scalars['String']>;
  defaultSalesUnitItems?: Maybe<Scalars['Float']>;
  defaultSalesUnitNetWeight?: Maybe<Scalars['Float']>;
  defaultSalesUnitGrossWeight?: Maybe<Scalars['Float']>;
  defaultSalesUnitNetVolume?: Maybe<Scalars['Float']>;
  defaultSalesUnitGrossVolume?: Maybe<Scalars['Float']>;
  defaultSalesUnitLength?: Maybe<Scalars['Float']>;
  defaultSalesUnitHeight?: Maybe<Scalars['Float']>;
  defaultSalesUnitWidth?: Maybe<Scalars['Float']>;
  master?: Maybe<SkuUnit>;
  pall?: Maybe<SkuUnit>;
  box?: Maybe<SkuUnit>;
};


export type SkuAvailabilityGlobalArgs = {
  excludedCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SkuCategorization = {
  __typename?: 'SkuCategorization';
  group1?: Maybe<Scalars['String']>;
  group2?: Maybe<Scalars['String']>;
  group3?: Maybe<Scalars['String']>;
  group4?: Maybe<Scalars['String']>;
  group5?: Maybe<Scalars['String']>;
  group6?: Maybe<Scalars['String']>;
};

export type SkuPrice = {
  __typename?: 'SkuPrice';
  currency?: Maybe<Scalars['String']>;
  salesPrice?: Maybe<Scalars['Float']>;
  retailPrice?: Maybe<Scalars['Float']>;
  priceUnit?: Maybe<Scalars['String']>;
  priceList?: Maybe<Scalars['String']>;
};

export type SkuRetailPrice = {
  __typename?: 'SkuRetailPrice';
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceUnit?: Maybe<Scalars['String']>;
  priceList?: Maybe<Scalars['String']>;
};

export type SkuSize = {
  __typename?: 'SkuSize';
  size?: Maybe<Scalars['String']>;
  webtext?: Maybe<Scalars['String']>;
};

export type SkuUnit = {
  __typename?: 'SkuUnit';
  grossVolume?: Maybe<Scalars['Float']>;
  netVolume?: Maybe<Scalars['Float']>;
  grossWeight?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Int']>;
  eanCode?: Maybe<Scalars['String']>;
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortFindByIdsuserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyapplicationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyarticleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManycommerceUserLinkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManycompanyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyemailInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyexportConfigurationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManynotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyserviceContainerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyuserAssortmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyuserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyuserRoleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type SortingInput = {
  productNumber?: Maybe<Sort>;
};

export type StringKvp = {
  __typename?: 'StringKVP';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated?: Maybe<Notification>;
  userNotificationCreated?: Maybe<UserNotification>;
};

export type Translation = {
  __typename?: 'Translation';
  en?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  fi?: Maybe<Scalars['String']>;
  da?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  deCH?: Maybe<Scalars['String']>;
  frCH?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  cs?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  is?: Maybe<Scalars['String']>;
};

export type TranslationKvp = {
  __typename?: 'TranslationKVP';
  key?: Maybe<Scalars['String']>;
  translation?: Maybe<Translation>;
};

export type UpdateByIdarticleInput = {
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdarticlePayload = {
  __typename?: 'UpdateByIdarticlePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Article>;
};

export type UpdateByIdcommerceUserLinkInput = {
  name?: Maybe<Scalars['String']>;
  contexts?: Maybe<Array<Maybe<CommerceUserLinkContextsInput>>>;
  users?: Maybe<Array<Maybe<CommerceUserLinkUsersInput>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdcommerceUserLinkPayload = {
  __typename?: 'UpdateByIdcommerceUserLinkPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<CommerceUserLink>;
};

export type UpdateByIduserAssortmentInput = {
  name?: Maybe<Scalars['String']>;
  assortmentId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIduserAssortmentPayload = {
  __typename?: 'UpdateByIduserAssortmentPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<UserAssortment>;
};

export type UpdateByIduserNotificationInput = {
  userId?: Maybe<Scalars['MongoID']>;
  notificationId?: Maybe<Scalars['MongoID']>;
  isRead?: Maybe<Scalars['Boolean']>;
  receivedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIduserNotificationPayload = {
  __typename?: 'UpdateByIduserNotificationPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<UserNotification>;
};

export type UpdateByIduserPayload = {
  __typename?: 'UpdateByIduserPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<User>;
};

export type User = {
  __typename?: 'user';
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  companyIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  pricelists?: Maybe<Array<Maybe<UserPricelists>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  registeredAt?: Maybe<Scalars['Date']>;
  lastLogin?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  /** Token of authenticated user. */
  token?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Maybe<Application>>>;
  exportConfigurations?: Maybe<Array<Maybe<ExportConfiguration>>>;
  assortments?: Maybe<Array<Maybe<UserAssortment>>>;
  serviceContainers?: Maybe<Array<Maybe<ServiceContainer>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  commerceUserLink?: Maybe<CommerceUserLink>;
};


export type UserApplicationsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyapplicationInput>;
};


export type UserExportConfigurationsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyexportConfigurationInput>;
};


export type UserAssortmentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyuserAssortmentInput>;
};


export type UserServiceContainersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyserviceContainerInput>;
};


export type UserCompaniesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManycompanyInput>;
};

export type UserAssortment = {
  __typename?: 'userAssortment';
  name?: Maybe<Scalars['String']>;
  assortmentId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<User>>>;
  _id: Scalars['MongoID'];
};


export type UserAssortmentUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindByIdsuserInput>;
};

export type UserNotification = {
  __typename?: 'userNotification';
  userId?: Maybe<Scalars['MongoID']>;
  notificationId?: Maybe<Scalars['MongoID']>;
  isRead?: Maybe<Scalars['Boolean']>;
  receivedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  notification?: Maybe<Notification>;
};

/** List of items with pagination. */
export type UserPagination = {
  __typename?: 'userPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserPricelists = {
  __typename?: 'userPricelists';
  pricelist?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserPricelistsInput = {
  pricelist?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserRole = {
  __typename?: 'userRole';
  name?: Maybe<Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  _id: Scalars['MongoID'];
  serviceContainers?: Maybe<Array<Maybe<ServiceContainer>>>;
};


export type UserRoleServiceContainersArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindManyserviceContainerInput>;
};

export type ToggleAsideMenuMutationVariables = Exact<{
  active: Scalars['Boolean'];
}>;


export type ToggleAsideMenuMutation = (
  { __typename?: 'Mutation' }
  & { toggleAsideMenu?: Maybe<(
    { __typename?: 'AsideMenu' }
    & Pick<AsideMenu, 'active'>
  )> }
);

export type AsideMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type AsideMenuQuery = (
  { __typename?: 'Query' }
  & { asideMenu?: Maybe<(
    { __typename?: 'AsideMenu' }
    & Pick<AsideMenu, 'active'>
  )> }
);

export type DataLanguageQueryVariables = Exact<{ [key: string]: never; }>;


export type DataLanguageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dataLanguage'>
);

export type UserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isLoggedIn' | 'isAdmin' | 'userId' | 'username'>
);

export type IsUserLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUserLoggedInQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isLoggedIn'>
);

export type ConfirmUserMutationVariables = Exact<{
  id: Scalars['MongoID'];
  password: Scalars['String'];
}>;


export type ConfirmUserMutation = (
  { __typename?: 'Mutation' }
  & { userConfirm?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'confirmed'>
  )> }
);

export type CreateApplicationMutationVariables = Exact<{
  name: Scalars['String'];
  companyCode: Scalars['String'];
  commerceUserId?: Maybe<Scalars['String']>;
  commerceContextId?: Maybe<Scalars['String']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>> | Maybe<PricelistInput>>;
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { applicationCreate?: Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'companyCode' | 'userId' | 'active' | 'token' | 'tokenExp' | 'tokenExpiration' | 'deletedAt' | 'complexityLimit'>
    & { commerceApp?: Maybe<(
      { __typename?: 'CommerceApp' }
      & Pick<CommerceApp, 'name'>
    )>, commerceAppUser?: Maybe<(
      { __typename?: 'CommerceAppUser' }
      & Pick<CommerceAppUser, 'email'>
    )>, user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username'>
      & { pricelists?: Maybe<Array<Maybe<(
        { __typename?: 'userPricelists' }
        & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
      )>>> }
    )>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )> }
);

export type CreateArticleMutationVariables = Exact<{
  headline: Scalars['String'];
  description: Scalars['String'];
  body: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
}>;


export type CreateArticleMutation = (
  { __typename?: 'Mutation' }
  & { articleCreate?: Maybe<(
    { __typename?: 'CreateOnearticlePayload' }
    & { record?: Maybe<(
      { __typename?: 'article' }
      & Pick<Article, '_id' | 'headline' | 'description' | 'body' | 'author' | 'imageUrl' | 'publishedAt' | 'published' | 'updatedAt'>
    )> }
  )> }
);

export type CreateCommerceUserLinkMutationVariables = Exact<{
  name: Scalars['String'];
  users: Array<Maybe<CommerceUserLinkUsersInput>> | Maybe<CommerceUserLinkUsersInput>;
  contexts: Array<Maybe<CommerceUserLinkContextsInput>> | Maybe<CommerceUserLinkContextsInput>;
}>;


export type CreateCommerceUserLinkMutation = (
  { __typename?: 'Mutation' }
  & { commerceUserLinkCreate?: Maybe<(
    { __typename?: 'CreateOnecommerceUserLinkPayload' }
    & { record?: Maybe<(
      { __typename?: 'commerceUserLink' }
      & Pick<CommerceUserLink, '_id' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkUsers' }
        & Pick<CommerceUserLinkUsers, 'id' | 'email'>
      )>>>, contexts?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkContexts' }
        & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode' | 'name'>
      )>>> }
    )> }
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  companyCode: Scalars['String'];
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { companyCreate?: Maybe<(
    { __typename?: 'CreateOnecompanyPayload' }
    & { record?: Maybe<(
      { __typename?: 'company' }
      & Pick<Company, '_id' | 'name' | 'companyCode'>
    )> }
  )> }
);

export type CreateNotificationMutationVariables = Exact<{
  userIds?: Maybe<Array<Scalars['MongoID']> | Scalars['MongoID']>;
  headline: Scalars['String'];
  description: Scalars['String'];
  body: Scalars['String'];
  link?: Maybe<Scalars['String']>;
}>;


export type CreateNotificationMutation = (
  { __typename?: 'Mutation' }
  & { notificationCreateAndSend?: Maybe<(
    { __typename?: 'CreateOnenotificationPayload' }
    & { record?: Maybe<(
      { __typename?: 'notification' }
      & Pick<Notification, 'headline' | 'description' | 'body' | 'link' | 'createdAt'>
    )> }
  )> }
);

export type CreateServiceContainerMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateServiceContainerMutation = (
  { __typename?: 'Mutation' }
  & { serviceContainerCreate?: Maybe<(
    { __typename?: 'CreateOneserviceContainerPayload' }
    & { record?: Maybe<(
      { __typename?: 'serviceContainer' }
      & Pick<ServiceContainer, '_id' | 'name' | 'description'>
    )> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  companyIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Scalars['MongoID']> | Scalars['MongoID']>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>> | Maybe<PricelistInput>>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { userRegister?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'isAdmin' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { serviceContainers?: Maybe<Array<Maybe<(
      { __typename?: 'serviceContainer' }
      & Pick<ServiceContainer, '_id' | 'name'>
    )>>>, companies?: Maybe<Array<Maybe<(
      { __typename?: 'company' }
      & Pick<Company, '_id' | 'name' | 'companyCode'>
    )>>>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode'>
    )>>> }
  )> }
);

export type CreateUserAssortmentMutationVariables = Exact<{
  record: CreateOneuserAssortmentInput;
}>;


export type CreateUserAssortmentMutation = (
  { __typename?: 'Mutation' }
  & { userAssortmentCreate?: Maybe<(
    { __typename?: 'CreateOneuserAssortmentPayload' }
    & { record?: Maybe<(
      { __typename?: 'userAssortment' }
      & Pick<UserAssortment, 'name' | '_id' | 'assortmentId'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'user' }
        & Pick<User, '_id'>
      )>>> }
    )> }
  )> }
);

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
  companyIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>> | Maybe<Scalars['MongoID']>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { userInvite?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'isAdmin' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { serviceContainers?: Maybe<Array<Maybe<(
      { __typename?: 'serviceContainer' }
      & Pick<ServiceContainer, '_id' | 'name'>
    )>>>, companies?: Maybe<Array<Maybe<(
      { __typename?: 'company' }
      & Pick<Company, '_id' | 'name' | 'companyCode'>
    )>>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'UpdateByIduserPayload' }
    & { record?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username' | 'token' | 'isAdmin'>
    )> }
  )> }
);

export type RemoveApplicationMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveApplicationMutation = (
  { __typename?: 'Mutation' }
  & { applicationRemoveById?: Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'userId' | 'companyCode' | 'active' | 'token' | 'tokenExp' | 'tokenExpiration' | 'deletedAt'>
    & { commerceApp?: Maybe<(
      { __typename?: 'CommerceApp' }
      & Pick<CommerceApp, 'name'>
    )>, commerceAppUser?: Maybe<(
      { __typename?: 'CommerceAppUser' }
      & Pick<CommerceAppUser, 'email'>
    )> }
  )> }
);

export type RemoveArticleMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveArticleMutation = (
  { __typename?: 'Mutation' }
  & { articleRemoveById?: Maybe<(
    { __typename?: 'RemoveByIdarticlePayload' }
    & { record?: Maybe<(
      { __typename?: 'article' }
      & Pick<Article, '_id' | 'headline' | 'description' | 'body' | 'author' | 'imageUrl' | 'publishedAt' | 'published' | 'updatedAt'>
    )> }
  )> }
);

export type RemoveCommerceUserLinkMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveCommerceUserLinkMutation = (
  { __typename?: 'Mutation' }
  & { commerceUserLinkRemoveById?: Maybe<(
    { __typename?: 'RemoveByIdcommerceUserLinkPayload' }
    & { record?: Maybe<(
      { __typename?: 'commerceUserLink' }
      & Pick<CommerceUserLink, '_id' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkUsers' }
        & Pick<CommerceUserLinkUsers, 'id' | 'email'>
      )>>>, contexts?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkContexts' }
        & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode'>
      )>>> }
    )> }
  )> }
);

export type RemoveCompanyMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveCompanyMutation = (
  { __typename?: 'Mutation' }
  & { companyRemoveById?: Maybe<(
    { __typename?: 'RemoveByIdcompanyPayload' }
    & { record?: Maybe<(
      { __typename?: 'company' }
      & Pick<Company, '_id' | 'name' | 'companyCode'>
    )> }
  )> }
);

export type RemoveExportConfigurationMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveExportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { exportConfigurationRemove?: Maybe<(
    { __typename?: 'RemoveByIdexportConfigurationPayload' }
    & { record?: Maybe<(
      { __typename?: 'exportConfiguration' }
      & Pick<ExportConfiguration, 'name'>
    )> }
  )> }
);

export type RemoveServiceContainerMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveServiceContainerMutation = (
  { __typename?: 'Mutation' }
  & { serviceContainerRemoveById?: Maybe<(
    { __typename?: 'RemoveByIdserviceContainerPayload' }
    & { record?: Maybe<(
      { __typename?: 'serviceContainer' }
      & Pick<ServiceContainer, '_id' | 'name' | 'description'>
    )> }
  )> }
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & { userRemoveById?: Maybe<(
    { __typename?: 'RemoveByIduserPayload' }
    & { record?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId'>
      & { serviceContainers?: Maybe<Array<Maybe<(
        { __typename?: 'serviceContainer' }
        & Pick<ServiceContainer, 'name'>
      )>>> }
    )> }
  )> }
);

export type RemoveUserAssortmentMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type RemoveUserAssortmentMutation = (
  { __typename?: 'Mutation' }
  & { userAssortmentDelete?: Maybe<(
    { __typename?: 'RemoveByIduserAssortmentPayload' }
    & { record?: Maybe<(
      { __typename?: 'userAssortment' }
      & Pick<UserAssortment, 'name' | '_id' | 'assortmentId'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'user' }
        & Pick<User, '_id'>
      )>>> }
    )> }
  )> }
);

export type SaveExportConfigurationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type SaveExportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { exportConfigurationSave?: Maybe<(
    { __typename?: 'exportConfiguration' }
    & Pick<ExportConfiguration, '_id' | 'name'>
  )> }
);

export type UpdateApplicationStatusMutationVariables = Exact<{
  id: Scalars['MongoID'];
  active?: Maybe<Scalars['Boolean']>;
  complexityLimit?: Maybe<Scalars['Int']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>> | Maybe<PricelistInput>>;
}>;


export type UpdateApplicationStatusMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdateStatus?: Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'userId' | 'active' | 'complexityLimit'>
    & { pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )> }
);

export type UpdateApplicationStatusByUserIdMutationVariables = Exact<{
  userId: Scalars['MongoID'];
  active: Scalars['Boolean'];
}>;


export type UpdateApplicationStatusByUserIdMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdateStatusByUserId?: Maybe<Array<Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'userId' | 'active'>
  )>>> }
);

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['MongoID'];
  headline: Scalars['String'];
  description: Scalars['String'];
  body: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
}>;


export type UpdateArticleMutation = (
  { __typename?: 'Mutation' }
  & { articleUpdate?: Maybe<(
    { __typename?: 'UpdateByIdarticlePayload' }
    & { record?: Maybe<(
      { __typename?: 'article' }
      & Pick<Article, '_id' | 'headline' | 'description' | 'body' | 'author' | 'imageUrl' | 'publishedAt' | 'published'>
    )> }
  )> }
);

export type UpdateCommerceUserLinkMutationVariables = Exact<{
  id: Scalars['MongoID'];
  name: Scalars['String'];
  users: Array<Maybe<CommerceUserLinkUsersInput>> | Maybe<CommerceUserLinkUsersInput>;
  contexts: Array<Maybe<CommerceUserLinkContextsInput>> | Maybe<CommerceUserLinkContextsInput>;
}>;


export type UpdateCommerceUserLinkMutation = (
  { __typename?: 'Mutation' }
  & { commerceUserLinkUpdateById?: Maybe<(
    { __typename?: 'UpdateByIdcommerceUserLinkPayload' }
    & { record?: Maybe<(
      { __typename?: 'commerceUserLink' }
      & Pick<CommerceUserLink, '_id' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkUsers' }
        & Pick<CommerceUserLinkUsers, 'id' | 'email'>
      )>>>, contexts?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkContexts' }
        & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode' | 'name'>
      )>>> }
    )> }
  )> }
);

export type UpdateCurrentExportConfigurationMutationVariables = Exact<{
  queryVariables?: Maybe<ExportConfigurationQueryVariablesInput>;
}>;


export type UpdateCurrentExportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { exportConfigurationUpdateCurrent?: Maybe<(
    { __typename?: 'exportConfiguration' }
    & Pick<ExportConfiguration, '_id'>
  )> }
);

export type UpdateCurrentUserMutationVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type UpdateCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { meUpdate?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'isAdmin' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { commerceUserLink?: Maybe<(
      { __typename?: 'commerceUserLink' }
      & Pick<CommerceUserLink, '_id' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkUsers' }
        & Pick<CommerceUserLinkUsers, 'id' | 'email'>
      )>>>, contexts?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkContexts' }
        & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode'>
      )>>> }
    )> }
  )> }
);

export type UpdateUserAssortmentMutationVariables = Exact<{
  record: UpdateByIduserAssortmentInput;
}>;


export type UpdateUserAssortmentMutation = (
  { __typename?: 'Mutation' }
  & { userAssortmentUpdate?: Maybe<(
    { __typename?: 'UpdateByIduserAssortmentPayload' }
    & Pick<UpdateByIduserAssortmentPayload, 'recordId'>
    & { record?: Maybe<(
      { __typename?: 'userAssortment' }
      & Pick<UserAssortment, 'name' | 'assortmentId' | '_id'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'user' }
        & Pick<User, '_id'>
      )>>> }
    )> }
  )> }
);

export type UpdateUserByIdMutationVariables = Exact<{
  id: Scalars['MongoID'];
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  companyIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  serviceContainerIds?: Maybe<Array<Maybe<Scalars['MongoID']>> | Maybe<Scalars['MongoID']>>;
  commerceUserLinkId?: Maybe<Scalars['MongoID']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  pricelists?: Maybe<Array<Maybe<PricelistInput>> | Maybe<PricelistInput>>;
}>;


export type UpdateUserByIdMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateById?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'isAdmin' | 'confirmed' | 'active' | 'email' | 'serviceContainerIds' | 'companyIds' | 'commerceUserLinkId' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )> }
);

export type UpdateUserNotificationByIdMutationVariables = Exact<{
  id: Scalars['MongoID'];
  isRead: Scalars['Boolean'];
}>;


export type UpdateUserNotificationByIdMutation = (
  { __typename?: 'Mutation' }
  & { userNotificationUpdateById?: Maybe<(
    { __typename?: 'UpdateByIduserNotificationPayload' }
    & { record?: Maybe<(
      { __typename?: 'userNotification' }
      & Pick<UserNotification, '_id' | 'userId' | 'notificationId' | 'isRead'>
      & { notification?: Maybe<(
        { __typename?: 'notification' }
        & Pick<Notification, '_id' | 'headline' | 'description' | 'body' | 'createdAt'>
        & { author?: Maybe<(
          { __typename?: 'notificationAuthor' }
          & Pick<NotificationAuthor, 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type AllLanguagesFragment = (
  { __typename?: 'Translation' }
  & Pick<Translation, 'cs' | 'da' | 'de' | 'deCH' | 'en' | 'es' | 'fi' | 'fr' | 'frCH' | 'is' | 'it' | 'nl' | 'no' | 'pl' | 'sv'>
);

export type FullProductTranslatedFragment = (
  { __typename?: 'ProductTranslated' }
  & Pick<ProductTranslated, 'productNumber' | 'productBrand' | 'productRange' | 'productPrintCode' | 'productCapacityStandard' | 'productDesignerStandard' | 'productDiameterStandard' | 'productCatalogSize' | 'productHeightStandard' | 'productLengthStandard' | 'productMeasure' | 'productVolumeStandard' | 'productWidthStandard' | 'productWeight' | 'productWeightStandard' | 'sizes'>
  & { productName?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productGender?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productPackaging?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productPackagingStandard?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productColorComment?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productColorName?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productCareInstructions?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productCertifications?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productApplicationAreas?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productApplicationTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productActivityTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productCategory?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productClosure?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productText?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productCommerceText?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productCatalogText?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productCatalogPrice?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productFabrics?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, productFeature?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productFit?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productHoodDetails?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productMaterialTechnique?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productNeckline?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productPockets?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productSleeve?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productTheme?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, productUsp?: Maybe<(
    { __typename?: 'Translation' }
    & AllLanguagesFragment
  )>, filterColor?: Maybe<Array<Maybe<(
    { __typename?: 'TranslationKVP' }
    & Pick<TranslationKvp, 'key'>
    & { translation?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )> }
  )>>>, variations?: Maybe<Array<Maybe<(
    { __typename?: 'ProductVariationTranslated' }
    & Pick<ProductVariationTranslated, 'itemNumber' | 'itemColorCode' | 'sizes'>
    & { itemColorName?: Maybe<(
      { __typename?: 'Translation' }
      & AllLanguagesFragment
    )>, filterColor?: Maybe<Array<Maybe<(
      { __typename?: 'TranslationKVP' }
      & Pick<TranslationKvp, 'key'>
      & { translation?: Maybe<(
        { __typename?: 'Translation' }
        & AllLanguagesFragment
      )> }
    )>>>, pictures?: Maybe<Array<Maybe<(
      { __typename?: 'ProductResource' }
      & Pick<ProductResource, 'imageUrl' | 'resourcePictureType' | 'resourceFileId'>
    )>>> }
  )>>>, pictures?: Maybe<Array<Maybe<(
    { __typename?: 'ProductResource' }
    & Pick<ProductResource, 'resourcePictureType' | 'imageUrl' | 'resourceFileId'>
  )>>>, skus?: Maybe<Array<Maybe<(
    { __typename?: 'Sku' }
    & Pick<Sku, 'sku' | 'company' | 'eanCode' | 'defaultSalesUnitGrossVolume' | 'description' | 'enDescription' | 'stockUnitCode' | 'commodityCode' | 'countryOfOrigin' | 'defaultSalesUnitCode' | 'availability' | 'nextStockUpdate' | 'changed' | 'skucolor' | 'defaultSalesUnitGrossWeight' | 'defaultSalesUnitNetWeight' | 'defaultSalesUnitNetVolume' | 'defaultSalesUnitLength' | 'defaultSalesUnitWidth' | 'defaultSalesUnitHeight' | 'defaultSalesUnitItems'>
    & { skuSize?: Maybe<(
      { __typename?: 'SkuSize' }
      & Pick<SkuSize, 'size' | 'webtext'>
    )>, retailPrice?: Maybe<(
      { __typename?: 'SkuRetailPrice' }
      & Pick<SkuRetailPrice, 'price' | 'currency'>
    )>, prices?: Maybe<Array<Maybe<(
      { __typename?: 'SkuPrice' }
      & Pick<SkuPrice, 'salesPrice' | 'currency' | 'priceList'>
    )>>>, master?: Maybe<(
      { __typename?: 'SkuUnit' }
      & SkuUnitFragment
    )>, pall?: Maybe<(
      { __typename?: 'SkuUnit' }
      & SkuUnitFragment
    )>, box?: Maybe<(
      { __typename?: 'SkuUnit' }
      & SkuUnitFragment
    )>, categorization?: Maybe<(
      { __typename?: 'SkuCategorization' }
      & Pick<SkuCategorization, 'group1' | 'group2' | 'group3' | 'group4' | 'group5' | 'group6'>
    )> }
  )>>> }
);

export type SkuUnitFragment = (
  { __typename?: 'SkuUnit' }
  & Pick<SkuUnit, 'grossVolume' | 'netVolume' | 'grossWeight' | 'netWeight' | 'length' | 'height' | 'width' | 'items' | 'eanCode'>
);

export type ApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationsQuery = (
  { __typename?: 'Query' }
  & { applications?: Maybe<Array<Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'userId' | 'companyCode' | 'active' | 'token' | 'tokenExp' | 'tokenExpiration' | 'deletedAt' | 'complexityLimit'>
    & { commerceApp?: Maybe<(
      { __typename?: 'CommerceApp' }
      & Pick<CommerceApp, 'name'>
    )>, commerceAppUser?: Maybe<(
      { __typename?: 'CommerceAppUser' }
      & Pick<CommerceAppUser, 'email'>
    )>, user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username'>
      & { pricelists?: Maybe<Array<Maybe<(
        { __typename?: 'userPricelists' }
        & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
      )>>> }
    )>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )>>> }
);

export type ApplicationsByUserIdQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type ApplicationsByUserIdQuery = (
  { __typename?: 'Query' }
  & { applicationsByUserId?: Maybe<Array<Maybe<(
    { __typename?: 'application' }
    & Pick<Application, '_id' | 'name' | 'userId' | 'companyCode' | 'active' | 'token' | 'tokenExp' | 'tokenExpiration' | 'deletedAt' | 'complexityLimit'>
    & { commerceApp?: Maybe<(
      { __typename?: 'CommerceApp' }
      & Pick<CommerceApp, 'name'>
    )>, commerceAppUser?: Maybe<(
      { __typename?: 'CommerceAppUser' }
      & Pick<CommerceAppUser, 'email'>
    )>, user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username'>
      & { pricelists?: Maybe<Array<Maybe<(
        { __typename?: 'userPricelists' }
        & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
      )>>> }
    )>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )>>> }
);

export type ArticleQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { article?: Maybe<(
    { __typename?: 'article' }
    & Pick<Article, '_id' | 'headline' | 'description' | 'body' | 'author' | 'imageUrl' | 'publishedAt' | 'published' | 'updatedAt'>
  )> }
);

export type ArticlesQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
}>;


export type ArticlesQuery = (
  { __typename?: 'Query' }
  & { articlesPagination?: Maybe<(
    { __typename?: 'articlePagination' }
    & Pick<ArticlePagination, 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'article' }
      & Pick<Article, '_id' | 'headline' | 'description' | 'body' | 'author' | 'imageUrl' | 'publishedAt' | 'published' | 'updatedAt'>
    )>>>, pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'currentPage' | 'pageCount' | 'perPage' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type AssortmentQueryVariables = Exact<{
  assortmentId: Scalars['String'];
  language: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
}>;


export type AssortmentQuery = (
  { __typename?: 'Query' }
  & { assortment?: Maybe<(
    { __typename?: 'AssortmentResult' }
    & Pick<AssortmentResult, 'took' | 'count'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Assortment' }
      & Pick<Assortment, 'assortmentId' | 'id' | 'name' | 'parentId' | 'childNodeIds'>
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'page'>
    )>, error?: Maybe<(
      { __typename?: 'ElasticError' }
      & Pick<ElasticError, 'code' | 'message'>
    )> }
  )> }
);

export type AssortmentNodeQueryVariables = Exact<{
  id: Scalars['String'];
  language: Scalars['String'];
  productPage: Scalars['Int'];
  productPageSize: Scalars['PageSize'];
}>;


export type AssortmentNodeQuery = (
  { __typename?: 'Query' }
  & { assortmentNodeById?: Maybe<(
    { __typename?: 'Assortment' }
    & Pick<Assortment, 'id' | 'name'>
    & { childProducts?: Maybe<(
      { __typename?: 'ProductResult' }
      & Pick<ProductResult, 'count'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'productName' | 'productNumber'>
        & { pictures?: Maybe<Array<Maybe<(
          { __typename?: 'ProductResource' }
          & Pick<ProductResource, 'imageUrl' | 'resourceFileId'>
        )>>> }
      )>>>, pageInfo?: Maybe<(
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'page' | 'hasNextPage'>
      )> }
    )> }
  )> }
);

export type CommerceAppUsersByContextQueryVariables = Exact<{
  contextId: Scalars['String'];
  userIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
}>;


export type CommerceAppUsersByContextQuery = (
  { __typename?: 'Query' }
  & { commerceAppUsersByContext?: Maybe<(
    { __typename?: 'CommerceAppUserResult' }
    & Pick<CommerceAppUserResult, 'count'>
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'page' | 'pageSize' | 'pageCount' | 'hasNextPage' | 'hasPreviousPage'>
    )>, result?: Maybe<Array<Maybe<(
      { __typename?: 'CommerceAppUser' }
      & Pick<CommerceAppUser, 'id' | 'contextId' | 'email' | 'customerCode'>
    )>>> }
  )> }
);

export type CommerceAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type CommerceAppsQuery = (
  { __typename?: 'Query' }
  & { commerceApps?: Maybe<Array<Maybe<(
    { __typename?: 'CommerceAppBase' }
    & Pick<CommerceAppBase, 'id' | 'name'>
    & { settings?: Maybe<(
      { __typename?: 'CommerceAppSettings' }
      & Pick<CommerceAppSettings, 'erpCompanyCode'>
    )> }
  )>>> }
);

export type CommerceAppsByCompanyQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type CommerceAppsByCompanyQuery = (
  { __typename?: 'Query' }
  & { commerceAppsByCompanyCode?: Maybe<Array<Maybe<(
    { __typename?: 'CommerceAppBase' }
    & Pick<CommerceAppBase, 'id' | 'name'>
  )>>> }
);

export type CommerceUserLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type CommerceUserLinksQuery = (
  { __typename?: 'Query' }
  & { commerceUserLinks?: Maybe<Array<Maybe<(
    { __typename?: 'commerceUserLink' }
    & Pick<CommerceUserLink, '_id' | 'name'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'commerceUserLinkUsers' }
      & Pick<CommerceUserLinkUsers, 'id' | 'email'>
    )>>>, contexts?: Maybe<Array<Maybe<(
      { __typename?: 'commerceUserLinkContexts' }
      & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode' | 'name'>
    )>>> }
  )>>> }
);

export type CommerceUsersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;


export type CommerceUsersQuery = (
  { __typename?: 'Query' }
  & { commerceUsers?: Maybe<(
    { __typename?: 'CommerceUserResult' }
    & Pick<CommerceUserResult, 'count'>
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'page' | 'pageSize' | 'pageCount' | 'hasNextPage' | 'hasPreviousPage'>
    )>, result?: Maybe<Array<Maybe<(
      { __typename?: 'CommerceUser' }
      & Pick<CommerceUser, 'id' | 'email' | 'company'>
    )>>> }
  )> }
);

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<Maybe<(
    { __typename?: 'company' }
    & Pick<Company, '_id' | 'name' | 'companyCode'>
  )>>> }
);

export type CurrentExportConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentExportConfigurationQuery = (
  { __typename?: 'Query' }
  & { exportConfigurationCurrent?: Maybe<(
    { __typename?: 'exportConfiguration' }
    & Pick<ExportConfiguration, '_id'>
    & { queryVariables?: Maybe<(
      { __typename?: 'exportConfigurationQueryVariables' }
      & Pick<ExportConfigurationQueryVariables, 'assortmentId' | 'companyCode' | 'language'>
      & { filters?: Maybe<Array<Maybe<(
        { __typename?: 'exportConfigurationQueryVariablesFilters' }
        & Pick<ExportConfigurationQueryVariablesFilters, 'filter' | 'values'>
      )>>>, sheetSettings?: Maybe<(
        { __typename?: 'exportConfigurationQueryVariablesSheetSettings' }
        & Pick<ExportConfigurationQueryVariablesSheetSettings, 'imageLevel' | 'rowLevel' | 'languages' | 'selectedColumns' | 'units'>
        & { pricelists?: Maybe<Array<Maybe<(
          { __typename?: 'userPricelists' }
          & Pick<UserPricelists, 'pricelist' | 'currency' | 'companyCode'>
        )>>> }
      )> }
    )> }
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'isAdmin' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { commerceUserLink?: Maybe<(
      { __typename?: 'commerceUserLink' }
      & Pick<CommerceUserLink, '_id' | 'name'>
      & { contexts?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkContexts' }
        & Pick<CommerceUserLinkContexts, 'contextId' | 'companyCode' | 'name'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'commerceUserLinkUsers' }
        & Pick<CommerceUserLinkUsers, 'id' | 'email'>
      )>>> }
    )>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )> }
);

export type GetExportConfigurationQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type GetExportConfigurationQuery = (
  { __typename?: 'Query' }
  & { exportConfigurationById?: Maybe<(
    { __typename?: 'exportConfiguration' }
    & { queryVariables?: Maybe<(
      { __typename?: 'exportConfigurationQueryVariables' }
      & Pick<ExportConfigurationQueryVariables, 'assortmentId' | 'companyCode' | 'language'>
      & { filters?: Maybe<Array<Maybe<(
        { __typename?: 'exportConfigurationQueryVariablesFilters' }
        & Pick<ExportConfigurationQueryVariablesFilters, 'filter' | 'values'>
      )>>>, sheetSettings?: Maybe<(
        { __typename?: 'exportConfigurationQueryVariablesSheetSettings' }
        & Pick<ExportConfigurationQueryVariablesSheetSettings, 'rowLevel' | 'imageLevel' | 'languages' | 'selectedColumns' | 'units'>
        & { pricelists?: Maybe<Array<Maybe<(
          { __typename?: 'userPricelists' }
          & Pick<UserPricelists, 'pricelist' | 'currency' | 'companyCode'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetExportConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExportConfigurationsQuery = (
  { __typename?: 'Query' }
  & { exportConfigurationsByUserId?: Maybe<Array<Maybe<(
    { __typename?: 'exportConfiguration' }
    & Pick<ExportConfiguration, 'name' | '_id'>
  )>>> }
);

export type NotificationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<Array<Maybe<(
    { __typename?: 'notification' }
    & Pick<Notification, '_id' | 'headline' | 'description' | 'body'>
    & { author?: Maybe<(
      { __typename?: 'notificationAuthor' }
      & Pick<NotificationAuthor, 'name'>
    )> }
  )>>> }
);

export type NotificationsPaginationQueryVariables = Exact<{
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;


export type NotificationsPaginationQuery = (
  { __typename?: 'Query' }
  & { notificationsPagination?: Maybe<(
    { __typename?: 'notificationPagination' }
    & Pick<NotificationPagination, 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'notification' }
      & Pick<Notification, 'headline'>
    )>>> }
  )> }
);

export type GetProductByIdQueryVariables = Exact<{
  productNumber: Scalars['String'];
  language: Scalars['String'];
}>;


export type GetProductByIdQuery = (
  { __typename?: 'Query' }
  & { productById?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'productNumber' | 'productName' | 'productText'>
    & { pictures?: Maybe<Array<Maybe<(
      { __typename?: 'ProductResource' }
      & Pick<ProductResource, 'resourceFileId'>
    )>>>, retailPrice?: Maybe<(
      { __typename?: 'SkuRetailPrice' }
      & Pick<SkuRetailPrice, 'price' | 'currency'>
    )>, variations?: Maybe<Array<Maybe<(
      { __typename?: 'ProductVariation' }
      & Pick<ProductVariation, 'itemNumber' | 'itemColorName'>
      & { pictures?: Maybe<Array<Maybe<(
        { __typename?: 'ProductResource' }
        & Pick<ProductResource, 'resourceFileId'>
      )>>> }
    )>>> }
  )> }
);

export type GetProductFiltersQueryVariables = Exact<{
  assortmentId: Scalars['String'];
  language: Scalars['String'];
  companyCode?: Maybe<Scalars['String']>;
}>;


export type GetProductFiltersQuery = (
  { __typename?: 'Query' }
  & { productFilters?: Maybe<Array<Maybe<(
    { __typename?: 'ProductFilter' }
    & Pick<ProductFilter, 'filter'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'StringKVP' }
      & Pick<StringKvp, 'key' | 'value'>
    )>>> }
  )>>> }
);

export type ProductsQueryVariables = Exact<{
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { productsByAssortmentId?: Maybe<(
    { __typename?: 'ProductResult' }
    & Pick<ProductResult, 'count'>
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    )>, result?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productNumber' | 'productName' | 'productBrand'>
      & { productGender?: Maybe<Array<Maybe<(
        { __typename?: 'StringKVP' }
        & Pick<StringKvp, 'value'>
      )>>>, productCareInstructions?: Maybe<Array<Maybe<(
        { __typename?: 'StringKVP' }
        & Pick<StringKvp, 'key' | 'value'>
      )>>>, variations?: Maybe<Array<Maybe<(
        { __typename?: 'ProductVariation' }
        & Pick<ProductVariation, 'itemNumber' | 'itemColorCode' | 'itemColorName'>
        & { pictures?: Maybe<Array<Maybe<(
          { __typename?: 'ProductResource' }
          & Pick<ProductResource, 'imageUrl' | 'resourceFileId' | 'resourcePictureType'>
        )>>> }
      )>>>, pictures?: Maybe<Array<Maybe<(
        { __typename?: 'ProductResource' }
        & Pick<ProductResource, 'imageUrl' | 'resourceFileId' | 'resourcePictureType'>
      )>>>, retailPrice?: Maybe<(
        { __typename?: 'SkuRetailPrice' }
        & Pick<SkuRetailPrice, 'price' | 'currency'>
      )> }
    )>>> }
  )> }
);

export type TranslatedProductsQueryVariables = Exact<{
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
}>;


export type TranslatedProductsQuery = (
  { __typename?: 'Query' }
  & { productsTranslatedByAssortmentId?: Maybe<(
    { __typename?: 'ProductTranslatedResult' }
    & Pick<ProductTranslatedResult, 'count'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProductTranslated' }
      & FullProductTranslatedFragment
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage'>
    )> }
  )> }
);

export type ServiceContainersQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceContainersQuery = (
  { __typename?: 'Query' }
  & { serviceContainers?: Maybe<Array<Maybe<(
    { __typename?: 'serviceContainer' }
    & Pick<ServiceContainer, '_id' | 'name' | 'description'>
  )>>> }
);

export type UserAssortmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAssortmentsQuery = (
  { __typename?: 'Query' }
  & { userAssortments?: Maybe<Array<Maybe<(
    { __typename?: 'userAssortment' }
    & Pick<UserAssortment, 'name' | 'assortmentId' | '_id'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id'>
    )>>> }
  )>>> }
);

export type UserByIdQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type UserByIdQuery = (
  { __typename?: 'Query' }
  & { userById?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'isAdmin' | 'active' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )> }
);

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<Maybe<(
    { __typename?: 'company' }
    & Pick<Company, '_id' | 'name' | 'companyCode'>
  )>>> }
);

export type UserInviteLinkQueryVariables = Exact<{
  userId: Scalars['MongoID'];
}>;


export type UserInviteLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userInviteLink'>
);

export type UserNotificationsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type UserNotificationsQuery = (
  { __typename?: 'Query' }
  & { userNotifications?: Maybe<Array<Maybe<(
    { __typename?: 'userNotification' }
    & Pick<UserNotification, '_id' | 'userId' | 'notificationId' | 'isRead' | 'receivedAt'>
    & { notification?: Maybe<(
      { __typename?: 'notification' }
      & Pick<Notification, '_id' | 'headline' | 'description' | 'body' | 'link' | 'createdAt'>
      & { author?: Maybe<(
        { __typename?: 'notificationAuthor' }
        & Pick<NotificationAuthor, 'name'>
      )> }
    )> }
  )>>> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'user' }
    & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
    & { serviceContainers?: Maybe<Array<Maybe<(
      { __typename?: 'serviceContainer' }
      & Pick<ServiceContainer, '_id' | 'name'>
    )>>>, companies?: Maybe<Array<Maybe<(
      { __typename?: 'company' }
      & Pick<Company, '_id' | 'name' | 'companyCode'>
    )>>>, pricelists?: Maybe<Array<Maybe<(
      { __typename?: 'userPricelists' }
      & Pick<UserPricelists, 'pricelist' | 'companyCode' | 'currency'>
    )>>> }
  )>>> }
);

export type SearchProductsQueryVariables = Exact<{
  keyword: Scalars['String'];
  language: Scalars['String'];
  assortmentId: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>> | Maybe<ProductFilterInput>>;
  companyCode?: Maybe<Scalars['String']>;
}>;


export type SearchProductsQuery = (
  { __typename?: 'Query' }
  & { productSearch?: Maybe<(
    { __typename?: 'ProductResult' }
    & Pick<ProductResult, 'count' | 'took'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'productName' | 'productNumber' | 'productBrand'>
      & { pictures?: Maybe<Array<Maybe<(
        { __typename?: 'ProductResource' }
        & Pick<ProductResource, 'imageUrl' | 'resourceFileId'>
      )>>> }
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'page' | 'pageSize' | 'pageCount' | 'hasNextPage' | 'hasPreviousPage'>
    )>, error?: Maybe<(
      { __typename?: 'ElasticError' }
      & Pick<ElasticError, 'code' | 'message'>
    )> }
  )> }
);

export type SearchProductsTranslatedQueryVariables = Exact<{
  keyword: Scalars['String'];
  assortmentId: Scalars['String'];
  language: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['PageSize'];
  filters?: Maybe<Array<Maybe<ProductFilterInput>> | Maybe<ProductFilterInput>>;
  companyCode?: Maybe<Scalars['String']>;
}>;


export type SearchProductsTranslatedQuery = (
  { __typename?: 'Query' }
  & { productTranslatedSearch?: Maybe<(
    { __typename?: 'ProductTranslatedResult' }
    & Pick<ProductTranslatedResult, 'count' | 'took'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProductTranslated' }
      & FullProductTranslatedFragment
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'page' | 'pageSize' | 'pageCount' | 'hasNextPage' | 'hasPreviousPage'>
    )>, error?: Maybe<(
      { __typename?: 'ElasticError' }
      & Pick<ElasticError, 'code' | 'message'>
    )> }
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { userSearch?: Maybe<(
    { __typename?: 'userPagination' }
    & Pick<UserPagination, 'count'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'user' }
      & Pick<User, '_id' | 'username' | 'email' | 'companyIds' | 'serviceContainerIds' | 'commerceUserLinkId' | 'confirmed' | 'active' | 'isAdmin' | 'registeredAt' | 'lastLogin' | 'updatedAt'>
      & { serviceContainers?: Maybe<Array<Maybe<(
        { __typename?: 'serviceContainer' }
        & Pick<ServiceContainer, '_id' | 'name'>
      )>>>, companies?: Maybe<Array<Maybe<(
        { __typename?: 'company' }
        & Pick<Company, '_id' | 'name' | 'companyCode'>
      )>>> }
    )>>>, pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<PaginationInfo, 'currentPage' | 'perPage' | 'pageCount' | 'itemCount' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type CreatedNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CreatedNotificationsSubscription = (
  { __typename?: 'Subscription' }
  & { notificationCreated?: Maybe<(
    { __typename?: 'notification' }
    & Pick<Notification, '_id' | 'headline' | 'description' | 'link' | 'body'>
    & { author?: Maybe<(
      { __typename?: 'notificationAuthor' }
      & Pick<NotificationAuthor, 'name'>
    )> }
  )> }
);

export type CreatedUserNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CreatedUserNotificationsSubscription = (
  { __typename?: 'Subscription' }
  & { userNotificationCreated?: Maybe<(
    { __typename?: 'userNotification' }
    & Pick<UserNotification, '_id' | 'userId' | 'notificationId' | 'isRead' | 'receivedAt'>
    & { notification?: Maybe<(
      { __typename?: 'notification' }
      & Pick<Notification, '_id' | 'headline' | 'description' | 'body' | 'link' | 'createdAt'>
      & { author?: Maybe<(
        { __typename?: 'notificationAuthor' }
        & Pick<NotificationAuthor, 'name'>
      )> }
    )> }
  )> }
);

export const AllLanguagesFragmentDoc = gql`
    fragment allLanguages on Translation {
  cs
  da
  de
  deCH
  en
  es
  fi
  fr
  frCH
  is
  it
  nl
  no
  pl
  sv
}
    `;
export const SkuUnitFragmentDoc = gql`
    fragment skuUnit on SkuUnit {
  grossVolume
  netVolume
  grossWeight
  netWeight
  length
  height
  width
  items
  eanCode
}
    `;
export const FullProductTranslatedFragmentDoc = gql`
    fragment fullProductTranslated on ProductTranslated {
  productNumber
  productName {
    ...allLanguages
  }
  productGender {
    key
    translation {
      ...allLanguages
    }
  }
  productBrand
  productRange
  productPrintCode
  productCapacityStandard
  productDesignerStandard
  productDiameterStandard
  productPackaging {
    ...allLanguages
  }
  productPackagingStandard {
    key
    translation {
      ...allLanguages
    }
  }
  productColorComment {
    ...allLanguages
  }
  productColorName {
    ...allLanguages
  }
  productCareInstructions {
    key
    translation {
      ...allLanguages
    }
  }
  productCertifications {
    key
    translation {
      ...allLanguages
    }
  }
  productApplicationAreas {
    key
    translation {
      ...allLanguages
    }
  }
  productApplicationTypes {
    key
    translation {
      ...allLanguages
    }
  }
  productActivityTypes {
    key
    translation {
      ...allLanguages
    }
  }
  productCatalogSize
  productCategory {
    key
    translation {
      ...allLanguages
    }
  }
  productClosure {
    key
    translation {
      ...allLanguages
    }
  }
  productText {
    ...allLanguages
  }
  productCommerceText {
    ...allLanguages
  }
  productCatalogText {
    ...allLanguages
  }
  productCatalogPrice {
    ...allLanguages
  }
  productFabrics {
    ...allLanguages
  }
  productFeature {
    key
    translation {
      ...allLanguages
    }
  }
  productFit {
    key
    translation {
      ...allLanguages
    }
  }
  productHeightStandard
  productHoodDetails {
    key
    translation {
      ...allLanguages
    }
  }
  productLengthStandard
  productMaterialTechnique {
    key
    translation {
      ...allLanguages
    }
  }
  productMeasure
  productNeckline {
    key
    translation {
      ...allLanguages
    }
  }
  productPockets {
    key
    translation {
      ...allLanguages
    }
  }
  productSleeve {
    key
    translation {
      ...allLanguages
    }
  }
  productTheme {
    key
    translation {
      ...allLanguages
    }
  }
  productUsp {
    ...allLanguages
  }
  productVolumeStandard
  productWidthStandard
  productWeight
  productWeightStandard
  filterColor {
    key
    translation {
      ...allLanguages
    }
  }
  sizes
  variations {
    itemNumber
    itemColorCode
    itemColorName {
      ...allLanguages
    }
    filterColor {
      key
      translation {
        ...allLanguages
      }
    }
    pictures {
      imageUrl
      resourcePictureType
      resourceFileId
    }
    sizes
  }
  pictures {
    resourcePictureType
    imageUrl
    resourceFileId
  }
  skus {
    sku
    company
    eanCode
    defaultSalesUnitGrossVolume
    description
    enDescription
    stockUnitCode
    commodityCode
    countryOfOrigin
    defaultSalesUnitCode
    availability
    nextStockUpdate
    changed
    skucolor
    skuSize {
      size
      webtext
    }
    defaultSalesUnitGrossWeight
    defaultSalesUnitNetWeight
    defaultSalesUnitGrossVolume
    defaultSalesUnitNetVolume
    defaultSalesUnitLength
    defaultSalesUnitWidth
    defaultSalesUnitHeight
    defaultSalesUnitItems
    retailPrice {
      price
      currency
    }
    prices {
      salesPrice
      currency
      priceList
    }
    master {
      ...skuUnit
    }
    pall {
      ...skuUnit
    }
    box {
      ...skuUnit
    }
    categorization {
      group1
      group2
      group3
      group4
      group5
      group6
    }
  }
}
    ${AllLanguagesFragmentDoc}
${SkuUnitFragmentDoc}`;
export const ToggleAsideMenuDocument = gql`
    mutation ToggleAsideMenu($active: Boolean!) {
  toggleAsideMenu(active: $active) @client {
    active
  }
}
    `;
export type ToggleAsideMenuMutationFn = ApolloReactCommon.MutationFunction<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables>;
export type ToggleAsideMenuComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables>, 'mutation'>;

    export const ToggleAsideMenuComponent = (props: ToggleAsideMenuComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables> mutation={ToggleAsideMenuDocument} {...props} />
    );
    

/**
 * __useToggleAsideMenuMutation__
 *
 * To run a mutation, you first call `useToggleAsideMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAsideMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAsideMenuMutation, { data, loading, error }] = useToggleAsideMenuMutation({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleAsideMenuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables>(ToggleAsideMenuDocument, baseOptions);
      }
export type ToggleAsideMenuMutationHookResult = ReturnType<typeof useToggleAsideMenuMutation>;
export type ToggleAsideMenuMutationResult = ApolloReactCommon.MutationResult<ToggleAsideMenuMutation>;
export type ToggleAsideMenuMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleAsideMenuMutation, ToggleAsideMenuMutationVariables>;
export const AsideMenuDocument = gql`
    query AsideMenu {
  asideMenu @client {
    active
  }
}
    `;
export type AsideMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AsideMenuQuery, AsideMenuQueryVariables>, 'query'>;

    export const AsideMenuComponent = (props: AsideMenuComponentProps) => (
      <ApolloReactComponents.Query<AsideMenuQuery, AsideMenuQueryVariables> query={AsideMenuDocument} {...props} />
    );
    

/**
 * __useAsideMenuQuery__
 *
 * To run a query within a React component, call `useAsideMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAsideMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsideMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useAsideMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AsideMenuQuery, AsideMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<AsideMenuQuery, AsideMenuQueryVariables>(AsideMenuDocument, baseOptions);
      }
export function useAsideMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AsideMenuQuery, AsideMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AsideMenuQuery, AsideMenuQueryVariables>(AsideMenuDocument, baseOptions);
        }
export type AsideMenuQueryHookResult = ReturnType<typeof useAsideMenuQuery>;
export type AsideMenuLazyQueryHookResult = ReturnType<typeof useAsideMenuLazyQuery>;
export type AsideMenuQueryResult = ApolloReactCommon.QueryResult<AsideMenuQuery, AsideMenuQueryVariables>;
export const DataLanguageDocument = gql`
    query DataLanguage {
  dataLanguage @client
}
    `;
export type DataLanguageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DataLanguageQuery, DataLanguageQueryVariables>, 'query'>;

    export const DataLanguageComponent = (props: DataLanguageComponentProps) => (
      <ApolloReactComponents.Query<DataLanguageQuery, DataLanguageQueryVariables> query={DataLanguageDocument} {...props} />
    );
    

/**
 * __useDataLanguageQuery__
 *
 * To run a query within a React component, call `useDataLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataLanguageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataLanguageQuery, DataLanguageQueryVariables>) {
        return ApolloReactHooks.useQuery<DataLanguageQuery, DataLanguageQueryVariables>(DataLanguageDocument, baseOptions);
      }
export function useDataLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataLanguageQuery, DataLanguageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataLanguageQuery, DataLanguageQueryVariables>(DataLanguageDocument, baseOptions);
        }
export type DataLanguageQueryHookResult = ReturnType<typeof useDataLanguageQuery>;
export type DataLanguageLazyQueryHookResult = ReturnType<typeof useDataLanguageLazyQuery>;
export type DataLanguageQueryResult = ApolloReactCommon.QueryResult<DataLanguageQuery, DataLanguageQueryVariables>;
export const UserDataDocument = gql`
    query UserData {
  isLoggedIn @client
  isAdmin @client
  userId @client
  username @client
}
    `;
export type UserDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserDataQuery, UserDataQueryVariables>, 'query'>;

    export const UserDataComponent = (props: UserDataComponentProps) => (
      <ApolloReactComponents.Query<UserDataQuery, UserDataQueryVariables> query={UserDataDocument} {...props} />
    );
    

/**
 * __useUserDataQuery__
 *
 * To run a query within a React component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDataQuery, UserDataQueryVariables>) {
        return ApolloReactHooks.useQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, baseOptions);
      }
export function useUserDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDataQuery, UserDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, baseOptions);
        }
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataLazyQueryHookResult = ReturnType<typeof useUserDataLazyQuery>;
export type UserDataQueryResult = ApolloReactCommon.QueryResult<UserDataQuery, UserDataQueryVariables>;
export const IsUserLoggedInDocument = gql`
    query IsUserLoggedIn {
  isLoggedIn @client
}
    `;
export type IsUserLoggedInComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>, 'query'>;

    export const IsUserLoggedInComponent = (props: IsUserLoggedInComponentProps) => (
      <ApolloReactComponents.Query<IsUserLoggedInQuery, IsUserLoggedInQueryVariables> query={IsUserLoggedInDocument} {...props} />
    );
    

/**
 * __useIsUserLoggedInQuery__
 *
 * To run a query within a React component, call `useIsUserLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserLoggedInQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
        return ApolloReactHooks.useQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
      }
export function useIsUserLoggedInLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>(IsUserLoggedInDocument, baseOptions);
        }
export type IsUserLoggedInQueryHookResult = ReturnType<typeof useIsUserLoggedInQuery>;
export type IsUserLoggedInLazyQueryHookResult = ReturnType<typeof useIsUserLoggedInLazyQuery>;
export type IsUserLoggedInQueryResult = ApolloReactCommon.QueryResult<IsUserLoggedInQuery, IsUserLoggedInQueryVariables>;
export const ConfirmUserDocument = gql`
    mutation ConfirmUser($id: MongoID!, $password: String!) {
  userConfirm(id: $id, password: $password) {
    _id
    username
    email
    confirmed
  }
}
    `;
export type ConfirmUserMutationFn = ApolloReactCommon.MutationFunction<ConfirmUserMutation, ConfirmUserMutationVariables>;
export type ConfirmUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmUserMutation, ConfirmUserMutationVariables>, 'mutation'>;

    export const ConfirmUserComponent = (props: ConfirmUserComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmUserMutation, ConfirmUserMutationVariables> mutation={ConfirmUserDocument} {...props} />
    );
    

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmUserMutation, ConfirmUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(ConfirmUserDocument, baseOptions);
      }
export type ConfirmUserMutationHookResult = ReturnType<typeof useConfirmUserMutation>;
export type ConfirmUserMutationResult = ApolloReactCommon.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const CreateApplicationDocument = gql`
    mutation CreateApplication($name: String!, $companyCode: String!, $commerceUserId: String, $commerceContextId: String, $pricelists: [PricelistInput]) {
  applicationCreate(name: $name, companyCode: $companyCode, commerceUserId: $commerceUserId, commerceContextId: $commerceContextId, pricelists: $pricelists) {
    _id
    name
    companyCode
    userId
    commerceApp {
      name
    }
    commerceAppUser {
      email
    }
    active
    token
    tokenExp
    tokenExpiration
    deletedAt
    user {
      _id
      username
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
    complexityLimit
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type CreateApplicationMutationFn = ApolloReactCommon.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;
export type CreateApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApplicationMutation, CreateApplicationMutationVariables>, 'mutation'>;

    export const CreateApplicationComponent = (props: CreateApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApplicationMutation, CreateApplicationMutationVariables> mutation={CreateApplicationDocument} {...props} />
    );
    

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyCode: // value for 'companyCode'
 *      commerceUserId: // value for 'commerceUserId'
 *      commerceContextId: // value for 'commerceContextId'
 *      pricelists: // value for 'pricelists'
 *   },
 * });
 */
export function useCreateApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, baseOptions);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = ApolloReactCommon.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($headline: String!, $description: String!, $body: String!, $imageUrl: String, $author: String, $published: Boolean!) {
  articleCreate(record: {headline: $headline, description: $description, body: $body, imageUrl: $imageUrl, author: $author, published: $published}) {
    record {
      _id
      headline
      description
      body
      author
      imageUrl
      publishedAt
      published
      updatedAt
    }
  }
}
    `;
export type CreateArticleMutationFn = ApolloReactCommon.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;
export type CreateArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArticleMutation, CreateArticleMutationVariables>, 'mutation'>;

    export const CreateArticleComponent = (props: CreateArticleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArticleMutation, CreateArticleMutationVariables> mutation={CreateArticleDocument} {...props} />
    );
    

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      headline: // value for 'headline'
 *      description: // value for 'description'
 *      body: // value for 'body'
 *      imageUrl: // value for 'imageUrl'
 *      author: // value for 'author'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, baseOptions);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = ApolloReactCommon.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const CreateCommerceUserLinkDocument = gql`
    mutation CreateCommerceUserLink($name: String!, $users: [commerceUserLinkUsersInput]!, $contexts: [commerceUserLinkContextsInput]!) {
  commerceUserLinkCreate(record: {name: $name, users: $users, contexts: $contexts}) {
    record {
      _id
      name
      users {
        id
        email
      }
      contexts {
        contextId
        companyCode
        name
      }
    }
  }
}
    `;
export type CreateCommerceUserLinkMutationFn = ApolloReactCommon.MutationFunction<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables>;
export type CreateCommerceUserLinkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables>, 'mutation'>;

    export const CreateCommerceUserLinkComponent = (props: CreateCommerceUserLinkComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables> mutation={CreateCommerceUserLinkDocument} {...props} />
    );
    

/**
 * __useCreateCommerceUserLinkMutation__
 *
 * To run a mutation, you first call `useCreateCommerceUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceUserLinkMutation, { data, loading, error }] = useCreateCommerceUserLinkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      users: // value for 'users'
 *      contexts: // value for 'contexts'
 *   },
 * });
 */
export function useCreateCommerceUserLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables>(CreateCommerceUserLinkDocument, baseOptions);
      }
export type CreateCommerceUserLinkMutationHookResult = ReturnType<typeof useCreateCommerceUserLinkMutation>;
export type CreateCommerceUserLinkMutationResult = ApolloReactCommon.MutationResult<CreateCommerceUserLinkMutation>;
export type CreateCommerceUserLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCommerceUserLinkMutation, CreateCommerceUserLinkMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($name: String!, $companyCode: String!) {
  companyCreate(record: {name: $name, companyCode: $companyCode}) {
    record {
      _id
      name
      companyCode
    }
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export type CreateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyMutation, CreateCompanyMutationVariables>, 'mutation'>;

    export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> mutation={CreateCompanyDocument} {...props} />
    );
    

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateNotificationDocument = gql`
    mutation CreateNotification($userIds: [MongoID!], $headline: String!, $description: String!, $body: String!, $link: String) {
  notificationCreateAndSend(userIds: $userIds, record: {headline: $headline, description: $description, body: $body, link: $link}) {
    record {
      headline
      description
      body
      link
      createdAt
    }
  }
}
    `;
export type CreateNotificationMutationFn = ApolloReactCommon.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;
export type CreateNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNotificationMutation, CreateNotificationMutationVariables>, 'mutation'>;

    export const CreateNotificationComponent = (props: CreateNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNotificationMutation, CreateNotificationMutationVariables> mutation={CreateNotificationDocument} {...props} />
    );
    

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      headline: // value for 'headline'
 *      description: // value for 'description'
 *      body: // value for 'body'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, baseOptions);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = ApolloReactCommon.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const CreateServiceContainerDocument = gql`
    mutation CreateServiceContainer($name: String!, $description: String) {
  serviceContainerCreate(record: {name: $name, description: $description}) {
    record {
      _id
      name
      description
    }
  }
}
    `;
export type CreateServiceContainerMutationFn = ApolloReactCommon.MutationFunction<CreateServiceContainerMutation, CreateServiceContainerMutationVariables>;
export type CreateServiceContainerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateServiceContainerMutation, CreateServiceContainerMutationVariables>, 'mutation'>;

    export const CreateServiceContainerComponent = (props: CreateServiceContainerComponentProps) => (
      <ApolloReactComponents.Mutation<CreateServiceContainerMutation, CreateServiceContainerMutationVariables> mutation={CreateServiceContainerDocument} {...props} />
    );
    

/**
 * __useCreateServiceContainerMutation__
 *
 * To run a mutation, you first call `useCreateServiceContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceContainerMutation, { data, loading, error }] = useCreateServiceContainerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateServiceContainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateServiceContainerMutation, CreateServiceContainerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateServiceContainerMutation, CreateServiceContainerMutationVariables>(CreateServiceContainerDocument, baseOptions);
      }
export type CreateServiceContainerMutationHookResult = ReturnType<typeof useCreateServiceContainerMutation>;
export type CreateServiceContainerMutationResult = ApolloReactCommon.MutationResult<CreateServiceContainerMutation>;
export type CreateServiceContainerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateServiceContainerMutation, CreateServiceContainerMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($username: String!, $password: String!, $email: String!, $companyIds: [String!], $serviceContainerIds: [MongoID!], $commerceUserLinkId: MongoID, $isAdmin: Boolean, $confirmed: Boolean, $active: Boolean, $pricelists: [PricelistInput]) {
  userRegister(username: $username, password: $password, email: $email, companyIds: $companyIds, serviceContainerIds: $serviceContainerIds, commerceUserLinkId: $commerceUserLinkId, isAdmin: $isAdmin, confirmed: $confirmed, active: $active, pricelists: $pricelists) {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    serviceContainers {
      _id
      name
    }
    companies {
      _id
      name
      companyCode
    }
    confirmed
    active
    isAdmin
    registeredAt
    lastLogin
    updatedAt
    pricelists {
      pricelist
      companyCode
    }
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *      companyIds: // value for 'companyIds'
 *      serviceContainerIds: // value for 'serviceContainerIds'
 *      commerceUserLinkId: // value for 'commerceUserLinkId'
 *      isAdmin: // value for 'isAdmin'
 *      confirmed: // value for 'confirmed'
 *      active: // value for 'active'
 *      pricelists: // value for 'pricelists'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserAssortmentDocument = gql`
    mutation CreateUserAssortment($record: CreateOneuserAssortmentInput!) {
  userAssortmentCreate(record: $record) {
    record {
      name
      _id
      assortmentId
      users {
        _id
      }
    }
  }
}
    `;
export type CreateUserAssortmentMutationFn = ApolloReactCommon.MutationFunction<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables>;
export type CreateUserAssortmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables>, 'mutation'>;

    export const CreateUserAssortmentComponent = (props: CreateUserAssortmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables> mutation={CreateUserAssortmentDocument} {...props} />
    );
    

/**
 * __useCreateUserAssortmentMutation__
 *
 * To run a mutation, you first call `useCreateUserAssortmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAssortmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAssortmentMutation, { data, loading, error }] = useCreateUserAssortmentMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useCreateUserAssortmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables>(CreateUserAssortmentDocument, baseOptions);
      }
export type CreateUserAssortmentMutationHookResult = ReturnType<typeof useCreateUserAssortmentMutation>;
export type CreateUserAssortmentMutationResult = ApolloReactCommon.MutationResult<CreateUserAssortmentMutation>;
export type CreateUserAssortmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserAssortmentMutation, CreateUserAssortmentMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($email: String!, $companyIds: [String!], $serviceContainerIds: [MongoID], $commerceUserLinkId: MongoID) {
  userInvite(email: $email, companyIds: $companyIds, serviceContainerIds: $serviceContainerIds, commerceUserLinkId: $commerceUserLinkId) {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    serviceContainers {
      _id
      name
    }
    companies {
      _id
      name
      companyCode
    }
    confirmed
    active
    isAdmin
    registeredAt
    lastLogin
    updatedAt
  }
}
    `;
export type InviteUserMutationFn = ApolloReactCommon.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export type InviteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserMutation, InviteUserMutationVariables>, 'mutation'>;

    export const InviteUserComponent = (props: InviteUserComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserMutation, InviteUserMutationVariables> mutation={InviteUserDocument} {...props} />
    );
    

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyIds: // value for 'companyIds'
 *      serviceContainerIds: // value for 'serviceContainerIds'
 *      commerceUserLinkId: // value for 'commerceUserLinkId'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, baseOptions);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = ApolloReactCommon.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    record {
      _id
      username
      token
      isAdmin
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RemoveApplicationDocument = gql`
    mutation RemoveApplication($id: MongoID!) {
  applicationRemoveById(_id: $id) {
    _id
    name
    userId
    companyCode
    commerceApp {
      name
    }
    commerceAppUser {
      email
    }
    active
    token
    tokenExp
    tokenExpiration
    deletedAt
  }
}
    `;
export type RemoveApplicationMutationFn = ApolloReactCommon.MutationFunction<RemoveApplicationMutation, RemoveApplicationMutationVariables>;
export type RemoveApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>, 'mutation'>;

    export const RemoveApplicationComponent = (props: RemoveApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveApplicationMutation, RemoveApplicationMutationVariables> mutation={RemoveApplicationDocument} {...props} />
    );
    

/**
 * __useRemoveApplicationMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationMutation, { data, loading, error }] = useRemoveApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveApplicationMutation, RemoveApplicationMutationVariables>(RemoveApplicationDocument, baseOptions);
      }
export type RemoveApplicationMutationHookResult = ReturnType<typeof useRemoveApplicationMutation>;
export type RemoveApplicationMutationResult = ApolloReactCommon.MutationResult<RemoveApplicationMutation>;
export type RemoveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveApplicationMutation, RemoveApplicationMutationVariables>;
export const RemoveArticleDocument = gql`
    mutation RemoveArticle($id: MongoID!) {
  articleRemoveById(_id: $id) {
    record {
      _id
      headline
      description
      body
      author
      imageUrl
      publishedAt
      published
      updatedAt
    }
  }
}
    `;
export type RemoveArticleMutationFn = ApolloReactCommon.MutationFunction<RemoveArticleMutation, RemoveArticleMutationVariables>;
export type RemoveArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveArticleMutation, RemoveArticleMutationVariables>, 'mutation'>;

    export const RemoveArticleComponent = (props: RemoveArticleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveArticleMutation, RemoveArticleMutationVariables> mutation={RemoveArticleDocument} {...props} />
    );
    

/**
 * __useRemoveArticleMutation__
 *
 * To run a mutation, you first call `useRemoveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleMutation, { data, loading, error }] = useRemoveArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveArticleMutation, RemoveArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveArticleMutation, RemoveArticleMutationVariables>(RemoveArticleDocument, baseOptions);
      }
export type RemoveArticleMutationHookResult = ReturnType<typeof useRemoveArticleMutation>;
export type RemoveArticleMutationResult = ApolloReactCommon.MutationResult<RemoveArticleMutation>;
export type RemoveArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveArticleMutation, RemoveArticleMutationVariables>;
export const RemoveCommerceUserLinkDocument = gql`
    mutation RemoveCommerceUserLink($id: MongoID!) {
  commerceUserLinkRemoveById(_id: $id) {
    record {
      _id
      name
      users {
        id
        email
      }
      contexts {
        contextId
        companyCode
      }
    }
  }
}
    `;
export type RemoveCommerceUserLinkMutationFn = ApolloReactCommon.MutationFunction<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables>;
export type RemoveCommerceUserLinkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables>, 'mutation'>;

    export const RemoveCommerceUserLinkComponent = (props: RemoveCommerceUserLinkComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables> mutation={RemoveCommerceUserLinkDocument} {...props} />
    );
    

/**
 * __useRemoveCommerceUserLinkMutation__
 *
 * To run a mutation, you first call `useRemoveCommerceUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommerceUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommerceUserLinkMutation, { data, loading, error }] = useRemoveCommerceUserLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCommerceUserLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables>(RemoveCommerceUserLinkDocument, baseOptions);
      }
export type RemoveCommerceUserLinkMutationHookResult = ReturnType<typeof useRemoveCommerceUserLinkMutation>;
export type RemoveCommerceUserLinkMutationResult = ApolloReactCommon.MutationResult<RemoveCommerceUserLinkMutation>;
export type RemoveCommerceUserLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCommerceUserLinkMutation, RemoveCommerceUserLinkMutationVariables>;
export const RemoveCompanyDocument = gql`
    mutation RemoveCompany($id: MongoID!) {
  companyRemoveById(_id: $id) {
    record {
      _id
      name
      companyCode
    }
  }
}
    `;
export type RemoveCompanyMutationFn = ApolloReactCommon.MutationFunction<RemoveCompanyMutation, RemoveCompanyMutationVariables>;
export type RemoveCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>, 'mutation'>;

    export const RemoveCompanyComponent = (props: RemoveCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCompanyMutation, RemoveCompanyMutationVariables> mutation={RemoveCompanyDocument} {...props} />
    );
    

/**
 * __useRemoveCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMutation, { data, loading, error }] = useRemoveCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCompanyMutation, RemoveCompanyMutationVariables>(RemoveCompanyDocument, baseOptions);
      }
export type RemoveCompanyMutationHookResult = ReturnType<typeof useRemoveCompanyMutation>;
export type RemoveCompanyMutationResult = ApolloReactCommon.MutationResult<RemoveCompanyMutation>;
export type RemoveCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>;
export const RemoveExportConfigurationDocument = gql`
    mutation RemoveExportConfiguration($id: MongoID!) {
  exportConfigurationRemove(_id: $id) {
    record {
      name
    }
  }
}
    `;
export type RemoveExportConfigurationMutationFn = ApolloReactCommon.MutationFunction<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables>;
export type RemoveExportConfigurationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables>, 'mutation'>;

    export const RemoveExportConfigurationComponent = (props: RemoveExportConfigurationComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables> mutation={RemoveExportConfigurationDocument} {...props} />
    );
    

/**
 * __useRemoveExportConfigurationMutation__
 *
 * To run a mutation, you first call `useRemoveExportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExportConfigurationMutation, { data, loading, error }] = useRemoveExportConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveExportConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables>(RemoveExportConfigurationDocument, baseOptions);
      }
export type RemoveExportConfigurationMutationHookResult = ReturnType<typeof useRemoveExportConfigurationMutation>;
export type RemoveExportConfigurationMutationResult = ApolloReactCommon.MutationResult<RemoveExportConfigurationMutation>;
export type RemoveExportConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveExportConfigurationMutation, RemoveExportConfigurationMutationVariables>;
export const RemoveServiceContainerDocument = gql`
    mutation RemoveServiceContainer($id: MongoID!) {
  serviceContainerRemoveById(_id: $id) {
    record {
      _id
      name
      description
    }
  }
}
    `;
export type RemoveServiceContainerMutationFn = ApolloReactCommon.MutationFunction<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables>;
export type RemoveServiceContainerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables>, 'mutation'>;

    export const RemoveServiceContainerComponent = (props: RemoveServiceContainerComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables> mutation={RemoveServiceContainerDocument} {...props} />
    );
    

/**
 * __useRemoveServiceContainerMutation__
 *
 * To run a mutation, you first call `useRemoveServiceContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveServiceContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeServiceContainerMutation, { data, loading, error }] = useRemoveServiceContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveServiceContainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables>(RemoveServiceContainerDocument, baseOptions);
      }
export type RemoveServiceContainerMutationHookResult = ReturnType<typeof useRemoveServiceContainerMutation>;
export type RemoveServiceContainerMutationResult = ApolloReactCommon.MutationResult<RemoveServiceContainerMutation>;
export type RemoveServiceContainerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveServiceContainerMutation, RemoveServiceContainerMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: MongoID!) {
  userRemoveById(_id: $id) {
    record {
      _id
      username
      email
      companyIds
      serviceContainerIds
      commerceUserLinkId
      serviceContainers {
        name
      }
    }
  }
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;
export type RemoveUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserMutation, RemoveUserMutationVariables>, 'mutation'>;

    export const RemoveUserComponent = (props: RemoveUserComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserMutation, RemoveUserMutationVariables> mutation={RemoveUserDocument} {...props} />
    );
    

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RemoveUserAssortmentDocument = gql`
    mutation RemoveUserAssortment($id: MongoID!) {
  userAssortmentDelete(_id: $id) {
    record {
      name
      _id
      assortmentId
      users {
        _id
      }
    }
  }
}
    `;
export type RemoveUserAssortmentMutationFn = ApolloReactCommon.MutationFunction<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables>;
export type RemoveUserAssortmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables>, 'mutation'>;

    export const RemoveUserAssortmentComponent = (props: RemoveUserAssortmentComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables> mutation={RemoveUserAssortmentDocument} {...props} />
    );
    

/**
 * __useRemoveUserAssortmentMutation__
 *
 * To run a mutation, you first call `useRemoveUserAssortmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserAssortmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserAssortmentMutation, { data, loading, error }] = useRemoveUserAssortmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserAssortmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables>(RemoveUserAssortmentDocument, baseOptions);
      }
export type RemoveUserAssortmentMutationHookResult = ReturnType<typeof useRemoveUserAssortmentMutation>;
export type RemoveUserAssortmentMutationResult = ApolloReactCommon.MutationResult<RemoveUserAssortmentMutation>;
export type RemoveUserAssortmentMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserAssortmentMutation, RemoveUserAssortmentMutationVariables>;
export const SaveExportConfigurationDocument = gql`
    mutation SaveExportConfiguration($name: String!) {
  exportConfigurationSave(name: $name) {
    _id
    name
  }
}
    `;
export type SaveExportConfigurationMutationFn = ApolloReactCommon.MutationFunction<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables>;
export type SaveExportConfigurationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables>, 'mutation'>;

    export const SaveExportConfigurationComponent = (props: SaveExportConfigurationComponentProps) => (
      <ApolloReactComponents.Mutation<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables> mutation={SaveExportConfigurationDocument} {...props} />
    );
    

/**
 * __useSaveExportConfigurationMutation__
 *
 * To run a mutation, you first call `useSaveExportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExportConfigurationMutation, { data, loading, error }] = useSaveExportConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSaveExportConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables>(SaveExportConfigurationDocument, baseOptions);
      }
export type SaveExportConfigurationMutationHookResult = ReturnType<typeof useSaveExportConfigurationMutation>;
export type SaveExportConfigurationMutationResult = ApolloReactCommon.MutationResult<SaveExportConfigurationMutation>;
export type SaveExportConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveExportConfigurationMutation, SaveExportConfigurationMutationVariables>;
export const UpdateApplicationStatusDocument = gql`
    mutation UpdateApplicationStatus($id: MongoID!, $active: Boolean, $complexityLimit: Int, $pricelists: [PricelistInput]) {
  applicationUpdateStatus(id: $id, active: $active, complexityLimit: $complexityLimit, pricelists: $pricelists) {
    _id
    name
    userId
    active
    complexityLimit
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type UpdateApplicationStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>;
export type UpdateApplicationStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>, 'mutation'>;

    export const UpdateApplicationStatusComponent = (props: UpdateApplicationStatusComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables> mutation={UpdateApplicationStatusDocument} {...props} />
    );
    

/**
 * __useUpdateApplicationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStatusMutation, { data, loading, error }] = useUpdateApplicationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      complexityLimit: // value for 'complexityLimit'
 *      pricelists: // value for 'pricelists'
 *   },
 * });
 */
export function useUpdateApplicationStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>(UpdateApplicationStatusDocument, baseOptions);
      }
export type UpdateApplicationStatusMutationHookResult = ReturnType<typeof useUpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationResult = ApolloReactCommon.MutationResult<UpdateApplicationStatusMutation>;
export type UpdateApplicationStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApplicationStatusMutation, UpdateApplicationStatusMutationVariables>;
export const UpdateApplicationStatusByUserIdDocument = gql`
    mutation UpdateApplicationStatusByUserId($userId: MongoID!, $active: Boolean!) {
  applicationUpdateStatusByUserId(userId: $userId, active: $active) {
    _id
    name
    userId
    active
  }
}
    `;
export type UpdateApplicationStatusByUserIdMutationFn = ApolloReactCommon.MutationFunction<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables>;
export type UpdateApplicationStatusByUserIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables>, 'mutation'>;

    export const UpdateApplicationStatusByUserIdComponent = (props: UpdateApplicationStatusByUserIdComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables> mutation={UpdateApplicationStatusByUserIdDocument} {...props} />
    );
    

/**
 * __useUpdateApplicationStatusByUserIdMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStatusByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStatusByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStatusByUserIdMutation, { data, loading, error }] = useUpdateApplicationStatusByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateApplicationStatusByUserIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables>(UpdateApplicationStatusByUserIdDocument, baseOptions);
      }
export type UpdateApplicationStatusByUserIdMutationHookResult = ReturnType<typeof useUpdateApplicationStatusByUserIdMutation>;
export type UpdateApplicationStatusByUserIdMutationResult = ApolloReactCommon.MutationResult<UpdateApplicationStatusByUserIdMutation>;
export type UpdateApplicationStatusByUserIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApplicationStatusByUserIdMutation, UpdateApplicationStatusByUserIdMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($id: MongoID!, $headline: String!, $description: String!, $body: String!, $imageUrl: String, $author: String, $published: Boolean!) {
  articleUpdate(record: {_id: $id, headline: $headline, description: $description, body: $body, imageUrl: $imageUrl, author: $author, published: $published}) {
    record {
      _id
      headline
      description
      body
      author
      imageUrl
      publishedAt
      published
    }
  }
}
    `;
export type UpdateArticleMutationFn = ApolloReactCommon.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;
export type UpdateArticleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArticleMutation, UpdateArticleMutationVariables>, 'mutation'>;

    export const UpdateArticleComponent = (props: UpdateArticleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArticleMutation, UpdateArticleMutationVariables> mutation={UpdateArticleDocument} {...props} />
    );
    

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      headline: // value for 'headline'
 *      description: // value for 'description'
 *      body: // value for 'body'
 *      imageUrl: // value for 'imageUrl'
 *      author: // value for 'author'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, baseOptions);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = ApolloReactCommon.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const UpdateCommerceUserLinkDocument = gql`
    mutation UpdateCommerceUserLink($id: MongoID!, $name: String!, $users: [commerceUserLinkUsersInput]!, $contexts: [commerceUserLinkContextsInput]!) {
  commerceUserLinkUpdateById(record: {_id: $id, name: $name, users: $users, contexts: $contexts}) {
    record {
      _id
      name
      users {
        id
        email
      }
      contexts {
        contextId
        companyCode
        name
      }
    }
  }
}
    `;
export type UpdateCommerceUserLinkMutationFn = ApolloReactCommon.MutationFunction<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables>;
export type UpdateCommerceUserLinkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables>, 'mutation'>;

    export const UpdateCommerceUserLinkComponent = (props: UpdateCommerceUserLinkComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables> mutation={UpdateCommerceUserLinkDocument} {...props} />
    );
    

/**
 * __useUpdateCommerceUserLinkMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceUserLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceUserLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceUserLinkMutation, { data, loading, error }] = useUpdateCommerceUserLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      users: // value for 'users'
 *      contexts: // value for 'contexts'
 *   },
 * });
 */
export function useUpdateCommerceUserLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables>(UpdateCommerceUserLinkDocument, baseOptions);
      }
export type UpdateCommerceUserLinkMutationHookResult = ReturnType<typeof useUpdateCommerceUserLinkMutation>;
export type UpdateCommerceUserLinkMutationResult = ApolloReactCommon.MutationResult<UpdateCommerceUserLinkMutation>;
export type UpdateCommerceUserLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCommerceUserLinkMutation, UpdateCommerceUserLinkMutationVariables>;
export const UpdateCurrentExportConfigurationDocument = gql`
    mutation UpdateCurrentExportConfiguration($queryVariables: exportConfigurationQueryVariablesInput) {
  exportConfigurationUpdateCurrent(queryVariables: $queryVariables) {
    _id
  }
}
    `;
export type UpdateCurrentExportConfigurationMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables>;
export type UpdateCurrentExportConfigurationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables>, 'mutation'>;

    export const UpdateCurrentExportConfigurationComponent = (props: UpdateCurrentExportConfigurationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables> mutation={UpdateCurrentExportConfigurationDocument} {...props} />
    );
    

/**
 * __useUpdateCurrentExportConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentExportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentExportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentExportConfigurationMutation, { data, loading, error }] = useUpdateCurrentExportConfigurationMutation({
 *   variables: {
 *      queryVariables: // value for 'queryVariables'
 *   },
 * });
 */
export function useUpdateCurrentExportConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables>(UpdateCurrentExportConfigurationDocument, baseOptions);
      }
export type UpdateCurrentExportConfigurationMutationHookResult = ReturnType<typeof useUpdateCurrentExportConfigurationMutation>;
export type UpdateCurrentExportConfigurationMutationResult = ApolloReactCommon.MutationResult<UpdateCurrentExportConfigurationMutation>;
export type UpdateCurrentExportConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrentExportConfigurationMutation, UpdateCurrentExportConfigurationMutationVariables>;
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($username: String, $email: String, $password: String) {
  meUpdate(username: $username, email: $email, password: $password) {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    confirmed
    active
    isAdmin
    registeredAt
    lastLogin
    updatedAt
    commerceUserLink {
      _id
      name
      users {
        id
        email
      }
      contexts {
        contextId
        companyCode
      }
    }
  }
}
    `;
export type UpdateCurrentUserMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export type UpdateCurrentUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>, 'mutation'>;

    export const UpdateCurrentUserComponent = (props: UpdateCurrentUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables> mutation={UpdateCurrentUserDocument} {...props} />
    );
    

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument, baseOptions);
      }
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationResult = ApolloReactCommon.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const UpdateUserAssortmentDocument = gql`
    mutation UpdateUserAssortment($record: UpdateByIduserAssortmentInput!) {
  userAssortmentUpdate(record: $record) {
    recordId
    record {
      name
      assortmentId
      users {
        _id
      }
      _id
    }
  }
}
    `;
export type UpdateUserAssortmentMutationFn = ApolloReactCommon.MutationFunction<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables>;
export type UpdateUserAssortmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables>, 'mutation'>;

    export const UpdateUserAssortmentComponent = (props: UpdateUserAssortmentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables> mutation={UpdateUserAssortmentDocument} {...props} />
    );
    

/**
 * __useUpdateUserAssortmentMutation__
 *
 * To run a mutation, you first call `useUpdateUserAssortmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAssortmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAssortmentMutation, { data, loading, error }] = useUpdateUserAssortmentMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useUpdateUserAssortmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables>(UpdateUserAssortmentDocument, baseOptions);
      }
export type UpdateUserAssortmentMutationHookResult = ReturnType<typeof useUpdateUserAssortmentMutation>;
export type UpdateUserAssortmentMutationResult = ApolloReactCommon.MutationResult<UpdateUserAssortmentMutation>;
export type UpdateUserAssortmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserAssortmentMutation, UpdateUserAssortmentMutationVariables>;
export const UpdateUserByIdDocument = gql`
    mutation UpdateUserById($id: MongoID!, $username: String, $email: String, $password: String, $companyIds: [String], $serviceContainerIds: [MongoID], $commerceUserLinkId: MongoID, $isAdmin: Boolean, $confirmed: Boolean, $active: Boolean, $pricelists: [PricelistInput]) {
  userUpdateById(_id: $id, username: $username, email: $email, password: $password, companyIds: $companyIds, serviceContainerIds: $serviceContainerIds, commerceUserLinkId: $commerceUserLinkId, isAdmin: $isAdmin, confirmed: $confirmed, active: $active, pricelists: $pricelists) {
    _id
    username
    isAdmin
    confirmed
    active
    email
    serviceContainerIds
    companyIds
    commerceUserLinkId
    registeredAt
    lastLogin
    updatedAt
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type UpdateUserByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export type UpdateUserByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>, 'mutation'>;

    export const UpdateUserByIdComponent = (props: UpdateUserByIdComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables> mutation={UpdateUserByIdDocument} {...props} />
    );
    

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      companyIds: // value for 'companyIds'
 *      serviceContainerIds: // value for 'serviceContainerIds'
 *      commerceUserLinkId: // value for 'commerceUserLinkId'
 *      isAdmin: // value for 'isAdmin'
 *      confirmed: // value for 'confirmed'
 *      active: // value for 'active'
 *      pricelists: // value for 'pricelists'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(UpdateUserByIdDocument, baseOptions);
      }
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = ApolloReactCommon.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
export const UpdateUserNotificationByIdDocument = gql`
    mutation UpdateUserNotificationById($id: MongoID!, $isRead: Boolean!) {
  userNotificationUpdateById(record: {_id: $id, isRead: $isRead}) {
    record {
      _id
      userId
      notificationId
      isRead
      notification {
        _id
        headline
        description
        body
        createdAt
        author {
          name
        }
      }
    }
  }
}
    `;
export type UpdateUserNotificationByIdMutationFn = ApolloReactCommon.MutationFunction<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables>;
export type UpdateUserNotificationByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables>, 'mutation'>;

    export const UpdateUserNotificationByIdComponent = (props: UpdateUserNotificationByIdComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables> mutation={UpdateUserNotificationByIdDocument} {...props} />
    );
    

/**
 * __useUpdateUserNotificationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationByIdMutation, { data, loading, error }] = useUpdateUserNotificationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isRead: // value for 'isRead'
 *   },
 * });
 */
export function useUpdateUserNotificationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables>(UpdateUserNotificationByIdDocument, baseOptions);
      }
export type UpdateUserNotificationByIdMutationHookResult = ReturnType<typeof useUpdateUserNotificationByIdMutation>;
export type UpdateUserNotificationByIdMutationResult = ApolloReactCommon.MutationResult<UpdateUserNotificationByIdMutation>;
export type UpdateUserNotificationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserNotificationByIdMutation, UpdateUserNotificationByIdMutationVariables>;
export const ApplicationsDocument = gql`
    query Applications {
  applications {
    _id
    name
    userId
    companyCode
    commerceApp {
      name
    }
    commerceAppUser {
      email
    }
    active
    token
    tokenExp
    tokenExpiration
    deletedAt
    user {
      _id
      username
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
    complexityLimit
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type ApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsQuery, ApplicationsQueryVariables>, 'query'>;

    export const ApplicationsComponent = (props: ApplicationsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsQuery, ApplicationsQueryVariables> query={ApplicationsDocument} {...props} />
    );
    

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, baseOptions);
      }
export function useApplicationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, baseOptions);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = ApolloReactCommon.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const ApplicationsByUserIdDocument = gql`
    query ApplicationsByUserId($userId: String) {
  applicationsByUserId(userId: $userId) {
    _id
    name
    userId
    companyCode
    commerceApp {
      name
    }
    commerceAppUser {
      email
    }
    active
    token
    tokenExp
    tokenExpiration
    deletedAt
    user {
      _id
      username
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
    complexityLimit
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type ApplicationsByUserIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>, 'query'>;

    export const ApplicationsByUserIdComponent = (props: ApplicationsByUserIdComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables> query={ApplicationsByUserIdDocument} {...props} />
    );
    

/**
 * __useApplicationsByUserIdQuery__
 *
 * To run a query within a React component, call `useApplicationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useApplicationsByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>(ApplicationsByUserIdDocument, baseOptions);
      }
export function useApplicationsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>(ApplicationsByUserIdDocument, baseOptions);
        }
export type ApplicationsByUserIdQueryHookResult = ReturnType<typeof useApplicationsByUserIdQuery>;
export type ApplicationsByUserIdLazyQueryHookResult = ReturnType<typeof useApplicationsByUserIdLazyQuery>;
export type ApplicationsByUserIdQueryResult = ApolloReactCommon.QueryResult<ApplicationsByUserIdQuery, ApplicationsByUserIdQueryVariables>;
export const ArticleDocument = gql`
    query Article($id: MongoID!) {
  article(_id: $id) {
    _id
    headline
    description
    body
    author
    imageUrl
    publishedAt
    published
    updatedAt
  }
}
    `;
export type ArticleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArticleQuery, ArticleQueryVariables>, 'query'> & ({ variables: ArticleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArticleComponent = (props: ArticleComponentProps) => (
      <ApolloReactComponents.Query<ArticleQuery, ArticleQueryVariables> query={ArticleDocument} {...props} />
    );
    

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
      }
export function useArticleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, baseOptions);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = ApolloReactCommon.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query Articles($page: Int!, $perPage: Int!, $published: Boolean) {
  articlesPagination(page: $page, perPage: $perPage, filter: {published: $published}, sort: _ID_DESC) {
    items {
      _id
      headline
      description
      body
      author
      imageUrl
      publishedAt
      published
      updatedAt
    }
    pageInfo {
      currentPage
      pageCount
      perPage
      hasNextPage
      hasPreviousPage
    }
    count
  }
}
    `;
export type ArticlesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArticlesQuery, ArticlesQueryVariables>, 'query'> & ({ variables: ArticlesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArticlesComponent = (props: ArticlesComponentProps) => (
      <ApolloReactComponents.Query<ArticlesQuery, ArticlesQueryVariables> query={ArticlesDocument} {...props} />
    );
    

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
      }
export function useArticlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, baseOptions);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = ApolloReactCommon.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const AssortmentDocument = gql`
    query Assortment($assortmentId: String!, $language: String!, $page: Int!, $pageSize: PageSize!) {
  assortment(assortmentId: $assortmentId, language: $language, page: $page, pageSize: $pageSize) {
    result {
      assortmentId
      id
      name
      parentId
      childNodeIds
    }
    took
    count
    pageInfo {
      hasNextPage
      page
    }
    error {
      code
      message
    }
  }
}
    `;
export type AssortmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AssortmentQuery, AssortmentQueryVariables>, 'query'> & ({ variables: AssortmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AssortmentComponent = (props: AssortmentComponentProps) => (
      <ApolloReactComponents.Query<AssortmentQuery, AssortmentQueryVariables> query={AssortmentDocument} {...props} />
    );
    

/**
 * __useAssortmentQuery__
 *
 * To run a query within a React component, call `useAssortmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssortmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssortmentQuery({
 *   variables: {
 *      assortmentId: // value for 'assortmentId'
 *      language: // value for 'language'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useAssortmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssortmentQuery, AssortmentQueryVariables>) {
        return ApolloReactHooks.useQuery<AssortmentQuery, AssortmentQueryVariables>(AssortmentDocument, baseOptions);
      }
export function useAssortmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssortmentQuery, AssortmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssortmentQuery, AssortmentQueryVariables>(AssortmentDocument, baseOptions);
        }
export type AssortmentQueryHookResult = ReturnType<typeof useAssortmentQuery>;
export type AssortmentLazyQueryHookResult = ReturnType<typeof useAssortmentLazyQuery>;
export type AssortmentQueryResult = ApolloReactCommon.QueryResult<AssortmentQuery, AssortmentQueryVariables>;
export const AssortmentNodeDocument = gql`
    query AssortmentNode($id: String!, $language: String!, $productPage: Int!, $productPageSize: PageSize!) {
  assortmentNodeById(id: $id, language: $language) {
    id
    name
    childProducts(page: $productPage, pageSize: $productPageSize) {
      result {
        productName
        productNumber
        pictures {
          imageUrl
          resourceFileId
        }
      }
      pageInfo {
        page
        hasNextPage
      }
      count
    }
  }
}
    `;
export type AssortmentNodeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AssortmentNodeQuery, AssortmentNodeQueryVariables>, 'query'> & ({ variables: AssortmentNodeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AssortmentNodeComponent = (props: AssortmentNodeComponentProps) => (
      <ApolloReactComponents.Query<AssortmentNodeQuery, AssortmentNodeQueryVariables> query={AssortmentNodeDocument} {...props} />
    );
    

/**
 * __useAssortmentNodeQuery__
 *
 * To run a query within a React component, call `useAssortmentNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssortmentNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssortmentNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *      productPage: // value for 'productPage'
 *      productPageSize: // value for 'productPageSize'
 *   },
 * });
 */
export function useAssortmentNodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssortmentNodeQuery, AssortmentNodeQueryVariables>) {
        return ApolloReactHooks.useQuery<AssortmentNodeQuery, AssortmentNodeQueryVariables>(AssortmentNodeDocument, baseOptions);
      }
export function useAssortmentNodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssortmentNodeQuery, AssortmentNodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssortmentNodeQuery, AssortmentNodeQueryVariables>(AssortmentNodeDocument, baseOptions);
        }
export type AssortmentNodeQueryHookResult = ReturnType<typeof useAssortmentNodeQuery>;
export type AssortmentNodeLazyQueryHookResult = ReturnType<typeof useAssortmentNodeLazyQuery>;
export type AssortmentNodeQueryResult = ApolloReactCommon.QueryResult<AssortmentNodeQuery, AssortmentNodeQueryVariables>;
export const CommerceAppUsersByContextDocument = gql`
    query CommerceAppUsersByContext($contextId: String!, $userIds: [String], $search: String, $page: Int!, $pageSize: PageSize!) {
  commerceAppUsersByContext(contextId: $contextId, userIds: $userIds, search: $search, page: $page, pageSize: $pageSize) {
    pageInfo {
      page
      pageSize
      pageCount
      hasNextPage
      hasPreviousPage
    }
    count
    result {
      id
      contextId
      email
      customerCode
    }
  }
}
    `;
export type CommerceAppUsersByContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>, 'query'> & ({ variables: CommerceAppUsersByContextQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CommerceAppUsersByContextComponent = (props: CommerceAppUsersByContextComponentProps) => (
      <ApolloReactComponents.Query<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables> query={CommerceAppUsersByContextDocument} {...props} />
    );
    

/**
 * __useCommerceAppUsersByContextQuery__
 *
 * To run a query within a React component, call `useCommerceAppUsersByContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceAppUsersByContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceAppUsersByContextQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      userIds: // value for 'userIds'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCommerceAppUsersByContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>(CommerceAppUsersByContextDocument, baseOptions);
      }
export function useCommerceAppUsersByContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>(CommerceAppUsersByContextDocument, baseOptions);
        }
export type CommerceAppUsersByContextQueryHookResult = ReturnType<typeof useCommerceAppUsersByContextQuery>;
export type CommerceAppUsersByContextLazyQueryHookResult = ReturnType<typeof useCommerceAppUsersByContextLazyQuery>;
export type CommerceAppUsersByContextQueryResult = ApolloReactCommon.QueryResult<CommerceAppUsersByContextQuery, CommerceAppUsersByContextQueryVariables>;
export const CommerceAppsDocument = gql`
    query CommerceApps {
  commerceApps {
    id
    name
    settings {
      erpCompanyCode
    }
  }
}
    `;
export type CommerceAppsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommerceAppsQuery, CommerceAppsQueryVariables>, 'query'>;

    export const CommerceAppsComponent = (props: CommerceAppsComponentProps) => (
      <ApolloReactComponents.Query<CommerceAppsQuery, CommerceAppsQueryVariables> query={CommerceAppsDocument} {...props} />
    );
    

/**
 * __useCommerceAppsQuery__
 *
 * To run a query within a React component, call `useCommerceAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommerceAppsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceAppsQuery, CommerceAppsQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceAppsQuery, CommerceAppsQueryVariables>(CommerceAppsDocument, baseOptions);
      }
export function useCommerceAppsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceAppsQuery, CommerceAppsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceAppsQuery, CommerceAppsQueryVariables>(CommerceAppsDocument, baseOptions);
        }
export type CommerceAppsQueryHookResult = ReturnType<typeof useCommerceAppsQuery>;
export type CommerceAppsLazyQueryHookResult = ReturnType<typeof useCommerceAppsLazyQuery>;
export type CommerceAppsQueryResult = ApolloReactCommon.QueryResult<CommerceAppsQuery, CommerceAppsQueryVariables>;
export const CommerceAppsByCompanyDocument = gql`
    query CommerceAppsByCompany($companyCode: String!) {
  commerceAppsByCompanyCode(companyCode: $companyCode) {
    id
    name
  }
}
    `;
export type CommerceAppsByCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>, 'query'> & ({ variables: CommerceAppsByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CommerceAppsByCompanyComponent = (props: CommerceAppsByCompanyComponentProps) => (
      <ApolloReactComponents.Query<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables> query={CommerceAppsByCompanyDocument} {...props} />
    );
    

/**
 * __useCommerceAppsByCompanyQuery__
 *
 * To run a query within a React component, call `useCommerceAppsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceAppsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceAppsByCompanyQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useCommerceAppsByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>(CommerceAppsByCompanyDocument, baseOptions);
      }
export function useCommerceAppsByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>(CommerceAppsByCompanyDocument, baseOptions);
        }
export type CommerceAppsByCompanyQueryHookResult = ReturnType<typeof useCommerceAppsByCompanyQuery>;
export type CommerceAppsByCompanyLazyQueryHookResult = ReturnType<typeof useCommerceAppsByCompanyLazyQuery>;
export type CommerceAppsByCompanyQueryResult = ApolloReactCommon.QueryResult<CommerceAppsByCompanyQuery, CommerceAppsByCompanyQueryVariables>;
export const CommerceUserLinksDocument = gql`
    query CommerceUserLinks {
  commerceUserLinks {
    _id
    name
    users {
      id
      email
    }
    contexts {
      contextId
      companyCode
      name
    }
  }
}
    `;
export type CommerceUserLinksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>, 'query'>;

    export const CommerceUserLinksComponent = (props: CommerceUserLinksComponentProps) => (
      <ApolloReactComponents.Query<CommerceUserLinksQuery, CommerceUserLinksQueryVariables> query={CommerceUserLinksDocument} {...props} />
    );
    

/**
 * __useCommerceUserLinksQuery__
 *
 * To run a query within a React component, call `useCommerceUserLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceUserLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceUserLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommerceUserLinksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>(CommerceUserLinksDocument, baseOptions);
      }
export function useCommerceUserLinksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>(CommerceUserLinksDocument, baseOptions);
        }
export type CommerceUserLinksQueryHookResult = ReturnType<typeof useCommerceUserLinksQuery>;
export type CommerceUserLinksLazyQueryHookResult = ReturnType<typeof useCommerceUserLinksLazyQuery>;
export type CommerceUserLinksQueryResult = ApolloReactCommon.QueryResult<CommerceUserLinksQuery, CommerceUserLinksQueryVariables>;
export const CommerceUsersDocument = gql`
    query CommerceUsers($page: Int!, $pageSize: PageSize!, $email: String, $userId: String, $search: String) {
  commerceUsers(page: $page, pageSize: $pageSize, email: $email, userId: $userId, search: $search) {
    pageInfo {
      page
      pageSize
      pageCount
      hasNextPage
      hasPreviousPage
    }
    count
    result {
      id
      email
      company
    }
  }
}
    `;
export type CommerceUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CommerceUsersQuery, CommerceUsersQueryVariables>, 'query'> & ({ variables: CommerceUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CommerceUsersComponent = (props: CommerceUsersComponentProps) => (
      <ApolloReactComponents.Query<CommerceUsersQuery, CommerceUsersQueryVariables> query={CommerceUsersDocument} {...props} />
    );
    

/**
 * __useCommerceUsersQuery__
 *
 * To run a query within a React component, call `useCommerceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCommerceUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceUsersQuery, CommerceUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<CommerceUsersQuery, CommerceUsersQueryVariables>(CommerceUsersDocument, baseOptions);
      }
export function useCommerceUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceUsersQuery, CommerceUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommerceUsersQuery, CommerceUsersQueryVariables>(CommerceUsersDocument, baseOptions);
        }
export type CommerceUsersQueryHookResult = ReturnType<typeof useCommerceUsersQuery>;
export type CommerceUsersLazyQueryHookResult = ReturnType<typeof useCommerceUsersLazyQuery>;
export type CommerceUsersQueryResult = ApolloReactCommon.QueryResult<CommerceUsersQuery, CommerceUsersQueryVariables>;
export const CompaniesDocument = gql`
    query Companies {
  companies {
    _id
    name
    companyCode
  }
}
    `;
export type CompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompaniesQuery, CompaniesQueryVariables>, 'query'>;

    export const CompaniesComponent = (props: CompaniesComponentProps) => (
      <ApolloReactComponents.Query<CompaniesQuery, CompaniesQueryVariables> query={CompaniesDocument} {...props} />
    );
    

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CurrentExportConfigurationDocument = gql`
    query CurrentExportConfiguration {
  exportConfigurationCurrent {
    _id
    queryVariables {
      assortmentId
      companyCode
      language
      filters {
        filter
        values
      }
      sheetSettings {
        imageLevel
        rowLevel
        languages
        selectedColumns
        pricelists {
          pricelist
          currency
          companyCode
        }
        units
      }
    }
  }
}
    `;
export type CurrentExportConfigurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>, 'query'>;

    export const CurrentExportConfigurationComponent = (props: CurrentExportConfigurationComponentProps) => (
      <ApolloReactComponents.Query<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables> query={CurrentExportConfigurationDocument} {...props} />
    );
    

/**
 * __useCurrentExportConfigurationQuery__
 *
 * To run a query within a React component, call `useCurrentExportConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentExportConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentExportConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentExportConfigurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>(CurrentExportConfigurationDocument, baseOptions);
      }
export function useCurrentExportConfigurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>(CurrentExportConfigurationDocument, baseOptions);
        }
export type CurrentExportConfigurationQueryHookResult = ReturnType<typeof useCurrentExportConfigurationQuery>;
export type CurrentExportConfigurationLazyQueryHookResult = ReturnType<typeof useCurrentExportConfigurationLazyQuery>;
export type CurrentExportConfigurationQueryResult = ApolloReactCommon.QueryResult<CurrentExportConfigurationQuery, CurrentExportConfigurationQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  me {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    confirmed
    active
    isAdmin
    registeredAt
    lastLogin
    updatedAt
    commerceUserLink {
      _id
      name
      contexts {
        contextId
        companyCode
        name
      }
      users {
        id
        email
      }
    }
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetExportConfigurationDocument = gql`
    query GetExportConfiguration($id: MongoID!) {
  exportConfigurationById(_id: $id) {
    queryVariables {
      assortmentId
      companyCode
      language
      filters {
        filter
        values
      }
      sheetSettings {
        rowLevel
        imageLevel
        languages
        selectedColumns
        pricelists {
          pricelist
          currency
          companyCode
        }
        units
      }
    }
  }
}
    `;
export type GetExportConfigurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>, 'query'> & ({ variables: GetExportConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetExportConfigurationComponent = (props: GetExportConfigurationComponentProps) => (
      <ApolloReactComponents.Query<GetExportConfigurationQuery, GetExportConfigurationQueryVariables> query={GetExportConfigurationDocument} {...props} />
    );
    

/**
 * __useGetExportConfigurationQuery__
 *
 * To run a query within a React component, call `useGetExportConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExportConfigurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>(GetExportConfigurationDocument, baseOptions);
      }
export function useGetExportConfigurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>(GetExportConfigurationDocument, baseOptions);
        }
export type GetExportConfigurationQueryHookResult = ReturnType<typeof useGetExportConfigurationQuery>;
export type GetExportConfigurationLazyQueryHookResult = ReturnType<typeof useGetExportConfigurationLazyQuery>;
export type GetExportConfigurationQueryResult = ApolloReactCommon.QueryResult<GetExportConfigurationQuery, GetExportConfigurationQueryVariables>;
export const GetExportConfigurationsDocument = gql`
    query GetExportConfigurations {
  exportConfigurationsByUserId {
    name
    _id
  }
}
    `;
export type GetExportConfigurationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>, 'query'>;

    export const GetExportConfigurationsComponent = (props: GetExportConfigurationsComponentProps) => (
      <ApolloReactComponents.Query<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables> query={GetExportConfigurationsDocument} {...props} />
    );
    

/**
 * __useGetExportConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetExportConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExportConfigurationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>(GetExportConfigurationsDocument, baseOptions);
      }
export function useGetExportConfigurationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>(GetExportConfigurationsDocument, baseOptions);
        }
export type GetExportConfigurationsQueryHookResult = ReturnType<typeof useGetExportConfigurationsQuery>;
export type GetExportConfigurationsLazyQueryHookResult = ReturnType<typeof useGetExportConfigurationsLazyQuery>;
export type GetExportConfigurationsQueryResult = ApolloReactCommon.QueryResult<GetExportConfigurationsQuery, GetExportConfigurationsQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($limit: Int) {
  notifications(limit: $limit, sort: _ID_DESC) {
    _id
    author {
      name
    }
    headline
    description
    body
  }
}
    `;
export type NotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationsQuery, NotificationsQueryVariables>, 'query'>;

    export const NotificationsComponent = (props: NotificationsComponentProps) => (
      <ApolloReactComponents.Query<NotificationsQuery, NotificationsQueryVariables> query={NotificationsDocument} {...props} />
    );
    

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const NotificationsPaginationDocument = gql`
    query NotificationsPagination($page: Int!, $perPage: Int!) {
  notificationsPagination(page: $page, perPage: $perPage, sort: _ID_DESC) {
    count
    items {
      headline
    }
  }
}
    `;
export type NotificationsPaginationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>, 'query'> & ({ variables: NotificationsPaginationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NotificationsPaginationComponent = (props: NotificationsPaginationComponentProps) => (
      <ApolloReactComponents.Query<NotificationsPaginationQuery, NotificationsPaginationQueryVariables> query={NotificationsPaginationDocument} {...props} />
    );
    

/**
 * __useNotificationsPaginationQuery__
 *
 * To run a query within a React component, call `useNotificationsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useNotificationsPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>(NotificationsPaginationDocument, baseOptions);
      }
export function useNotificationsPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>(NotificationsPaginationDocument, baseOptions);
        }
export type NotificationsPaginationQueryHookResult = ReturnType<typeof useNotificationsPaginationQuery>;
export type NotificationsPaginationLazyQueryHookResult = ReturnType<typeof useNotificationsPaginationLazyQuery>;
export type NotificationsPaginationQueryResult = ApolloReactCommon.QueryResult<NotificationsPaginationQuery, NotificationsPaginationQueryVariables>;
export const GetProductByIdDocument = gql`
    query GetProductById($productNumber: String!, $language: String!) {
  productById(productNumber: $productNumber, language: $language) {
    productNumber
    productName
    productText
    pictures {
      resourceFileId
    }
    retailPrice {
      price
      currency
    }
    variations {
      itemNumber
      itemColorName
      pictures {
        resourceFileId
      }
    }
  }
}
    `;
export type GetProductByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProductByIdQuery, GetProductByIdQueryVariables>, 'query'> & ({ variables: GetProductByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProductByIdComponent = (props: GetProductByIdComponentProps) => (
      <ApolloReactComponents.Query<GetProductByIdQuery, GetProductByIdQueryVariables> query={GetProductByIdDocument} {...props} />
    );
    

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetProductByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
      }
export function useGetProductByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
        }
export type GetProductByIdQueryHookResult = ReturnType<typeof useGetProductByIdQuery>;
export type GetProductByIdLazyQueryHookResult = ReturnType<typeof useGetProductByIdLazyQuery>;
export type GetProductByIdQueryResult = ApolloReactCommon.QueryResult<GetProductByIdQuery, GetProductByIdQueryVariables>;
export const GetProductFiltersDocument = gql`
    query GetProductFilters($assortmentId: String!, $language: String!, $companyCode: String) {
  productFilters(assortmentId: $assortmentId, language: $language, companyCode: $companyCode) {
    filter
    values {
      key
      value
    }
  }
}
    `;
export type GetProductFiltersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProductFiltersQuery, GetProductFiltersQueryVariables>, 'query'> & ({ variables: GetProductFiltersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProductFiltersComponent = (props: GetProductFiltersComponentProps) => (
      <ApolloReactComponents.Query<GetProductFiltersQuery, GetProductFiltersQueryVariables> query={GetProductFiltersDocument} {...props} />
    );
    

/**
 * __useGetProductFiltersQuery__
 *
 * To run a query within a React component, call `useGetProductFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductFiltersQuery({
 *   variables: {
 *      assortmentId: // value for 'assortmentId'
 *      language: // value for 'language'
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetProductFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProductFiltersQuery, GetProductFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProductFiltersQuery, GetProductFiltersQueryVariables>(GetProductFiltersDocument, baseOptions);
      }
export function useGetProductFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProductFiltersQuery, GetProductFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProductFiltersQuery, GetProductFiltersQueryVariables>(GetProductFiltersDocument, baseOptions);
        }
export type GetProductFiltersQueryHookResult = ReturnType<typeof useGetProductFiltersQuery>;
export type GetProductFiltersLazyQueryHookResult = ReturnType<typeof useGetProductFiltersLazyQuery>;
export type GetProductFiltersQueryResult = ApolloReactCommon.QueryResult<GetProductFiltersQuery, GetProductFiltersQueryVariables>;
export const ProductsDocument = gql`
    query Products($language: String!, $assortmentId: String!, $page: Int!, $pageSize: PageSize!) {
  productsByAssortmentId(language: $language, assortmentId: $assortmentId, page: $page, pageSize: $pageSize) {
    pageInfo {
      hasNextPage
    }
    count
    result {
      productNumber
      productName
      productGender {
        value
      }
      productBrand
      productCareInstructions {
        key
        value
      }
      variations {
        itemNumber
        itemColorCode
        itemColorName
        pictures {
          imageUrl
          resourceFileId
          resourcePictureType
        }
      }
      pictures {
        imageUrl
        resourceFileId
        resourcePictureType
      }
      retailPrice {
        price
        currency
      }
    }
  }
}
    `;
export type ProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>, 'query'> & ({ variables: ProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProductsComponent = (props: ProductsComponentProps) => (
      <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables> query={ProductsDocument} {...props} />
    );
    

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      assortmentId: // value for 'assortmentId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const TranslatedProductsDocument = gql`
    query TranslatedProducts($assortmentId: String!, $page: Int!, $pageSize: PageSize!) {
  productsTranslatedByAssortmentId(assortmentId: $assortmentId, page: $page, pageSize: $pageSize) {
    result {
      ...fullProductTranslated
    }
    pageInfo {
      hasNextPage
    }
    count
  }
}
    ${FullProductTranslatedFragmentDoc}`;
export type TranslatedProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TranslatedProductsQuery, TranslatedProductsQueryVariables>, 'query'> & ({ variables: TranslatedProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TranslatedProductsComponent = (props: TranslatedProductsComponentProps) => (
      <ApolloReactComponents.Query<TranslatedProductsQuery, TranslatedProductsQueryVariables> query={TranslatedProductsDocument} {...props} />
    );
    

/**
 * __useTranslatedProductsQuery__
 *
 * To run a query within a React component, call `useTranslatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslatedProductsQuery({
 *   variables: {
 *      assortmentId: // value for 'assortmentId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTranslatedProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TranslatedProductsQuery, TranslatedProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<TranslatedProductsQuery, TranslatedProductsQueryVariables>(TranslatedProductsDocument, baseOptions);
      }
export function useTranslatedProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TranslatedProductsQuery, TranslatedProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TranslatedProductsQuery, TranslatedProductsQueryVariables>(TranslatedProductsDocument, baseOptions);
        }
export type TranslatedProductsQueryHookResult = ReturnType<typeof useTranslatedProductsQuery>;
export type TranslatedProductsLazyQueryHookResult = ReturnType<typeof useTranslatedProductsLazyQuery>;
export type TranslatedProductsQueryResult = ApolloReactCommon.QueryResult<TranslatedProductsQuery, TranslatedProductsQueryVariables>;
export const ServiceContainersDocument = gql`
    query ServiceContainers {
  serviceContainers {
    _id
    name
    description
  }
}
    `;
export type ServiceContainersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServiceContainersQuery, ServiceContainersQueryVariables>, 'query'>;

    export const ServiceContainersComponent = (props: ServiceContainersComponentProps) => (
      <ApolloReactComponents.Query<ServiceContainersQuery, ServiceContainersQueryVariables> query={ServiceContainersDocument} {...props} />
    );
    

/**
 * __useServiceContainersQuery__
 *
 * To run a query within a React component, call `useServiceContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceContainersQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceContainersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceContainersQuery, ServiceContainersQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceContainersQuery, ServiceContainersQueryVariables>(ServiceContainersDocument, baseOptions);
      }
export function useServiceContainersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceContainersQuery, ServiceContainersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceContainersQuery, ServiceContainersQueryVariables>(ServiceContainersDocument, baseOptions);
        }
export type ServiceContainersQueryHookResult = ReturnType<typeof useServiceContainersQuery>;
export type ServiceContainersLazyQueryHookResult = ReturnType<typeof useServiceContainersLazyQuery>;
export type ServiceContainersQueryResult = ApolloReactCommon.QueryResult<ServiceContainersQuery, ServiceContainersQueryVariables>;
export const UserAssortmentsDocument = gql`
    query UserAssortments {
  userAssortments {
    name
    assortmentId
    users {
      _id
    }
    _id
  }
}
    `;
export type UserAssortmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserAssortmentsQuery, UserAssortmentsQueryVariables>, 'query'>;

    export const UserAssortmentsComponent = (props: UserAssortmentsComponentProps) => (
      <ApolloReactComponents.Query<UserAssortmentsQuery, UserAssortmentsQueryVariables> query={UserAssortmentsDocument} {...props} />
    );
    

/**
 * __useUserAssortmentsQuery__
 *
 * To run a query within a React component, call `useUserAssortmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAssortmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAssortmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAssortmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAssortmentsQuery, UserAssortmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAssortmentsQuery, UserAssortmentsQueryVariables>(UserAssortmentsDocument, baseOptions);
      }
export function useUserAssortmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAssortmentsQuery, UserAssortmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAssortmentsQuery, UserAssortmentsQueryVariables>(UserAssortmentsDocument, baseOptions);
        }
export type UserAssortmentsQueryHookResult = ReturnType<typeof useUserAssortmentsQuery>;
export type UserAssortmentsLazyQueryHookResult = ReturnType<typeof useUserAssortmentsLazyQuery>;
export type UserAssortmentsQueryResult = ApolloReactCommon.QueryResult<UserAssortmentsQuery, UserAssortmentsQueryVariables>;
export const UserByIdDocument = gql`
    query UserById($id: MongoID!) {
  userById(_id: $id) {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    confirmed
    isAdmin
    active
    registeredAt
    lastLogin
    updatedAt
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type UserByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserByIdQuery, UserByIdQueryVariables>, 'query'> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserByIdComponent = (props: UserByIdComponentProps) => (
      <ApolloReactComponents.Query<UserByIdQuery, UserByIdQueryVariables> query={UserByIdDocument} {...props} />
    );
    

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, baseOptions);
      }
export function useUserByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, baseOptions);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = ApolloReactCommon.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  companies {
    _id
    name
    companyCode
  }
}
    `;
export type GetCompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompaniesQuery, GetCompaniesQueryVariables>, 'query'>;

    export const GetCompaniesComponent = (props: GetCompaniesComponentProps) => (
      <ApolloReactComponents.Query<GetCompaniesQuery, GetCompaniesQueryVariables> query={GetCompaniesDocument} {...props} />
    );
    

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
      }
export function useGetCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = ApolloReactCommon.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const UserInviteLinkDocument = gql`
    query UserInviteLink($userId: MongoID!) {
  userInviteLink(userId: $userId)
}
    `;
export type UserInviteLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserInviteLinkQuery, UserInviteLinkQueryVariables>, 'query'> & ({ variables: UserInviteLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserInviteLinkComponent = (props: UserInviteLinkComponentProps) => (
      <ApolloReactComponents.Query<UserInviteLinkQuery, UserInviteLinkQueryVariables> query={UserInviteLinkDocument} {...props} />
    );
    

/**
 * __useUserInviteLinkQuery__
 *
 * To run a query within a React component, call `useUserInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInviteLinkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserInviteLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserInviteLinkQuery, UserInviteLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<UserInviteLinkQuery, UserInviteLinkQueryVariables>(UserInviteLinkDocument, baseOptions);
      }
export function useUserInviteLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserInviteLinkQuery, UserInviteLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserInviteLinkQuery, UserInviteLinkQueryVariables>(UserInviteLinkDocument, baseOptions);
        }
export type UserInviteLinkQueryHookResult = ReturnType<typeof useUserInviteLinkQuery>;
export type UserInviteLinkLazyQueryHookResult = ReturnType<typeof useUserInviteLinkLazyQuery>;
export type UserInviteLinkQueryResult = ApolloReactCommon.QueryResult<UserInviteLinkQuery, UserInviteLinkQueryVariables>;
export const UserNotificationsDocument = gql`
    query UserNotifications($limit: Int) {
  userNotifications(limit: $limit, sort: "desc") {
    _id
    userId
    notificationId
    isRead
    receivedAt
    notification {
      _id
      headline
      description
      body
      link
      createdAt
      author {
        name
      }
    }
  }
}
    `;
export type UserNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserNotificationsQuery, UserNotificationsQueryVariables>, 'query'>;

    export const UserNotificationsComponent = (props: UserNotificationsComponentProps) => (
      <ApolloReactComponents.Query<UserNotificationsQuery, UserNotificationsQueryVariables> query={UserNotificationsDocument} {...props} />
    );
    

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, baseOptions);
      }
export function useUserNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, baseOptions);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = ApolloReactCommon.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    _id
    username
    email
    companyIds
    serviceContainerIds
    commerceUserLinkId
    serviceContainers {
      _id
      name
    }
    companies {
      _id
      name
      companyCode
    }
    confirmed
    active
    registeredAt
    lastLogin
    updatedAt
    pricelists {
      pricelist
      companyCode
      currency
    }
  }
}
    `;
export type UsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>, 'query'>;

    export const UsersComponent = (props: UsersComponentProps) => (
      <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
    );
    

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const SearchProductsDocument = gql`
    query SearchProducts($keyword: String!, $language: String!, $assortmentId: String!, $page: Int!, $pageSize: PageSize!, $filters: [ProductFilterInput], $companyCode: String) {
  productSearch(q: $keyword, language: $language, assortmentId: $assortmentId, page: $page, pageSize: $pageSize, filters: $filters, companyCode: $companyCode) {
    result {
      productName
      productNumber
      productBrand
      pictures {
        imageUrl
        resourceFileId
      }
    }
    count
    pageInfo {
      page
      pageSize
      pageCount
      hasNextPage
      hasPreviousPage
    }
    took
    error {
      code
      message
    }
  }
}
    `;
export type SearchProductsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchProductsQuery, SearchProductsQueryVariables>, 'query'> & ({ variables: SearchProductsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchProductsComponent = (props: SearchProductsComponentProps) => (
      <ApolloReactComponents.Query<SearchProductsQuery, SearchProductsQueryVariables> query={SearchProductsDocument} {...props} />
    );
    

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      language: // value for 'language'
 *      assortmentId: // value for 'assortmentId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filters: // value for 'filters'
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useSearchProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, baseOptions);
      }
export function useSearchProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, baseOptions);
        }
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<typeof useSearchProductsLazyQuery>;
export type SearchProductsQueryResult = ApolloReactCommon.QueryResult<SearchProductsQuery, SearchProductsQueryVariables>;
export const SearchProductsTranslatedDocument = gql`
    query SearchProductsTranslated($keyword: String!, $assortmentId: String!, $language: String!, $page: Int!, $pageSize: PageSize!, $filters: [ProductFilterInput], $companyCode: String) {
  productTranslatedSearch(q: $keyword, assortmentId: $assortmentId, language: $language, page: $page, pageSize: $pageSize, filters: $filters, companyCode: $companyCode) {
    result {
      ...fullProductTranslated
    }
    count
    pageInfo {
      page
      pageSize
      pageCount
      hasNextPage
      hasPreviousPage
    }
    took
    error {
      code
      message
    }
  }
}
    ${FullProductTranslatedFragmentDoc}`;
export type SearchProductsTranslatedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>, 'query'> & ({ variables: SearchProductsTranslatedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchProductsTranslatedComponent = (props: SearchProductsTranslatedComponentProps) => (
      <ApolloReactComponents.Query<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables> query={SearchProductsTranslatedDocument} {...props} />
    );
    

/**
 * __useSearchProductsTranslatedQuery__
 *
 * To run a query within a React component, call `useSearchProductsTranslatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsTranslatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsTranslatedQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      assortmentId: // value for 'assortmentId'
 *      language: // value for 'language'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filters: // value for 'filters'
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useSearchProductsTranslatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>(SearchProductsTranslatedDocument, baseOptions);
      }
export function useSearchProductsTranslatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>(SearchProductsTranslatedDocument, baseOptions);
        }
export type SearchProductsTranslatedQueryHookResult = ReturnType<typeof useSearchProductsTranslatedQuery>;
export type SearchProductsTranslatedLazyQueryHookResult = ReturnType<typeof useSearchProductsTranslatedLazyQuery>;
export type SearchProductsTranslatedQueryResult = ApolloReactCommon.QueryResult<SearchProductsTranslatedQuery, SearchProductsTranslatedQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($keyword: String, $page: Int!, $perPage: Int!) {
  userSearch(page: $page, perPage: $perPage, sort: _ID_ASC, filter: {usernameRegexp: $keyword}) {
    items {
      _id
      username
      email
      companyIds
      serviceContainerIds
      commerceUserLinkId
      serviceContainers {
        _id
        name
      }
      companies {
        _id
        name
        companyCode
      }
      confirmed
      active
      isAdmin
      registeredAt
      lastLogin
      updatedAt
    }
    count
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type SearchUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchUsersQuery, SearchUsersQueryVariables>, 'query'> & ({ variables: SearchUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchUsersComponent = (props: SearchUsersComponentProps) => (
      <ApolloReactComponents.Query<SearchUsersQuery, SearchUsersQueryVariables> query={SearchUsersDocument} {...props} />
    );
    

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const CreatedNotificationsDocument = gql`
    subscription CreatedNotifications {
  notificationCreated {
    _id
    author {
      name
    }
    headline
    description
    link
    body
  }
}
    `;
export type CreatedNotificationsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CreatedNotificationsSubscription, CreatedNotificationsSubscriptionVariables>, 'subscription'>;

    export const CreatedNotificationsComponent = (props: CreatedNotificationsComponentProps) => (
      <ApolloReactComponents.Subscription<CreatedNotificationsSubscription, CreatedNotificationsSubscriptionVariables> subscription={CreatedNotificationsDocument} {...props} />
    );
    

/**
 * __useCreatedNotificationsSubscription__
 *
 * To run a query within a React component, call `useCreatedNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedNotificationsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CreatedNotificationsSubscription, CreatedNotificationsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CreatedNotificationsSubscription, CreatedNotificationsSubscriptionVariables>(CreatedNotificationsDocument, baseOptions);
      }
export type CreatedNotificationsSubscriptionHookResult = ReturnType<typeof useCreatedNotificationsSubscription>;
export type CreatedNotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<CreatedNotificationsSubscription>;
export const CreatedUserNotificationsDocument = gql`
    subscription CreatedUserNotifications {
  userNotificationCreated {
    _id
    userId
    notificationId
    isRead
    receivedAt
    notification {
      _id
      headline
      description
      body
      link
      createdAt
      author {
        name
      }
    }
  }
}
    `;
export type CreatedUserNotificationsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<CreatedUserNotificationsSubscription, CreatedUserNotificationsSubscriptionVariables>, 'subscription'>;

    export const CreatedUserNotificationsComponent = (props: CreatedUserNotificationsComponentProps) => (
      <ApolloReactComponents.Subscription<CreatedUserNotificationsSubscription, CreatedUserNotificationsSubscriptionVariables> subscription={CreatedUserNotificationsDocument} {...props} />
    );
    

/**
 * __useCreatedUserNotificationsSubscription__
 *
 * To run a query within a React component, call `useCreatedUserNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedUserNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedUserNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedUserNotificationsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CreatedUserNotificationsSubscription, CreatedUserNotificationsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<CreatedUserNotificationsSubscription, CreatedUserNotificationsSubscriptionVariables>(CreatedUserNotificationsDocument, baseOptions);
      }
export type CreatedUserNotificationsSubscriptionHookResult = ReturnType<typeof useCreatedUserNotificationsSubscription>;
export type CreatedUserNotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<CreatedUserNotificationsSubscription>;