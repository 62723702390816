import gql from 'graphql-tag';

export const GET_SERVICECONTAINERS = gql`
  query ServiceContainers {
    serviceContainers {
      _id
      name
      description
    }
  }
`;
