import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useUserDataQuery, useCurrentUserQuery } from 'generated/graphql';

// Global styles
import 'styles/app.scss';

// Components
import { PrivateRoute, AppHeader, AppNavigation } from 'app/components';
import { Login, UserConfirm } from 'auth/components';

// Pages
import {
  Dashboard,
  Users,
  ServiceContainers,
  Companies,
  Applications,
  UserApplications,
  Playground,
  Assortments,
  Products,
  UserSettings,
  AssortmentBuilder,
  Commerce,
  Articles,
  Article,
  ArticleEditor,
} from 'app/pages';
import { toast } from 'react-toastify';

toast.configure({
  position: toast.POSITION.BOTTOM_CENTER,
});

function App() {
  const { error: userError, client } = useCurrentUserQuery({
    fetchPolicy: 'no-cache',
    skip: !localStorage.getItem('token'),
  });
  const { data: userData, loading: userDataLoading, error: userDataError } = useUserDataQuery();

  if (userError) {
    localStorage.clear();
    client.resetStore();
  }

  if (userDataLoading) {
    return <div>Loading...</div>;
  }

  if (userDataError) {
    return <div>User data error</div>;
  }

  const createRedirect = (to: string, isLoggedIn?: boolean) => () => (
    <Redirect to={isLoggedIn ? '/my-applications' : to} />
  );

  return (
    <>
      <Router>
        <React.Fragment>
          {userData && userData.isLoggedIn && (
            <Route
              render={({ history }) => (
                <React.Fragment>
                  <AppHeader user={userData} history={history} />
                  <AppNavigation isAdmin={userData.isAdmin || false} />
                </React.Fragment>
              )}
            />
          )}

          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/confirm/:id/:token" component={UserConfirm} />

            <PrivateRoute
              authed={userData && userData.isLoggedIn}
              path="/my-applications"
              component={UserApplications}
            />
            <PrivateRoute authed={userData && userData.isLoggedIn} path="/playground" component={Playground} />
            <PrivateRoute authed={userData && userData.isLoggedIn} path="/products" component={Products} />
            <PrivateRoute authed={userData && userData.isLoggedIn} path="/usersettings" component={UserSettings} />
            <PrivateRoute authed={userData && userData.isLoggedIn} path="/docs" component={Articles} />
            <PrivateRoute authed={userData && userData.isLoggedIn} path="/article/:id" component={Article} />
            <PrivateRoute authed={userData && userData.isAdmin} path="/article-editor" component={ArticleEditor} />
            <PrivateRoute authed={userData && userData.isAdmin} path="/Dashboard" component={Dashboard} />
            <PrivateRoute authed={userData && userData.isAdmin} path="/users" component={Users} />
            <PrivateRoute authed={userData && userData.isAdmin} path="/applications" component={Applications} />
            <PrivateRoute
              authed={userData && userData.isAdmin}
              path="/servicecontainers"
              component={ServiceContainers}
            />
            <PrivateRoute authed={userData && userData.isAdmin} path="/companies" component={Companies} />
            <PrivateRoute authed={userData && userData.isAdmin} path="/commerce" component={Commerce} />
            <PrivateRoute exact authed={userData && userData.isAdmin} path="/assortments" component={Assortments} />
            <PrivateRoute
              authed={userData && userData.isAdmin}
              exact
              path="/assortments/:id"
              component={AssortmentBuilder}
            />

            <Route path="/*" component={createRedirect('/', userData && userData.isLoggedIn)} />
          </Switch>
        </React.Fragment>
      </Router>
    </>
  );
}

export default App;
