import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, IconWrapper, Pagination } from 'ui';
import { useArticlesQuery } from 'generated/graphql';

type Props = {
  page: number;
  perPage: number;
  published: boolean | undefined;
  setPage: any;
};

const ArticlesList: React.FC<Props> = ({ page, perPage, published, setPage }) => {
  const { data, loading, error } = useArticlesQuery({
    variables: {
      page: page,
      perPage: perPage,
      published: published,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <div>Loading...</div>;

  if (error) return <div>Error: {error?.message}</div>;

  return (
    <>
      {data?.articlesPagination?.items?.length ? (
        <>
          <h2>Latest</h2>
          {data?.articlesPagination?.items?.map((article) => (
            <div key={article?._id} className="mb-4">
              <Card title={article?.headline || 'unknown title'}>
                <div className="mb-4">
                  <IconWrapper iconClass="far fa-user" className="mr-1" /> {article?.author}
                  {article?.published && article.publishedAt
                    ? ' - ' + moment(article?.publishedAt).format('LL')
                    : ' - Not published.'}
                </div>
                <p>{article?.description}</p>
                <Link to={`article/${article?._id}`}>
                  Read more <i className="fas fa-arrow-right" />
                </Link>
              </Card>
            </div>
          ))}
          {data?.articlesPagination?.pageInfo &&
            data?.articlesPagination?.pageInfo.pageCount &&
            data?.articlesPagination?.pageInfo.pageCount > 1 && (
              <Pagination
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={data?.articlesPagination?.pageInfo.pageCount}
                forcePage={data?.articlesPagination?.pageInfo.currentPage - 1}
                onPageChange={({ selected }) => {
                  setPage(selected + 1);
                }}
              ></Pagination>
            )}
        </>
      ) : (
        <div className="p-3 bg-white">
          <IconWrapper iconClass="far fa-question-circle" className="mr-1" />
          There're no articles available right now, check back again later.
        </div>
      )}
    </>
  );
};

export default ArticlesList;
