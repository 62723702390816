import gql from 'graphql-tag';

export const SEARCH_PRODUCTS = gql`
  query SearchProducts(
    $keyword: String!
    $language: String!
    $assortmentId: String!
    $page: Int!
    $pageSize: PageSize!
    $filters: [ProductFilterInput]
    $companyCode: String
  ) {
    productSearch(
      q: $keyword
      language: $language
      assortmentId: $assortmentId
      page: $page
      pageSize: $pageSize
      filters: $filters
      companyCode: $companyCode
    ) {
      result {
        productName
        productNumber
        productBrand
        pictures {
          imageUrl
          resourceFileId
        }
      }
      count
      pageInfo {
        page
        pageSize
        pageCount
        hasNextPage
        hasPreviousPage
      }
      took
      error {
        code
        message
      }
    }
  }
`;

export const SEARCH_PRODUCTS_TRANSLATED = gql`
  query SearchProductsTranslated(
    $keyword: String!
    $assortmentId: String!
    $language: String!
    $page: Int!
    $pageSize: PageSize!
    $filters: [ProductFilterInput]
    $companyCode: String
  ) {
    productTranslatedSearch(
      q: $keyword
      assortmentId: $assortmentId
      language: $language
      page: $page
      pageSize: $pageSize
      filters: $filters
      companyCode: $companyCode
    ) {
      result {
        ...fullProductTranslated
      }
      count
      pageInfo {
        page
        pageSize
        pageCount
        hasNextPage
        hasPreviousPage
      }
      took
      error {
        code
        message
      }
    }
  }
`;
