import React from 'react';
import { InputForm, InputField, DropdownMenu, IconWrapper, Button } from 'ui';
import ReactTable, { RowInfo, Column } from 'react-table';
import {
  useGetExportConfigurationsQuery,
  useRemoveExportConfigurationMutation,
  GetExportConfigurationsQuery,
  useSaveExportConfigurationMutation,
} from 'generated/graphql';
import { GET_EXPORT_CONFIGURATIONS } from 'graphql/queries';

type ExportssavedProps = {
  onOpenConfiguration: (id: string) => void;
  isActive: boolean;
};
const ExportsSaved: React.FC<ExportssavedProps> = ({ onOpenConfiguration, isActive }) => {
  const { data, loading } = useGetExportConfigurationsQuery();
  const [removeConfig] = useRemoveExportConfigurationMutation();
  const [saveConfig, { loading: saveLoading, error: saveError }] = useSaveExportConfigurationMutation();

  const deleteConfig = (row: RowInfo) => {
    if (row && row.original && row.original._id) {
      removeConfig({
        variables: {
          id: row.original._id,
        },
        update(cache) {
          const configurations = cache.readQuery<GetExportConfigurationsQuery>({
            query: GET_EXPORT_CONFIGURATIONS,
          });

          if (!configurations || !configurations.exportConfigurationsByUserId) return;

          const filteredConfigurations = configurations.exportConfigurationsByUserId.filter(
            (c) => c!._id !== row.original._id,
          );

          cache.writeQuery<GetExportConfigurationsQuery>({
            query: GET_EXPORT_CONFIGURATIONS,
            data: { exportConfigurationsByUserId: filteredConfigurations },
          });
        },
      });
    }
  };

  const handleSaveConfig = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!e.currentTarget.configurationName || !e.currentTarget.configurationName.value) return;

    saveConfig({
      variables: { name: e.currentTarget.configurationName.value },
      update(cache, { data }) {
        if (!data) return;

        const configurations = cache.readQuery<GetExportConfigurationsQuery>({
          query: GET_EXPORT_CONFIGURATIONS,
        });

        if (!configurations || !configurations.exportConfigurationsByUserId) return;

        const newConfiguration = data.exportConfigurationSave;
        if (newConfiguration) {
          cache.writeQuery<GetExportConfigurationsQuery>({
            query: GET_EXPORT_CONFIGURATIONS,
            data: {
              exportConfigurationsByUserId: [newConfiguration, ...configurations.exportConfigurationsByUserId],
            },
          });
        }
      },
    });
  };

  const columns = [
    { Header: 'name', accessor: 'name', style: { lineHeight: '35px' } },
    {
      Header: 'remove',
      Cell: (row: RowInfo) => (
        <DropdownMenu>
          {{
            trigger: <IconWrapper color={'transparent'} iconClass="fas fa-ellipsis-h" />,
            items: [
              {
                title: 'Delete',
                action: () => deleteConfig(row),
              },
            ],
          }}
        </DropdownMenu>
      ),
      width: 47,
      style: { overflow: 'visible' },
    },
  ];

  return (
    <>
      <InputForm onSubmit={handleSaveConfig} loading={saveLoading} error={saveError}>
        <div className="d-flex justify-content-between">
          <InputField name="configurationName" placeholder="Name" className="form-control" required></InputField>
          <Button type="submit" size={35} color="transparent" className="" disabled={!isActive}>
            ✔
          </Button>
        </div>
      </InputForm>
      {data && data.exportConfigurationsByUserId && data.exportConfigurationsByUserId.length > 0 && (
        <>
          <ReactTable
            className="mb-4"
            style={{ overflow: 'visible' }}
            TheadComponent={() => null}
            data={data.exportConfigurationsByUserId}
            loading={loading}
            columns={columns}
            showPaginationBottom={false}
            pageSize={data.exportConfigurationsByUserId.length > 10 ? 10 : data.exportConfigurationsByUserId.length}
            getTdProps={(_: any, rowInfo?: RowInfo, column?: Column) => {
              return {
                onClick: (_e: MouseEvent, handleOriginal: any) => {
                  if (column && column.Header === 'remove') return;

                  if (rowInfo && rowInfo.original && rowInfo.original._id) onOpenConfiguration(rowInfo.original._id);

                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
                style: {
                  cursor: 'pointer',
                  border: 'none',
                },
              };
            }}
            getTableProps={() => ({
              style: {
                overflow: 'visible',
              },
            })}
            getTbodyProps={() => ({
              style: {
                overflow: 'visible',
                border: 'none',
              },
            })}
            getProps={() => ({
              style: {
                border: 'none',
              },
            })}
          />
        </>
      )}
    </>
  );
};

export default ExportsSaved;
