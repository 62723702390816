import React, { useEffect, useRef, useState, ReactText } from 'react';
import CSSModule from 'react-css-modules';
import styles from './AssortmentNode.module.scss';

import AssortmentNode from './AssortmentNode';
import {
  TreeData,
  TreeSourcePosition,
  TreeDestinationPosition,
  ItemId,
  RenderItemParams,
  TreeItem,
} from '@atlaskit/tree';
import { IconWrapper, DropdownMenu } from 'ui';
import {
  addNodeToTree,
  removeNodeFromTree,
  changeNameOfItemInTree,
  toggleCollapseAllInTree,
  toggleCollapseIdInTree,
  addProductToTree,
  moveNodeOnTree,
  moveNodesOnTree,
  addProductsToTree,
  removeProductsFromTree,
} from './treeActions';
import { MultiTree } from 'app/components';
import AssortmentProductListNode from './AssortmentProductListNode';
import TreeBuilder, { TreeType } from './TreeBuilder';
import { LoadedProductTrees } from './AssortmentBuilder';

function getParentTreeFromEntity(trees: TreeData[], id: ItemId) {
  return trees.find((tree) => !!tree.items[id]);
}

type AssortmentTreeProps = {
  title: string;
  trees: TreeData[];
  onAssortmentChange: (newTree: TreeData, skipSave?: boolean) => void;
  onProductTreePending: (nodeId: string, products: TreeItem[]) => void;
  onProductTreesChange: (newTrees: LoadedProductTrees) => void;
  onSelect: (id: string) => void;
  onNodeDeleted: (nodeId: string) => void;
  productCount: number | null | undefined;
  loadedProductTrees: LoadedProductTrees | null | undefined;
  selectedNode: string | number | null | undefined;
  isProductsloading: boolean;
};
const AssortmentTree: React.FC<AssortmentTreeProps> = ({
  trees,
  title,
  onAssortmentChange,
  productCount,
  onSelect,
  onNodeDeleted,
  loadedProductTrees,
  onProductTreePending,
  onProductTreesChange,
  isProductsloading,
  selectedNode,
}) => {
  const productList = trees[0];
  const assortmentTree = trees[1];
  const selectedTree =
    (loadedProductTrees && selectedNode && loadedProductTrees[selectedNode]) ||
    new TreeBuilder('emptySelectedTree', TreeType.Selected).build();
  const [selectedProducts, setSelectedProducts] = useState<ItemId[]>();
  const [lastSelected, setLastSelected] = useState<ItemId | null>();
  const [draggingItem, setDraggingItem] = useState<ItemId | null>();

  const handleDragStarted = (id: ItemId) => {
    if (!selectedProducts?.includes(id)) setSelectedProducts([id]);
    setDraggingItem(id);
  };

  const toggleOne = (id: ItemId) => {
    if (selectedProducts?.length === 1 && selectedProducts[0] === id) {
      setSelectedProducts([]);
      setLastSelected(null);
    } else setSelectedProducts([id]);
  };

  const reSelectOnDrop = (source: ReactText, dest: ReactText) => {
    setSelectedProducts(selectedProducts?.map((id) => id.toString().replace(`${source}`, `${dest}`)));
  };

  const handleSelectProduct = (e: MouseEvent, item: TreeItem) => {
    const modifier = e.shiftKey ? 'shift' : e.ctrlKey ? 'ctrl' : '';
    const clickedTree = getParentTreeFromEntity(selectedTree ? [...trees, selectedTree] : trees, item.id);
    if (!clickedTree) return;

    const isLastInClickedTree = lastSelected ? clickedTree.items[lastSelected] : false;

    switch (modifier) {
      case 'ctrl':
        if (isLastInClickedTree) {
          if (selectedProducts?.includes(item.id)) setSelectedProducts(selectedProducts.filter((id) => id !== item.id));
          else setSelectedProducts([...(selectedProducts || []), item.id]);
        } else toggleOne(item.id);
        break;
      case 'shift':
        if (isLastInClickedTree) {
          const orderedList = clickedTree.items[clickedTree.rootId].children;
          const lastIndex = orderedList.findIndex((id) => id === lastSelected);
          const clickIndex = orderedList.findIndex((id) => id === item.id);
          const direction = lastIndex > clickIndex ? 'up' : 'down';

          const productIdsToSelect = orderedList.filter((id, i) => {
            const shouldSelect =
              direction === 'down' ? i > lastIndex && i <= clickIndex : i < lastIndex && i >= clickIndex;
            return shouldSelect && !selectedProducts?.includes(id);
          });
          setSelectedProducts([...(selectedProducts || []), ...productIdsToSelect]);
        } else toggleOne(item.id);
        break;
      default:
        toggleOne(item.id);
        break;
    }
    setLastSelected(item.id);
  };

  const handleDragEnded = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    setDraggingItem(null);

    if (!destination) return;
    if (source.parentId === destination.parentId && source.index === destination.index) return;

    const allTrees = selectedTree ? [...trees, selectedTree] : trees;
    const sourceTree = getParentTreeFromEntity(allTrees, source.parentId);
    const destTree = getParentTreeFromEntity(allTrees, destination.parentId);

    if (!sourceTree || !destTree) return;

    const sourceType: TreeType = sourceTree.items[source.parentId].data.treeType;
    const destType: TreeType = destTree.items[destination.parentId].data.treeType;

    // Cannot move nodes to producttree.
    if (sourceType === TreeType.Assortment && destType !== TreeType.Assortment) return;

    const movedItemId = sourceTree.items[source.parentId].children[source.index];
    const selectedTreeItems = selectedProducts?.reduce((list, id) => {
      if (id !== movedItemId) {
        return [...list, sourceTree.items[id]];
      }
      return list;
    }, new Array<TreeItem>());

    // Product is dropped on nodeTree - it might not be loaded yet, so we create a pending change and load the tree.
    if (sourceType !== TreeType.Assortment && destType === TreeType.Assortment) {
      const nodeId = destTree.items[destination.parentId].data.entityId;

      let newLoaded = loadedProductTrees;
      if (sourceType === TreeType.Selected) {
        const newTree = [...(selectedProducts || [])].reduce((tree, id) => removeNodeFromTree(tree, id), sourceTree);
        newLoaded = { ...newLoaded, [sourceTree.rootId]: newTree };
      }

      if (!loadedProductTrees || !loadedProductTrees[nodeId]) {
        if (nodeId === trees[1].rootId) return;

        onProductTreePending(nodeId, [sourceTree.items[movedItemId], ...(selectedTreeItems || [])]);
      } else {
        const newDestTree = loadedProductTrees[nodeId]!;
        const multiDragTree = addProductsToTree(newDestTree, selectedTreeItems);
        const finalTree = addProductToTree(multiDragTree, multiDragTree.rootId, sourceTree.items[movedItemId]);
        newLoaded = { ...newLoaded, [finalTree.rootId]: finalTree };
      }

      if (newLoaded && newLoaded !== loadedProductTrees) {
        onProductTreesChange(newLoaded);
      }
      onSelect(nodeId);
      reSelectOnDrop(source.parentId, nodeId);
      return;
    }

    // Node is dragged within nodeTree
    if (sourceType === TreeType.Assortment && destType === TreeType.Assortment) {
      const newTree = moveNodeOnTree(destTree, source, destination, movedItemId);
      onAssortmentChange(newTree);
      return;
    }

    // Product is moved within selected productTree
    if (sourceType === TreeType.Selected && destType === TreeType.Selected) {
      const multiDragTree = moveNodesOnTree(
        destTree,
        source,
        destination,
        selectedTreeItems?.map((item) => item.id) || [],
      );
      const finalTree = moveNodeOnTree(multiDragTree, source, destination, movedItemId);
      onProductTreesChange({ ...loadedProductTrees, [finalTree.rootId]: finalTree });
      return;
    }

    // Product is moved from product list to selected node.
    if (sourceType === TreeType.Products && destType === TreeType.Selected) {
      const multiDragTree = addProductsToTree(destTree, selectedTreeItems, destination.index);
      const newTree = addProductToTree(
        multiDragTree,
        multiDragTree.rootId,
        sourceTree.items[movedItemId],
        destination.index,
      );

      reSelectOnDrop(source.parentId, destTree.items[destination.parentId].data.entityId);
      onProductTreesChange({ ...loadedProductTrees, [newTree.rootId]: newTree });
      return;
    }
  };

  const handleProductDeleted = (productNumber: ItemId) => {
    const newTree = removeProductsFromTree(selectedTree, selectedProducts || [productNumber]);
    onProductTreesChange({ ...loadedProductTrees, [newTree.rootId]: newTree });
  };

  const renderItem = ({ item, onExpand, onCollapse, provided, snapshot }: RenderItemParams) => {
    const text = item.data?.title;
    const isSelected = selectedProducts?.includes(`${item.id}`) || false;
    const isDragging = draggingItem === item.id;

    return (
      <>
        {(item.data.treeType === TreeType.Assortment && (
          <div styleName="container" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <AssortmentNode
              text={text}
              isExpanded={item.isExpanded || false}
              onExpand={onExpand}
              onCollapse={onCollapse}
              onNodeAdd={(id: ItemId) => {
                const newTree = addNodeToTree(assortmentTree, id);
                const newId = newTree.items[id].children[newTree.items[id].children.length - 1];
                const newEntityId = newTree.items[newId].data.entityId;
                const newLoaded = new TreeBuilder(newEntityId, TreeType.Selected, '').build();
                onProductTreesChange({ ...loadedProductTrees, [newEntityId]: newLoaded });
                onAssortmentChange(newTree);
                onSelect(`${newEntityId}`);
              }}
              onNodeDeleted={(id: ItemId) => {
                onNodeDeleted(item.data.entityId);
                onAssortmentChange(removeNodeFromTree(assortmentTree, id));
              }}
              onSelect={(id: ItemId) => {
                onSelect(assortmentTree.items[id].data.entityId);
              }}
              hasChildren={!!item.children.find((id) => !assortmentTree.items[id].data.isProduct)}
              itemId={item.id}
              selected={item.data.entityId === selectedNode}
            />
          </div>
        )) || (
          <div
            onClick={(e: MouseEvent) => handleSelectProduct(e, item)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            styleName="container"
            className={`mb-1 ${
              !snapshot.isDragging && item.data.treeType === TreeType.Products ? 'lock-transform' : ''
            }`}
          >
            <AssortmentProductListNode
              selected={isSelected}
              multiDragging={isSelected && !!draggingItem && !isDragging} // is dragging multiples but not started on this item.
              product={item.data.product}
              count={isDragging ? selectedProducts?.length : null}
              details={item.data.treeType === TreeType.Selected}
              onProductDeleted={handleProductDeleted}
              itemId={item.id}
            />
          </div>
        )}
        {
          /* Copy in static list */
          item.data.treeType === TreeType.Products && snapshot.isDragging && (
            <div className="mb-1">
              <AssortmentProductListNode product={item.data.product} />
            </div>
          )
        }
      </>
    );
  };

  const selectedTitle =
    selectedTree.rootId !== 'emptySelectedTree' && selectedTree.items[selectedTree.rootId]?.data.title;
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  useEffect(() => {
    if (selectedTitle === '') {
      setIsEditing(true);
    }

    return function clean() {
      setIsEditing(false);
    };
  }, [selectedTitle]);

  const handleEsc = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.keyCode) {
      case 27:
      case 13:
        inputRef.current?.blur();
        break;
    }
  };

  const handleNameChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!selectedNode) return;

    e.preventDefault();

    if (inputRef.current?.value !== selectedTitle && inputRef.current?.value !== '') {
      const newName = inputRef.current?.value || '';
      if (loadedProductTrees && loadedProductTrees[selectedNode]) {
        onProductTreesChange({
          ...loadedProductTrees,
          [selectedNode]: changeNameOfItemInTree(loadedProductTrees[selectedNode]!, selectedNode, newName),
        });
        const assortmentNodeId = Object.keys(assortmentTree.items).find(
          (i: string) => assortmentTree.items[i].data.entityId === selectedNode,
        );
        if (assortmentNodeId) onAssortmentChange(changeNameOfItemInTree(assortmentTree, assortmentNodeId, newName));

        onSelect(`${selectedNode}`);
      }
    }
    setIsEditing(false);
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col col-3 mb-1">
          {productCount && (
            <>
              <IconWrapper color="primary" iconClass={'fas fa-tags'} />
              <label>
                {productCount}
                {productCount === 10000 && '+'} products
              </label>
            </>
          )}
        </div>
        <div className="col col-5 px-2 mb-1">
          <IconWrapper color="primary" iconClass={'fas fa-sitemap'} />
          <label className="c-default">{title}</label>
          <div className="d-inline-block ml-3">
            <DropdownMenu closeOnSelect>
              {{
                trigger: <i className="fas fa-bars"></i>,
                items: [
                  {
                    title: 'Add top node',
                    action: () => onAssortmentChange(addNodeToTree(assortmentTree, assortmentTree.rootId)),
                  },
                  {
                    title: 'Collapse all',
                    action: () => onAssortmentChange(toggleCollapseAllInTree(assortmentTree, true), true),
                  },
                  {
                    title: 'Expand all',
                    action: () => onAssortmentChange(toggleCollapseAllInTree(assortmentTree, false), true),
                  },
                ],
              }}
            </DropdownMenu>
          </div>
        </div>
        <div className="col col-3 px-2 mb-1">
          {selectedTree && selectedTree.rootId !== 'emptySelectedTree' && (
            <>
              <IconWrapper color="primary" iconClass={'fas fa-project-diagram'} />
              {!isEditing && (
                <label className="c-text" onClick={() => setIsEditing(true)}>
                  {selectedTitle.trim().length > 0 ? selectedTitle : <span className="o-50">No name</span>}
                </label>
              )}
              {isEditing && (
                <div className="d-inline-block">
                  <input
                    ref={inputRef}
                    defaultValue={selectedTitle}
                    className="form-control"
                    onBlur={(e) => {
                      setIsEditing(false);
                      handleNameChange(e);
                    }}
                    onKeyDown={handleEsc}
                  ></input>
                </div>
              )}
            </>
          )}
        </div>
        <MultiTree
          trees={selectedTree ? [...trees, selectedTree] : trees}
          renderItem={CSSModule(renderItem, styles)}
          onExpand={(id: ItemId) => onAssortmentChange(toggleCollapseIdInTree(assortmentTree, id, false), true)}
          onCollapse={(id: ItemId) => onAssortmentChange(toggleCollapseIdInTree(assortmentTree, id, true), true)}
          onDragEnd={handleDragEnded}
          onDragStart={handleDragStarted}
          isDragEnabled
          isNestingEnabled
          nwgOptions={{ disableDrop: [productList.rootId], isProductsLoading: isProductsloading }}
        />
      </div>
    </>
  );
};

export default AssortmentTree;
