import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, DropdownMenu, IconWrapper, Table, Modal, Button } from 'ui';
import { CreateCommerceUserLink, EditCommerceUserLink } from 'app/components';
import { useModal } from 'app/hooks';
import {
  useCommerceUserLinksQuery,
  CommerceUserLinksQuery,
  useRemoveCommerceUserLinkMutation,
  CommerceUserLink,
} from 'generated/graphql';
import { GET_COMMERCE_USER_LINKS, GET_USERS } from 'graphql/queries';

const CommerceUserLinkTable = () => {
  const { modalOpen: createModalOpen, setModalOpen: setCreateModalOpen } = useModal(false);
  const { modalOpen: editModalOpen, setModalOpen: setEditModalOpen } = useModal(false);
  const [editingCommerceUserLink, setEditingCommerceUserLink] = useState<CommerceUserLink>();
  const { data, error, loading } = useCommerceUserLinksQuery();
  const [removeCommerceUserLink] = useRemoveCommerceUserLinkMutation();

  const remove = (commerceUserLinkId: string) => {
    // TODO: Add extra step to confirm action, e.g "Really remove?"
    removeCommerceUserLink({
      variables: {
        id: commerceUserLinkId,
      },
      update(cache) {
        const existingItems = cache.readQuery<CommerceUserLinksQuery>({
          query: GET_COMMERCE_USER_LINKS,
        });
        const newItems = existingItems!.commerceUserLinks
          ? existingItems!.commerceUserLinks.filter(t => t!._id !== commerceUserLinkId)
          : [];
        cache.writeQuery<CommerceUserLinksQuery>({
          query: GET_COMMERCE_USER_LINKS,
          data: { commerceUserLinks: newItems },
        });
      },
      refetchQueries: [{ query: GET_USERS }],
    });
  };

  const edit = (commerceUserLink: CommerceUserLink) => {
    setEditingCommerceUserLink(commerceUserLink);
    setEditModalOpen(true);
  };

  if (loading) {
    return (
      <Card title="Commerce user links" className="mb-3">
        <div className="mb-2">
          <Skeleton height={35} />
        </div>
        <Skeleton count={5} height={35} />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <Card title="Commerce user links" className="mb-3">
        <div className="text-error">Error when fetching commerce user links.</div>
      </Card>
    );
  }

  const columns = [
    {
      Header: 'Id',
      accessor: '_id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Action: (cell: CommerceUserLink) => (
        <DropdownMenu>
          {{
            trigger: (
              <>
                <IconWrapper iconClass="fas fa-ellipsis-h" className="mr-1" />
              </>
            ),
            items: [
              {
                title: 'Edit',
                action: () => edit(cell),
              },
              {
                title: 'Remove',
                action: () => remove(cell?._id),
              },
            ],
          }}
        </DropdownMenu>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Card title="Commerce user links" className="mb-3">
        <Table data={data.commerceUserLinks} columns={columns} />
        <Button size={35} color="white" className="w-100 mt-4" onClick={() => setCreateModalOpen(true)}>
          Create new commerce link <i className="fas fa-plus ml-1"></i>
        </Button>
      </Card>

      {createModalOpen && (
        <Modal title="Create new commerce link" onCloseModal={() => setCreateModalOpen(false)} footer={false}>
          <CreateCommerceUserLink callback={() => setCreateModalOpen(false)} />
        </Modal>
      )}

      {editModalOpen && (
        <Modal title="Edit commerce link" onCloseModal={() => setEditModalOpen(false)} footer={false}>
          <EditCommerceUserLink commerceUserLink={editingCommerceUserLink} callback={() => setEditModalOpen(false)} />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CommerceUserLinkTable;
