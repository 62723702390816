import gql from 'graphql-tag';

export const GET_NOTIFICATIONS_PAGINATED = gql`
  query NotificationsPagination($page: Int!, $perPage: Int!) {
    notificationsPagination(page: $page, perPage: $perPage, sort: _ID_DESC) {
      count
      items {
        headline
      }
    }
  }
`;
