import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
  query Notifications($limit: Int) {
    notifications(limit: $limit, sort: _ID_DESC) {
      _id
      author {
        name
      }
      headline
      description
      body
    }
  }
`;
