import gql from 'graphql-tag';

export const GET_ASSORTMENT = gql`
  query Assortment($assortmentId: String!, $language: String!, $page: Int!, $pageSize: PageSize!) {
    assortment(assortmentId: $assortmentId, language: $language, page: $page, pageSize: $pageSize) {
      result {
        assortmentId
        id
        name
        parentId
        childNodeIds
      }
      took
      count
      pageInfo {
        hasNextPage
        page
      }
      error {
        code
        message
      }
    }
  }
`;
