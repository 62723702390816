import React, { useEffect } from 'react';
import { IconWrapper, DropdownBox } from 'ui';
import NotificationMessage from './NotificationMessage';
import { CREATED_USERNOTIFICATIONS } from 'graphql/subscriptions';
import { useCreatedUserNotificationsSubscription, useUserNotificationsQuery } from 'generated/graphql';

const NotificationsMenu = () => {
  const { subscribeToMore, data, loading, error } = useUserNotificationsQuery({
    variables: { limit: 5 },
    fetchPolicy: 'network-only',
  });
  const { loading: loadingSubscription } = useCreatedUserNotificationsSubscription();

  const checkUnreadNotifications = () => {
    return data && data.userNotifications && data.userNotifications.filter((n) => n!.isRead === false).length > 0;
  };

  useEffect(() => {
    subscribeToMore({
      document: CREATED_USERNOTIFICATIONS,
      // ? Typescript is giving the wrong type on subscriptionData. Workaround is to set "any" as the type.
      updateQuery: (prev, { subscriptionData }: any) => {
        if (!subscriptionData.data) return prev;
        const newItem = subscriptionData.data.userNotificationCreated;

        return Object.assign({}, prev, {
          userNotifications: [newItem, ...(prev.userNotifications || [])],
        });
      },
    });
  }, [subscribeToMore]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return (
    <DropdownBox header="Notifications">
      {{
        trigger: (
          <IconWrapper
            iconClass="far fa-bell"
            color={!loadingSubscription || checkUnreadNotifications() ? 'red' : 'default'}
            animationClass={!loadingSubscription || checkUnreadNotifications() ? 'heartBeat' : ''}
          />
        ),
        content: (
          <>
            {!loading &&
              data &&
              data.userNotifications &&
              data.userNotifications!.map((n) => (
                <NotificationMessage key={n && n._id} userNotification={n && n} iconClass="fas fa-exclamation-circle" />
              ))}
            {data && data.userNotifications && !data.userNotifications.length && (
              <div className="text-center py-2">No notifications</div>
            )}
          </>
        ),
      }}
    </DropdownBox>
  );
};

export default NotificationsMenu;
