import React, { useEffect, useState } from 'react';
import {
  ProductTranslatedResult,
  ExportConfigurationQueryVariablesSheetSettings,
  SearchProductsTranslatedQueryVariables,
  useSearchProductsTranslatedQuery,
} from 'generated/graphql';
import { ExportType } from './exportConstants';
import { toast, ToastId } from 'react-toastify';

//! Do not change from require unless they fixed compatibility with typescript
//! See: https://github.com/developit/workerize-loader/issues/5
const ExportWorker = require('workerize-loader!./export.worker'); // eslint-disable-line import/no-webpack-loader-syntax

type ExportFileProps = {
  queryVariables: SearchProductsTranslatedQueryVariables;
  sheetSettings?: ExportConfigurationQueryVariablesSheetSettings | null;
  type: ExportType;
  onAllLoaded: () => void;
};
const ExportFile: React.FC<ExportFileProps> = ({ queryVariables, type, onAllLoaded, sheetSettings }) => {
  const [exportToast, setExportToast] = useState<ToastId>();
  const {
    data: dataAll,
    loading: loadingAll,
    error: errorAll,
    fetchMore: fetchMoreAll,
  } = useSearchProductsTranslatedQuery({
    variables: { ...queryVariables, page: 1, pageSize: 100 },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (loadingAll && !exportToast) {
      setExportToast(
        toast.info('Fetching data, please wait...', {
          autoClose: false,
        }),
      );
    }
  }, [loadingAll, exportToast]);

  useEffect(() => {
    if (dataAll && dataAll.productTranslatedSearch && sheetSettings) {
      if (dataAll.productTranslatedSearch.pageInfo?.hasNextPage) {
        fetchMoreAll({
          variables: {
            page: dataAll.productTranslatedSearch.pageInfo.page + 1,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            if (exportToast) {
              const percentage = Math.round(
                ((fetchMoreResult.productTranslatedSearch?.pageInfo?.page || 1) /
                  (fetchMoreResult.productTranslatedSearch?.pageInfo?.pageCount || 1)) *
                  100,
              );
              toast.update(exportToast, {
                render: `Fetching data, please wait... ${percentage}%`,
              });
            }
            const copy = Object.assign({}, fetchMoreResult, {
              productTranslatedSearch: {
                ...fetchMoreResult.productTranslatedSearch,
                result: [
                  ...(prev.productTranslatedSearch?.result || []),
                  ...(fetchMoreResult.productTranslatedSearch?.result || []),
                ],
              },
            });

            return copy;
          },
        });
      } else {
        if (exportToast) {
          toast.update(exportToast, {
            render: 'Creating file...',
            className: 'animated fadeIn',
          });
        }
        offloadFileCreationToWorker(dataAll.productTranslatedSearch, type, sheetSettings)
          .then(() => {
            if (exportToast) {
              toast.update(exportToast, {
                render: '✓ Success!',
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
                className: 'animated flipInX',
              });
            }

            onAllLoaded();
          })
          .catch((error: Error) => {
            toast.error(error.message);
          });
      }
    }
  }, [dataAll, queryVariables, type, onAllLoaded, exportToast, fetchMoreAll, sheetSettings]);

  useEffect(() => {
    if (errorAll && exportToast) {
      toast.update(exportToast, {
        render: '✗ Failed ',
        type: toast.TYPE.ERROR,
        autoClose: 4000,
        className: 'animated shake',
      });
    }
  }, [errorAll, exportToast]);

  return (
    <>
      <div></div>
    </>
  );
};
export default ExportFile;

function getFileEnding(type: ExportType) {
  switch (type) {
    case ExportType.JSON:
      return '.json';
    case ExportType.XML:
      return '.xml';
    case ExportType.Sheet:
      return '.xlsb';
    default:
      break;
  }
}

function offloadFileCreationToWorker(
  data: ProductTranslatedResult,
  type: ExportType,
  settings: ExportConfigurationQueryVariablesSheetSettings,
) {
  const worker = new ExportWorker();

  if (!worker || !worker.exportToFile) {
    return Promise.reject(new Error('Web worker unavailable. Please contact system administrator.'));
  }

  return worker
    .exportToFile(
      data,
      type,
      settings.rowLevel || 'model',
      settings.imageLevel || 'mainOnly',
      settings.selectedColumns || [],
      settings.languages || ['en'],
      settings.pricelists || [],
      settings.units || [],
    )
    .then((url: string) => {
      const filename = 'Products_' + new Date().toLocaleDateString() + getFileEnding(type);

      var a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      worker.terminate();
    });
}
