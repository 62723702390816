import React from 'react';
import styles from './AssortmentNode.module.scss';
import CSSModule from 'react-css-modules';
import { ItemId } from '@atlaskit/tree';
import { DropdownMenu } from 'ui';

type AssortmentNodeProps = {
  text: string;
  isExpanded: boolean;
  onExpand: (itemId: React.ReactText) => void;
  onCollapse: (itemId: ItemId) => void;
  onNodeAdd: (itemId: ItemId) => void;
  onNodeDeleted: (itemId: ItemId) => void;
  onSelect: (itemId: ItemId) => void;
  hasChildren: boolean;
  itemId: React.ReactText;
  selected: boolean;
};
const AssortmentNode: React.FC<AssortmentNodeProps> = ({
  text,
  isExpanded,
  onExpand,
  onCollapse,
  onNodeAdd,
  onNodeDeleted,
  hasChildren,
  itemId,
  onSelect,
  selected,
}) => {
  const nodeMenuItems = [
    {
      title: 'Remove node',
      action: () => onNodeDeleted(itemId),
    },
    {
      title: 'Add subnode',
      action: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation();
        onNodeAdd(itemId);
      },
    },
  ];

  return (
    <div
      onClick={() => {
        onSelect(itemId);
      }}
      className={`d-inline-flex alignIt`}
      styleName={'node'}
    >
      <div className={`p-2 d-flex align-items-center justify-content-between`} styleName={selected ? 'focus' : ''}>
        <i className={'fas fa-project-diagram mr-1'}></i>
        <div className="p-1">
          <div>{text.trim().length > 0 ? text : <span className="o-50">No name</span>}</div>
        </div>
        <div className="ml-2 pl-2 divider-vertical-before position-relative h-100 d-flex align-items-center">
          <div className="mr-1">
            <DropdownMenu closeOnSelect>
              {{
                trigger: <i className="fas fa-ellipsis-v ml-1"></i>,
                items: nodeMenuItems,
              }}
            </DropdownMenu>
          </div>
          {hasChildren && (
            <>
              <AssortmentExpandButton
                onCollapse={onCollapse}
                onExpand={onExpand}
                itemId={itemId}
                isExpanded={isExpanded}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CSSModule(AssortmentNode, styles);

type ExpandButtonProps = {
  onExpand: (itemId: React.ReactText) => void;
  onCollapse: (itemId: ItemId) => void;
  itemId: React.ReactText;
  isExpanded: boolean;
};
const ExpandButton: React.FC<ExpandButtonProps> = ({ isExpanded, itemId, onExpand, onCollapse }) => {
  return (
    <div
      className="d-flex text-bigger align-items-center"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (isExpanded) {
          onCollapse(itemId);
        } else {
          onExpand(itemId);
        }
      }}
    >
      <i className={`fas fa-angle-${isExpanded ? 'down' : 'right'} ml-1`} style={{ minWidth: '12px' }}></i>
    </div>
  );
};

const AssortmentExpandButton = CSSModule(ExpandButton, styles);
