export const imageLevels: IStringKVP = {
  mainOnly: 'Main Image only',
  productOnly: 'Product Images only',
  all: 'All Images',
};

export const rowLevels: IStringKVP = {
  model: 'Model',
  variation: 'Variation',
  size: 'Size',
};

export const columns: IStringKVP = {
  name: 'Name',
  text: 'Text',
  brand: 'Brand',
  gender: 'Gender',
  images: 'Images',

  activityTypes: 'Activity Types',
  applicationAreas: 'Application Areas',
  applicationTypes: 'Application Types',
  capacity: 'Capacity',
  careInstructions: 'Care Instructions',
  catalogText: 'Catalog Text',
  catalogPrice: 'Catalog Price',
  catalogSizes: 'Catalog Sizes',
  category: 'Category',
  certifications: 'Certifications',
  closure: 'Closure',
  colorComment: 'Color Comment',
  commerceText: 'Commerce Text',
  currency: 'Currency',
  designer: 'Designer',
  diameter: 'Diameter',
  dimensions: 'Dimensions',
  fabrics: 'Fabrics',
  feature: 'Feature',
  filterColor: 'Filter Color',
  fit: 'Fit',
  height: 'Height',
  hoodDetails: 'Hood details',
  length: 'Length',
  materialTechnique: 'Material technique',
  measure: 'Measure',
  modelSizes: 'Model Sizes',
  neckline: 'Neckline',
  packagingStandard: 'Packaging Standard',
  packaging: 'Packaging',
  pockets: 'Pockets',
  printCode: 'Print Code',
  range: 'Range',
  retailPrice: 'Retail Price',
  sleeve: 'Sleeve',
  theme: 'Theme',
  usp: 'Unique Selling Point (USP)',
  volume: 'Volume',
  weight: 'Weight',
  weightText: 'Weight Text',
  width: 'Width',
};

export const variationColumns: IStringKVP = {
  model: 'Model',
  colorCode: 'Color Code',
  colorName: 'Color Name',
  colorSizes: 'Color Sizes',
};

export const sizeColumns: IStringKVP = {
  availability: 'Availability',
  changed: 'Changed',
  commodityCode: 'Commodity Code',
  countryOfOrigin: 'Country of Origin',
  description: 'Description',
  ean: 'EAN Code',
  enDescription: 'English Description',
  isAvailable: 'Available',
  nextStockUpdate: 'Next stock update',
  salesUnit: 'Sales Unit',
  size: 'Size',
  sizeDescription: 'Size Description',

  items: 'Items',
  grossVolume: 'Gross Volume',
  netVolume: 'Net Volume',
  grossWeight: 'Gross Weight',
  netWeight: 'Net Weight',

  defaultSalesUnitDimensions: 'Default Unit Dimensions (HxWxL)',
  defaultSalesUnitHeight: 'Default Unit Height',
  defaultSalesUnitWidth: 'Default Unit Width',
  defaultSalesUnitLength: 'Default Unit Length',

  masterGrossVolume: 'Master Gross Volume',
  masterNetVolume: 'Master Net Volume',
  masterGrossWeight: 'Master Gross Weight',
  masterNetWeight: 'Master Net Weight',
  masterLength: 'Master Length',
  masterHeight: 'Master Height',
  masterWidth: 'Master Width',
  masterDimensions: 'Master Dimensions (HxWxL)',
  masterItems: 'Master Items',
  masterEanCode: 'Master EAN Code',

  pallGrossVolume: 'Pall Gross Volume',
  pallNetVolume: 'Pall Net Volume',
  pallGrossWeight: 'Pall Gross Weight',
  pallNetWeight: 'Pall Net Weight',
  pallLength: 'Pall Length',
  pallHeight: 'Pall Height',
  pallWidth: 'Pall Width',
  pallDimensions: 'Pall Dimensions (HxWxL)',
  pallItems: 'Pall Items',
  pallEanCode: 'Pall EAN Code',

  boxGrossVolume: 'Box Gross Volume',
  boxNetVolume: 'Box Net Volume',
  boxGrossWeight: 'Box Gross Weight',
  boxNetWeight: 'Box Net Weight',
  boxLength: 'Box Length',
  boxHeight: 'Box Height',
  boxWidth: 'Box Width',
  boxDimensions: 'Box Dimensions (HxWxL)',
  boxItems: 'Box Items',

  skuCategorizationGroup1: 'Sku Categorization Group 1',
  skuCategorizationGroup2: 'Sku Categorization Group 2',
  skuCategorizationGroup3: 'Sku Categorization Group 3',
  skuCategorizationGroup4: 'Sku Categorization Group 4',
  skuCategorizationGroup5: 'Sku Categorization Group 5',
  skuCategorizationGroup6: 'Sku Categorization Group 6',
};

export const priceColumns: IStringKVP = {
  prices: 'Sales Price',
};

type Units = {
  [key: string]: IStringKVP;
};
export const units: Units = {
  vol: {
    oz: 'oz',
    cl: 'cl',
    l: 'l',
    m3: 'm3',
  },
  length: {
    mm: 'mm',
    cm: 'cm',
    in: 'inch',
    ft: 'ft',
  },
  weight: {
    kg: 'kg',
    g: 'g',
    lbs: 'lbs',
  },
};

export const volumeUnitFieldsBaseCl = ['volume'];
export const volumeUnitFieldsBaseM3 = [
  'grossVolume',
  'netVolume',
  'masterGrossVolume',
  'masterNetVolume',
  'pallGrossVolume',
  'pallNetVolume',
  'boxGrossVolume',
  'boxNetVolume',
];
export const volumeUnitFieldsBaseL = ['capacity'];
export const volumeUnitFields = [...volumeUnitFieldsBaseCl, ...volumeUnitFieldsBaseL, ...volumeUnitFieldsBaseM3];

export const weightUnitFields = [
  'weight',
  'grossWeight',
  'netWeight',
  'masterGrossWeight',
  'masterNetWeight',
  'pallGrossWeight',
  'pallNetWeight',
  'boxGrossWeight',
  'boxNetWeight',
];
export const lengthUnitFields = [
  'height',
  'width',
  'length',
  'masterHeight',
  'masterWidth',
  'masterLength',
  'pallHeight',
  'pallWidth',
  'pallLength',
  'boxHeight',
  'boxWidth',
  'boxLength',
  'defaultSalesUnitHeight',
  'defaultSalesUnitWidth',
  'defaultSalesUnitLength',
  'diameter',
];

export const dimensionsUnitFields = [
  'dimensions',
  'defaultSalesUnitDimensions',
  'masterDimensions',
  'pallDimensions',
  'boxDimensions',
];

export const defaultVars = {
  assortmentId: '',
  companyCode: '',
  filters: {},
  keyword: '',
  language: 'en',
  page: 1,
  pageSize: 2,
  sheetSettings: {},
};

export const defaultSheetSettings = {
  rowLevel: 'model',
  imageLevel: 'mainOnly',
  languages: ['en'],
  selectedColumns: [],
};

export enum ExportType {
  Sheet = 'Sheet',
  JSON = 'JSON',
  XML = 'XML',
}
