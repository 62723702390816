import React from 'react';
import styles from './Aside.module.scss';
import CSSModule from 'react-css-modules';
import { Portal } from 'react-portal';
import { useAsideMenuQuery, useToggleAsideMenuMutation } from 'generated/graphql';
import { GET_ASIDE_MENU } from 'graphql/apollo';

interface Props {
  title: string;
}

const Aside: React.FC<Props> = ({ title, children }) => {
  const { data } = useAsideMenuQuery();
  const [toggleAsideMenu] = useToggleAsideMenuMutation();

  return (
    <Portal>
      {data && data.asideMenu && data.asideMenu.active && (
        <React.Fragment>
          <div
            styleName="overlay"
            onClick={() =>
              toggleAsideMenu({
                variables: {
                  active: false,
                },
                refetchQueries: [{ query: GET_ASIDE_MENU }],
              })
            }
          ></div>
          <div styleName="aside">
            <h2>{title}</h2>
            {children}
          </div>
        </React.Fragment>
      )}
    </Portal>
  );
};

export default CSSModule(Aside, styles);
