import React from 'react';
import styles from './InputField.module.scss';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  showLabel?: boolean;
  error?: string | boolean;
  success?: boolean;
  ref?: React.Ref<HTMLInputElement>;
};

const InputField: React.FC<InputProps> = React.forwardRef(({ name, showLabel, error, success, ...rest }, ref) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className={`${!showLabel && 'sr-only'}`}>
        {rest.placeholder || name}
      </label>
      <input className={`${styles.inputField} form-control`} type="text" ref={ref} name={name} {...rest} />
      {success && (
        <div className={styles.successIcon}>
          <i className={`animated fadeIn fas fa-check-circle`}></i>
        </div>
      )}
      {error && <div className="animated fadeIn text-danger">{error}</div>}
    </div>
  );
});

export default InputField;
