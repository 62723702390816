import React from 'react';
import { Select } from 'ui';
import Skeleton from 'react-loading-skeleton';
import { useCommerceUserLinksQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';

type Props = {
  defaultValues: IUserInputData;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionCommerceAccess: React.FC<Props> = ({ defaultValues, setValue, register }) => {
  const { data, loading, error } = useCommerceUserLinksQuery();

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading commerce user links: <br />
        {error.message}
      </div>
    );
  }

  const options = data?.commerceUserLinks?.map((link) => ({
    value: link?._id!,
    label: link?.name!,
  }));

  const handleChange = (option: ValueType<OptionTypeBase>) => {
    if (!option) {
      setValue('commerceUserLinkId', null, false);
    } else {
      return setValue('commerceUserLinkId', (option as OptionTypeBase).value, false);
    }
  };

  register({ name: 'commerceUserLinkId' });

  return (
    <>
      <Select
        label="Commerce User Restriction"
        name="commerceUserLinkId"
        defaultValue={options?.filter((option) => defaultValues?.commerceUserLinkId?.includes(option.value))}
        onChange={(value) => handleChange(value)}
        options={options}
        isClearable={true}
      />
    </>
  );
};

export default FormSectionCommerceAccess;
