import React from 'react';
import { View } from 'ui';
import { UserManager } from 'app/components';

const Users = () => {
  return (
    <View sleek>
      <UserManager />
    </View>
  );
};

export default Users;
