import React from 'react';
import moment from 'moment';
import CSSModules from 'react-css-modules';
import styles from './Table.module.scss';

type Column = {
  Header?: string;
  accessor?: string;
  dateFormat?: boolean;
  Action?: (cell: any) => JSX.Element;
};

interface TableProps {
  data?: any[] | undefined | null;
  columns?: Array<Column>;
}

const Table: React.FC<TableProps> = ({ data, columns }) => {

  const getDescendantProp = (obj: Record<string, any>, desc: string) => {
    return desc.split('.').reduce((a, b) => a ? a[b] : null, obj);
  }

  return (
    <table styleName="table">
      <thead>
        <tr>
          {columns?.map((col, i) => (
            <th key={i}>{col.Header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, i) => (
          <tr key={i}>
            {columns?.map((col, i) => {
              // check if col is an Action
              if (col.Action) {
                return (
                  <td key={i} styleName="push-right">
                    {col.Action(item)}
                  </td>
                );
              }

              // check if accessor is provided
              if (!col.accessor) {
                return <td key={i}>Missing accessor.</td>;
              }

              const accessedProp = getDescendantProp(item, col.accessor)

              // check if accessor provided exists
              if (accessedProp === null) {
                return <td key={i}>-</td>;
              }

              // check if the cell is of type Boolean
              if (typeof accessedProp === 'boolean') {
                return (
                  <td key={i} className={accessedProp ? 'text-success' : 'text-danger'}>
                    {accessedProp ? 'True' : 'False'}
                  </td>
                );
              }

              // check if the cell needs date format
              if (col.dateFormat) {
                return <td key={i}>{moment(accessedProp).format('LL')}</td>;
              }

              // else render cell value as is
              return <td key={i}>{accessedProp}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CSSModules(Table, styles);
