import gql from 'graphql-tag';

export const GET_COMMERCE_USERS = gql`
  query CommerceUsers($page: Int!, $pageSize: PageSize!, $email: String, $userId: String, $search: String) {
    commerceUsers(page: $page, pageSize: $pageSize, email: $email, userId: $userId, search: $search) {
      pageInfo {
        page
        pageSize
        pageCount
        hasNextPage
        hasPreviousPage
      }
      count
      result {
        id
        email
        company
      }
    }
  }
`;
