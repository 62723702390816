import React from 'react';
import { UserForm } from 'app/components';
import { Card } from 'ui';
import { useCreateUserMutation } from 'generated/graphql';
import { SEARCH_USERS } from 'graphql/queries';

const CreateUser = () => {
  const [userRegister, { error: errorUserAdd, loading: loadingUserAdd }] = useCreateUserMutation();

  const submitData = (inputs: IUserInputData) => {
    userRegister({
      variables: {
        username: inputs.username!,
        password: inputs.password!,
        email: inputs.email!,
        companyIds: inputs.companyIds || null,
        serviceContainerIds: inputs.serviceContainerIds || null,
        commerceUserLinkId: inputs.commerceUserLinkId || null,
        isAdmin: inputs.isAdmin,
        confirmed: inputs.confirmed,
        active: inputs.active,
      },
      refetchQueries: [{ query: SEARCH_USERS, variables: { page: 1, perPage: 15 } }],
    });
  };

  return (
    <Card title="Create user" className="mb-3">
      <UserForm
        defaultInputValues={{
          username: '',
          email: '',
          password: '',
          companyIds: null,
          serviceContainerIds: null,
          commerceUserLinkId: null,
          isAdmin: false,
          confirmed: false,
          active: false,
          pricelists: [],
        }}
        submitData={submitData}
        error={errorUserAdd!}
        isLoading={!!loadingUserAdd}
      />
    </Card>
  );
};

export default CreateUser;
