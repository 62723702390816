import React, { useState, ChangeEvent, useEffect } from 'react';
import { InputField, Select } from 'ui';
import {
  useGetProductFiltersQuery,
  ProductFilter,
  useCompaniesQuery,
  useUserAssortmentsQuery,
  ExportConfigurationQueryVariablesFilters,
  Maybe,
} from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { OptionValue } from 'ui/Select/Select';
import { ProductState } from 'app/pages/Products/Products';
import Skeleton from 'react-loading-skeleton';

export type FilterPayload = {
  keyword?: string;
  language?: string;
  assortmentId?: string;
  companyCode?: string;
  filters?: Maybe<ExportConfigurationQueryVariablesFilters>[] | null;
};

type FilterProps = {
  dispatchProductsState: React.Dispatch<{
    type: 'set';
    payload: FilterPayload;
    from: string;
  }>;
  productState: ProductState;
  forceAssortment?: boolean;
};

const Filter: React.FC<FilterProps> = ({ dispatchProductsState, productState, forceAssortment = false }) => {
  const [companyOptions, setCompanyOptions] = useState<OptionValue[]>();
  const [assortmentOptions, setAssortmentOptions] = useState<OptionValue[]>();
  const [filters, setFilters] = useState<ProductFilter[]>();

  const { data: companyData, loading: loadingCompany } = useCompaniesQuery();
  const { data: assortmentData, loading: loadingAssortment } = useUserAssortmentsQuery();
  const { data, loading: loadingFilters } = useGetProductFiltersQuery({
    variables: {
      assortmentId: productState.assortmentId || '',
      language: productState.language || '',
      companyCode: productState.companyCode || '',
    },
    skip: !productState.assortmentId,
  });

  useEffect(() => {
    if (data && data.productFilters) {
      const productFilters = data.productFilters.reduce(
        (acc, curr) => (curr ? [...acc, curr] : acc),
        new Array<ProductFilter>(),
      );
      setFilters(productFilters);
    }
  }, [data, setFilters]);

  useEffect(() => {
    if (companyData && companyData.companies && companyData.companies.length) {
      const foundCompanies = companyData.companies.map((c) => ({ value: c!.companyCode || '', label: c!.name || '' }));
      setCompanyOptions([...foundCompanies, { value: '', label: '-- All companies --' }]);
    }
  }, [companyData]);

  useEffect(() => {
    if (assortmentData?.userAssortments?.length) {
      const foundAssortments = assortmentData.userAssortments.map((a) => ({
        value: a!.assortmentId || '',
        label: a!.name || '',
      }));
      setAssortmentOptions(
        forceAssortment ? foundAssortments : [...foundAssortments, { value: '', label: '-- All assortments --' }],
      );
    }
  }, [assortmentData, forceAssortment]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const queryString = e.currentTarget.value.length < 3 ? '' : e.currentTarget.value;
    dispatchProductsState({ type: 'set', payload: { keyword: queryString }, from: 'handleSearch' });
  };

  const handleFilterChanged = (filterName: string, options: ValueType<OptionTypeBase>) => {
    const otherFilters = productState.filters?.filter((f) => f?.filter !== filterName);

    dispatchProductsState({
      type: 'set',
      payload: {
        filters: [...(otherFilters || []), { filter: filterName, values: options?.map((o: OptionValue) => o.value) }],
      },
      from: 'filter',
    });
  };

  const handleCompanyChanged = (option: ValueType<OptionTypeBase>) => {
    if (option && option.hasOwnProperty('value'))
      dispatchProductsState({ type: 'set', payload: { companyCode: (option as OptionValue).value }, from: 'company' });
  };

  const handleAssortmentChanged = (option: ValueType<OptionTypeBase>) => {
    if (option && option.hasOwnProperty('value'))
      dispatchProductsState({
        type: 'set',
        payload: { assortmentId: (option as OptionValue).value },
        from: 'assortment',
      });
  };

  return (
    <>
      <InputField
        className="form-control mr-2"
        name="filter-search"
        placeholder="Name / articlenumber"
        onChange={handleSearchInput}
      ></InputField>
      {loadingFilters && <Skeleton count={3} height={35}></Skeleton>}
      {filters &&
        filters.map(({ filter, values }) => {
          const options = values!.map((kvp) => {
            return { value: kvp!.key!, label: kvp!.value || kvp!.key! };
          });

          const qFilters = productState.filters?.find((f) => f?.filter === filter)?.values;

          const value =
            qFilters?.map((f) => {
              const label = values && values.find((v) => v!.key === f);
              return {
                value: f || '',
                label: label?.value || label?.key || `${f}: Unavailable`,
              };
            }) || [];

          return (
            <Select
              key={filter}
              isMulti
              compact
              color={'transparent'}
              closeMenuOnSelect={false}
              label={filter}
              name={filter}
              value={value}
              onChange={(value) => handleFilterChanged(filter, value)}
              options={options}
            />
          );
        })}
      {loadingCompany && <Skeleton count={1} height={35}></Skeleton>}
      {companyOptions && companyOptions.length > 0 && (
        <Select
          color={'transparent'}
          compact
          label={'company'}
          name={'company'}
          value={companyOptions.find((co) => co.value === productState.companyCode)}
          onChange={handleCompanyChanged}
          options={companyOptions}
        ></Select>
      )}
      {loadingAssortment && <Skeleton count={1} height={35}></Skeleton>}
      {assortmentOptions && assortmentOptions.length > 0 && (
        <Select
          color={'transparent'}
          compact
          label={'assortment'}
          name={'assortment'}
          value={assortmentOptions.find((ao) => ao.value === productState.assortmentId)}
          onChange={handleAssortmentChanged}
          options={assortmentOptions}
        ></Select>
      )}
    </>
  );
};

export default Filter;
