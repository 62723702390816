import React, { useEffect } from 'react';
import { PricelistInput } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { Select } from 'ui';

export type PricelistOption = {
  pricelist: string;
  companyCode: string;
  currency: string;
};

type Props = {
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register?: any;
  companyCode: string;
  pricelists: PricelistOption[];
};
const FormSectionPricelistsOption: React.FC<Props> = ({ setValue, register, companyCode, pricelists }) => {
  // empty set pricelists if companycode or pricelists change.
  useEffect(() => {
    setValue('pricelists', null);
  }, [companyCode, pricelists, setValue]);

  if (!pricelists.length) return null;

  const options = pricelists.map((pl) => ({
    value: `${pl.pricelist}|${pl.currency}|${pl.companyCode}`,
    label: `${pl.pricelist}: ${pl.currency}`,
  }));

  /**
   * Set companyCode by selected option
   * @param option
   */
  const handleChange = (options: ValueType<OptionTypeBase>) => {
    if (!options || !options.length) {
      setValue('pricelists', null);
    } else {
      const pricelists: PricelistInput[] = options.map((o: OptionTypeBase) => {
        const values = o.value.split('|');
        return {
          pricelist: values[0],
          currency: values[1],
          companyCode: values[2],
        };
      });

      setValue('pricelists', pricelists);
    }
  };

  if (register) register({ name: 'pricelists' });

  return (
    <Select
      isMulti
      name="pricelists"
      placeholder="Pricelists"
      label="Pricelists"
      onChange={(value) => handleChange(value)}
      options={options}
      className="mb-3"
    />
  );
};

export default FormSectionPricelistsOption;
