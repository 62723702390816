import { ItemId, TreeItem } from '@atlaskit/tree';
import { Product, Assortment, Maybe } from 'generated/graphql';

export enum TreeType {
  Products = 'Products',
  Assortment = 'Assortment',
  Selected = 'Selected',
}

export default class TreeBuilder {
  rootId: ItemId;
  items: Record<ItemId, TreeItem>;

  constructor(rootId: ItemId, treeType: TreeType, name: string = '') {
    this.rootId = rootId;
    this.items = {
      [rootId]: createItem(`${rootId}`, `${rootId}`, treeType, name, null),
    };
  }

  withLeaf(id: ItemId, data: Product | null, treeType: TreeType) {
    const leafItem = createItem(`${this.rootId}-${id}`, `${id}`, treeType, `${id}`, data);
    this._addItemToRoot(leafItem.id);
    this.items[leafItem.id] = leafItem;
    return this;
  }

  withSubTree(tree: TreeBuilder) {
    const subTree = tree.build();
    this._addItemToRoot(`${this.rootId}-${subTree.rootId}`);

    Object.keys(subTree.items).forEach(itemId => {
      const finalId = `${this.rootId}-${itemId}`;
      this.items[finalId] = {
        ...subTree.items[itemId],
        data: { ...subTree.items[itemId].data, treeType: TreeType.Assortment },
        id: finalId,
        children: subTree.items[itemId].children.map(i => `${this.rootId}-${i}`),
      };
    });

    return this;
  }

  build() {
    return {
      rootId: this.rootId,
      items: this.items,
    };
  }

  _addItemToRoot(id: string) {
    const rootItem = this.items[this.rootId];
    rootItem.children.push(id);
    rootItem.isExpanded = false;
    rootItem.hasChildren = true;
  }
}

export const createItem = (
  treeId: string,
  entityId: string,
  treeType: TreeType,
  name: string = '',
  product: Product | null = null,
) => {
  const data = {
    isProduct: !!product,
    title: product?.productName || name,
    product: product,
    entityId: entityId,
    treeType: treeType,
  };

  return {
    id: treeId,
    children: [],
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: data,
  };
};

export const createItemByProductItem = (treeId: string, product: TreeItem) => {
  return {
    id: treeId,
    children: [],
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: { ...product.data, treeType: TreeType.Selected },
  };
};

type AssortmentNodeMap = {
  topNodes: Assortment[];
  nodes: {
    [index: string]: Assortment;
  };
};

export const createSubTreeFromAssortment = (node: Assortment, nodeMap: AssortmentNodeMap) => {
  const subTree = new TreeBuilder(node.id || 'noIdTree', TreeType.Assortment, node.name || '');
  node.childNodeIds?.forEach(id => {
    if (id && nodeMap.nodes[id]) {
      subTree.withSubTree(createSubTreeFromAssortment(nodeMap.nodes[id], nodeMap));
    }
  });

  node.childProducts?.result?.forEach(prod => {
    if (prod?.productNumber) {
      subTree.withLeaf(prod?.productNumber, prod, TreeType.Assortment);
    }
  });

  return subTree;
};

export const getNodeMap = (nodes: Maybe<Assortment>[], topNodeId: string) => {
  return nodes.reduce<AssortmentNodeMap>(
    (acc, curr) => {
      if (!curr || !curr.id) return acc;

      return {
        nodes: { ...acc.nodes, [curr.id]: curr },
        topNodes: curr.parentId === topNodeId ? [...acc.topNodes, curr] : acc.topNodes,
      };
    },
    { topNodes: [], nodes: {} },
  );
};
