import React from 'react';
import { ApolloError } from 'apollo-boost';
import { useForm } from 'react-hook-form';
import { InputForm, StateButton } from 'ui';

// Form sections
import FormSectionUserAccount from './FormSectionUserAccount';
import FormSectionCompanyAccess from './FormSectionCompanyAccess';
import FormSectionServiceAccess from './FormSectionServiceAccess';
import FormSectionCommerceAccess from './FormSectionCommerceAccess';
import FormSectionPricelistAccess from './FormSectionPricelistAccess';
import { ArrayFixUpdateUserByIdMutationVariables } from '../EditUser/EditUser';

type FormData = IUserInputData & {
  defaultValues: Partial<ArrayFixUpdateUserByIdMutationVariables>;
};
type UserFormProps = {
  defaultInputValues?: Partial<ArrayFixUpdateUserByIdMutationVariables>;
  submitData: any; // ! Todo - set type
  isLoading?: boolean;
  error: ApolloError;
  isSuccess?: boolean;
};

const UserForm: React.FC<UserFormProps> = ({ defaultInputValues, submitData, isLoading, error, isSuccess }) => {
  const { register, setValue, handleSubmit, errors, formState } = useForm<
    Partial<ArrayFixUpdateUserByIdMutationVariables>
  >({
    defaultValues: {
      ...defaultInputValues,
    },
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((data: IUserInputData) => {
    const error = Object.keys(errors).length;

    if (!error) {
      submitData(data);
    }
  });

  return (
    <React.Fragment>
      <InputForm onSubmit={onSubmit}>
        {defaultInputValues && (
          <>
            <FormSectionUserAccount defaultValues={defaultInputValues} register={register} errors={errors} />
            <FormSectionCompanyAccess defaultValues={defaultInputValues} setValue={setValue} register={register} />
            <FormSectionPricelistAccess defaultValues={defaultInputValues} setValue={setValue} register={register} />
            <FormSectionServiceAccess defaultValues={defaultInputValues} setValue={setValue} register={register} />
            <FormSectionCommerceAccess defaultValues={defaultInputValues} setValue={setValue} register={register} />
          </>
        )}

        <StateButton
          type="submit"
          size={35}
          color="green"
          disabled={!formState.isValid}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={!!error}
        >
          Save
        </StateButton>
      </InputForm>
    </React.Fragment>
  );
};

export default UserForm;
