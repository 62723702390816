import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Radio.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  groupName: string;
  groupClass?: string;
  choices: { key: string; value: string }[];
  checkedValue: string;
  inline?: boolean;
};

const Radio: React.FC<Props> = ({ label, groupName, groupClass, choices, checkedValue, inline, ...rest }) => (
  <div className={`form-check ${groupClass}`}>
    {label && <label>{label}</label>}
    {choices.map(choice => (
      <div className={inline ? 'd-inline-block' : ''} key={choice.key}>
        <input
          className="form-check-input mr-1"
          type="radio"
          id={choice.key}
          name={groupName}
          value={choice.key}
          checked={choice.key === checkedValue}
          {...rest}
        ></input>
        <label className="font-weight-normal form-check-label mr-2" htmlFor={choice.key}>
          {choice.value}
        </label>
      </div>
    ))}
  </div>
);

export default CSSModules(Radio, styles);
