import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Select } from 'ui';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { useCommerceAppsQuery } from 'generated/graphql';
import { ArrayFixUpdateCommerceUserLinkMutationVariables } from './CommerceUserLinkForm';

type Props = {
  defaultValues?: ArrayFixUpdateCommerceUserLinkMutationVariables;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionCommerceApps: React.FC<Props> = ({ setValue, register, defaultValues }) => {
  const { data, loading, error } = useCommerceAppsQuery();

  const options = data?.commerceApps?.map((app) => ({
    value: `${app?.id!},${app?.settings?.erpCompanyCode!}`,
    label: app?.name!,
  }));

  useEffect(() => {
    if (defaultValues) {
      setValue(
        'contexts',
        defaultValues?.contexts?.map((context) => {
          return {
            contextId: context?.contextId,
            companyCode: context?.companyCode,
            name: context?.name,
          };
        }),
        true,
      );
    }
  }, [defaultValues, setValue]);

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading commerce contexts: <br />
        {error.message}
      </div>
    );
  }

  const handleChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setValue('contexts', [''], true);
    } else {
      const contexts = values.map((val: OptionTypeBase) => {
        return {
          contextId: val.value.split(',')[0],
          companyCode: val.value.split(',')[1],
          name: val.label,
        };
      });

      return setValue('contexts', contexts, true);
    }
  };

  register({ name: 'contexts' }, { required: true });

  return (
    <>
      <Select
        isMulti
        label="Commerce apps"
        name="contextIds"
        defaultValue={options?.filter((option) =>
          defaultValues?.contexts
            .map((context) => {
              return context?.contextId;
            })
            .includes(option.value.split(',')[0]),
        )}
        onChange={(value) => handleChange(value)}
        options={options}
      />
    </>
  );
};

export default FormSectionCommerceApps;
