import React from 'react';
import { ExportConfigurationQueryVariablesSheetSettings, Maybe, UserPricelists } from 'generated/graphql';
import { Select } from 'ui';
import { OptionValue } from 'ui/Select/Select';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import {
  columns,
  variationColumns,
  sizeColumns,
  priceColumns,
  units,
  rowLevels,
  imageLevels,
  volumeUnitFieldsBaseCl,
  volumeUnitFieldsBaseL,
  volumeUnitFieldsBaseM3,
  volumeUnitFields,
  lengthUnitFields,
  weightUnitFields,
  dimensionsUnitFields,
} from './exportConstants';
import { LANGUAGES } from 'app/constants';
import { ProductState, QueryVariablesActions } from 'app/pages/Products/Products';

type ExportSettings = {
  imageLevel: string;
  rowLevel: string;
  languages: Maybe<string>[];
  selectedColumns: Maybe<string>[];
};

type ExportSheetProps = {
  dispatchQueryVariables: React.Dispatch<QueryVariablesActions>;
  queryVariables: ProductState;
  userPricelists?: UserPricelists[];
};

const ExportSettings: React.FC<ExportSheetProps> = ({ queryVariables, dispatchQueryVariables, userPricelists }) => {
  const allColumnChoices = {
    ...columns,
    ...((queryVariables.sheetSettings?.rowLevel !== 'model' && variationColumns) || {}),
    ...((queryVariables.sheetSettings?.rowLevel === 'size' && sizeColumns) || {}),
    ...((queryVariables.sheetSettings?.pricelists?.length && priceColumns) || {}),
  };

  const handleSettingsChanged = (value: Partial<ExportConfigurationQueryVariablesSheetSettings>) => {
    dispatchQueryVariables({
      type: 'set',
      payload: {
        sheetSettings: { ...queryVariables.sheetSettings, ...value },
      },
      from: 'settings',
    });
  };

  const maxLanguageOptions = 5;
  const languageValue = queryVariables.sheetSettings?.languages
    ?.filter((l): l is string => l !== null)
    .map((l) => ({ value: l, label: LANGUAGES[l] }));
  const languageOptions = Object.keys(LANGUAGES).map((k) => ({ value: k, label: LANGUAGES[k] }));

  const allUnits = Object.keys(units).reduce((acc, group) => {
    return { ...acc, ...units[group] };
  }, {} as IStringKVP);
  const unitValue = queryVariables.sheetSettings?.units
    ?.filter((u): u is string => u !== null)
    .map((u) => ({ value: u, label: allUnits[u] }));
  const unitOptions = Object.keys(units).map((groupKey) => ({
    label: groupKey,
    options: Object.keys(units[groupKey]).map((unitKey) => ({ value: unitKey, label: units[groupKey][unitKey] })),
  }));

  const priceOptions =
    userPricelists?.map((cpl) => ({
      value: `${cpl!.pricelist}|${cpl!.companyCode}|${cpl!.currency}`,
      label: `${cpl!.pricelist}, ${cpl!.currency}`,
    })) || [];

  return (
    <>
      <Select
        key={'typeSelect' + queryVariables.companyCode}
        label="Type"
        name="Type"
        innerLabel
        compact
        color="transparent"
        value={{
          value: queryVariables.sheetSettings?.rowLevel || 'model',
          label: rowLevels[queryVariables.sheetSettings?.rowLevel || 'model'],
        }}
        options={Object.keys(rowLevels).map((k) => ({ value: k, label: rowLevels[k] }))}
        isOptionDisabled={(o) => o.value === 'size' && !queryVariables.companyCode}
        onChange={(value) => handleSettingsChanged({ rowLevel: (value as OptionValue).value })}
      ></Select>
      <Select
        label="Images"
        name="Images"
        color="transparent"
        compact
        innerLabel
        value={{
          value: queryVariables.sheetSettings?.imageLevel || 'mainOnly',
          label: imageLevels[queryVariables.sheetSettings?.imageLevel || 'mainOnly'],
        }}
        options={Object.keys(imageLevels).map((k) => ({ value: k, label: imageLevels[k] }))}
        onChange={(value) => handleSettingsChanged({ imageLevel: (value as OptionValue).value })}
      ></Select>
      <Select
        name="Languages"
        closeMenuOnSelect={false}
        label="Languages"
        isMulti={true}
        color="transparent"
        compact
        innerLabel
        value={languageValue}
        options={languageValue && languageValue.length >= maxLanguageOptions ? [] : languageOptions}
        noOptionsMessage={() => {
          return languageValue && languageValue.length === maxLanguageOptions
            ? `Max ${maxLanguageOptions} languages per file.`
            : 'No options available';
        }}
        onChange={(value: ValueType<OptionTypeBase>) => {
          const selectedOptions = (value && value.map(({ value }: { value: string }) => value)) || [];
          handleSettingsChanged({ languages: selectedOptions });
        }}
      ></Select>
      {userPricelists && userPricelists.length > 1 && (
        <Select
          label="Prices"
          name="Prices"
          isMulti={true}
          innerLabel
          color="transparent"
          compact
          selectAll
          closeMenuOnSelect={false}
          options={priceOptions}
          value={queryVariables.sheetSettings?.pricelists?.reduce((acc, pl) => {
            if (priceOptions.some((po) => po.value === `${pl!.pricelist}|${pl!.companyCode}|${pl!.currency}`)) {
              return [
                ...acc,
                {
                  value: `${pl!.pricelist}|${pl!.companyCode}|${pl!.currency}`,
                  label: `${pl!.pricelist}, ${pl!.currency}`,
                },
              ];
            }
            return acc;
          }, new Array<OptionValue>())}
          onChange={(value: ValueType<OptionTypeBase>) => {
            const selectedOptions = value?.map(({ value }: { value: string }) => {
              const splitValue = value.split('|');
              return {
                pricelist: splitValue[0],
                companyCode: splitValue[1],
                currency: splitValue[2],
              };
            });
            handleSettingsChanged({ pricelists: selectedOptions });
          }}
        ></Select>
      )}
      <Select
        label="Units"
        name="Units"
        isMulti={true}
        innerLabel
        color="transparent"
        compact
        closeMenuOnSelect={false}
        value={unitValue}
        options={unitOptions}
        onChange={(value: ValueType<OptionTypeBase>) => {
          const selectedOptions = (value && value.map(({ value }: { value: string }) => value)) || [];
          handleSettingsChanged({ units: selectedOptions });
        }}
      ></Select>
      <Select
        label="Columns"
        name="Columns"
        isMulti={true}
        innerLabel
        color="transparent"
        compact
        selectAll
        closeMenuOnSelect={false}
        value={queryVariables.sheetSettings?.selectedColumns
          ?.filter((col): col is string => !!col)
          .map((col) => ({
            value: col,
            label: allColumnChoices[col]
              ? allColumnChoices[col] + unitSuffix(col, queryVariables.sheetSettings?.units)
              : `${col}: Unavailable`,
          }))}
        options={Object.keys(allColumnChoices).map((col) => ({
          value: col,
          label: allColumnChoices[col] + unitSuffix(col, queryVariables.sheetSettings?.units),
        }))}
        onChange={(value: ValueType<OptionTypeBase>) => {
          const selectedOptions = (value && value.map(({ value }: { value: string }) => value)) || [];
          handleSettingsChanged({ selectedColumns: selectedOptions });
        }}
      ></Select>
    </>
  );
};

export default ExportSettings;

function unitSuffix(col: string, units?: Maybe<Maybe<string>[]>) {
  if (units?.find((u) => u === 'oz' || u === 'cl' || u === 'l' || u === 'm3') && volumeUnitFields.includes(col))
    return '';
  if (units?.find((u) => u === 'g' || u === 'kg' || u === 'lb') && weightUnitFields.includes(col)) return '';
  if (
    units?.find((u) => u === 'mm' || u === 'ft' || u === 'in' || u === 'cm') &&
    (lengthUnitFields.includes(col) || dimensionsUnitFields.includes(col))
  )
    return '';

  if (volumeUnitFieldsBaseCl.includes(col)) return ' (cl)';
  if (volumeUnitFieldsBaseL.includes(col)) return ' (l)';
  if (volumeUnitFieldsBaseM3.includes(col)) return ' (m3)';
  if (lengthUnitFields.includes(col) || dimensionsUnitFields.includes(col)) return ' (mm)';
  if (weightUnitFields.includes(col)) return ' (kg)';

  return '';
}
