import React, { useState } from 'react';
import { View, InputForm, InputField, Button } from 'ui';
import { useCurrentUserQuery, useUpdateCurrentUserMutation, CurrentUserQuery } from 'generated/graphql';
import { GET_CURRENT_USER } from 'graphql/queries';
import { useApolloClient } from '@apollo/react-hooks';

const UserSettings = () => {
  const client = useApolloClient();
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [meUpdate] = useUpdateCurrentUserMutation();
  const { data, loading, error } = useCurrentUserQuery({
    onCompleted: ({ me }) => {
      setInputs({
        username: me!.username,
        email: me!.email,
      });
    },
  });
  const [inputs, setInputs] = useState<IUserInputData>({
    username: '',
    email: '',
    password: '',
  });

  const submitData = (inputs: IUserInputData) => {
    meUpdate({
      variables: {
        username: inputs.username,
        email: inputs.email,
        password: inputs.password,
      },
      update(cache, { data }) {
        if (!data) return null;

        const newUser = data.meUpdate;

        cache.writeQuery<CurrentUserQuery>({
          query: GET_CURRENT_USER,
          data: { me: newUser },
        });
      },
    }).then(data => {
      client.writeData({ data: { username: data!.data!.meUpdate!.username } });
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));

    setIsEditing(true);
  };

  return (
    <View title="User settings" iconClass="fas fa-user-cog">
      {loading && <div>Loading...</div>}
      {error && <div>Error!</div>}

      {data && data.me && (
        <InputForm
          onSubmit={e => {
            e.preventDefault();

            submitData(inputs);

            setIsUpdated(true);
            setIsEditing(false);
          }}
          loading={loading}
          error={error}
        >
          <InputField
            name="username"
            placeholder="Username"
            value={(inputs && inputs.username) || ''}
            onChange={handleInputChange}
            className="form-control"
            showLabel
          ></InputField>
          <InputField
            name="email"
            placeholder="E-mail"
            value={(inputs && inputs.email) || ''}
            onChange={handleInputChange}
            className="form-control"
            showLabel
          ></InputField>
          <InputField
            name="password"
            placeholder="Password"
            value={(inputs && inputs.password) || ''}
            onChange={handleInputChange}
            className="form-control"
            showLabel
          ></InputField>
          <Button type="submit" size={35} color="green" disabled={!isEditing} className="float-right my-2">
            {isUpdated && !isEditing && !error ? (
              <div className="animated fadeIn">
                <i className="fas fa-check"></i> Settings saved
              </div>
            ) : (
              <div className="animated fadeIn">Save</div>
            )}
          </Button>
        </InputForm>
      )}
    </View>
  );
};

export default UserSettings;
