import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './SplitView.module.scss';

type ActionViewHeader = {
  title: string;
  iconClassName?: string;
};

type ActionView = {
  header?: ActionViewHeader;
  body: React.ReactNode;
};

type Props = {
  children: {
    actionPanel: React.ReactNode;
    actionView: ActionView;
  };
};

const SplitView: React.FC<Props> = ({ children }) => {
  return (
    <div styleName="splitview">
      <div id="scrollable" styleName="actionpanel">
        {children.actionPanel ? children.actionPanel : null}
      </div>
      <div styleName="actionview">
        {children.actionView.header && (
          <div styleName="actionview-header">
            {children.actionView.header.iconClassName && (
              <div styleName="actionview-title-icon">
                <i className={children.actionView.header.iconClassName}></i>
              </div>
            )}
            <div>
              <div styleName="actionview-title">{children.actionView.header.title}</div>
            </div>
          </div>
        )}
        <div styleName="actionview-body">{children.actionView.body ? children.actionView.body : null}</div>
      </div>
    </div>
  );
};

export default CSSModules(SplitView, styles);
