import gql from 'graphql-tag';

export const GET_CURRENT_EXPORT_CONFIGURATION = gql`
  query CurrentExportConfiguration {
    exportConfigurationCurrent {
      _id
      queryVariables {
        assortmentId
        companyCode
        language
        filters {
          filter
          values
        }
        sheetSettings {
          imageLevel
          rowLevel
          languages
          selectedColumns
          pricelists {
            pricelist
            currency
            companyCode
          }
          units
        }
      }
    }
  }
`;
