import React from 'react';
import { useCompaniesQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { Select } from 'ui';
import Skeleton from 'react-loading-skeleton';

type Props = {
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionCompanyOption: React.FC<Props> = ({ setValue, register }) => {
  const { data, loading, error } = useCompaniesQuery();

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading companies: <br />
        {error.message}
      </div>
    );
  }

  // Set available options by user's granted company access
  const options = data?.companies?.map(company => ({
    value: company?.companyCode!,
    label: company?.name!,
  }));

  /**
   * Set companyCode by selected option
   * @param option
   */
  const handleChange = (option: ValueType<OptionTypeBase>) => {
    if (!option) {
      setValue('companyCode', null, true);
    } else {
      const companyCode = (option as OptionTypeBase).value;
      setValue('companyCode', companyCode, true);
    }
  };

  /**
   * Manually register fields in react-hook-form
   * Fields are also required and will need a field validation trigger in setValue()
   * E.g setValue('name', value, true) where 'true' triggers a field validation
   */
  register({ name: 'companyCode' }, { required: true });

  return (
    <Select
      name="companies"
      placeholder="Company"
      label="Company"
      onChange={value => handleChange(value)}
      options={options}
      className="mb-3"
    />
  );
};

export default FormSectionCompanyOption;
