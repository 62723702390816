import React from 'react';
import { DropdownMenu, IconWrapper, Table } from 'ui';
import { useApplicationsByUserIdQuery, useRemoveApplicationMutation, Application } from 'generated/graphql';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

const ApplicationList = ({ userId }: { userId?: string }) => {
  const { data, loading, error } = useApplicationsByUserIdQuery({ variables: { userId: userId } });
  const [applicationRemoveById] = useRemoveApplicationMutation();

  const removeApplication = (id: string) => {
    applicationRemoveById({
      variables: {
        id: id,
      },
    });
  };

  const copyToClipBoard = (string: string) => {
    navigator.clipboard.writeText(string).then(() => {
      toast.success(`Token copied to clipboard!`);
    });
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Company',
      accessor: 'companyCode',
    },
    {
      Header: 'Commerce app',
      accessor: 'commerceApp.name',
    },
    {
      Header: 'Commerce user',
      accessor: 'commerceAppUser.email',
    },
    {
      Header: 'Active',
      accessor: 'active',
    },
    {
      Header: 'Expiration date',
      accessor: 'tokenExp',
      dateFormat: true,
    },
    {
      Action: (cell: Application) => (
        <DropdownMenu>
          {{
            trigger: (
              <>
                <IconWrapper iconClass="fas fa-ellipsis-h" className="mr-1" />
              </>
            ),
            items: [
              {
                title: 'Copy Token',
                action: () => copyToClipBoard(cell?.token!),
              },
              {
                title: 'Remove',
                action: () => removeApplication(cell?._id),
              },
            ],
          }}
        </DropdownMenu>
      ),
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {loading ? (
        <React.Fragment>
          <div className="mb-2">
            <Skeleton height={35} />
          </div>
          <Skeleton count={5} height={35} />
        </React.Fragment>
      ) : data?.applicationsByUserId?.length === 0 ? (
        <div>No application keys found.</div>
      ) : (
        <Table data={data?.applicationsByUserId?.filter(app => app?.deletedAt === null)} columns={columns} />
      )}
    </div>
  );
};

export default ApplicationList;
