import React from 'react';
import { UserForm } from 'app/components';
import {
  useUserByIdQuery,
  useUpdateUserByIdMutation,
  UpdateUserByIdMutationVariables,
  PricelistInput,
  Maybe,
} from 'generated/graphql';
import { GET_APPLICATIONS, GET_USERS, GET_USER_BY_ID, GET_CURRENT_USER } from 'graphql/queries';
import moment from 'moment';

export type ArrayFixUpdateUserByIdMutationVariables = Omit<UpdateUserByIdMutationVariables, 'pricelists'> & {
  pricelists: Maybe<Array<Maybe<PricelistInput>>>;
};

const EditUser = ({ userId }: { userId: string }) => {
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    networkStatus,
  } = useUserByIdQuery({
    variables: { id: userId },
    fetchPolicy: 'network-only',
  });
  const [userUpdateById, { data, loading, error }] = useUpdateUserByIdMutation();

  const submitData = (inputs: ArrayFixUpdateUserByIdMutationVariables) => {
    userUpdateById({
      variables: {
        id: userData?.userById?._id,
        username: inputs.username,
        email: inputs.email,
        password: inputs.password,
        companyIds: inputs.companyIds,
        serviceContainerIds: inputs.serviceContainerIds,
        commerceUserLinkId: inputs.commerceUserLinkId,
        isAdmin: inputs.isAdmin,
        confirmed: inputs.confirmed,
        active: inputs.active,
        pricelists: inputs.pricelists?.reduce(
          (acc, pl) =>
            pl && pl.pricelist && pl.companyCode
              ? [...acc, { pricelist: pl?.pricelist, companyCode: pl?.companyCode, currency: pl?.currency }]
              : acc,
          new Array<PricelistInput>(),
        ),
      },
      refetchQueries: [
        { query: GET_APPLICATIONS },
        { query: GET_USERS },
        { query: GET_USER_BY_ID, variables: { id: userId } },
        { query: GET_CURRENT_USER },
      ],
    });
  };

  if (userError) return <div>{userError.message}</div>;
  if (userLoading) return <div>Loading...</div>;
  if (networkStatus === 4) return <div>Refetching...</div>;

  return (
    <>
      <UserForm
        defaultInputValues={{
          id: userData?.userById?._id,
          username: userData?.userById?.username || '',
          email: userData?.userById?.email || '',
          password: null,
          companyIds: userData?.userById?.companyIds || null,
          serviceContainerIds: userData?.userById?.serviceContainerIds || null,
          commerceUserLinkId: userData?.userById?.commerceUserLinkId || null,
          isAdmin: userData?.userById?.isAdmin || null,
          confirmed: userData?.userById?.confirmed || null,
          active: userData?.userById?.active || null,
          pricelists:
            userData?.userById?.pricelists?.reduce(
              (acc, curr) =>
                curr?.pricelist && curr.currency && curr.companyCode
                  ? [...acc, { pricelist: curr.pricelist!, currency: curr.currency!, companyCode: curr.companyCode! }]
                  : acc,
              new Array<PricelistInput>(),
            ) || null,
        }}
        submitData={submitData}
        error={error!}
        isSuccess={!!data}
        isLoading={!!loading}
      />
      <div className="border-top mt-3 pt-3">
        <div>
          Registered at:{' '}
          {userData?.userById?.registeredAt ? moment(userData?.userById?.registeredAt).format('LLL') : '-'}
        </div>
        <div>
          Updated at: {userData?.userById?.updatedAt ? moment(userData?.userById?.updatedAt).format('LLL') : '-'}
        </div>
        <div>
          Last login at: {userData?.userById?.lastLogin ? moment(userData?.userById?.lastLogin).format('LLL') : '-'}
        </div>
      </div>
    </>
  );
};

export default EditUser;
