import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, DropdownMenu, IconWrapper, Table } from 'ui';
import { useCompaniesQuery, CompaniesQuery, useRemoveCompanyMutation, Company } from 'generated/graphql';
import { GET_COMPANIES, GET_USERS } from 'graphql/queries';

const CompanyTable = () => {
  const { data, error, loading } = useCompaniesQuery();
  const [removeCompany] = useRemoveCompanyMutation();

  const remove = (companyId: string) => {
    // TODO: Add extra step to confirm action, e.g "Really remove?"
    removeCompany({
      variables: {
        id: companyId,
      },
      update(cache) {
        const existingCompanies = cache.readQuery<CompaniesQuery>({
          query: GET_COMPANIES,
        });
        const newCompanies = existingCompanies!.companies
          ? existingCompanies!.companies.filter(t => t!._id !== companyId)
          : [];
        cache.writeQuery<CompaniesQuery>({
          query: GET_COMPANIES,
          data: { companies: newCompanies },
        });
      },
      refetchQueries: [{ query: GET_USERS }],
    });
  };

  if (loading) {
    return (
      <Card title="Companies" className="mb-3">
        <div className="mb-2">
          <Skeleton height={35} />
        </div>
        <Skeleton count={5} height={35} />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <Card title="Companies" className="mb-3">
        <div className="text-error">Error when fetching companies.</div>
      </Card>
    );
  }

  const columns = [
    {
      Header: 'Company code',
      accessor: 'companyCode',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Id',
      accessor: '_id',
    },
    {
      Action: (cell: Company) => (
        <DropdownMenu>
          {{
            trigger: (
              <>
                <IconWrapper iconClass="fas fa-ellipsis-h" className="mr-1" />
              </>
            ),
            items: [
              {
                title: 'Remove',
                action: () => remove(cell?._id),
              },
            ],
          }}
        </DropdownMenu>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Card title="Companies" className="mb-3">
        <Table data={data.companies} columns={columns} />
      </Card>
    </React.Fragment>
  );
};

export default CompanyTable;
