import gql from 'graphql-tag';
import { GET_ASIDE_MENU } from './queries/GetAsideMenu';

export const typeDefs = gql`
  type AsideMenu {
    active: Boolean
  }

  extend type Query {
    isLoggedIn: Boolean!
    isAdmin: Boolean
    userId: String
    username: String
    asideMenu: AsideMenu
    dataLanguage: String
  }

  extend type Mutation {
    toggleAsideMenu(active: Boolean!): AsideMenu
  }
`;

export const resolvers = {
  Mutation: {
    toggleAsideMenu: (_: any, { active }: any, { cache }: any) => {
      const { asideMenu } = cache.readQuery({ query: GET_ASIDE_MENU });
      const newAsideMenu = { ...asideMenu, active: active };
      cache.writeData({ data: { asideMenu: newAsideMenu } });

      return newAsideMenu;
    },
  },
};
