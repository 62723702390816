import React from 'react';
import { CommerceUserLinkTable } from 'app/components';
import { View } from 'ui';

const Commerce = () => {
  return (
    <View
      title="Commerce restrictions"
      subTitle={'Add or remove commerce user links used for filtered user access.'}
      iconClass="fas fa-link"
    >
      <CommerceUserLinkTable />
    </View>
  );
};

export default Commerce;
