import gql from 'graphql-tag';

export const GET_COMMERCE_USER_LINKS = gql`
  query CommerceUserLinks {
    commerceUserLinks {
      _id
      name
      users {
        id
        email
      }
      contexts {
        contextId
        companyCode
        name
      }
    }
  }
`;
