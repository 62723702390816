import React from 'react';
import { Button } from 'ui';

type Props = {
  id: string;
  text: string;
  actionTitle: string;
  onAction: (id: string) => void;
};

const ToastContentAction: React.FC<Props> = ({ id, text, actionTitle, onAction }) => {
  function handleClick() {
    onAction(id);
  }

  return (
    <div className="p-1 d-flex justify-content-between align-items-center">
      <div className="text-align-left">{text}</div>
      <Button onClick={handleClick} size={30} color="default">
        {actionTitle}
      </Button>
    </div>
  );
};

export default ToastContentAction;
