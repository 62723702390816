import React from 'react';
import { ApolloError } from 'apollo-boost';
import { useForm } from 'react-hook-form';
import { InputForm, StateButton } from 'ui';
import { Article } from 'generated/graphql';
import { InputField, Checkbox } from 'ui';
import ArticleBodySection from './ArticleBodySection';

type ArticleFormProps = {
  defaultInputValues?: Article;
  submitData: any; // ! Todo - set type
  isLoading?: boolean;
  error: ApolloError;
  isSuccess?: boolean;
};

const ArticleForm: React.FC<ArticleFormProps> = ({ defaultInputValues, submitData, isLoading, error, isSuccess }) => {
  const { register, setValue, handleSubmit, errors, formState } = useForm<Article>({
    defaultValues: {
      ...defaultInputValues,
    },
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((data: Article) => {
    submitData(data);
  });

  return (
    <React.Fragment>
      <InputForm onSubmit={onSubmit}>
        <InputField
          name="headline"
          placeholder="Headline"
          showLabel
          defaultValue={defaultInputValues?.headline || ''}
          ref={register({ required: true })}
          error={errors?.headline && 'Headline required'}
        ></InputField>
        <InputField
          name="description"
          placeholder="Description"
          showLabel
          defaultValue={defaultInputValues?.description || ''}
          ref={register({ required: true })}
          error={errors?.description && 'Description required'}
        ></InputField>

        <ArticleBodySection
          register={register}
          setValue={setValue}
          defaultInput={defaultInputValues?.body || undefined}
        />

        <InputField
          name="author"
          placeholder="Author"
          showLabel
          defaultValue={defaultInputValues?.author || ''}
          ref={register({ required: true })}
          error={errors?.author && 'Author required'}
        ></InputField>

        <Checkbox
          name="published"
          label="Published"
          defaultChecked={defaultInputValues?.published!}
          ref={register}
        ></Checkbox>

        <StateButton
          type="submit"
          size={35}
          color="green"
          disabled={!formState.isValid}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={!!error}
        >
          Save
        </StateButton>
      </InputForm>
    </React.Fragment>
  );
};

export default ArticleForm;
