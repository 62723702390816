import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  useServiceContainersQuery,
  useRemoveServiceContainerMutation,
  ServiceContainersQuery,
  ServiceContainer,
} from 'generated/graphql';
import { GET_SERVICECONTAINERS } from 'graphql/queries';
import { Card, DropdownMenu, IconWrapper, Table } from 'ui';

const ServiceContainerTable = () => {
  const { data, loading, error } = useServiceContainersQuery();
  const [removeServiceContainer] = useRemoveServiceContainerMutation();

  const remove = (serviceContainerId: String) => {
    removeServiceContainer({
      variables: {
        id: serviceContainerId,
      },
      update(cache, { data }) {
        if (!data) {
          return null;
        }

        const existingServiceContainers = cache.readQuery<ServiceContainersQuery>({
          query: GET_SERVICECONTAINERS,
        });
        const newServiceContainers = existingServiceContainers!.serviceContainers
          ? existingServiceContainers!.serviceContainers.filter(t => t!._id !== serviceContainerId)
          : [];

        cache.writeQuery<ServiceContainersQuery>({
          query: GET_SERVICECONTAINERS,
          data: { serviceContainers: newServiceContainers },
        });
      },
    });
  };

  if (loading) {
    return (
      <Card title="Services" className="mb-3">
        <div className="mb-2">
          <Skeleton height={35} />
        </div>
        <Skeleton count={5} height={35} />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <Card title="Services" className="mb-3">
        <div className="text-error">Error fetching services.</div>
      </Card>
    );
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Id',
      accessor: '_id',
    },
    {
      Action: (cell: ServiceContainer) => (
        <DropdownMenu>
          {{
            trigger: (
              <>
                <IconWrapper iconClass="fas fa-ellipsis-h" className="mr-1" />
              </>
            ),
            items: [
              {
                title: 'Remove',
                action: () => remove(cell?._id),
              },
            ],
          }}
        </DropdownMenu>
      ),
    },
  ];

  return (
    <Card title="Services" className="mb-3">
      <Table data={data.serviceContainers} columns={columns} />
    </Card>
  );
};

export default ServiceContainerTable;
