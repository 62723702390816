import React, { useState } from 'react';
import { View, IconWrapper, Modal } from 'ui';
import { useHistory } from 'react-router-dom';
import { EditArticle } from 'app/components';
import { useArticleQuery, useUserDataQuery, useRemoveArticleMutation } from 'generated/graphql';
import { useModal } from 'app/hooks';
import moment from 'moment';
import CSSModules from 'react-css-modules';
import styles from './Article.module.scss';

const Article: React.FC<HistoryProps> = ({ match }) => {
  const { push } = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const { modalOpen, setModalOpen } = useModal(false);
  const { data: userData, loading: userDataLoading, error: userDataError } = useUserDataQuery();
  const { data, loading, error } = useArticleQuery({
    variables: {
      id: match.params.id,
    },
  });
  const [removeArticle] = useRemoveArticleMutation();

  const remove = () => {
    removeArticle({
      variables: {
        id: match.params.id,
      },
    }).then(() => {
      setModalOpen(false);
      push('/docs');
    });
  };

  if (loading || userDataLoading) return <div>Loading...</div>;

  if (error || userDataError)
    return (
      <div>
        Error: {error?.message} / {userDataError?.message}
      </div>
    );

  return (
    <>
      <View sleek={true}>
        <div className="py-5 px-3 px-xl-5 bg-white vh-100">
          <div className="col-12 col-xl-8 mx-auto">
            {userData?.isAdmin && (
              <div className="text-right pb-3">
                <div onClick={() => setIsEditing(!isEditing)} className="c-pointer d-inline-block mr-3">
                  <IconWrapper iconClass={isEditing ? 'far fa-times-circle' : 'far fa-edit'} />{' '}
                  {isEditing ? <>Exit editing</> : <>Edit article</>}
                </div>

                <div onClick={() => setModalOpen(true)} className="c-pointer d-inline-block">
                  <IconWrapper color="red" iconClass="far fa-trash-alt" /> Remove article
                </div>
              </div>
            )}
            {userData?.isAdmin && isEditing && (
              <div className="animated fadeIn mb-2">
                <EditArticle articleId={data?.article?._id} />
              </div>
            )}
            <div className={`${isEditing && 'animated slideInDown'}`}>
              <div styleName="article">
                <h1>{data?.article?.headline}</h1>
                <div className="mb-4" styleName="author">
                  <div>
                    <IconWrapper iconClass="far fa-user" className="mr-1" />
                  </div>
                  <div styleName="details">
                    {data?.article?.author}
                    <div styleName="date">
                      {data?.article?.published && data.article.publishedAt
                        ? moment(data?.article?.publishedAt).format('ll')
                        : ' - Not published.'}
                      {data?.article?.updatedAt && ` (Updated at ${moment(data.article.updatedAt).format('ll')})`}
                    </div>
                  </div>
                </div>
                <div styleName="content">
                  <p styleName="description">{data?.article?.description}</p>
                  <div dangerouslySetInnerHTML={{ __html: `${data?.article?.body}` }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </View>
      {modalOpen && (
        <Modal title="Really remove?" onCloseModal={() => setModalOpen(false)} onSubmit={() => remove()}>
          <p>
            <strong>Article: {data?.article?.headline}</strong>
          </p>
          Are you sure you want to remove this article? This action cannot be undone.
        </Modal>
      )}
    </>
  );
};

export default CSSModules(Article, styles);
