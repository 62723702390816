import React, { useState } from 'react';
import { GET_APPLICATIONS, SEARCH_USERS } from 'graphql/queries';
import { useRemoveUserMutation, useInviteUserMutation, useUserInviteLinkQuery, User } from 'generated/graphql';
import { IconWrapper, InputField, Card, SplitView, Button, StateButton, Modal } from 'ui';
import { EditUser, InviteUser, CreateUser, ApplicationList } from 'app/components';
import { useModal } from 'app/hooks';
import UserSearch from './UserSearch';
import { toast } from 'react-toastify';

const UserManager = () => {
  const { modalOpen, setModalOpen } = useModal(false);
  const [createUser, setCreateUser] = useState<boolean>();
  const [searchInput, setSearchInput] = useState<string | undefined>('');
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [userInvite, { data: inviteData, error: inviteError, loading: inviteLoading }] = useInviteUserMutation();
  const [removeUser] = useRemoveUserMutation();
  const { data: inviteLinkData, error: inviteLinkError, loading: inviteLinkLoading } = useUserInviteLinkQuery({
    variables: {
      userId: selectedUser?._id,
    },
    skip: !selectedUser,
    fetchPolicy: 'network-only',
  });

  const remove = (userId: string) => {
    removeUser({
      variables: {
        id: userId,
      },
      refetchQueries: [{ query: GET_APPLICATIONS }, { query: SEARCH_USERS, variables: { page: 1, perPage: 15 } }],
    }).then((res) => {
      setModalOpen(false);
    });
  };

  const sendConfirmation = () => {
    userInvite({
      variables: {
        email: selectedUser?.email!,
      },
    });
  };

  const copyToClipBoard = (string: string) => {
    navigator.clipboard.writeText(string).then(() => {
      toast.success(`Link copied to clipboard!`);
    });
  };

  return (
    <>
      {modalOpen && (
        <Modal
          title="Really remove?"
          onCloseModal={() => setModalOpen(false)}
          onSubmit={() => remove(selectedUser && selectedUser._id)}
        >
          <p>
            <strong>User: {selectedUser && selectedUser.username}</strong>
          </p>
          Removing this user will also deactivate all Application keys connected to the user. This action cannot be
          undone.
        </Modal>
      )}
      <SplitView>
        {{
          actionPanel: (
            <>
              <div className="mb-2 d-flex">
                <div
                  className="c-pointer"
                  onClick={() => {
                    setCreateUser(true);
                    setSelectedUser(null);
                  }}
                >
                  <IconWrapper iconClass="fas fa-plus" color="green" className="mr-1" /> New user
                </div>
                <div className="ml-auto">
                  <IconWrapper iconClass="fas fa-sort-alpha-down" color="transparent" />
                </div>
              </div>
              <InputField
                name="search"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="form-control my-3"
              ></InputField>
              <UserSearch
                keyword={searchInput}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser && selectedUser._id}
              />
            </>
          ),
          actionView: {
            header: {
              title: selectedUser?.username
                ? selectedUser.username
                : !!createUser && !selectedUser
                ? 'Create new user'
                : 'User manager',
              iconClassName: selectedUser
                ? 'fas fa-user-cog'
                : !!createUser && !selectedUser
                ? 'fas fa-user-plus'
                : 'fas fa-users',
            },
            body: (
              <>
                {!!selectedUser && (
                  <>
                    <StateButton
                      size={35}
                      color="primary"
                      className="mr-2"
                      isLoading={inviteLoading}
                      isError={!!inviteError}
                      isSuccess={!!inviteData}
                      onClick={() => sendConfirmation()}
                    >
                      <i className="far fa-paper-plane mr-1" />
                      Send confirmation e-mail
                    </StateButton>
                    <StateButton
                      size={35}
                      color="primary"
                      className="mr-2"
                      isLoading={inviteLinkLoading}
                      isError={!!inviteLinkError}
                      onClick={() => {
                        inviteLinkData?.userInviteLink && copyToClipBoard(inviteLinkData.userInviteLink);
                      }}
                    >
                      <i className="far fa-clone mr-1" />
                      Copy confirm link
                    </StateButton>
                    <Button size={35} color="red" className="mb-2" onClick={() => setModalOpen(true)}>
                      <i className="far fa-trash-alt mr-1" />
                      Delete user
                    </Button>
                    <Card title="User details" className="mb-2">
                      <EditUser userId={selectedUser?._id} />
                    </Card>
                    <Card title="Applications">
                      <ApplicationList userId={selectedUser?._id} />
                    </Card>
                  </>
                )}
                {!!createUser && !selectedUser && (
                  <>
                    <InviteUser />
                    <CreateUser />
                  </>
                )}
              </>
            ),
          },
        }}
      </SplitView>
    </>
  );
};

export default UserManager;
