import React from 'react';
import { Select } from 'ui';
import Skeleton from 'react-loading-skeleton';
import { UpdateUserByIdMutationVariables, useCompaniesQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';

type Props = {
  defaultValues: Partial<UpdateUserByIdMutationVariables>;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionCompanyAccess: React.FC<Props> = ({ defaultValues, setValue, register }) => {
  const { data, loading, error } = useCompaniesQuery();

  if (loading) {
    return (
      <div className="mb-1">
        <Skeleton height={35} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mb-1 p-2 bg-gray text-error">
        Error loading companies: <br />
        {error.message}
      </div>
    );
  }

  const options = data?.companies?.map((company) => ({
    value: company?._id!,
    label: company?.name!,
  }));

  const handleChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setValue('companyIds', [], false);
    } else {
      const ids = values.map((val: OptionTypeBase) => val.value);
      return setValue('companyIds', ids, false);
    }
  };

  register({ name: 'companyIds' });

  return (
    <>
      <Select
        isMulti
        label="Companies"
        name="companyIds"
        defaultValue={options?.filter((option) => defaultValues?.companyIds?.includes(option.value))}
        onChange={(value) => handleChange(value)}
        options={options}
      />
    </>
  );
};

export default FormSectionCompanyAccess;
