import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
  defaultInput?: string;
};

const ArticleBodySection: React.FC<Props> = ({ register, setValue, defaultInput }) => {
  register({ name: 'body' }, { required: true });

  const onChange = (text: any) => {
    if (!text) {
      setValue('body', null, true);
    } else {
      setValue('body', text, true);
    }
  };

  return (
    <div className="mb-5">
      <ReactQuill theme="snow" onChange={onChange} defaultValue={defaultInput} />
    </div>
  );
};

export default ArticleBodySection;
