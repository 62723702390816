import gql from 'graphql-tag';

export const GET_COMMERCE_APPS_BY_COMPANY = gql`
  query CommerceAppsByCompany($companyCode: String!) {
    commerceAppsByCompanyCode(companyCode: $companyCode) {
      id
      name
    }
  }
`;
