import React, { useEffect } from 'react';
import { ApolloError } from 'apollo-boost';
import { InputForm, StateButton } from 'ui';
import { useForm } from 'react-hook-form';
import { MutationApplicationCreateArgs, useCurrentUserQuery, CommerceUserLink } from 'generated/graphql';

import FormSectionUserApplicationDetails from './FormSectionApplicationDetails';
import FormSectionCompanyOption from './FormSectionCompanyOption';
import FormSectionCommerceAppOption from './FormSectionCommerceAppOption';
import FormSectionCommerceAccountOption from './FormSectionCommerceAccountOption';
import FormSectionPricelistsOption, { PricelistOption } from './FormSectionPricelistsOption';

type UserApplicationFormProps = {
  submit: any;
  isLoading?: boolean;
  isSuccess?: boolean;
  error: ApolloError;
};

const UserApplicationForm: React.FC<UserApplicationFormProps> = ({ submit, isLoading, isSuccess, error }) => {
  const { register, unregister, setValue, handleSubmit, errors, formState, getValues, watch, triggerValidation } =
    useForm<MutationApplicationCreateArgs>({
      mode: 'onChange',
    });

  const { data: userData, loading: userLoading, error: userError } = useCurrentUserQuery();

  /**
   * Watch companyCode field changes
   * Used to render commerce account option if the user has access to the commerce service
   */
  const watchCompanyCode = watch('companyCode');
  const watchContextId = watch('commerceContextId');

  useEffect(() => {
    if (watchCompanyCode) triggerValidation();
    if (watchContextId) triggerValidation();
  }, [watchCompanyCode, watchContextId, triggerValidation]);

  // Submit field data to mutation component
  const onSubmit = handleSubmit((data: MutationApplicationCreateArgs) => {
    submit(data);
  });

  if (userLoading) {
    return <div>Loading user settings...</div>;
  }

  if (userError) {
    return <div>Error: {userError.message}</div>;
  }

  return (
    <InputForm className="my-2" onSubmit={onSubmit}>
      <FormSectionUserApplicationDetails register={register} errors={errors} />
      <FormSectionCompanyOption setValue={setValue} register={register} />
      {watchCompanyCode && (
        <FormSectionCommerceAppOption
          companyCode={getValues().companyCode}
          commerceUserLink={userData?.me?.commerceUserLink as CommerceUserLink}
          setValue={setValue}
          register={register}
          unregister={unregister}
        />
      )}
      {watchCompanyCode && watchContextId && (
        <FormSectionCommerceAccountOption
          companyCode={getValues().companyCode}
          contextId={getValues().commerceContextId}
          commerceUserLink={userData?.me?.commerceUserLink as CommerceUserLink}
          setValue={setValue}
          register={register}
        />
      )}
      {watchCompanyCode && userData?.me?.pricelists && (
        <FormSectionPricelistsOption
          companyCode={getValues().companyCode}
          pricelists={
            userData.me.pricelists.filter(
              (pl) => pl?.pricelist && pl.companyCode === getValues().companyCode,
            ) as PricelistOption[]
          }
          setValue={setValue}
          register={register}
        />
      )}

      <StateButton
        type="submit"
        size={35}
        color="green"
        disabled={!formState.isValid}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={!!error}
      >
        Generate key
      </StateButton>
    </InputForm>
  );
};

export default UserApplicationForm;
