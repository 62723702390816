import React, { useState, useEffect, useReducer } from 'react';
import CSSModule from 'react-css-modules';
import styles from './Products.module.scss';

import { View, SplitView, IconWrapper, DropdownMenu } from 'ui';
import {
  useCurrentExportConfigurationQuery,
  ExportConfigurationQueryVariablesSheetSettings,
  useGetExportConfigurationQuery,
  useDataLanguageQuery,
  Maybe,
  useUpdateCurrentExportConfigurationMutation,
  useCurrentUserQuery,
  UserPricelists,
} from 'generated/graphql';
import { ProductFilter } from 'app/components';

import ExportsSaved from 'app/components/ExportProductData/ExportsSaved';
import ExportSettings from 'app/components/ExportProductData/ExportSettings';
import ExportPreview from 'app/components/ExportProductData/ExportPreview';
import ProductList from './ProductList';
import { ExportType } from 'app/components/ExportProductData/exportConstants';
import ExportFile from 'app/components/ExportProductData/ExportFile';
import LanguageSelector from 'ui/LanguageSelector/LanguageSelector';
import { useApolloClient } from '@apollo/react-hooks';
import { FilterPayload } from 'app/components/ProductFilter/ProductFilter';
import Skeleton from 'react-loading-skeleton';

export type ProductState = FilterPayload & {
  page?: number;
  pageSize?: number;
  sheetSettings?: Maybe<ExportConfigurationQueryVariablesSheetSettings>;
  __typename?: string;
  userPricelists?: UserPricelists[];
  userPricelistsForCompany?: UserPricelists[];
};

const defaultQueryVariables = {
  assortmentId: '',
  companyCode: '',
  language: 'en',
  page: 1,
  pageSize: 8,
  keyword: '',
  filters: [],
};

function clearTypenames(payload: ProductState): ProductState {
  delete payload.__typename;
  delete payload.sheetSettings?.__typename;
  payload.filters?.forEach((f) => {
    if (f && '__typename' in f) delete f.__typename;
  });
  payload.sheetSettings?.pricelists?.forEach((p) => {
    if (p && '__typename' in p) delete p.__typename;
  });
  return payload;
}

function setPricelists(payload: ProductState, userPricelists?: UserPricelists[]): ProductState {
  if (!('companyCode' in payload)) return payload;

  const pricelistsForCompany = userPricelists?.filter((pl) => pl?.companyCode === payload.companyCode) || [];
  if (pricelistsForCompany.length === 1)
    payload.sheetSettings = { ...payload.sheetSettings, pricelists: pricelistsForCompany };

  const allowedPricelists = (payload.sheetSettings?.pricelists || []).reduce(
    (acc, curr) =>
      curr &&
      pricelistsForCompany.find(
        (plc) =>
          plc?.companyCode === curr?.companyCode &&
          plc?.currency === curr?.currency &&
          plc?.pricelist === curr?.pricelist,
      )
        ? [...acc, curr]
        : acc,
    new Array<UserPricelists>(),
  );

  payload.sheetSettings = { ...payload.sheetSettings, pricelists: allowedPricelists };

  return {
    ...payload,
    userPricelists,
    userPricelistsForCompany: pricelistsForCompany,
  };
}

export type QueryVariablesActions = {
  type: 'set' | 'setEmpty' | 'setFull' | 'setPricelists' | 'setUserPricelists';
  payload: ProductState;
  from: string;
};

function queryVariablesReducer(state: ProductState | null, action: QueryVariablesActions): ProductState | null {
  let payload = clearTypenames(action.payload);
  payload = setPricelists(payload, state?.userPricelists);

  switch (action.type) {
    case 'setFull':
      return payload;
    case 'set':
      return {
        ...defaultQueryVariables,
        ...state,
        ...payload,
        sheetSettings: {
          ...state?.sheetSettings,
          ...payload.sheetSettings,
        },
      };
    case 'setEmpty':
      return defaultQueryVariables;
    case 'setPricelists':
      return {
        ...defaultQueryVariables,
        ...state,
        sheetSettings: {
          ...state?.sheetSettings,
          pricelists: payload.sheetSettings?.pricelists,
        },
      };
    case 'setUserPricelists':
      return {
        ...state,
        userPricelists: action.payload.userPricelists,
      };
    default:
      return state;
  }
}

const Products = () => {
  const [productState, dispatchProductsState] = useReducer(queryVariablesReducer, null);
  const [loadAllType, setLoadAllType] = useState<ExportType | null>(null);
  const [openConfigId, setOpenConfigId] = useState<string | null>();

  const client = useApolloClient();
  const [updateCurrentExportConfiguration] = useUpdateCurrentExportConfigurationMutation();

  const { data: dataLanguage, loading: loadingLanguage } = useDataLanguageQuery();

  // * Prepare pricelists
  const { data: dataUser, loading: loadingUser } = useCurrentUserQuery();

  // * If specific config opened - get query variables from db.
  const { data: dataConfig, loading: loadingConfig } = useGetExportConfigurationQuery({
    variables: { id: openConfigId },
    skip: !openConfigId,
  });
  // * If first load or refresh page - get users last queryvariables from db.
  const { data: dataLastQ, loading: loadingLastQ } = useCurrentExportConfigurationQuery({
    fetchPolicy: 'no-cache',
    skip: !dataUser,
  });

  useEffect(() => {
    if (dataUser?.me?.pricelists) {
      const userPricelists = dataUser.me.pricelists?.reduce(
        (acc, pl) =>
          pl !== null ? [...acc, { pricelist: pl.pricelist, currency: pl.currency, companyCode: pl.companyCode }] : acc,
        new Array<UserPricelists>(),
      );

      dispatchProductsState({
        type: 'setUserPricelists',
        payload: {
          userPricelists: userPricelists,
        },
        from: 'me',
      });
    }
  }, [dataUser]);

  useEffect(() => {
    setOpenConfigId(null);

    if (dataConfig && dataConfig.exportConfigurationById) {
      if (dataConfig.exportConfigurationById.queryVariables) {
        dispatchProductsState({
          type: 'setFull',
          payload: {
            ...dataConfig.exportConfigurationById.queryVariables,
            assortmentId: dataConfig.exportConfigurationById.queryVariables.assortmentId || '',
            language: dataConfig.exportConfigurationById.queryVariables.language || '',
            companyCode: dataConfig.exportConfigurationById.queryVariables.companyCode || '',
          },
          from: 'openConfig',
        });
      }
    }
  }, [dataConfig, setOpenConfigId]);

  useEffect(() => {
    if (dataLastQ) {
      if (dataLastQ.exportConfigurationCurrent?.queryVariables) {
        const { __typename, ...lastQuery } = dataLastQ.exportConfigurationCurrent.queryVariables;
        delete lastQuery.sheetSettings?.__typename;

        dispatchProductsState({
          type: 'setFull',
          payload: {
            ...lastQuery,
            assortmentId: dataLastQ.exportConfigurationCurrent.queryVariables.assortmentId || '',
            language: dataLastQ.exportConfigurationCurrent.queryVariables.language || '',
            companyCode: dataLastQ.exportConfigurationCurrent.queryVariables.companyCode || '',
          },
          from: 'lastQuery',
        });
        client.writeData({ data: { dataLanguage: dataLastQ.exportConfigurationCurrent.queryVariables.language } });
      } else {
        dispatchProductsState({
          type: 'setEmpty',
          payload: {},
          from: 'lastQueryNone',
        });
      }
    }
  }, [dataLastQ, client]);

  useEffect(() => {
    if (dataLanguage?.dataLanguage && productState?.language !== dataLanguage.dataLanguage && dataLastQ) {
      dispatchProductsState({
        type: 'set',
        payload: { language: dataLanguage.dataLanguage },
        from: 'language',
      });
    }
  }, [dataLanguage, productState, dataLastQ]);

  useEffect(() => {
    if (!productState || !productState.assortmentId) return;

    const { keyword, page, pageSize, userPricelists, userPricelistsForCompany, language, ...variablesToSave } = {
      ...productState,
    };
    updateCurrentExportConfiguration({
      variables: {
        queryVariables: variablesToSave,
      },
    });
  }, [productState, updateCurrentExportConfiguration]);

  return (
    <View sleek>
      <SplitView>
        {{
          actionPanel: (
            <ActionPanel
              productState={productState}
              dispatchProductsState={dispatchProductsState}
              onOpenConfiguration={setOpenConfigId}
              onLoadAll={setLoadAllType}
              loading={loadingConfig || loadingUser || loadingLastQ || loadingLanguage}
            />
          ),
          actionView: {
            header: {
              title: 'Products',
              iconClassName: 'fas fa-tags',
            },
            body: (
              <>
                {productState && (
                  <>
                    {loadAllType && (
                      <ExportFile
                        type={loadAllType}
                        queryVariables={{ ...defaultQueryVariables, ...productState }}
                        sheetSettings={productState.sheetSettings}
                        onAllLoaded={() => setLoadAllType(null)}
                      />
                    )}
                    {productState.sheetSettings && (
                      <ExportPreview
                        queryVariables={{ ...defaultQueryVariables, ...productState }}
                        sheetSettings={productState.sheetSettings}
                      />
                    )}
                    <ProductList queryVariables={{ ...defaultQueryVariables, ...productState }} />
                  </>
                )}
              </>
            ),
          },
        }}
      </SplitView>
    </View>
  );
};

export default CSSModule(Products, styles);

type ActionPanelProps = {
  productState?: ProductState | null;
  dispatchProductsState: React.Dispatch<QueryVariablesActions>;
  onOpenConfiguration: (id: string) => void;
  onLoadAll: (type: ExportType) => void;
  loading: boolean;
};

const ProductsActionPanel: React.FC<ActionPanelProps> = ({
  productState,
  dispatchProductsState,
  onOpenConfiguration,
  onLoadAll,
  loading,
}) => {
  const [showSavedConfigurations, setShowSavedConfigurations] = useState(false);
  const [showConfigurationSettings, setShowConfigurationSettings] = useState(true);
  const [showFilter, setShowFilter] = useState(true);

  const exportMenuItems = [
    {
      title: 'Excel (.xlsb)',
      action: () => {
        onLoadAll(ExportType.Sheet);
      },
    },
    {
      title: 'JSON',
      action: () => {
        onLoadAll(ExportType.JSON);
      },
    },
    {
      title: 'XML',
      action: () => {
        onLoadAll(ExportType.XML);
      },
    },
  ];

  if (loading)
    return (
      <>
        <div className="mb-4">
          <Skeleton count={1} height={35} />
        </div>
        <Skeleton count={1} height={35} />
        <Skeleton count={2} height={250} />
      </>
    );

  return (
    <>
      <div className="mb-4 d-flex">
        <div className="pr-1 mr-3">
          <LanguageSelector />
        </div>
        <DropdownMenu closeOnSelect>
          {{
            trigger: (
              <>
                <IconWrapper iconClass="fas fa-table" color={'green '} className="mr-1" />
                Export
              </>
            ),
            items: exportMenuItems,
          }}
        </DropdownMenu>
      </div>
      {productState && (
        <>
          <div
            styleName="c-pointer"
            className="mb-1"
            onClick={() => setShowSavedConfigurations(!showSavedConfigurations)}
          >
            Save / Open
            <i
              styleName="action-icon"
              className={`fas fa-angle-${showSavedConfigurations ? 'up' : 'down'} float-right`}
            ></i>
          </div>
          {showSavedConfigurations && (
            <ExportsSaved
              onOpenConfiguration={(id) => {
                setShowSavedConfigurations(false);
                onOpenConfiguration(id);
              }}
              isActive={!!productState?.assortmentId}
            ></ExportsSaved>
          )}
          <div className="my-3">
            <div
              styleName="c-pointer"
              className="mb-1"
              onClick={() => setShowConfigurationSettings(!showConfigurationSettings)}
            >
              Configure export
              <i
                styleName="action-icon"
                className={`fas fa-angle-${showConfigurationSettings ? 'up' : 'down'} float-right`}
              ></i>
            </div>
            {showConfigurationSettings && (
              <ExportSettings
                dispatchQueryVariables={dispatchProductsState}
                queryVariables={productState}
                userPricelists={productState.userPricelistsForCompany}
              ></ExportSettings>
            )}
          </div>
          <div className="mb-3">
            <div styleName="c-pointer" className="mb-1" onClick={() => setShowFilter(!showFilter)}>
              Filter
              <i styleName="action-icon" className={`fas fa-angle-${showFilter ? 'up' : 'down'} float-right`}></i>
            </div>
            {showFilter && (
              <ProductFilter
                productState={productState}
                dispatchProductsState={dispatchProductsState}
                forceAssortment={true}
              ></ProductFilter>
            )}
          </div>
        </>
      )}
    </>
  );
};
const ActionPanel = CSSModule(ProductsActionPanel, styles);
