import {
  TreeData,
  ItemId,
  mutateTree,
  TreeItem,
  TreeSourcePosition,
  moveItemOnTree,
  TreeDestinationPosition,
} from '@atlaskit/tree';
import uuid from 'uuid';
import { createItem, createItemByProductItem, TreeType } from './TreeBuilder';

export function addNodeToTree(tree: TreeData, parentNodeId: ItemId): TreeData {
  const parentNode = tree.items[parentNodeId];

  const entityId = uuid();
  const treeId = `${parentNodeId}-${entityId}`;
  const newNode = createItem(treeId, entityId, TreeType.Assortment, '', null);

  let newTree = { ...tree, items: { ...tree.items, [treeId]: newNode } };
  newTree = mutateTree(newTree, parentNodeId, {
    children: [...parentNode.children, treeId],
    hasChildren: true,
    isExpanded: true,
  });

  return newTree;
}

export function moveNodeOnTree(
  tree: TreeData,
  source: TreeSourcePosition,
  destination: TreeDestinationPosition,
  itemId: ItemId,
) {
  const parentNode = tree.items[destination.parentId];
  const item = tree.items[itemId];
  const existingProductIndex = parentNode.children.findIndex(c => tree.items[c].data.entityId === item.data.entityId);

  // Product already exist in node. Update index and remove from old node.
  if (source.parentId !== destination.parentId && existingProductIndex >= 0) {
    const existingProductId = parentNode.children[existingProductIndex];
    const newChildren = [...parentNode.children];
    newChildren.splice(existingProductIndex, 1);
    newChildren.splice(destination.index || 0, 0, existingProductId);

    const newIndexTree = mutateTree(tree, parentNode.id, { children: newChildren });

    return removeNodeFromTree(newIndexTree, itemId);
  } else {
    const newTree = moveItemOnTree(
      tree,
      { ...source, index: existingProductIndex >= 0 ? existingProductIndex : source.index },
      destination,
    );
    const finalTree = mutateTree(newTree, itemId, {
      data: { ...item.data, parent: destination.parentId },
    });

    return finalTree;
  }
}

export function moveNodesOnTree(
  tree: TreeData,
  source: TreeSourcePosition,
  destination: TreeDestinationPosition,
  itemIds: ItemId[],
) {
  return itemIds.reverse().reduce((accTree, item) => {
    return moveNodeOnTree(accTree, source, destination, item);
  }, tree);
}

export function addProductToTree(
  tree: TreeData,
  parentNodeId: ItemId,
  productItem: TreeItem,
  index: number | undefined = 0,
): TreeData {
  const parentNode = tree.items[parentNodeId];

  const entityId = productItem.data.entityId;
  const treeId = `${parentNodeId}-${entityId}`;
  const newLeaf = createItemByProductItem(treeId, productItem);

  // Product already exist in node. Update index.
  const childrenItems = parentNode.children.map(c => tree.items[c]);
  const existingProducts = childrenItems.filter(c => c.data.entityId === productItem.data.entityId);
  const indexOfExisting = childrenItems.findIndex(c => c.data.entityId === productItem.data.entityId);

  if (indexOfExisting >= 0) {
    if (indexOfExisting === index || index === indexOfExisting + 1) {
      index = indexOfExisting;
    } else if (index > indexOfExisting) {
      index = index - 1;
    }
  }

  const filteredChildren = existingProducts.length
    ? parentNode.children.filter(id => id !== existingProducts[0].id)
    : parentNode.children;

  const newChildren = [...filteredChildren.slice(0, index), treeId, ...filteredChildren.slice(index)];

  let newTree = { ...tree, items: { ...tree.items, [treeId]: newLeaf } };
  newTree = mutateTree(newTree, parentNodeId, {
    children: newChildren,
    hasChildren: true,
    isExpanded: true,
  });

  return newTree;
}

export function addProductsToTree(tree: TreeData, productItems: TreeItem[] = [], index: number | undefined = 0) {
  return productItems.reduce((tree, item) => {
    return addProductToTree(tree, tree.rootId, item, index);
  }, tree);
}

export function removeNodeFromTree(tree: TreeData, itemId: ItemId): TreeData {
  const descendantsIds = [itemId, ..._getDescendantIds(tree.items[itemId], tree)];
  const remainingItems = Object.keys(tree.items).reduce((acc, key) => {
    if (descendantsIds.indexOf(key) === -1) return { ...acc, [key]: tree.items[key] };

    return acc;
  }, {} as Record<ItemId, TreeItem>);

  const parentId = Object.keys(tree.items).find(i => tree.items[i].children.indexOf(itemId) >= 0);

  if (!parentId) return tree;
  const parentRemainingChildren = tree.items[parentId].children.filter(k => k !== itemId);

  return mutateTree({ ...tree, items: remainingItems }, parentId, { children: parentRemainingChildren });
}

export function removeProductFromTree(tree: TreeData, itemId: ItemId): TreeData {
  delete tree.items[itemId];
  const parentId = Object.keys(tree.items).find(i => tree.items[i].children.indexOf(itemId) >= 0);

  if (!parentId) return tree;
  const parentRemainingChildren = tree.items[parentId].children.filter(k => k !== itemId);

  return mutateTree(tree, parentId, { children: parentRemainingChildren });
}

export function removeProductsFromTree(tree: TreeData, itemIds: ItemId[]): TreeData {
  return itemIds.reduce((accTree: TreeData, id: ItemId) => {
    return removeProductFromTree(accTree, id);
  }, tree);
}

export function changeNameOfItemInTree(tree: TreeData, itemId: ItemId, newName: string): TreeData {
  const oldData = tree.items[itemId].data;
  return mutateTree(tree, itemId, { data: { ...oldData, title: newName } });
}

export function toggleCollapseAllInTree(tree: TreeData, shouldCollapse: boolean): TreeData {
  const expandedItems = Object.keys(tree.items).filter(k => {
    return shouldCollapse ? tree.items[k].isExpanded : !tree.items[k].isExpanded;
  });

  let newTree = { ...tree, items: { ...tree.items } };
  expandedItems.forEach(item => {
    newTree = mutateTree(newTree, item, { isExpanded: !shouldCollapse });
  });

  return newTree;
}

export function toggleCollapseIdInTree(tree: TreeData, itemId: ItemId, shouldCollapse: boolean): TreeData {
  return mutateTree(tree, itemId, { isExpanded: !shouldCollapse });
}

function _getDescendantIds(item: TreeItem, tree: TreeData): ItemId[] {
  if (!item.children) return [`${item.id}`];

  const test = item.children.reduce((acc, childId) => {
    const childIds = _getDescendantIds(tree.items[childId], tree);

    return [...acc, ...childIds];
  }, new Array<ItemId>());

  return [...item.children, ...test];
}
