import React, { useState, useEffect } from 'react';
import { InputForm, InputField, StateButton, Select } from 'ui';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { useUsersQuery, UserAssortment } from 'generated/graphql';
import { ApolloError } from 'apollo-boost';

type AssortmentFormProps = {
  currentAssortment: UserAssortment;
  onSubmit: (record: UserAssortment) => void;
  isLoading?: boolean;
  error: ApolloError | undefined;
  isSuccess?: boolean;
};

const AssortmentForm: React.FC<AssortmentFormProps> = ({
  currentAssortment,
  onSubmit,
  isLoading,
  error,
  isSuccess,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputs, setInputs] = useState<UserAssortment>({
    name: currentAssortment.name,
    assortmentId: currentAssortment?.assortmentId,
    users: currentAssortment.users?.map(u => u?._id) || [],
    _id: currentAssortment._id,
  });

  const { data: usersData } = useUsersQuery();

  const userOptions =
    usersData &&
    usersData.users!.map(user => ({
      value: user!._id!,
      label: user!.username!,
    }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs(inputs => ({
      ...inputs,
      [name]: value,
    }));

    setIsEditing(true);
  };

  const handleUserChanged = (values: ValueType<OptionTypeBase>) => {
    setInputs({ ...inputs, users: !values ? [] : values.map((val: OptionTypeBase) => val.value) });
    setIsEditing(true);
  };

  useEffect(() => {
    setInputs({
      name: currentAssortment.name,
      assortmentId: currentAssortment.assortmentId,
      users: currentAssortment.users?.map(u => u?._id) || [],
      _id: currentAssortment._id,
    });
  }, [setInputs, currentAssortment]);

  return (
    <React.Fragment>
      <InputForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit(inputs);
          setIsEditing(false);
        }}
        loading={isLoading}
        error={error}
      >
        <InputField
          name="name"
          placeholder="Name"
          showLabel
          value={(inputs && inputs.name) || ''}
          onChange={handleInputChange}
          className="form-control"
          required
        ></InputField>
        <InputField
          name="assortmentId"
          placeholder="Assortment id"
          showLabel
          value={(inputs && inputs.assortmentId) || ''}
          onChange={handleInputChange}
          className="form-control"
          required
        ></InputField>
        {userOptions && (
          <Select
            isMulti
            label="Users"
            name="users"
            value={userOptions?.filter(option => inputs.users?.includes(option.value))}
            onChange={value => handleUserChanged(value)}
            options={userOptions}
          />
        )}
        <StateButton
          type="submit"
          mode="pill"
          size={35}
          color="green"
          disabled={!isEditing}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={!!error}
          className="float-right my-2"
        >
          Save
        </StateButton>
      </InputForm>
    </React.Fragment>
  );
};

export default AssortmentForm;
