import React from 'react'
import { InputField, Checkbox } from 'ui';
import { NestDataObject } from 'react-hook-form';
import { UpdateUserByIdMutationVariables } from 'generated/graphql';

type Props = {
  defaultValues: Partial<UpdateUserByIdMutationVariables>;
  register: any;
  errors: NestDataObject<IUserInputData>;
}

const FormSectionUserAccount: React.FC<Props> = ({ defaultValues, register, errors }) => {
  return (
    <>
      <InputField
        name="username"
        placeholder="Username"
        showLabel
        defaultValue={defaultValues.username || 'username unknown'}
        ref={register({ required: true })}
        error={errors.username && 'Username required'}
      ></InputField>
      {defaultValues.password !== null && (
        <InputField
          name="password"
          placeholder="Password"
          showLabel
          type="password"
          defaultValue={defaultValues.password}
          ref={register({ required: true })}
          error={errors.password && 'Password required'}
        ></InputField>
      )}
      <InputField
        name="email"
        placeholder="E-mail address"
        showLabel
        defaultValue={defaultValues.email || 'email unknown'}
        ref={register({ required: true })}
        error={errors.email && 'Email required'}
      ></InputField>
      <Checkbox
        name="confirmed"
        label="Confirmed"
        defaultChecked={defaultValues.confirmed!}
        ref={register}
      ></Checkbox>
      <Checkbox
        name="active"
        label="Active"
        defaultChecked={defaultValues.active!}
        ref={register}
      ></Checkbox>
      <Checkbox
        name="isAdmin"
        label="Admin"
        defaultChecked={defaultValues.isAdmin!}
        ref={register}
      ></Checkbox>
    </>
  )
}

export default FormSectionUserAccount
