import React from 'react';
import ReactTable from 'react-table';
import { getSelectedColumns, getRows } from './exportHelpers';
import {
  useSearchProductsTranslatedQuery,
  SearchProductsTranslatedQueryVariables,
  ExportConfigurationQueryVariablesSheetSettings,
} from 'generated/graphql';
import { rowLevels, imageLevels } from './exportConstants';

type ExportPreviewProps = {
  queryVariables: SearchProductsTranslatedQueryVariables;
  sheetSettings: ExportConfigurationQueryVariablesSheetSettings;
};

const ExportPreview: React.FC<ExportPreviewProps> = ({ queryVariables, sheetSettings }) => {
  const { data, loading } = useSearchProductsTranslatedQuery({
    variables: { ...queryVariables!, pageSize: 3 },
    skip: !queryVariables || !queryVariables.assortmentId,
    fetchPolicy: 'cache-and-network',
  });

  const rows = sheetSettings
    ? getRows(
        data?.productTranslatedSearch?.result!,
        sheetSettings!.rowLevel || rowLevels.model,
        sheetSettings!.imageLevel || imageLevels.mainOnly,
        3,
      )
    : [];

  const columns = rows.length
    ? getSelectedColumns(
        rows,
        sheetSettings.languages || ['en'],
        sheetSettings.selectedColumns || [],
        sheetSettings.pricelists,
        sheetSettings.units || [],
      )
    : [];

  return (
    <div className="row mb-4">
      <div className="col col-12">
        <label>Export Preview</label>
        {data && data.productTranslatedSearch && (
          <ReactTable
            loading={loading}
            data={rows}
            columns={columns}
            showPaginationBottom={false}
            defaultPageSize={3}
            getTheadProps={() => ({
              style: {
                boxShadow: 'none',
                borderBottom: '1px solid rgba(0,0,0,0.02)',
                background: 'rgba(0,0,0,0.05)',
              },
            })}
          />
        )}
      </div>
    </div>
  );
};

export default ExportPreview;
