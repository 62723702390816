import React, { useState, useEffect } from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';
import { useLoginMutation } from 'generated/graphql';
import { Alert, StateButton } from 'ui';
import styles from './Login.module.scss';
import logo from 'media/logo.svg';
import * as H from 'history';
import { useUserDataQuery } from 'generated/graphql';

interface MatchParams {}

interface Props extends RouteComponentProps<MatchParams> {
  isLoggedIn: boolean;
}

interface RouteComponentProps<P> {
  match: match<P>;
  location: H.Location;
  history: H.History;
  staticContext?: any;
}

interface match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

const Login: React.FC<Props> = ({ history }) => {
  const { data: userData, loading: userDataLoading, error: userDataError } = useUserDataQuery();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login, { loading, error }] = useLoginMutation();

  useEffect(() => {
    if (userData && userData.isLoggedIn) {
      history.push('/my-applications');
    }
  }, [userData, history]);

  if (userDataLoading) {
    return <div>Loading...</div>;
  }

  if (userDataError) {
    return <div>User error!</div>;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <div className={`bg-gray ${styles.wrapper}`}>
            <div className={`${styles.logowrapper} d-none d-lg-flex`}>
              <div className="animated">
                <div className={`${styles.logo} ${styles.shadowDropCenter} d-none d-lg-flex`}>
                  <img src={logo} alt="NWG Gateway" className="w-100" />
                </div>
              </div>
            </div>
            <div className="row h-100">
              <div className="col col-lg-6 bg-white">
                <div className={`${styles.login} bg-white animated fadeIn`}>
                  <div className="text-center mb-2 d-block d-lg-none">
                    <img src={logo} alt="NWG Gateway" className="mx-auto mb-3" />
                  </div>
                  {error && error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    <Alert message={error.graphQLErrors[0].message} messageType="error" classNames="text-center" />
                  ) : (
                    error && <Alert message="Something went wrong." messageType="error" classNames="text-center" />
                  )}
                  {loading && <div className="mx-auto mb-1 text-center animated flash infinite">Loading...</div>}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      login({
                        variables: {
                          username: username,
                          password: password,
                        },
                      })
                        .then((data) => {
                          if (!data) {
                            return null;
                          }
                          localStorage.setItem('token', data!.data!.login!.record!.token!);
                          client.writeData({
                            data: {
                              isLoggedIn: true,
                              isAdmin: data!.data!.login!.record!.isAdmin || false,
                              userId: data!.data!.login!.record!._id,
                              username: data!.data!.login!.record!.username,
                            },
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="username">Username / Email</label>
                      <input
                        className="form-control mb-2"
                        type="text"
                        name="username"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        className="form-control mb-2"
                        type="password"
                        name="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-3">
                      <StateButton
                        type="submit"
                        size={35}
                        color="primary"
                        className="w-50"
                        isLoading={loading}
                        isError={!!error}
                      >
                        Log in
                      </StateButton>
                      {/* <a href="/" className="mt-1 text-primary ml-auto">
                        Forgot password?
                      </a> */}
                    </div>
                  </form>
                </div>
              </div>
              <div className={`col col-lg-6 d-none d-lg-flex animated fadeIn p-0`}>
                <div className={`${styles.bgPan} w-100 h-100`}></div>
              </div>
            </div>
          </div>
        );
      }}
    </ApolloConsumer>
  );
};

export default Login;
