import React from 'react';
import { View } from 'ui';
import { ServiceContainerTable, CreateServiceContainer } from 'app/components';

const ServiceContainers = () => {
  return (
    <View
      title='Service containers'
      subTitle={'Add or remove services offered from NWG architecture.'}
      iconClass='fab fa-servicestack'
    >
      <ServiceContainerTable />
      <CreateServiceContainer />
    </View>
  );
};

export default ServiceContainers;
