import React from 'react';
import { DropdownMenu, IconWrapper } from 'ui';
import { LANGUAGES } from 'app/constants';
import { useApolloClient } from '@apollo/react-hooks';
import { useDataLanguageQuery } from 'generated/graphql';

type LanguageSelectorProps = {
  color?: string;
  disabled?: boolean;
};
const LanguageSelector: React.FC<LanguageSelectorProps> = ({ color = 'default', disabled = false }) => {
  const client = useApolloClient();
  const { data } = useDataLanguageQuery();

  const languageMenuItems = Object.keys(LANGUAGES).map(key => ({
    title: LANGUAGES[key],
    action: () => client.writeData({ data: { dataLanguage: key } }),
  }));

  return (
    <DropdownMenu closeOnSelect disabled={disabled}>
      {{
        trigger: (
          <>
            <IconWrapper color={color} iconClass="fas fa-language" className="mr-1" />
            {data?.dataLanguage && LANGUAGES[data.dataLanguage]}
          </>
        ),
        items: languageMenuItems,
        alignment: 'left',
      }}
    </DropdownMenu>
  );
};

export default LanguageSelector;
