import React from 'react';
import { CompanyTable, CreateCompany } from 'app/components';
import { View } from 'ui';

const Companies = () => {
  return (
    <View
      title="Companies"
      subTitle={'Add or remove companies used for filtered user access.'}
      iconClass="far fa-building"
    >
      <CompanyTable />
      <CreateCompany />
    </View>
  );
};

export default Companies;
