import React, { useState } from 'react';
import { IconWrapper } from 'ui';
import { useSearchUsersQuery, User } from 'generated/graphql';
import CSSModules from 'react-css-modules';
import styles from './UserSearch.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  keyword: string | undefined;
  setSelectedUser(user: User): void;
  selectedUser?: string;
};

const UserSearch: React.FC<Props> = ({ keyword, setSelectedUser, selectedUser }) => {
  const [isHovered, setIsHovered] = useState<string>();
  const perPage = 25;

  const { fetchMore, data, loading, error } = useSearchUsersQuery({
    variables: {
      keyword: keyword,
      page: 1,
      perPage: perPage,
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {!loading && (
        <InfiniteScroll
          dataLength={data?.userSearch?.items?.length || 0}
          next={() => {
            fetchMore({
              variables: {
                keyword: keyword,
                page: data?.userSearch?.pageInfo.currentPage! + 1,
                perPage: perPage,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;

                return Object.assign({}, prev, {
                  userSearch: {
                    ...prev.userSearch,
                    pageInfo: {
                      ...prev.userSearch?.pageInfo,
                      ...fetchMoreResult.userSearch?.pageInfo,
                    },
                    items: [...(prev.userSearch?.items || []), ...(fetchMoreResult.userSearch?.items || [])],
                  },
                });
              },
            });
          }}
          hasMore={(data?.userSearch?.pageInfo?.hasNextPage && data.userSearch.pageInfo.hasNextPage) || false}
          loader={
            <div className="animated fadeIn py-2">
              <IconWrapper iconClass="far fa-user" className="mr-1" />
              Loading...
            </div>
          }
          endMessage={
            !loading && data?.userSearch?.items && !(data.userSearch.items.length > 0) ? (
              <div className="animated fadeIn py-2">
                <IconWrapper iconClass="fas fa-search" className="mr-1" />
                No users found by keyword "{keyword}".
              </div>
            ) : (
              data?.userSearch?.count &&
              data.userSearch.count > 0 &&
              !(keyword && keyword.length) && (
                <div className="animated fadeIn py-4" styleName="text-complete">
                  <IconWrapper iconClass="fas fa-check" color="default" className="mr-1" />
                  Finished loading {data?.userSearch?.count} users.
                </div>
              )
            )
          }
          scrollableTarget="scrollable"
        >
          {data?.userSearch?.items &&
            data.userSearch.items.map(
              (user) =>
                user && (
                  <div
                    key={user._id}
                    styleName={selectedUser === user._id ? 'searchresult--active' : 'searchresult'}
                    className="animated fadeIn py-2"
                    onClick={() => setSelectedUser(user)}
                    onMouseEnter={() => setIsHovered(user._id)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    <IconWrapper
                      color={user.isAdmin ? 'yellow' : 'default'}
                      iconClass={
                        user.isAdmin
                          ? 'fas fa-crown'
                          : selectedUser === user._id || isHovered === user._id
                          ? 'fas fa-pencil-alt'
                          : 'far fa-user'
                      }
                      className="mr-1"
                    />
                    {user.username}
                  </div>
                ),
            )}
        </InfiniteScroll>
      )}
    </>
  );
};

export default CSSModules(UserSearch, styles);
