import React, { useState } from 'react';
import styles from './Checkbox.module.scss';
import _ from 'lodash';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
};

const Checkbox = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  const { name, label, ...restProps } = props;
  const [id] = useState(_.uniqueId(`${name}-`));

  return (
    <div className="form-group">
      <input
        className={styles.checkbox + ' form-control'}
        id={id}
        type="checkbox"
        name={name}
        ref={ref}
        {...restProps}
      />
      <label htmlFor={id}>{label ? label : name}</label>
    </div>
  );
});

export default Checkbox;
