import React, { useState } from 'react';
import { GET_COMPANIES } from 'graphql/queries';
import { useCreateCompanyMutation, CompaniesQuery } from 'generated/graphql';
import { Card, InputForm, StateButton } from 'ui';

interface ICompany {
  name: string;
  companyCode: string;
}

const CreateCompany = () => {
  const [companyCreate, { data, loading, error }] = useCreateCompanyMutation();
  const [inputs, setInputs] = useState<ICompany>({ name: '', companyCode: '' });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Card title="Add company" className="col col-md-6 col-lg-8 mb-3">
      <InputForm
        className="my-2"
        onSubmit={(e) => {
          e.preventDefault();
          companyCreate({
            variables: {
              name: inputs!.name,
              companyCode: inputs!.companyCode,
            },
            update(cache, { data }) {
              if (!data) return null;

              const getExistingCompanies = cache.readQuery<CompaniesQuery>({
                query: GET_COMPANIES,
              });

              const newCompany = data.companyCreate && data.companyCreate.record;
              if (newCompany) {
                cache.writeQuery<CompaniesQuery>({
                  query: GET_COMPANIES,
                  data: {
                    companies: [newCompany, ...(getExistingCompanies?.companies || [])],
                  },
                });
              }
            },
          });
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                required
                value={(inputs && inputs.name) || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyCode">Company Id</label>
              <input
                className="form-control"
                type="text"
                name="companyCode"
                placeholder="Company code"
                required
                value={(inputs && inputs.companyCode) || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <StateButton
          type="submit"
          size={35}
          color="green"
          className="float-right my-2"
          isLoading={loading}
          isError={!!error}
          isSuccess={!!data}
        >
          Add Company
        </StateButton>
      </InputForm>
    </Card>
  );
};

export default CreateCompany;
