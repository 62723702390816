import gql from 'graphql-tag';

export const GET_USER_DATA = gql`
  query UserData {
    isLoggedIn @client
    isAdmin @client
    userId @client
    username @client
  }
`;
