import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { View, Card, InputField, SplitView, IconWrapper, Button, ToastContentAction } from 'ui';
import {
  UserAssortment,
  useCreateUserAssortmentMutation,
  CreateOneuserAssortmentInput,
  UserAssortmentsQuery,
  useRemoveUserAssortmentMutation,
  useUpdateUserAssortmentMutation,
} from 'generated/graphql';
import AssortmentForm from './AssortmentForm';
import { GET_USER_ASSORTMENTS } from 'graphql/queries/GetUserAssortments';
import AssortmentList from './AssortmentList';
import { Link } from 'react-router-dom';

const Assortments = () => {
  const [filterInput, setFilterInput] = useState<string>('');
  const [selectedAssortment, setSelectedAssortment] = useState<UserAssortment>();
  const [createAssortmentView, setCreateAssortmentView] = useState(false);
  const [isAsc, setIsAsc] = useState(false);

  const [createAssortment, { error: errorCreate, loading: loadingCreate }] = useCreateUserAssortmentMutation();
  const [updateAssortment, { error: errorUpdate, loading: loadingUpdate }] = useUpdateUserAssortmentMutation();
  const [removeAssortment] = useRemoveUserAssortmentMutation();

  const handleRemove = () => {
    if (!selectedAssortment?._id) return;

    removeAssortment({
      variables: {
        id: selectedAssortment?._id,
      },
      update(cache) {
        const cachedAssortments = cache.readQuery<UserAssortmentsQuery>({
          query: GET_USER_ASSORTMENTS,
        });

        if (!cachedAssortments) return;
        const filteredAssortments =
          cachedAssortments.userAssortments?.filter(t => t!._id !== selectedAssortment?._id) || [];

        cache.writeQuery<UserAssortmentsQuery>({
          query: GET_USER_ASSORTMENTS,
          data: { userAssortments: filteredAssortments },
        });
      },
    })
      .then(res => {
        if (!res?.data?.userAssortmentDelete?.record?._id) toast.error('Something went wrong');

        toast.warn(
          <ToastContentAction
            id={selectedAssortment._id}
            text={`Removed ${selectedAssortment.name}!`}
            actionTitle="Undo"
            onAction={id => {
              if (!selectedAssortment.name || !selectedAssortment.assortmentId || selectedAssortment._id !== id) return;
              handleCreateSubmit(
                { ...selectedAssortment, users: selectedAssortment.users?.map(u => u?._id) || [] },
                true,
              );
            }}
          />,
          {
            autoClose: 7000,
            closeButton: false,
          },
        );
      })
      .catch(error => {
        toast.error(error);
      })
      .finally(() => {
        setSelectedAssortment(undefined);
      });
  };

  const handleCreateSubmit = (inputs: UserAssortment, isRecreated: boolean = false) => {
    if (!inputs.name || !inputs.assortmentId) return;

    const newAssortment: CreateOneuserAssortmentInput = {
      name: inputs.name,
      assortmentId: inputs.assortmentId,
      users: inputs.users || [],
    };

    createAssortment({
      variables: { record: newAssortment },
      update(cache, { data }) {
        if (!data || !data.userAssortmentCreate?.record) return;

        const existingAssortments = cache.readQuery<UserAssortmentsQuery>({
          query: GET_USER_ASSORTMENTS,
        });
        if (!existingAssortments || !existingAssortments.userAssortments) return;

        const newAssortment = data.userAssortmentCreate?.record;
        cache.writeQuery<UserAssortmentsQuery>({
          query: GET_USER_ASSORTMENTS,
          data: { userAssortments: [newAssortment, ...existingAssortments.userAssortments] },
        });
      },
    }).then(res => {
      if (res?.data?.userAssortmentCreate?.record?._id) {
        if (!isRecreated) toast.success('Assortment created!');
        setCreateAssortmentView(false);
        setSelectedAssortment(res?.data?.userAssortmentCreate?.record);
      }
    });
  };

  const handleUpdateSubmit = (inputs: UserAssortment) => {
    if (!inputs.name || !inputs.assortmentId) return;

    updateAssortment({
      variables: { record: inputs },
    }).then(res => {
      if (res?.data?.userAssortmentUpdate?.recordId) {
        toast.success('Assortment updated!');
      }
    });
  };

  const actionTitle =
    (selectedAssortment && selectedAssortment.name) ||
    (createAssortmentView && 'Create new assortment') ||
    'Assortment manager';
  const actionIcon =
    (selectedAssortment && 'fas fa-edit') || (createAssortmentView && 'fas fa-plus-square') || 'fas fa-sitemap';

  return (
    <View sleek>
      <SplitView>
        {{
          actionPanel: (
            <>
              <div className="mb-2 d-flex">
                <div
                  className="c-pointer"
                  onClick={() => {
                    setCreateAssortmentView(true);
                    setSelectedAssortment(undefined);
                  }}
                >
                  <IconWrapper iconClass="fas fa-plus" color="green" className="mr-1" /> New assortment
                </div>
                <div className="ml-auto c-pointer" onClick={() => setIsAsc(!isAsc)}>
                  <IconWrapper iconClass={`fas fa-sort-alpha-${isAsc ? 'up' : 'down'}`} color="transparent" />
                </div>
              </div>
              <InputField
                name="filter"
                placeholder="Filter"
                value={filterInput}
                onChange={e => setFilterInput(e.target.value)}
                className="form-control my-2"
              ></InputField>
              <AssortmentList
                filter={filterInput}
                onSelect={a => {
                  setSelectedAssortment(a);
                  setCreateAssortmentView(false);
                }}
                selectedAssortment={selectedAssortment}
                isAsc={isAsc}
              />
            </>
          ),
          actionView: {
            header: {
              title: actionTitle,
              iconClassName: actionIcon,
            },
            body: (
              <>
                {!selectedAssortment && createAssortmentView && (
                  <Card title="Create assortment" className="mb-3">
                    <AssortmentForm
                      currentAssortment={{ assortmentId: '', name: '', users: [], _id: null }}
                      onSubmit={handleCreateSubmit}
                      error={errorCreate}
                      isLoading={loadingCreate}
                    />
                  </Card>
                )}
                {selectedAssortment && (
                  <>
                    <Button size={35} color="primary" className="mb-2 mr-2">
                      <Link
                        style={{ textDecoration: 'none', color: 'white' }}
                        to={`/assortments/${selectedAssortment.assortmentId}`}
                      >
                        Open assortment builder
                      </Link>
                    </Button>
                    <Button size={35} color="red" className="mb-2" onClick={handleRemove}>
                      Delete assortment
                    </Button>
                    <Card title={'Assortment details'}>
                      <AssortmentForm
                        currentAssortment={selectedAssortment}
                        onSubmit={handleUpdateSubmit}
                        error={errorUpdate}
                        isLoading={loadingUpdate}
                      />
                    </Card>
                  </>
                )}
              </>
            ),
          },
        }}
      </SplitView>
    </View>
  );
};

export default Assortments;
