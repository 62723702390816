import gql from 'graphql-tag';

export const GET_APPLICATIONS = gql`
  query Applications {
    applications {
      _id
      name
      userId
      companyCode
      commerceApp {
        name
      }
      commerceAppUser {
        email
      }
      active
      token
      tokenExp
      tokenExpiration
      deletedAt
      user {
        _id
        username
        pricelists {
          pricelist
          companyCode
          currency
        }
      }
      complexityLimit
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
  }
`;
