import React, { useState } from 'react';
import styles from './Assortments.module.scss';
import { IconWrapper } from 'ui';
import { useUserAssortmentsQuery, UserAssortment } from 'generated/graphql';
import CSSModules from 'react-css-modules';

type AssortmentListProps = {
  filter: string;
  onSelect: (assortment: UserAssortment) => void;
  selectedAssortment: UserAssortment | undefined;
  isAsc: boolean;
};
const AssortmentList: React.FC<AssortmentListProps> = ({ filter, onSelect, selectedAssortment, isAsc }) => {
  const { data, loading, error } = useUserAssortmentsQuery();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  let list = data?.userAssortments
    ?.filter((a) => {
      if (!a) return false;
      return (
        !filter ||
        (a.name && a.name.toLowerCase().indexOf(filter) >= 0) ||
        (a.assortmentId && a.assortmentId.toLowerCase().indexOf(filter) >= 0)
      );
    })
    .sort((a, b) => ((a?.name?.toLowerCase() || '') < (b?.name?.toLowerCase() || '') ? -1 : 1));

  if (list && isAsc) list = list.reverse();

  return (
    <>
      {!loading &&
        list?.length &&
        list.map(
          (assortment) =>
            assortment && (
              <Item
                key={assortment._id}
                assortment={assortment}
                onSelect={onSelect}
                isSelected={selectedAssortment === assortment}
              />
            ),
        )}
      {loading && (
        <div className="animated fadeIn py-2">
          <IconWrapper iconClass="far fa-user" className="mr-1" />
          Loading...
        </div>
      )}
    </>
  );
};

type ListItemProps = {
  assortment: UserAssortment;
  onSelect: (assortment: UserAssortment) => void;
  isSelected: boolean;
};
const ListItem: React.FC<ListItemProps> = ({ assortment, onSelect, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      key={assortment._id}
      styleName={`list-item ${isSelected ? 'active' : ''}`}
      className="animated fadeIn py-2"
      onClick={() => onSelect(assortment)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconWrapper
        color={isSelected ? 'blue' : 'default'}
        iconClass={isSelected || isHovered ? 'fas fa-pencil-alt' : 'fas fa-sitemap'}
        className="mr-1"
      />
      {assortment.name}
    </div>
  );
};

const Item = CSSModules(ListItem, styles, { allowMultiple: true });

export default AssortmentList;
