import React from 'react';
import { View, Card, Modal, Button } from 'ui';
import { useModal } from 'app/hooks';
import { CreateUserApplication, ApplicationList } from 'app/components';

const Applications = () => {
  const { modalOpen, setModalOpen } = useModal(false);

  return (
    <>
      <View
        title="My applications"
        subTitle={'This is an overview of your applications and tokens.'}
        iconClass="fas fa-key"
      >
        <Card title="Application keys">
          <ApplicationList />
          <Button size={35} color="white" className="w-100 mt-4" onClick={() => setModalOpen(true)}>
            Create new token <i className="fas fa-plus ml-1"></i>
          </Button>
        </Card>
      </View>

      {modalOpen && (
        <Modal title="Create new application token" onCloseModal={() => setModalOpen(false)} footer={false}>
          <CreateUserApplication callback={() => setModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default Applications;
