// API URL
export const NWG_API_URL = process.env.REACT_APP_NWG_API_URL;
export const NWG_API_WS_URL = process.env.REACT_APP_NWG_API_WS_URL;

// IMAGE BANK URL
export const IMG_HOST_URL = process.env.REACT_APP_NWG_IMAGEBANK_URL;

// Languages
export const LANGUAGES: IStringKVP = {
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  deCH: 'German (Swiss)',
  en: 'English',
  es: 'Spanish',
  fi: 'Finnish',
  fr: 'French',
  frCH: 'French (Swiss)',
  is: 'Icelandic',
  it: 'Italian',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  sv: 'Swedish',
  // ca: 'Catalan',
  // el: 'Greek',
  // hu: 'Hungarian',
  // pt: 'Portuguese',
  // ru: 'Russian',
  // sl: 'Slovenian',
};
