import React from 'react';
import { InputField } from 'ui';
import { NestDataObject } from 'react-hook-form';
import { CreateOnecommerceUserLinkInput, UpdateCommerceUserLinkMutationVariables } from 'generated/graphql';

type Props = {
  defaultValues?: UpdateCommerceUserLinkMutationVariables;
  register: any;
  errors: NestDataObject<CreateOnecommerceUserLinkInput>;
};

const FormSectionDescription: React.FC<Props> = ({ register, errors, defaultValues }) => {
  return (
    <>
      <InputField
        name="name"
        placeholder="Name"
        defaultValue={defaultValues?.name || ''}
        showLabel
        ref={register({ required: true })}
        error={errors?.name && 'Name required'}
      />
    </>
  );
};

export default FormSectionDescription;
