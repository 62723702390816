import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { View, IconWrapper } from 'ui';
import { useUserDataQuery } from 'generated/graphql';
import { ArticleList } from 'app/components';

const Articles = () => {
  const { push } = useHistory();

  const [page, setPage] = useState(1);

  const { data: userData, loading: userDataLoading, error: userDataError } = useUserDataQuery();

  if (userDataLoading) return <div>Loading...</div>;

  if (userDataError) return <div>Error: {userDataError.message}</div>;

  return (
    <View title="Documentation" iconClass="fas fa-book">
      <div className="p-2 p-lg-3">
        <div className="col-12 col-lg-8 mx-auto">
          {userData?.isAdmin && (
            <div className="text-right pb-3">
              <div onClick={() => push('/article-editor')} className="c-pointer d-inline-block">
                <IconWrapper color="green" iconClass="far fa-edit" /> Create new article
              </div>
            </div>
          )}
          <ArticleList page={page} perPage={10} setPage={setPage} published={userData?.isAdmin ? undefined : true} />
        </div>
      </div>
    </View>
  );
};

export default Articles;
