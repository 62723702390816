import React, { ReactNode } from 'react';
import CSSModules from 'react-css-modules';
import styles from './DropdownMenu.module.scss';
import { useComponentVisible } from 'app/hooks';

interface Props {
  children: {
    trigger: ReactNode;
    items: MenuItem[];
    direction?: string;
    alignment?: string;
  };
  closeOnSelect?: boolean;
  disabled?: boolean;
}

type MenuItem = {
  action?: React.MouseEventHandler<HTMLLIElement>;
  title: string;
  iconClass?: string;
};

const DropdownMenu: React.FC<Props> = ({ children, closeOnSelect = false, disabled = false }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <div styleName="menu-container" ref={ref}>
      <div
        className={disabled ? 'o-50 c-default' : ''}
        styleName="menu-trigger"
        onClick={e => {
          if (disabled) return;
          e.stopPropagation();
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        {children.trigger}
      </div>
      {isComponentVisible && (
        <ul
          styleName={`menu-dropdown-${children.direction ? children.direction : 'down'}-${
            children.alignment ? children.alignment : 'right'
          }`}
        >
          {children.items.map((menuItem, i) => (
            <li
              key={i}
              onClick={e => {
                menuItem.action && menuItem.action(e);
                closeOnSelect && setIsComponentVisible(false);
              }}
            >
              {menuItem.iconClass && <i className={menuItem.iconClass} />} {menuItem.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CSSModules(DropdownMenu, styles);
