import React from 'react';
import Select, { components } from 'react-select';
import { Props as SelectProps } from 'react-select/src/Select';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { SelectComponents } from 'react-select/src/components';
import styles from './Select.module.scss';
import CSSModules from 'react-css-modules';

export interface OptionValue {
  value: string;
  label: string;
}

const SelectAllMenuList: React.FC<MenuListComponentProps<OptionValue>> = (props) => {
  const selectedValues = props.getValue();
  const selectAll = selectedValues && (selectedValues as OptionValue[]).length < props.options.length;
  return (
    <>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      <div className="d-flex p-1">
        <span
          onClick={() => {
            if (selectAll) {
              props.setValue(props.options, 'select-option');
            } else {
              props.setValue(null, 'remove-value');
            }
          }}
          style={{ cursor: 'pointer' }}
          className="text-underline text-blue cursor-pointer ml-auto mr-2"
        >
          {selectAll ? 'Select All' : 'Select None'}
        </span>
      </div>
    </>
  );
};

type Props = SelectProps & {
  name: string;
  label?: string | undefined | null;
  selectAll?: boolean;
  color?: string;
};
const ReactSelect: React.FC<Props> = ({
  name,
  label = null,
  selectAll = false,
  compact = false,
  color = 'default',
  ...rest
}) => {
  const selectStyles = {
    input: (provided: Object) => ({
      ...provided,
      height: 30,
      borderColor: 'white',
    }),
    control: (provided: Object) => ({
      ...provided,
      boxShadow: 'none',
      border: '1px solid #cccccc',
      '&:hover,&:focus': {
        boxShadow: '0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03)',
      },
    }),
    menuList: (provided: Object) => ({
      ...provided,
      padding: 0,
    }),
  };

  let components: Partial<SelectComponents<{
    label: string;
    value: string;
  }>> = {};
  if (selectAll) components.MenuList = SelectAllMenuList;

  return (
    <div className={!compact ? 'mb-2' : ''}>
      {label && !compact && (
        <label className="text-capitalize" htmlFor="serviceContainers">
          {label}
        </label>
      )}
      {label && compact && (
        <div styleName={`select-inner-label-${color}`} className="text-uppercase">
          {label}
        </div>
      )}
      <Select
        label={label}
        styles={selectStyles}
        noOptionsMessage={() => 'All options selected'}
        {...rest}
        components={components}
      />
    </div>
  );
};

export default CSSModules(ReactSelect, styles);
