import React, { useEffect } from 'react';
import { CommerceUserLink, CommerceAppUser } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import AsyncSelect from 'react-select/async';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_COMMERCE_APP_USERS_BY_CONTEXT } from 'graphql/queries';

type Props = {
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
  companyCode: string;
  commerceUserLink: CommerceUserLink | undefined;
  contextId: string | null | undefined;
};

const FormSectionCommerceAccountOption: React.FC<Props> = ({
  setValue,
  register,
  companyCode,
  commerceUserLink,
  contextId,
}) => {
  const client = useApolloClient();

  // Reset options if the selected contextId changed
  useEffect(() => {
    setValue('commerceUserId', null, true);
  }, [contextId, companyCode, setValue]);

  /**
   * Set commerceUserId
   * @param option
   */
  const handleChange = (option: ValueType<OptionTypeBase>) => {
    if (!option) {
      setValue('commerceUserId', null, true);
      return;
    }

    setValue('commerceUserId', (option as OptionTypeBase).value, true);
  };

  /**
   * Manually register fields in react-hook-form
   * Fields are also required and will need a field validation trigger in setValue()
   * E.g setValue('name', value, true) where 'true' triggers a field validation
   */
  register({ name: 'commerceUserId' }, { required: !!contextId });

  const fetchCommerceUsers = async (inputValue: string) => {
    const userIds = commerceUserLink?.users?.map((user) => {
      return user?.id || '';
    });

    const variables: any = {
      page: 1,
      pageSize: 10,
      contextId: contextId!,
    };

    if (inputValue) variables.search = inputValue;

    if (userIds) {
      variables.userIds = userIds;
    }

    const res = await client.query({
      query: GET_COMMERCE_APP_USERS_BY_CONTEXT,
      variables: {
        ...variables,
        userIds,
      },
    });

    if (res.data?.commerceAppUsersByContext?.result?.length) {
      return res.data?.commerceAppUsersByContext?.result?.map((user: CommerceAppUser) => ({
        value: `${user?.id!}`,
        label: user?.customerCode + ' - ' + user?.email,
      }));
    }

    return [];
  };

  return (
    <>
      <label>Customer account</label>
      <AsyncSelect
        key={contextId}
        label="Customer account"
        name="userIds"
        loadOptions={fetchCommerceUsers}
        onChange={(value) => handleChange(value)}
        placeholder="Search commerce users"
        defaultOptions
        className="mb-3"
      />
    </>
  );
};

export default FormSectionCommerceAccountOption;
