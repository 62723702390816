import { Dimensions } from 'app/components/ExportProductData/exportHelpers';
import { Translation, TranslationKvp } from 'generated/graphql';

export function isTranslation(arg: any): arg is Translation {
  return arg && arg.en !== undefined;
}

export function isTranslationKvpArray(arg: any): arg is TranslationKvp[] {
  return arg && Array.isArray(arg) && arg[0] && arg[0].translation && arg[0].translation.en !== undefined;
}

export function isDimensions(arg: any): arg is Dimensions {
  return (
    arg &&
    typeof arg === 'object' &&
    (typeof arg.h === 'number' || typeof arg.w === 'number' || typeof arg.l === 'number')
  );
}
