import gql from 'graphql-tag';

export const GET_EXPORT_CONFIGURATIONS = gql`
  query GetExportConfigurations {
    exportConfigurationsByUserId {
      name
      _id
    }
  }
`;
