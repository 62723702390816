import gql from 'graphql-tag';

export const FRAGMENT_SKU_UNIT = gql`
  fragment skuUnit on SkuUnit {
    grossVolume
    netVolume
    grossWeight
    netWeight
    length
    height
    width
    items
    eanCode
  }
`;
