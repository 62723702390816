import gql from 'graphql-tag';

export const GET_USER_BY_ID = gql`
  query UserById($id: MongoID!) {
    userById(_id: $id) {
      _id
      username
      email
      companyIds
      serviceContainerIds
      commerceUserLinkId
      confirmed
      isAdmin
      active
      registeredAt
      lastLogin
      updatedAt
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
  }
`;
