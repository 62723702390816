import React from 'react';
import classnames from 'classnames';

interface Props {
  classNames?: string;
  messageType?: string;
  message?: string;
}

const Alert = (props: Props) => (
  <div
    className={classnames(`mx-auto mb-1 ${props.classNames}`, {
      'text-success animated fadeIn': props.messageType === 'success',
      'text-error animated shake': props.messageType === 'error',
    })}
  >
    <i
      className={classnames(`mx-auto mb-1 ${props.classNames}`, {
        'fas fa-check': props.messageType === 'success',
        'fas fa-exclamation-triangle': props.messageType === 'error',
      })}
    />{' '}
    {props.message}
  </div>
);

export default Alert;
