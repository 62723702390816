import gql from 'graphql-tag';

export const GET_USER_ASSORTMENTS = gql`
  query UserAssortments {
    userAssortments {
      name
      assortmentId
      users {
        _id
      }
      _id
    }
  }
`;
