import React, { useState, useEffect, useReducer } from 'react';
import CSSModule from 'react-css-modules';
import styles from './AssortmentBuilder.module.scss';

import { View, SplitView, StateButton, IconButton } from 'ui';
import {
  useSearchProductsQuery,
  useUserAssortmentsQuery,
  useAssortmentQuery,
  UserAssortment,
  Assortment,
  useAssortmentNodeQuery,
  useDataLanguageQuery,
} from 'generated/graphql';
import { ProductFilter } from 'app/components';
import AssortmentTree from './AssortmentTree';
import { TreeData, ItemId, TreeItem } from '@atlaskit/tree';
import TreeBuilder, { TreeType, createSubTreeFromAssortment, getNodeMap } from './TreeBuilder';
import { toast } from 'react-toastify';
import LanguageSelector from 'ui/LanguageSelector/LanguageSelector';
import { FilterPayload } from 'app/components/ProductFilter/ProductFilter';

const defaultQueryVariables = {
  assortmentId: '',
  companyCode: '',
  page: 1,
  pageSize: 20,
  keyword: '',
  language: 'en',
};

type PreviousTrees = {
  assortment: TreeData | undefined;
  loaded: LoadedProductTrees | undefined;
};

type ProductAddition = {
  [index: string]: TreeItem[];
};

export type LoadedProductTrees = {
  [index: string]: TreeData | null;
};

type Payload = FilterPayload & {
  language?: string;
  page?: number;
  pageSize?: number;
};

function queryVariablesReducer(state: Payload | null, action: { type: 'set'; payload: Payload }): Payload {
  switch (action.type) {
    case 'set':
      return {
        ...defaultQueryVariables,
        ...state,
        ...action.payload,
      };
    default:
      return defaultQueryVariables;
  }
}

const AssortmentBuilder: React.FC<HistoryProps> = ({ match }) => {
  const { data: languageData } = useDataLanguageQuery();

  const [queryVariables, dispatchQueryVariables] = useReducer(queryVariablesReducer, {
    ...defaultQueryVariables,
    language: languageData?.dataLanguage || 'en',
  });
  const [previousTrees, setPreviousTrees] = useState<PreviousTrees>();
  const [trees, setTrees] = useState<TreeData[]>();
  const [assortment, setAssortment] = useState<UserAssortment | null>();
  const [selectedNode, setSelectedNode] = useState<ItemId | null>();
  const [loadedProductTrees, setLoadedProductTrees] = useState<LoadedProductTrees>();
  const [pendingProductAddition, setPendingProductAddition] = useState<ProductAddition | null>();

  const { data: userAssortmentData } = useUserAssortmentsQuery();
  const { data: productData, loading, error, refetch: refetchProducts } = useSearchProductsQuery({
    variables: {
      ...defaultQueryVariables,
      ...queryVariables,
    },
    skip: !queryVariables,
  }); // TODO - add pagination or fetch more button to product result.
  const { data: assortmentData, refetch: refetchAssortment, fetchMore: fetchMoreAssortment } = useAssortmentQuery({
    variables: {
      assortmentId: assortment?.assortmentId!,
      language: languageData?.dataLanguage!,
      page: 1,
      pageSize: 100,
    },
    skip: !assortment?.assortmentId,
  });
  const {
    data: assortmentNodeData,
    loading: assortmentNodeLoading,
    refetch: refetchAssortmentNode,
    fetchMore: fetchMoreAssortmentNode,
  } = useAssortmentNodeQuery({
    variables: {
      id: (trees && `${selectedNode}`) || '',
      language: languageData?.dataLanguage!,
      productPage: 1,
      productPageSize: 100,
    },
    skip: !selectedNode,
  });

  useEffect(() => {
    if (languageData?.dataLanguage && assortment?.assortmentId) {
      dispatchQueryVariables({ type: 'set', payload: { language: languageData.dataLanguage || 'en' } });
      refetchAssortment();
      refetchAssortmentNode();
    }
  }, [languageData, assortment, refetchAssortment, refetchAssortmentNode]);

  useEffect(() => {
    if (assortmentNodeData && assortmentNodeData.assortmentNodeById) {
      const nodeData = assortmentNodeData.assortmentNodeById;
      if (!nodeData.id || (loadedProductTrees && loadedProductTrees[nodeData.id])) return;

      if (nodeData.childProducts?.pageInfo?.hasNextPage) {
        fetchMoreAssortmentNode({
          variables: {
            productPage: nodeData.childProducts?.pageInfo.page + 1,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            return Object.assign({}, prev, {
              assortmentNodeById: {
                ...prev.assortmentNodeById,
                ...fetchMoreResult.assortmentNodeById,
                childProducts: {
                  ...prev.assortmentNodeById?.childProducts,
                  ...fetchMoreResult.assortmentNodeById?.childProducts,
                  result: [
                    ...(prev.assortmentNodeById?.childProducts?.result || []),
                    ...(fetchMoreResult.assortmentNodeById?.childProducts?.result || []),
                  ],
                },
              },
            });
          },
        });
      } else {
        const newSelectedTree = new TreeBuilder(nodeData.id, TreeType.Selected, nodeData.name || '');

        if (pendingProductAddition && pendingProductAddition[newSelectedTree.rootId]) {
          pendingProductAddition[newSelectedTree.rootId].forEach((prod) => {
            newSelectedTree.withLeaf(prod.data.product.productNumber, prod.data.product, TreeType.Selected);
          });
          setPendingProductAddition(null);
        }

        nodeData.childProducts?.result?.forEach((prod) => {
          if (prod?.productNumber) {
            newSelectedTree.withLeaf(prod.productNumber, prod, TreeType.Selected);
          }
        });

        setLoadedProductTrees((l) => ({ ...l, [nodeData.id!]: newSelectedTree.build() }));
      }
    }
  }, [assortmentNodeData, pendingProductAddition, loadedProductTrees, fetchMoreAssortmentNode]);

  useEffect(() => {
    if (queryVariables) {
      refetchProducts({ ...defaultQueryVariables, ...queryVariables });
    }
  }, [queryVariables, refetchProducts]);

  useEffect(() => {
    if (userAssortmentData && match.params.id) {
      const currentAssortment = userAssortmentData.userAssortments?.find((ua) => ua?.assortmentId === match.params.id);
      setAssortment(currentAssortment);
    }
  }, [userAssortmentData, match.params.id]);

  useEffect(() => {
    if (!productData || !productData.productSearch) return;
    if (productData.productSearch.error) {
      toast.error(productData.productSearch.error.message);
      return;
    }

    const products = productData.productSearch?.result || [];

    const productListTree = new TreeBuilder('ProductList', TreeType.Products);
    products.forEach((p) => {
      if (p?.productNumber) {
        productListTree.withLeaf(p.productNumber, p, TreeType.Products);
      }
    });

    setTrees((t) => [
      productListTree.build(),
      t && t[1] ? t[1] : new TreeBuilder('emptyAssortmentTree', TreeType.Assortment).build(),
    ]);
  }, [productData]);

  useEffect(() => {
    if (!assortmentData?.assortment || !assortment?.assortmentId) return;

    if (assortmentData.assortment.pageInfo?.hasNextPage) {
      fetchMoreAssortment({
        variables: {
          page: assortmentData.assortment.pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            assortment: {
              ...prev.assortment,
              ...fetchMoreResult.assortment,
              result: [...(prev.assortment?.result || []), ...(fetchMoreResult.assortment?.result || [])],
            },
          });
        },
      });
    } else {
      const nodes = assortmentData.assortment.result || [];
      const newNodeMap = getNodeMap(nodes, assortment.assortmentId);

      const nodeTree = new TreeBuilder(assortment.assortmentId, TreeType.Assortment);
      (newNodeMap.topNodes as Assortment[]).forEach((topNode) =>
        nodeTree.withSubTree(createSubTreeFromAssortment(topNode, newNodeMap)),
      );

      setTrees((t) => [
        t && t[0] ? t[0] : new TreeBuilder('emptyProductTree', TreeType.Products).build(),
        nodeTree.build(),
      ]);
    }
  }, [assortmentData, assortment, fetchMoreAssortment]);

  const savePreviousTrees = () => {
    const prev = { assortment: trees ? trees[1] : undefined, loaded: loadedProductTrees };
    setPreviousTrees(prev); // TODO - expand to more than one undo by adding to a list instead. Maybe add redo as well.
  };

  const handleAssortmentTreeChanged = (newAssortmentTree: TreeData, skipSave?: boolean) => {
    if (!skipSave) savePreviousTrees();
    const productListTree = trees ? trees[0] : new TreeBuilder('emptyProductTree', TreeType.Products).build();
    const newTrees = [productListTree, newAssortmentTree];
    setTrees(newTrees);
  };

  const handleProductTreePending = (nodeId: string, products: TreeItem[]) => {
    savePreviousTrees();
    setPendingProductAddition({ [nodeId]: products });
  };

  const handleProductTreesChanged = (newProductTrees: LoadedProductTrees) => {
    savePreviousTrees();
    setLoadedProductTrees(newProductTrees);
  };

  const handleSelect = (entityId: string) => {
    setSelectedNode(entityId);
  };

  const handleNodeDeleted = (nodeId: string) => {
    setLoadedProductTrees({ ...loadedProductTrees, [nodeId]: null });
  };

  return (
    <View sleek>
      <SplitView>
        {{
          actionPanel: (
            <>
              <div className="mb-4 d-flex">
                <div className="pr-1 mr-3">
                  <LanguageSelector
                    disabled={!!previousTrees} // TODO - disable on unsaved instead
                    color="blue"
                  />
                </div>
              </div>
              {queryVariables && (
                <div className="mt-2">
                  <label>Filter products</label>
                  <ProductFilter
                    productState={queryVariables}
                    dispatchProductsState={(dispatchAction) =>
                      dispatchQueryVariables({
                        ...dispatchAction,
                        payload: { ...dispatchAction.payload, language: languageData?.dataLanguage || 'en' },
                      })
                    }
                  ></ProductFilter>
                </div>
              )}
            </>
          ),
          actionView: {
            header: {
              title: 'Assortment builder',
              iconClassName: 'fas fa-sitemap',
            },
            body: CSSModule(
              () => (
                <>
                  {queryVariables && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          {trees && (
                            <AssortmentTree
                              trees={trees}
                              title={assortment?.name || ''}
                              onAssortmentChange={handleAssortmentTreeChanged}
                              onProductTreePending={handleProductTreePending}
                              onProductTreesChange={handleProductTreesChanged}
                              onSelect={handleSelect}
                              onNodeDeleted={handleNodeDeleted}
                              productCount={productData?.productSearch?.count}
                              loadedProductTrees={loadedProductTrees}
                              selectedNode={selectedNode}
                              isProductsloading={assortmentNodeLoading}
                            />
                          )}
                        </div>
                      </div>
                      <div styleName="actions" className="fixed-bottom mr-4 mb-3">
                        {previousTrees && (
                          <IconButton
                            iconClass="fas fa-undo"
                            className="my-2 mr-2"
                            size={35}
                            color="default"
                            onClick={() => {
                              const list = trees
                                ? trees[0]
                                : new TreeBuilder('emptyProductTree', TreeType.Products).build();
                              setTrees(previousTrees.assortment ? [list, previousTrees.assortment] : [list]);
                              setLoadedProductTrees(previousTrees.loaded);
                              setPreviousTrees(undefined);
                            }}
                          >
                            Undo
                          </IconButton>
                        )}
                        <StateButton
                          type="submit"
                          mode="pill"
                          color="green"
                          size={35}
                          isLoading={loading}
                          isError={!!error}
                          isSuccess={!previousTrees} // TODO
                          className="my-2"
                        >
                          Save
                        </StateButton>
                      </div>
                    </>
                  )}
                </>
              ),
              styles,
            )(),
          },
        }}
      </SplitView>
    </View>
  );
};

export default AssortmentBuilder;
