import React from 'react';
import { History } from 'history';
import { ApolloConsumer } from '@apollo/react-hooks';
import { Button, IconWrapper, DropdownMenu } from 'ui';
import { NotificationsMenu } from 'app/components';
import CSSModules from 'react-css-modules';
import styles from './AppHeader.module.scss';
import { UserDataQuery } from 'generated/graphql';

interface Props {
  user: UserDataQuery;
  history: History;
}

const AppHeader: React.FC<Props> = ({ user, history }) => {
  const userMenuItems = [
    {
      title: 'Settings',
      action: () => history.push('/usersettings/'),
    },
    {
      title: 'Support',
      action: () => console.log('clicked'),
    },
  ];

  return (
    <nav styleName="header">
      <div styleName="header-content">
        <div styleName="header-right">
          <div className="c-pointer" onClick={() => history.push('/docs')}>
            <IconWrapper iconClass="fas fa-book" /> Docs
          </div>
          <NotificationsMenu />
          <DropdownMenu>
            {{
              trigger: (
                <>
                  <IconWrapper iconClass="far fa-user" className="mr-1" />
                  {user.username} <i className="fas fa-angle-down ml-1"></i>
                </>
              ),
              items: userMenuItems,
            }}
          </DropdownMenu>
        </div>
        <div>
          <ApolloConsumer>
            {(client) => (
              <Button
                size={35}
                color="default"
                onClick={() => {
                  localStorage.clear();
                  client.writeData({
                    data: {
                      isLoggedIn: false,
                      userId: '',
                      username: '',
                    },
                  });
                  client.resetStore();
                }}
              >
                <i className="fas fa-sign-out-alt mr-1" />
                Log out
              </Button>
            )}
          </ApolloConsumer>
        </div>
      </div>
    </nav>
  );
};

export default CSSModules(AppHeader, styles);
