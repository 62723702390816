import gql from 'graphql-tag';

export const CREATED_NOTIFICATIONS = gql`
  subscription CreatedNotifications {
    notificationCreated {
      _id
      author {
        name
      }
      headline
      description
      link
      body
    }
  }
`;
