import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { persistCache } from 'apollo-cache-persist';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { resolvers, typeDefs } from 'graphql/apollo/schema';
import { NWG_API_URL, NWG_API_WS_URL } from 'app/constants';
import { App } from 'app/components';

import localForage from 'localforage';

// Apollo client and cache setup
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'Product':
        return object.__typename + object.productNumber;
      case 'ProductTranslated':
        return object.__typename + object.productNumber;
      case 'ProductResource':
        return object.__typename + object.resourceFileId;
      case 'StringKVP':
        return object.__typename + object.key;
      case 'TranslationKVP':
        return object.__typename + object.key;
      case 'ProductVariation':
        return object.__typename + object.itemNumber;
      case 'ProductVariationTranslated':
        return object.__typename + object.itemNumber;
      case 'Sku':
        return object.__typename + object.sku + '_' + object.company;
      case 'company':
      case 'SortFindManycompanyInput':
        return object.__typename + object.companyCode;
      case 'Assortment':
        return object.__typename + object.id;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: NWG_API_URL,
});

const wsLink = new WebSocketLink(
  new SubscriptionClient(NWG_API_WS_URL, {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
    },
  }),
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link,
  cache: cache,
  resolvers: resolvers,
  typeDefs: typeDefs,
  name: 'NWG Gateway App',
  version: '1.0-beta',
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const defaultData = {
  isLoggedIn: false,
  isAdmin: false,
  userId: '',
  username: '',
  asideMenu: {
    __typename: 'AsideMenu',
    active: false,
  },
  dataLanguage: 'en',
};

cache.writeData({
  data: defaultData,
});

client.onResetStore(() => {
  cache.writeData({ data: defaultData });
});

const setupAndRender = async () => {
  await persistCache({
    cache,
    storage: localForage,
    maxSize: 20971520,
  });

  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.getElementById('root'),
  );
};

setupAndRender();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
