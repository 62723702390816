import React, { useState, useEffect } from 'react';
import 'react-table/react-table.css';
import moment from 'moment';
import {
  View,
  Card,
  Button,
  SplitView,
  InputForm,
  StateButton,
  Checkbox,
  InputField,
  IconWrapper,
  Select,
  Modal,
} from 'ui';
import {
  useUpdateApplicationStatusMutation,
  Application,
  UpdateApplicationStatusMutationVariables,
  useRemoveApplicationMutation,
  PricelistInput,
  Maybe,
} from 'generated/graphql';
import { ApolloError } from 'apollo-boost';
import { toast } from 'react-toastify';
import ApplicationList from './ApplicationList';
import { OptionValue } from 'ui/Select/Select';
import { OptionTypeBase, ValueType } from 'react-select';

function copyToClipBoard(string?: string) {
  if (!string) toast.error('Error - Could not copy token!');
  else {
    navigator.clipboard.writeText(string).then(() => {
      toast.success(`Token copied to clipboard!`);
    });
  }
}

export enum AppFilterOpts {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

const filterOptions = [
  { value: AppFilterOpts.Active, label: 'active' },
  { value: AppFilterOpts.Inactive, label: 'inactive' },
  { value: AppFilterOpts.Removed, label: 'removed' },
];

const Applications = () => {
  const [updateApplication, { error: errorUpdate, loading: loadingUpdate }] = useUpdateApplicationStatusMutation();
  const [selectedApplication, setSelectedApplication] = useState<Partial<Application>>();
  const [filterInput, setFilterInput] = useState<string>('');
  const [isAsc, setIsAsc] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<OptionValue[]>(
    filterOptions.filter((o) => o.value !== AppFilterOpts.Removed),
  );
  const [applicationRemoveById, { data: dataRemoveApp, error: errorRemoveApp }] = useRemoveApplicationMutation();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const handleRemoveAplication = () => {
    setRemoveModalOpen(false);

    if (!selectedApplication || !selectedApplication._id) {
      toast.error('Could not remove application. No id found');
      return;
    }

    applicationRemoveById({
      variables: {
        id: selectedApplication?._id,
      },
    });
  };

  useEffect(() => {
    if (dataRemoveApp) {
      setSelectedApplication(undefined);
      toast.success('Application removed');
    }

    if (errorRemoveApp) {
      toast.error(`Error removing app - ${errorRemoveApp.message}`);
    }
  }, [dataRemoveApp, errorRemoveApp]);

  const isSelectedDeleted = !!selectedApplication?.deletedAt || false;
  const actionIcon = (selectedApplication && 'fas fa-cog') || 'fas fa-key';
  const actionTitle = selectedApplication ? `Edit application: ${selectedApplication.name}` : 'Application manager';

  return (
    <View sleek>
      <SplitView>
        {{
          actionPanel: (
            <>
              <div className="mb-2 d-flex">
                <div className="ml-auto c-pointer" onClick={() => setIsAsc(!isAsc)}>
                  <IconWrapper iconClass={`fas fa-sort-alpha-${isAsc ? 'up' : 'down'}`} color="transparent" />
                </div>
              </div>
              <Select
                innerLabel
                placeholder={'Showing all'}
                label="Filter applications"
                name="filter"
                isMulti
                value={selectedFilters}
                onChange={(val) => setSelectedFilters(val as OptionValue[])}
                options={filterOptions}
              />
              <InputField
                name="search"
                placeholder="Name, username"
                value={filterInput}
                onChange={(e) => setFilterInput(e.target.value)}
                className="form-control my-3"
              ></InputField>
              <ApplicationList
                filter={filterInput}
                onSelect={(a) => {
                  setSelectedApplication(a);
                }}
                selectedApplication={selectedApplication}
                isAsc={isAsc}
                filterOptions={selectedFilters}
              />
            </>
          ),
          actionView: {
            header: {
              title: actionTitle,
              iconClassName: actionIcon,
            },
            body: (
              <>
                {removeModalOpen && (
                  <Modal
                    title="Really remove?"
                    onCloseModal={() => setRemoveModalOpen(false)}
                    onSubmit={handleRemoveAplication}
                  >
                    <p>
                      <strong>Application: {selectedApplication?.name}</strong>
                    </p>
                    Removing this application will make the application token unusable. This action cannot be undone.
                  </Modal>
                )}
                {selectedApplication && (
                  <>
                    <Button
                      size={35}
                      color="primary"
                      className="mb-2 mr-2"
                      onClick={() => copyToClipBoard(selectedApplication.token || undefined)}
                    >
                      Copy token
                    </Button>
                    {!isSelectedDeleted ? (
                      <Button size={35} color="red" className="mb-2" onClick={() => setRemoveModalOpen(true)}>
                        Remove application
                      </Button>
                    ) : (
                      'Removed!'
                    )}
                    <Card title={'Application details'}>
                      <ApplicationForm
                        isDeleted={isSelectedDeleted}
                        selectedApplication={selectedApplication}
                        onSubmit={(inputs) => {
                          updateApplication({
                            variables: inputs,
                          });

                          if (inputs.pricelists) {
                            selectedApplication.pricelists = inputs.pricelists;
                          }
                        }}
                        error={errorUpdate}
                        isLoading={loadingUpdate}
                      />
                      <div className="border-top mt-3 pt-3"></div>
                      <table>
                        <tbody>
                          <tr>
                            <td className="py-1 pr-3">Username</td>
                            <td>{(selectedApplication.user && selectedApplication.user.username) || '-'}</td>
                          </tr>
                          <tr>
                            <td className="py-1 pr-3">Company</td>
                            <td>{selectedApplication.companyCode || '-'}</td>
                          </tr>
                          <tr>
                            <td className="py-1 pr-3">Commerce app</td>
                            <td>{selectedApplication.commerceApp?.name || '-'}</td>
                          </tr>
                          <tr>
                            <td className="py-1 pr-3">Commerce user</td>
                            <td>{selectedApplication.commerceAppUser?.email || '-'}</td>
                          </tr>
                          <tr>
                            <td className="py-1 pr-3">Expiration date</td>
                            <td> {moment(selectedApplication.tokenExp).format('LL')}</td>
                          </tr>
                          {selectedApplication.deletedAt && (
                            <tr>
                              <td className="py-1 pr-3">Deleted</td>
                              <td>{selectedApplication.deletedAt}</td>
                            </tr>
                          )}
                          {selectedApplication.pricelists && (
                            <tr>
                              <td>Pricelists</td>
                              <td className="py-1 pr-3">
                                {selectedApplication.pricelists.map((pl, i) => (
                                  <span key={`${pl?.pricelist}_${pl?.companyCode}_${pl?.currency}`}>
                                    {i > 0 && '; '}
                                    {`${pl?.pricelist}, ${pl?.currency}`}
                                  </span>
                                ))}
                                {!selectedApplication.pricelists.length && <span>-</span>}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Card>
                  </>
                )}
              </>
            ),
          },
        }}
      </SplitView>
    </View>
  );
};

export default Applications;

type ArrayFixUpdateApplicationStatusMutationVariables = Omit<UpdateApplicationStatusMutationVariables, 'pricelists'> & {
  pricelists?: Maybe<Array<Maybe<PricelistInput>>>;
};

type Props = {
  selectedApplication: Partial<Application>;
  onSubmit: (inputs: ArrayFixUpdateApplicationStatusMutationVariables) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  isDeleted?: boolean;
  error?: ApolloError;
};
const ApplicationForm: React.FC<Props> = ({
  selectedApplication,
  onSubmit,
  isLoading,
  isSuccess,
  error,
  isDeleted,
}) => {
  const [inputs, setInputs] = useState<ArrayFixUpdateApplicationStatusMutationVariables>({
    id: selectedApplication._id,
    active: selectedApplication.active,
    complexityLimit: selectedApplication.complexityLimit,
  });
  const [options, setOptions] = useState(
    selectedApplication.user?.pricelists
      ?.filter((pl) => pl?.companyCode === selectedApplication.companyCode)
      .map((pl) => ({
        value: pl?.pricelist || '',
        label: pl?.pricelist || 'N/A',
      })),
  );

  useEffect(() => {
    setInputs({
      id: selectedApplication._id,
      active: selectedApplication.active,
      complexityLimit: selectedApplication.complexityLimit,
      pricelists: selectedApplication.pricelists?.reduce(
        (acc, curr) =>
          curr?.pricelist && curr?.currency && curr?.companyCode
            ? [...acc, { pricelist: curr.pricelist, currency: curr.currency, companyCode: curr.companyCode }]
            : acc,
        new Array<PricelistInput>(),
      ),
    });

    setOptions(
      selectedApplication.user?.pricelists
        ?.filter((pl) => pl?.companyCode === selectedApplication.companyCode)
        .map((pl) => ({
          value: `${pl?.pricelist}|${pl?.currency}|${pl?.companyCode}`,
          label: `${pl?.pricelist}, ${pl?.currency}`,
        })),
    );
  }, [selectedApplication, setOptions]);

  return (
    <>
      <InputForm
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(inputs);
        }}
        loading={isLoading}
        error={error}
      >
        {options?.length !== 0 && (
          <Select
            isMulti
            name="pricelists"
            placeholder="Pricelists"
            label="Pricelists"
            value={
              inputs.pricelists?.map((pl) => ({
                label: `${pl?.pricelist}, ${pl?.currency}`,
                value: `${pl?.pricelist}|${pl?.currency}|${pl?.companyCode}`,
              })) || []
            }
            onChange={(value: ValueType<OptionTypeBase>) => {
              if (!value || !value.length) {
                setInputs({
                  ...inputs,
                  pricelists: [],
                });
              } else {
                const pricelists = value.map((o: OptionTypeBase) => {
                  const splitValue = o.value.split('|');
                  return {
                    pricelist: splitValue[0],
                    currency: splitValue[1],
                    companyCode: selectedApplication.companyCode,
                  };
                });

                setInputs({
                  ...inputs,
                  pricelists: pricelists,
                });
              }
            }}
            options={options}
            className="mb-3"
          />
        )}
        <InputField
          type="number"
          name="complexityLimit"
          placeholder="Complexity limit"
          showLabel
          value={inputs.complexityLimit || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputs({
              ...inputs,
              complexityLimit: +e.currentTarget.value,
            });
          }}
          className="form-control"
          disabled={isDeleted}
        ></InputField>
        <Checkbox
          label="Active"
          name="active"
          checked={inputs.active || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputs({ ...inputs, active: e.currentTarget.checked });
          }}
          disabled={isDeleted}
        />
        <StateButton
          type="submit"
          size={35}
          color="green"
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={!!error}
          className="my-2"
          disabled={isDeleted}
        >
          Save
        </StateButton>
      </InputForm>
    </>
  );
};
