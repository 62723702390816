import React, { useState, useEffect } from 'react';
import { CommerceUserLink, Maybe, useCommerceAppsByCompanyQuery } from 'generated/graphql';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { Select } from 'ui';

type Props = {
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
  unregister: any;
  companyCode: string;
  commerceUserLink: CommerceUserLink | undefined;
};

type Context = {
  contextId?: Maybe<string>;
  name?: Maybe<string>;
  companyCode?: Maybe<string>;
};

const FormSectionCommerceAppOption: React.FC<Props> = ({
  setValue,
  register,
  unregister,
  companyCode,
  commerceUserLink,
}) => {
  const [option, setOption] = useState<any>(null);

  const { data } = useCommerceAppsByCompanyQuery({
    variables: {
      companyCode: companyCode,
    },
    skip: !!commerceUserLink,
  });

  // Reset options if the selected companyCode changed
  useEffect(() => {
    setOption(null);
    setValue('commerceContextId', null, true);

    // Commerce should not be required, even if there's a commerce link attached to the user
    register({ name: 'commerceContextId' }, { required: false });

    // unregister commerceUserId before it's rendered
    // this needs to be done in order for the form validation to be correct if a different company is selected after a commerce app was selected
    unregister('commerceUserId');
  }, [companyCode, register, unregister, setValue]);

  // if !commercelink but user has commerce permissions, render all appcontexts for company instead.
  const contexts: Maybe<Context>[] =
    commerceUserLink?.contexts?.filter((context) => {
      return context?.companyCode === companyCode;
    }) ||
    data?.commerceAppsByCompanyCode ||
    [];

  const options = contexts?.map((context: any) => {
    return {
      value: context?.id || context?.contextId,
      label: context?.name!,
    };
  });

  /**
   * Set commerceContextId
   * @param option
   */
  const handleChange = (option: ValueType<OptionTypeBase>) => {
    if (!option) {
      setValue('commerceContextId', null, true);
      setValue('commerceUserId', null, true);
      setOption(option);
      return;
    }

    setValue('commerceContextId', (option as OptionTypeBase).value, true);
    setOption(option);
  };

  return options?.length ? (
    <>
      <Select
        name="contextId"
        label="Commerce app"
        value={option}
        onChange={(value) => handleChange(value)}
        options={options}
        isClearable
        className="mb-3"
      />
    </>
  ) : null;
};

export default FormSectionCommerceAppOption;
