import React from 'react';
import { Product } from 'generated/graphql';
import CSSModules from 'react-css-modules';
import styles from './ProductBox.module.scss';

interface Props {
  colWidth: number;
  product: Product;
}

const ProductBox: React.FC<Props> = ({ colWidth, product }) => {
  return (
    <div className={`p-0 col-6 col-xl-${colWidth} animated fadeIn d-flex flex-column`}>
      <div className="mr-1 mb-1 py-3 px-1 bg-white br-3">
        <div className="flex-grow-2 d-flex align-items-center">
          <div
            style={{
              backgroundImage: `url(${(product.pictures![0] && product.pictures![0].imageUrl) || ''})`,
              minHeight: `${colWidth > 2 ? '220px' : '85px'}`,
              padding: `${colWidth > 2 ? '3.5em' : '2.5em'}`,
            }}
            styleName="image"
            className="mx-auto my-2"
          ></div>
        </div>
        <div className={`px-2 text-center`}>
          <div className="text-fade text-truncate">{product.productBrand}</div>
          <div className="text-uppercase text-bold text-truncate">{product.productName && product.productName}</div>
          <div className="text-fade">{product.productNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default CSSModules(ProductBox, styles);
