import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    me {
      _id
      username
      email
      companyIds
      serviceContainerIds
      commerceUserLinkId
      confirmed
      active
      isAdmin
      registeredAt
      lastLogin
      updatedAt
      commerceUserLink {
        _id
        name
        contexts {
          contextId
          companyCode
          name
        }
        users {
          id
          email
        }
      }
      pricelists {
        pricelist
        companyCode
        currency
      }
    }
  }
`;
