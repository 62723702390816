import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { ValueType, OptionTypeBase } from 'react-select/src/types';
import { useApolloClient } from '@apollo/react-hooks';
import { CommerceUser, CommerceUserLinkUsers } from 'generated/graphql';
import { GET_COMMERCE_USERS } from 'graphql/queries';
import { ArrayFixUpdateCommerceUserLinkMutationVariables } from './CommerceUserLinkForm';

type Props = {
  defaultValues?: ArrayFixUpdateCommerceUserLinkMutationVariables;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  register: any;
};

const FormSectionCommerceUsers: React.FC<Props> = ({ setValue, register, defaultValues }) => {
  const client = useApolloClient();

  const defaultOptions = defaultValues?.users?.map((user: CommerceUserLinkUsers | undefined | null) => ({
    value: `${user?.id!},${user?.email!}`,
    label: user?.email!,
  }));

  useEffect(() => {
    if (defaultValues) {
      setValue(
        'users',
        defaultValues?.users?.map((user) => {
          return {
            id: user?.id,
            email: user?.email,
          };
        }),
        true,
      );
    }
  }, [defaultValues, setValue]);

  const fetchCommerceUsers = async (inputValue: string) => {
    if (inputValue && inputValue.trim().length < 4) {
      return [];
    }

    const res = await client.query({
      query: GET_COMMERCE_USERS,
      variables: {
        page: 1,
        pageSize: 20,
        search: inputValue,
      },
    });

    if (res.data?.commerceUsers?.result?.length) {
      return res.data?.commerceUsers?.result?.map((user: CommerceUser) => ({
        value: `${user?.id!},${user?.email!}`,
        label: user?.email,
      }));
    }

    return [];
  };

  const handleChange = (values: ValueType<OptionTypeBase>) => {
    if (!values) {
      setValue('users', null, true);
    } else {
      const users = values.map((val: OptionTypeBase) => {
        return {
          id: val.value.split(',')[0],
          email: val.value.split(',')[1],
        };
      });
      return setValue('users', users, true);
    }
  };

  register({ name: 'users' }, { required: true });

  return (
    <>
      <label>Commerce users</label>
      <AsyncSelect
        label="Commerce users"
        name="userIds"
        loadOptions={fetchCommerceUsers}
        onChange={(value) => handleChange(value)}
        placeholder="Search commerce users"
        defaultOptions={defaultOptions}
        defaultValue={defaultOptions}
        cacheOptions
        isMulti
        className="mb-3"
      />
    </>
  );
};

export default FormSectionCommerceUsers;
