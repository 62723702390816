import gql from 'graphql-tag';

export const SEARCH_USERS = gql`
  query SearchUsers($keyword: String, $page: Int!, $perPage: Int!) {
    userSearch(page: $page, perPage: $perPage, sort: _ID_ASC, filter: { usernameRegexp: $keyword }) {
      items {
        _id
        username
        email
        companyIds
        serviceContainerIds
        commerceUserLinkId
        serviceContainers {
          _id
          name
        }
        companies {
          _id
          name
          companyCode
        }
        confirmed
        active
        isAdmin
        registeredAt
        lastLogin
        updatedAt
      }
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
