import React from 'react';
import { Product } from 'generated/graphql';
import CSSModules from 'react-css-modules';
import styles from './ProductBox.module.scss';

interface Props {
  product: Product;
  rightSpace?: number;
}

const ProductListItem: React.FC<Props> = ({ product, rightSpace }) => {
  const style = rightSpace ? { width: `calc(100% - ${rightSpace}px)` } : undefined;

  return (
    <div className={`p-0 animated fadeIn d-flex flex-column`} style={style}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="p-1" styleName="image-container">
          <div
            style={{
              backgroundImage: `url(${(product.pictures &&
                product.pictures[0] &&
                product.pictures[0].imageUrl + '&format=Thumbnail') ||
                ''})`,
            }}
            styleName="image-compact"
          ></div>
        </div>
        <div className={`flex-grow-2 px-2 overflow-hidden`}>
          <div className="text-uppercase text-bold text-truncate">{product.productName && product.productName}</div>
          <div className="text-fade">{product.productNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default CSSModules(ProductListItem, styles);
