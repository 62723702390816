import React, { useState } from 'react';
import { IconButton } from 'ui';

export enum ListType {
  List = 'List',
  CardSmall = 'CardSmall',
  CardLarge = 'CardLarge',
}

const getClass = (listType: ListType) => {
  return {
    [ListType.List]: 'fa-th-list',
    [ListType.CardSmall]: 'fa-th',
    [ListType.CardLarge]: 'fa-th-large',
  }[listType];
};

interface IListTypeSelector {
  onSelect: (listType: ListType) => void;
  current: ListType;
}
const ListTypeSelector: React.FC<IListTypeSelector> = ({ onSelect, current }) => {
  const [showSelector, setShowSelector] = useState(false);

  const toggleSelector = () => {
    setShowSelector(!showSelector);
  };

  const handleSelect = (listType: ListType) => {
    toggleSelector();
    onSelect(listType);
  };

  return (
    <>
      <IconButton onClick={toggleSelector} size={25} iconClass={getClass(current)} className="ml-1 mb-1"></IconButton>
      {showSelector &&
        Object.values(ListType).map(
          value =>
            value !== current && (
              <ListTypeButton key={value} listType={ListType[value]} onSelect={handleSelect}></ListTypeButton>
            ),
        )}
    </>
  );
};

interface IRenderModeButton {
  onSelect: (renderMode: ListType) => void;
  listType: ListType;
  key: string;
}
const ListTypeButton: React.FC<IRenderModeButton> = ({ listType, onSelect }) => {
  return (
    <IconButton
      onClick={() => onSelect(listType)}
      size={25}
      iconClass={getClass(listType)}
      className="ml-1"
    ></IconButton>
  );
};

export default ListTypeSelector;
